import React, { useEffect, useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import SocialFeed from '../components/social/SocialFeed';
import QuickPlay from '../pages/QuickPlay';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Post } from '../types/post';
import { supabase } from '../lib/supabase';
// Import the notifications module to register the global function
import '../lib/supabase/notifications';

interface DashboardProps {
  defaultView?: 'feed' | 'quickplay';
}

const Dashboard: React.FC<DashboardProps> = ({ defaultView = 'quickplay' }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [focusedPost, setFocusedPost] = useState<Post | null>(null);
  const postId = searchParams.get('post');
  const [showQuickPlay, setShowQuickPlay] = useState(false);

  useEffect(() => {
    // Reset any lingering styles
    document.body.style.overflow = 'auto';
    window.scrollTo(0, 0);
    
    // Reset any zoom or scale transforms
    document.body.style.zoom = '1';
    document.body.style.transform = 'none';
    document.documentElement.style.transform = 'none';
    
    // Show social feed if:
    // 1. There's a post ID in the URL
    // 2. The route was accessed directly with a specific state
    // 3. The path includes additional segments
    // 4. The path is /dashboard/feed
    const shouldShowFeed = 
      !!postId || 
      (location.state && !location.state.default) ||
      location.pathname.split('/').length > 2 ||
      location.pathname === '/dashboard/feed';
    
    // Only show QuickPlay if explicitly navigating to it or if it's the default view
    setShowQuickPlay(
      !shouldShowFeed && 
      (location.pathname === '/dashboard/quick-play' || 
       (defaultView === 'quickplay' && location.pathname === '/dashboard'))
    );
  }, [postId, location.pathname, location.state, defaultView]);

  // Force a re-render after initial mount to fix sidebar issue
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowQuickPlay(prev => prev);
    }, 50);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      if (!postId) return;

      try {
        const { data, error } = await supabase
          .from('posts')
          .select(`
            id,
            content,
            media_url,
            likes_count,
            comments_count,
            shares_count,
            created_at,
            users (
              id,
              auth_id,
              full_name,
              avatar_url,
              city
            )
          `)
          .eq('id', postId)
          .single();

        if (error) throw error;
        if (!data) return;

        setFocusedPost({
          id: data.id,
          author: {
            id: data.users.auth_id,
            name: data.users.full_name,
            image:
              data.users.avatar_url ||
              'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
            location: data.users.city,
          },
          content: data.content,
          media: data.media_url,
          likes: data.likes_count,
          comments: data.comments_count,
          shares: data.shares_count,
          timeAgo: data.created_at,
        });
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    if (postId) {
      fetchPost();
      setShowQuickPlay(false); // Ensure QuickPlay is hidden when post ID is present
    }
  }, [postId]);

  // (Optional) Log if the global registerPushToken function is available
    useEffect(() => {
      // Log if the global registerPushToken function is available
      if (typeof window !== 'undefined' && (window as any).registerPushToken) {
        console.log("Global registerPushToken function is available.");
      }
    
      // Call the native onLoginComplete for Android
      if (window.AndroidInterface && typeof window.AndroidInterface.onLoginComplete === 'function') {
        console.log("Calling onLoginComplete on AndroidInterface");
        window.AndroidInterface.onLoginComplete();
      } 
      // Call the native onLoginComplete for iOS via WKWebView
      else if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.onLoginComplete
      ) {
        console.log("Calling onLoginComplete on iOS interface");
        window.webkit.messageHandlers.onLoginComplete.postMessage(null);
      }
    }, []);

  
  return (
    <DashboardLayout>
      <div className="space-y-6 max-w-[1400px] mx-auto min-h-[calc(100vh-9rem)]">
        {showQuickPlay ? <QuickPlay /> : <SocialFeed focusedPost={focusedPost} />}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;