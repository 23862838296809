import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, Briefcase, Trophy, Users, TrendingUp, Building, Shield } from 'lucide-react';
import { getSearchSuggestions } from '@/lib/supabase/search';
import { cn } from '@/lib/utils';

interface SearchSuggestionsProps {
  onClose?: () => void;
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSuggestions = async () => {
      try {
        setIsLoading(true);
        const data = await getSearchSuggestions();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setError('Failed to load suggestions');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestions();
  }, []);

  const handleMemberClick = (memberId: string) => {
    navigate(`/dashboard/members/${memberId}`);
    onClose?.();
  };

  const handleClubClick = (clubId: string) => {
    navigate(`/dashboard/clubs/${clubId}`);
    onClose?.();
  };

  const handlePostClick = (postId: string) => {
    navigate(`/dashboard?post=${postId}`);
    onClose?.();
  };

  const handleSportClick = (sport: { name: string; emoji: string }) => {
    navigate('/dashboard/match', {
      state: {
        searchKey: Date.now(),
        preSelectedSport: `${sport.emoji} ${sport.name}`
      },
      replace: true
    });
  };

  if (isLoading) {
    return (
      <div className="p-4 space-y-4">
        <div className="animate-pulse space-y-4">
          {[1, 2, 3, 4, 5].map((i) => (
            <div key={i} className="h-10 bg-dark-300 rounded-lg" />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <p className="text-dark-800">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-6">
      {/* Popular Members */}
      {suggestions?.popularMembers?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <Users className="h-4 w-4 mr-2" />
            Popular Members
          </h3>
          <div className="space-y-2">
            {suggestions.popularMembers.map((member: any) => (
              <button
                key={member.id}
                onClick={() => handleMemberClick(member.id)}
                className="w-full flex items-center p-2 hover:bg-dark-300 rounded-lg transition-colors"
              >
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-8 h-8 rounded-full object-cover"
                />
                <div className="ml-3 text-left">
                  <div className="text-white text-sm">{member.name}</div>
                  <div className="text-dark-800 text-xs">{member.city}</div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Popular Clubs */}
      {suggestions?.popularClubs?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <Shield className="h-4 w-4 mr-2" />
            Popular Clubs
          </h3>
          <div className="space-y-2">
            {suggestions.popularClubs.map((club: any) => (
              <button
                key={club.id}
                onClick={() => handleClubClick(club.id)}
                className="w-full flex items-center p-2 hover:bg-dark-300 rounded-lg transition-colors"
              >
                <img
                  src={club.logo}
                  alt={club.name}
                  className="w-8 h-8 rounded-full object-cover"
                />
                <div className="ml-3 text-left">
                  <div className="text-white text-sm">{club.name}</div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Active Cities */}
      {suggestions?.cities?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <MapPin className="h-4 w-4 mr-2" />
            Active Cities
          </h3>
          <div className="flex flex-wrap gap-2">
            {suggestions.cities.map((city: string) => (
              <button
                key={city}
                onClick={() => {
                  navigate('/dashboard/facilities', { state: { selectedCity: city } });
                  onClose?.();
                }}
                className="px-3 py-1 bg-dark-300 hover:bg-dark-400 rounded-full text-white text-sm transition-colors"
              >
                {city}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Popular Sports */}
      {suggestions?.sports?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <Trophy className="h-4 w-4 mr-2" />
            Popular Sports
          </h3>
          <div className="flex flex-wrap gap-2">
            {suggestions.sports.map((sport: any) => (
              <button
                key={sport.name}
                onClick={() => {
                  navigate('/dashboard/match', { 
                    state: { selectedSport: `${sport.emoji} ${sport.name}` } 
                  });
                  onClose?.();
                }}
                className="px-3 py-1 bg-dark-300 hover:bg-dark-400 rounded-full text-white text-sm transition-colors"
              >
                {sport.emoji} {sport.name}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Industries */}
      {suggestions?.industries?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <Briefcase className="h-4 w-4 mr-2" />
            Industries
          </h3>
          <div className="flex flex-wrap gap-2">
            {suggestions.industries.map((industry: string) => (
              <button
                key={industry}
                onClick={() => {
                  navigate('/dashboard/match', { state: { searchTerm: industry } });
                  onClose?.();
                }}
                className="px-3 py-1 bg-dark-300 hover:bg-dark-400 rounded-full text-white text-sm transition-colors"
              >
                {industry}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Popular Companies */}
      {suggestions?.popularCompanies?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <Building className="h-4 w-4 mr-2" />
            Popular Companies
          </h3>
          <div className="flex flex-wrap gap-2">
            {suggestions.popularCompanies.map((company: string) => (
              <button
                key={company}
                onClick={() => {
                  // Update the URL with the search query for the selected company.
                  navigate(`/dashboard?search=${encodeURIComponent(company)}`);
                  onClose?.();
                }}
                className="px-3 py-1 bg-dark-300 hover:bg-dark-400 rounded-full text-white text-sm transition-colors"
              >
                {company}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Trending Posts */}
      {suggestions?.trendingPosts?.length > 0 && (
        <div>
          <h3 className="text-dark-800 text-sm font-medium mb-2 flex items-center">
            <TrendingUp className="h-4 w-4 mr-2" />
            Trending Posts
          </h3>
          <div className="space-y-2">
            {suggestions.trendingPosts.map((post: any) => (
              <button
                key={post.id}
                onClick={() => handlePostClick(post.id)}
                className="w-full flex items-start p-2 hover:bg-dark-300 rounded-lg transition-colors"
              >
                <img
                  src={post.author.image}
                  alt={post.author.name}
                  className="w-8 h-8 rounded-full object-cover"
                />
                <div className="ml-3 text-left">
                  <div className="text-white text-sm font-medium">
                    {post.author.name}
                  </div>
                  <div className="text-dark-800 text-xs line-clamp-2">
                    {post.content}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchSuggestions;