import React from 'react';
import { motion } from 'framer-motion';
import { Trophy, Globe, Shield, Crown } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';

interface JoinClubProps {
  onRequestAccess?: () => void;
}

const features = [
  {
    icon: Trophy,
    title: 'Elite Network',
    description: 'Connect with accomplished club members who share your passion for sports, wellness, and private club culture'
  },
  {
    icon: Globe,
    title: 'Global Access',
    description: 'Play at premium clubs and facilities worldwide, from Monte Carlo to Singapore'
  },
  {
    icon: Shield,
    title: 'Verified Members',
    description: 'Every member is carefully vetted to maintain the highest standards'
  }
];

export const JoinClub: React.FC<JoinClubProps> = ({ onRequestAccess }) => {
  const handleClick = () => {
    if (onRequestAccess) {
      onRequestAccess();
    }
  };

  return (
    <section className="relative py-24 overflow-hidden !text-white !fill-white">
      {/* Background with gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-dark-200 to-black" />
      
      {/* Animated background pattern */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0zNiAxOGMtOS45NDEgMC0xOCA4LjA1OS0xOCAxOHM4LjA1OSAxOCAxOCAxOGMxMC4yNiAwIDE4LTguMDU5IDE4LTE4cy03Ljc0LTE4LTE4LTE4em0wIDMyYy03LjczMiAwLTE0LTYuMjY4LTE0LTE0czYuMjY4LTE0IDE0LTE0IDE0IDYuMjY4IDE0IDE0LTYuMjY4IDE0LTE0IDE0eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9nPjwvc3ZnPg==')] animate-[spin_60s_linear_infinite]" />
      </div>

      <div className="relative container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center">
          {/* Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <div className="inline-block p-3 bg-gold-400/10 rounded-2xl mb-6">
              <Crown className="h-8 w-8 !text-gold-400 dark:!text-gold-400" />
            </div>
            <h2 className="text-4xl font-bold mb-6 !text-white dark:!text-white">
              Join the Club
            </h2>
            <p className="text-xl !text-white/80 dark:!text-white/80 max-w-2xl mx-auto">
              Playclub is an invite-only network for private club members and business travelers who prioritize their active and wellness-focused lifestyle.
            </p>
          </motion.div>

          {/* Features Grid */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="group"
              >
                <div className={cn(
                  "p-6 rounded-2xl h-full",
                  "!bg-gradient-to-b from-dark-200 to-dark-300",
                  "border border-dark-300 group-hover:border-gold-400",
                  "transition-all duration-300"
                )}>
                  <div className="p-3 !bg-dark-300 rounded-xl w-12 h-12 mx-auto mb-4 group-hover:scale-110 transition-transform">
                    <feature.icon className="w-6 h-6 !text-gold-400 dark:!text-gold-400" />
                  </div>
                  <h3 className="text-xl font-semibold mb-3 !text-white dark:!text-white">
                    {feature.title}
                  </h3>
                  <p className="!text-white/80 dark:!text-white/80">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* CTA Button */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6 }}
          >
            <Button 
              onClick={handleClick}
              className="bg-gradient-to-r from-gold-400 to-gold-600 text-dark-50 px-8 py-6 text-lg rounded-xl hover:from-gold-500 hover:to-gold-700 transform hover:scale-105 transition-all duration-300"
            >
              Apply for Membership
            </Button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
