import React from 'react';
import { Loader2, X } from 'lucide-react';
import { cn } from '@/lib/utils';

interface DeclineButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isDeclined?: boolean;
  className?: string;
}

const DeclineButton: React.FC<DeclineButtonProps> = ({ 
  onClick, 
  isLoading,
  isDeclined,
  className
}) => (
  <button
    onClick={onClick}
    disabled={isLoading || isDeclined}
    className={cn(
      "flex items-center justify-center gap-2",
      "w-9 h-9 sm:h-10 sm:px-4",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      "rounded-lg transition-all duration-300",
      "transform active:scale-95",
      isDeclined
        ? "bg-red-500/20 text-red-500 border border-red-500/20"
        : "bg-red-500 hover:bg-red-600 text-white",
      className
    )}
  >
    {isLoading ? (
      <>
        <Loader2 className="h-4 w-4 animate-spin" />
        <span className="hidden sm:inline">Declining...</span>
      </>
    ) : isDeclined ? (
      <>
        <X className="h-4 w-4" />
        <span className="hidden sm:inline">Declined</span>
      </>
    ) : (
      <>
        <X className="h-4 w-4" />
        <span className="hidden sm:inline">Decline</span>
      </>
    )}
  </button>
);

export default DeclineButton;