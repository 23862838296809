import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Lock, Eye, EyeOff, ArrowLeft, CheckCircle } from 'lucide-react';
import { Input } from '../../ui/input';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { cn } from '@/lib/utils';

interface PasswordStepProps {
  onNext: (password: string) => Promise<void>;
  onBack: () => void;
  isSubmitting?: boolean;
  error?: string | null;
}

const PasswordStep: React.FC<PasswordStepProps> = ({
  onNext, 
  onBack,
  isSubmitting = false,
  error: serverError = null
}) => {
  //console.log('Rendering PasswordStep:', { isSubmitting, serverError });
  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState<{
    password?: string;
    confirmPassword?: string;
    server?: string;
  }>({});

  const validatePassword = (pass: string) => {
    if (pass.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(pass)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(pass)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(pass)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(pass)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return '';
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    console.log('Password submit attempt');
    
    // Reset errors
    setErrors({});
    
    // Validate password
    const passwordError = validatePassword(password);
    if (passwordError) {
      console.log('Password validation failed:', passwordError);
      setErrors(prev => ({ ...prev, password: passwordError }));
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      console.log('Passwords do not match');
      setErrors(prev => ({ ...prev, confirmPassword: 'Passwords do not match' }));
      return;
    }

    try {
      setIsLoading(true);
      console.log('Submitting password...');
      await onNext(password);
    } catch (error) {
      console.error('Error submitting password:', error);
      setErrors(prev => ({
        ...prev,
        server: error instanceof Error ? error.message : 'Failed to create user profile'
      }));
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full flex flex-col bg-dark-200">
      {/* Sticky Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex-shrink-0 sticky top-0 z-10 bg-dark-200 pt-6 pb-4 border-b border-dark-300"
      >
        <div className="flex items-center space-x-3 px-6">
          <div className="bg-gold-400/30 p-3 rounded-lg">
            <Lock className="h-5 w-5 text-gold-400" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white">Create Password</h3>
            <p className="text-dark-800 text-sm mt-1">Create a strong password to secure your account</p>
          </div>
        </div>
      </motion.div>

      <motion.form 
        onSubmit={handleSubmit} 
        className="flex-1 px-6 py-6"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        <div className="space-y-6 max-w-md mx-auto">
          {/* Server Error Message */}
          {(serverError || errors.server) && (
            <div className="mb-6 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
              <p className="text-red-500 text-sm">{errors.server || serverError}</p>
            </div>
          )}

          {/* Password Field */}
          <div>
            <label className="block text-white/80 mb-2 font-medium">Password</label>
            <div className="relative">
              <Input
                type={showPassword ? 'text' : 'password'}
                name="new-password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={cn(
                  "bg-dark-300/30 text-white pr-10",
                  "h-11 rounded-lg border border-dark-400/30",
                  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gold-400",
                  "focus-visible:border-gold-400",
                  "transition duration-200",
                  errors.password && "border-red-500"
                )}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-dark-800 hover:text-white"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="text-red-500 text-sm mt-1">{errors.password}</p>
            )}
          </div>

          {/* Confirm Password Field */}
          <div>
            <label className="block text-white/80 mb-2 font-medium">Confirm Password</label>
            <div className="relative">
              <Input
                type={showPassword ? 'text' : 'password'}
                name="new-password"
                autoComplete="new-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={cn(
                  "bg-dark-300/30 text-white pr-10",
                  "h-11 rounded-lg border border-dark-400/30",
                  "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gold-400",
                  "focus-visible:border-gold-400",
                  "transition duration-200",
                  errors.confirmPassword && "border-red-500"
                )}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-dark-800 hover:text-white"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
            )}
          </div>
          {/* Password Requirements */}
          <div className="bg-dark-300/50 rounded-xl p-4 border border-dark-400/50">
            <h4 className="text-sm font-medium text-white mb-3">Password Requirements:</h4>
            <ul className="space-y-2 text-sm">
              <li className={cn(
                "flex items-center space-x-2",
                password.length >= 8 ? "text-green-400" : "text-dark-800"
              )}>
                <CheckCircle className="h-4 w-4" />
                <span>At least 8 characters</span>
              </li>
              <li className={cn(
                "flex items-center space-x-2",
                /[A-Z]/.test(password) ? "text-green-400" : "text-dark-800"
              )}>
                <CheckCircle className="h-4 w-4" />
                <span>One uppercase letter</span>
              </li>
              <li className={cn(
                "flex items-center space-x-2",
                /[a-z]/.test(password) ? "text-green-400" : "text-dark-800"
              )}>
                <CheckCircle className="h-4 w-4" />
                <span>One lowercase letter</span>
              </li>
              <li className={cn(
                "flex items-center space-x-2",
                /[0-9]/.test(password) ? "text-green-400" : "text-dark-800"
              )}>
                <CheckCircle className="h-4 w-4" />
                <span>One number</span>
              </li>
              <li className={cn(
                "flex items-center space-x-2",
                /[!@#$%^&*]/.test(password) ? "text-green-400" : "text-dark-800"
              )}>
                <CheckCircle className="h-4 w-4" />
                <span>One special character (!@#$%^&*)</span>
              </li>
            </ul>
          </div>
        </div>
      </motion.form>

      <FloatingNavigation
        onBack={onBack}
        onNext={handleSubmit}
        isNextDisabled={!password || !confirmPassword}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default PasswordStep;