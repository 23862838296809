import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import ReviewList from '../reviews/ReviewList';
import ReviewStatsDisplay from '../reviews/ReviewStats';
import type { Review, ReviewStats } from '../../types/review';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';

interface ReviewsSectionProps {
  memberId: string;
  memberName: string;
  sports: string[];
}

const ReviewsSection: React.FC<ReviewsSectionProps> = ({
  memberId,
  memberName,
  sports,
}) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [stats, setStats] = useState<ReviewStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activityReviews, setActivityReviews] = useState<Review[]>([]);

  useEffect(() => {
    const fetchUserRatings = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Get user's database ID using the auth_id provided by memberId
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', memberId)
          .single();

        if (userError) throw userError;
        if (!userData) throw new Error('User not found');

        // Fetch activity ratings where this user was either player 1 or 2
        const { data: activities, error: activitiesError } = await supabase
          .from('activities')
          .select(`
            id,
            sport,
            date,
            venue_city,
            rating_overall,
            rating_punctuality,
            rating_sportsmanship,
            rating_skill_match,
            rating_communication,
            rating_feedback,
            rating_timestamp,
            player_1:player_1_id(id, auth_id, full_name, avatar_url),
            player_2:player_2_id(id, auth_id, full_name, avatar_url)
          `)
          .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
          .eq('is_rated', true)
          .order('rating_timestamp', { ascending: false });

        if (activitiesError) throw activitiesError;

        // Transform activities into reviews
        const reviews = activities?.map(activity => {
          // Determine if the current user is player 1 or 2
          const isPlayer1 = activity.player_1.id === userData.id;
          const rater = isPlayer1 ? activity.player_2 : activity.player_1;

          return {
            id: activity.id,
            authorId: rater.auth_id,
            authorName: rater.full_name,
            authorImage: rater.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
            targetId: memberId,
            rating: activity.rating_overall,
            content: activity.rating_feedback || '',
            timestamp: activity.rating_timestamp,
            likes: 0,
            sport: activity.sport,
            venue: activity.venue_city,
            isVerified: true
          };
        }) || [];

        setActivityReviews(reviews);

        // Fetch ratings from user_ratings table using maybeSingle()
        const { data: ratingsData, error: ratingsError } = await supabase
          .from('user_ratings')
          .select('*')
          .eq('user_id', userData.id)
          .maybeSingle();

        if (ratingsError) throw ratingsError;

        if (ratingsData) {
          setStats({
            averageRating: ratingsData.overall_rating || 0,
            totalReviews: ratingsData.total_ratings || 0,
            avgPunctuality: ratingsData.avg_punctuality || 0,
            avgSportsmanship: ratingsData.avg_sportsmanship || 0,
            avgSkillMatch: ratingsData.avg_skill_match || 0,
            avgCommunication: ratingsData.avg_communication || 0,
            totalRatings: ratingsData.total_ratings || 0,
          });
        } else {
          // No ratings found: set stats to empty state
          setStats({
            averageRating: 0,
            totalReviews: 0,
            avgPunctuality: 0,
            avgSportsmanship: 0,
            avgSkillMatch: 0,
            avgCommunication: 0,
            totalRatings: 0,
          });
        }
      } catch (error) {
        console.error('Error fetching user ratings:', error);
        setError(error instanceof Error ? error.message : 'Failed to load ratings');
      } finally {
        setIsLoading(false);
      }
    };

    if (memberId) {
      fetchUserRatings();
    }
  }, [memberId]);

  const handleLikeReview = async (reviewId: string) => {
    setReviews(prev =>
      prev.map(review =>
        review.id === reviewId
          ? {
              ...review,
              likes: review.isLiked ? review.likes - 1 : review.likes + 1,
              isLiked: !review.isLiked,
            }
          : review
      )
    );
  };

  const handleReportReview = async (reviewId: string) => {
    console.log('Reporting review:', reviewId);
  };

  return (
    <div className="space-y-6">
      {error ? (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
          <p>{error}</p>
          <button onClick={() => window.location.reload()} className="text-sm underline mt-2 hover:text-red-400">
            Try Again
          </button>
        </div>
      ) : isLoading ? (
        <div className="animate-pulse space-y-4">
          <div className="h-48 bg-dark-300 rounded-xl" />
          <div className="h-24 bg-dark-300 rounded-xl" />
        </div>
      ) : stats && stats.totalRatings > 0 ? (
        <>
          <ReviewStatsDisplay stats={stats} />
          {activityReviews.length > 0 ? (
            <ReviewList
              reviews={activityReviews}
              onLike={handleLikeReview}
              onReport={handleReportReview}
            />
          ) : (
            <div className={cn(
              "text-center py-8 bg-dark-300 rounded-xl border border-dark-400",
              "mt-6"
            )}>
              <p className="text-dark-800">
                No detailed reviews available yet
              </p>
            </div>
          )}
        </>
      ) : (
        <div className={cn(
          "text-center py-12 bg-dark-300 rounded-xl border border-dark-400",
          "animate-in fade-in-50 duration-500"
        )}>
          <Star className="h-12 w-12 text-dark-800 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-white mb-2">No Ratings Yet</h3>
          <p className="text-dark-800 max-w-sm mx-auto">
            Complete activities to receive ratings from other members
          </p>
        </div>
      )}
    </div>
  );
};

export default ReviewsSection;
