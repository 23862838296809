import React, { useState } from 'react';
import { AlertTriangle, Trash2 } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase'; 

const DeleteAccountCard: React.FC = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // <-- New state variable

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    try {
      /* TODO: Add Supabase account deletion logic here
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulated delay
      window.location.href = '/';*/

       // Fetch the authenticated user
      console.log("Fetching authenticated user...");
      const { data: authData, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      const authUserId = authData.user?.id;
      console.log("Authenticated User ID:", authUserId);

      // Show success message and delay redirect
      setSuccessMessage("Account deleted successfully. Redirecting...");
      
      // Call the RPC function passing the authenticated user's ID
      const { data, error } = await supabase.rpc('delete_user_account', { user_id: authUserId });
      if (error) throw error;

      // Sign out locally so we don't keep the stale JWT.
      const { error: signOutError } = await supabase.auth.signOut();
      if (signOutError) {
        // This error object might be logged by Supabase internally,
        // but here you can choose to ignore it.
        console.log('User already deleted; ignoring sign out error.');
      }
      
      console.log("Account deleted successfully");

      setTimeout(() => {
        window.location.href = '/';
      }, 6000); // 6-second delay
      
    } catch (error) {
      console.error('Error deleting account:', error);
      setIsDeleting(false);
      setSuccessMessage("Error deleting your account. Please contatct support info@playclub.ai");
    }
  };

  return (
    <>
      <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-xl font-semibold text-white">Delete Account</h2>
            <p className="text-dark-800 mt-1">
              Permanently delete your account and all associated data
            </p>
          </div>
          <Button 
            onClick={() => {
              setShowConfirmDialog(true);
              setSuccessMessage(''); // Reset previous success message
              setConfirmText('');
            }}
            variant="outline"
            className={cn(
              "border-red-500/50 text-red-500",
              "hover:bg-red-500/10 hover:text-red-400",
              "transition-colors"
            )}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete Account
          </Button>
        </div>
      </div>

      <Dialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <div className="mx-auto w-12 h-12 rounded-full bg-red-500/20 flex items-center justify-center mb-4">
              <AlertTriangle className="h-6 w-6 text-red-500" />
            </div>
            <DialogTitle className="text-center">Delete Account</DialogTitle>
          </DialogHeader>

          {successMessage ? (
          <div className="py-6">
            <div className="space-y-4">
              <div className="bg-dark-300 rounded-xl p-4 border border-dark-400 space-y-2">
                <div className="mt-4 text-center text-green-500 p-4">
                  <p>{successMessage}</p>
                </div>
              </div>
            </div>
          </div> 
          ) : (
            <div className="py-6">
            <div className="space-y-4">
              <div className="bg-dark-300 rounded-xl p-4 border border-dark-400 space-y-2">
                <h4 className="font-medium text-red-500">Warning: This action cannot be undone</h4>
                <p className="text-dark-800 text-sm">
                  Deleting your account will:
                </p>
                <ul className="text-sm space-y-1 text-dark-800 list-disc pl-4">
                  <li>Remove all your personal information</li>
                  <li>Delete all your activities and matches</li>
                  <li>Remove your connections and messages</li>
                  <li>Delete your posts and comments</li>
                  <li>Cancel any active memberships</li>
                </ul>
              </div>

              <div className="space-y-2">
                <label className="text-sm text-dark-800">
                  Type "DELETE" to confirm
                </label>
                <Input
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  placeholder="DELETE"
                  className="bg-dark-300 border-dark-400 text-white"
                />
              </div>
            </div>
            </div>
          )}

          <DialogFooter className="sm:justify-center">
            <div className="flex flex-col sm:flex-row gap-3 w-full sm:w-auto">
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowConfirmDialog(false)}
                className="sm:order-1 border-dark-400"
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={handleDeleteAccount}
                disabled={confirmText !== 'DELETE' || isDeleting}
                className={cn(
                  "bg-red-500 hover:bg-red-600 text-white sm:order-2",
                  "disabled:opacity-50 disabled:cursor-not-allowed"
                )}
              >
                {isDeleting ? 'Deleting Account...' : 'Delete My Account'}
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteAccountCard;