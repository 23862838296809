import React from 'react';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { CheckCircle } from 'lucide-react';
import { Check } from 'lucide-react';

interface AcceptButtonProps {
  onClick: () => void;
  isLoading?: boolean;
  isAccepted?: boolean;
  className?: string;
}

const AcceptButton: React.FC<AcceptButtonProps> = ({ 
  onClick, 
  isLoading,
  isAccepted,
  className 
}) => (
  <button
    onClick={onClick}
    disabled={isLoading || isAccepted}
    className={cn(
      "flex items-center justify-center gap-2",
      "w-9 h-9 sm:h-10 sm:px-4",
      "disabled:opacity-50 disabled:cursor-not-allowed",
      "rounded-lg transition-all duration-300",
      "transform active:scale-95",
      isAccepted
        ? "bg-green-500/20 text-green-500 border border-green-500/20"
        : "bg-green-500 hover:bg-green-600 text-white",
      className
    )}
  >
    {isLoading ? (
      <>
        <Loader2 className="h-4 w-4 animate-spin" />
        <span className="hidden sm:inline">Accepting...</span>
      </>
    ) : isAccepted ? (
      <>
        <CheckCircle className="h-4 w-4" />
        <span className="hidden sm:inline">Accepted</span>
      </>
    ) : (
      <>
        <Check className="h-4 w-4" />
        <span className="hidden sm:inline">Accept</span>
      </>
    )}
  </button>
);

export default AcceptButton;