import { supabase } from '../supabase';
import { Notification } from '../../types/notification';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Retrying operation, ${retries} attempts remaining...`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export async function getNotifications(): Promise<Notification[]> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError || !user) {
      console.log('No active user:', userError?.message || 'User not logged in');
      return [];
    }
    const { data: userData, error: dbError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .maybeSingle();
    if (dbError) {
      console.log('User data error:', dbError?.message || 'User not found');
      return [];
    }
    if (!userData) {
      console.warn('User data not found');
      return [];
    }
    const { data: notifications, error: notificationsError } = await supabase
      .from('notification_details')
      .select(`*`)
      .eq('user_id', userData.id)
      .eq('read_status', false)
      .order('timestamp', { ascending: false });
    if (notificationsError) throw notificationsError;
    console.log('Get notifications: ', notifications);
    return notifications.map(notification => ({
      id: notification.id,
      type: notification.type,
      content: notification.content,
      timestamp: notification.timestamp,
      read_status: notification.read_status,
      user_id: notification.user_id,
      sender_id: notification.sender_id,
      sender: notification.sender ? {
        id: notification.sender_auth_id,
        name: notification.sender_name,
        image: notification.sender_avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
      } : undefined,
      metadata: notification.metadata
    }));
  } catch (error) {
    console.error('Failed to fetch notifications:', error);
    return [];
  }
}

export async function markAsRead(notificationId: string): Promise<void> {
  try {
    const { error } = await supabase
      .from('notifications')
      .update({ read_status: true })
      .eq('id', notificationId);
    console.log('Mark As Read notifications.');
    if (error) throw error;
  } catch (error) {
    console.error('Failed to mark notification as read:', error);
    throw error;
  }
}

export async function markAllAsRead(): Promise<void> {
  try {
    const { data: { user }, error: authError } = await supabase.auth.getUser();
    if (authError) throw authError;
    if (!user) throw new Error('Not authenticated');
    const { data: userData, error: dbError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();
    if (dbError) throw dbError;
    console.log('Mark all as read notifications.');
    const { error } = await supabase
      .from('notifications')
      .update({ read_status: true })
      .eq('user_id', userData.id)
      .eq('read_status', false);
    if (error) throw error;
  } catch (error) {
    console.error('Failed to mark all notifications as read:', error);
    throw error;
  }
}

export async function subscribeToNotifications(callback: (notification: Notification) => void) {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) {
    console.warn('No authenticated user for notification subscription');
    return () => undefined;
  }
  const { data: userData, error: dbError } = await supabase
    .from('users')
    .select('id')
    .eq('auth_id', user.id)
    .single();
  if (dbError) {
    console.error('Error getting user database ID:', dbError);
    return () => undefined;
  }
  if (!userData) {
    console.warn('User data not found for notification subscription');
    return () => undefined;
  }
  const subscription = supabase
    .channel(`notifications:${userData.id}`)
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'notifications',
        filter: `user_id=eq.${userData.id}`
      },
      async (payload) => {
        try {
          let sender;
          if (payload.new.sender_id) {
            const { data: senderData, error: senderError } = await supabase
              .from('users')
              .select('auth_id, full_name, avatar_url')
              .eq('id', payload.new.sender_id)
              .single();
            if (!senderError && senderData) {
              sender = {
                id: senderData.auth_id,
                name: senderData.full_name,
                image: senderData.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
              };
            }
          }
          const notification: Notification = {
            id: payload.new.id,
            type: payload.new.type,
            content: payload.new.content,
            timestamp: payload.new.timestamp,
            read_status: payload.new.read_status,
            user_id: payload.new.user_id,
            sender_id: payload.new.sender_id,
            sender,
            metadata: payload.new.metadata
          };
          // Simply call the callback—duplicate checking is handled by the store
          callback(notification);
        } catch (error) {
          console.error('Error processing new notification:', error);
        }
      }
    )
    .subscribe();
  return () => subscription.unsubscribe();
}

export function getNotificationTitle(type: string): string {
  switch (type) {
    case 'match_request':
      return 'New Match Request';
    case 'match_accepted':
      return 'Match Accepted';
    case 'match_declined':
      return 'Match Declined';
    case 'match_updated':
      return 'Match Updated';
    case 'match_cancelled':
      return 'Match Cancelled';
    case 'match_played':
      return 'Match Completed';
    case 'match_rated':
      return 'Match Rated';
    case 'new_message':
      return 'New Message';
    case 'mention':
      return 'New Mention';
    case 'connection_request':
      return 'New Connection Request';
    case 'connection_accepted':
      return 'Connection Accepted';
    case 'post_like':
      return 'New Post Like';
    case 'post_comment':
      return 'New Comment';
    default:
      return 'New Notification';
  }
}

export function getActionUrl(notification: Notification): string {
  switch (notification.type) {
    case 'match_request':
    case 'match_accepted':
    case 'match_declined':
    case 'match_updated':
    case 'match_cancelled':
    case 'match_played':
    case 'match_rated':
      return '/dashboard/activities';
    case 'new_message':
      return '/dashboard/messages';
    case 'mention':
      return notification.metadata?.postId
        ? `/dashboard?post=${notification.metadata.postId}`
        : '/dashboard';
    case 'connection_request':
    case 'connection_accepted':
      return '/dashboard/profile';
    case 'post_like':
    case 'post_comment':
      return notification.metadata?.postId
        ? `/dashboard?post=${notification.metadata.postId}`
        : '/dashboard';
    default:
      return '/dashboard';
  }
}

export async function addNotification(notification: Notification): Promise<void> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser(); 
    if (userError || !user?.id) {
      console.error('Auth error:', userError);
      throw new Error('Not authenticated');
    }
    const { data: newNotification, error: createError } = await supabase
      .rpc('add_notification_rpc', {
      p_user_id: notification.user_id,
      p_sender_id: notification.sender_id,
      p_type: notification.type,
      p_content: notification.content,
      p_metadata: notification.metadata || {}
    })
    .single();
    
    /* Replaceing with RPC call
      )  .from('notifications')
      .insert({
        user_id: notification.user_id,
        sender_id: notification.sender_id,
        type: notification.type,
        content: notification.content,
        metadata: notification.metadata || {},
        read_status: false,
        timestamp: new Date().toISOString()
      })
      .select()
      .single();
      */
    
    if (createError) {
      console.error('RPC Create notification error:', createError);
      return;
    }
    console.log('Notification created:', newNotification);
  } catch (error) {
    console.error('Failed to create notification:', error);
  }
}

export async function registerPushToken(fcmToken: string): Promise<void> {
  // 1. Check that the user is authenticated
  const { data: { user }, error: authError } = await supabase.auth.getUser();
  if (authError || !user) {
    console.error("User not authenticated, cannot register push token");
    return;
  }

  // 2. Retrieve the app-specific user id from your 'users' table using the auth id
  const { data: userData, error: dbError } = await supabase
    .from("users")
    .select("id")
    .eq("auth_id", user.id)
    .maybeSingle();
  if (dbError || !userData) {
    console.error("Error retrieving user data:", dbError);
    return;
  }

  console.log("Received FCM Token:", fcmToken);
  console.log("UserID for Push Notifications:", userData.id);

  // 3. Upsert the push subscription record into Supabase
  const { data: upsertData, error: upsertError } = await supabase
    .from("push_subscriptions")
    .upsert(
      {
        user_id: userData.id,
        fcm_token: fcmToken,
      },
      {
        onConflict: ["user_id"],
        returning: "representation",
      }
    )
    .select("*");

  if (upsertError) {
    console.error("Upsert error details:", upsertError);
    return;
  }
  console.log("✅ FCM token stored in Supabase:", upsertData);
}

// Attach the function to the global window object so that native code can call it
if (typeof window !== "undefined") {
  (window as any).registerPushToken = async (token: string) => {
    console.log("Global registerPushToken called with token:", token);
    await registerPushToken(token);
  };
}
