import React from 'react';
import { CheckCircle2, MessageCircle, Calendar } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';

interface SuccessStepProps {
  memberName: string;
  selectedSport: string;
  selectedDate: string;
  selectedTime: string;
  onClose: () => void;
  onViewActivities: () => void;
  onStartChat: () => void;
}

const SuccessStep: React.FC<SuccessStepProps> = ({
  memberName,
  selectedSport,
  selectedDate,
  selectedTime,
  onClose,
  onViewActivities,
  onStartChat
}) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  return (
    <div className="p-6">
      <motion.div 
        className="text-center"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        {/* Success Icon */}
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 200 }}
          className={cn(
            "w-20 h-20 mx-auto mb-6 rounded-full flex items-center justify-center",
            isDarkMode ? "bg-green-500/20" : "bg-green-100"
          )}
        >
          <CheckCircle2 className={cn(
            "h-10 w-10",
            isDarkMode ? "text-green-400" : "text-green-600"
          )} />
        </motion.div>

        {/* Success Message */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h3 className={cn(
            "text-2xl font-semibold mb-3",
            isDarkMode ? "text-white" : "text-gray-900"
          )}>
            Match Request Sent!
          </h3>
          <p className={cn(
            "mb-2",
            isDarkMode ? "text-dark-800" : "text-gray-600"
          )}>
            Your request has been sent to {memberName}.
          </p>
          <p className={cn(
            "mb-8",
            isDarkMode ? "text-dark-800" : "text-gray-600"
          )}>
            You'll be notified once they respond.
          </p>
        </motion.div>

        {/* Action Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="flex flex-col gap-3"
        >
          <button
            onClick={onStartChat}
            className={cn(
              "flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-xl transition-colors",
              "bg-gold-400 hover:bg-gold-500 text-dark-50"
            )}
          >
            <MessageCircle className="h-5 w-5" />
            <span>Send Message</span>
          </button>

          <button
            onClick={onViewActivities}
            className={cn(
              "flex items-center justify-center space-x-2 w-full px-6 py-3 rounded-xl transition-colors",
              isDarkMode 
                ? "bg-dark-300 hover:bg-dark-400 text-white"
                : "bg-gray-100 hover:bg-gray-200 text-gray-900"
            )}
          >
            <Calendar className="h-5 w-5" />
            <span>View Activities</span>
          </button>

          <button
            onClick={onClose}
            className={cn(
              "px-6 py-3 rounded-xl transition-colors",
              isDarkMode 
                ? "text-dark-800 hover:text-white hover:bg-dark-300"
                : "text-gray-500 hover:text-gray-700 hover:bg-gray-100"
            )}
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SuccessStep;