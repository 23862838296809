import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import NavigationOptions from './NavigationOptions';
import type { Facility } from '../../types/facility';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';

interface VenueCardProps {
  facility: Facility;
}

const VenueCard: React.FC<VenueCardProps> = ({ facility }) => {
  const navigate = useNavigate();

 const handleClick = async (e: React.MouseEvent) => {
  e.stopPropagation();

  try {
    const { data: clubData, error } = await supabase
      .from('clubs')
      .select('club_id')
      .ilike('club_name', `%${facility.name}%`)
      .maybeSingle();

    if (error) {
      console.error('Supabase error:', error.message);
      return;
    }

    if (!clubData?.club_id) {
      console.warn(`No matching club found for: "${facility.name}"`);
      return;
    }

    navigate(`/dashboard/clubs/${clubData.club_id}`);
  } catch (err) {
    console.error('Unexpected error navigating to club profile:', err);
  }
};


  return (
    <div className="bg-dark-200 rounded-xl overflow-hidden border border-dark-300 hover:border-gold-400 transition-all duration-300">
      {/* Image and Title Section - Clickable */}
      <div 
        onClick={handleClick}
        className="cursor-pointer"
      >
        <div className="relative h-48">
          <img
            src={facility.coverImage}
            alt={facility.name}
            className="w-full h-full object-cover"
          />
          {/* Optional overlay effect on hover */}
          <div className="absolute inset-0 bg-black/0 hover:bg-black/10 transition-colors" />
        </div>
        
        <h3 className={cn(
          "text-xl font-semibold text-white px-6 pt-6 pb-2",
          "hover:text-gold-400 transition-colors"
        )}>
          {facility.name}
        </h3>
      </div>
      
      {/* Non-clickable content */}
      <div className="px-6 pb-6">
        <div className="flex items-center text-dark-800 mb-4">
          <MapPin className="h-4 w-4 mr-2" />
          <span>{facility.address}, {facility.location}</span>
        </div>

        <div className="flex flex-wrap gap-2 mb-4">
          {facility.amenities.map((amenity, index) => (
            <span
              key={index}
              className="px-3 py-1 bg-dark-300 rounded-full text-sm text-white"
            >
              {amenity}
            </span>
          ))}
        </div>

        <NavigationOptions
          venueName={facility.name}
          coordinates={facility.coordinates}
        />
      </div>
    </div>
  );
};

export default VenueCard;