import React from 'react';
import { X, UserPlus, Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { members } from '../../../data/members';
import { useConnectionStore } from '../../../store/connectionStore';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface MutualConnectionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  connectionName: string;
  mutualCount: number;
}

const MutualConnectionsModal: React.FC<MutualConnectionsModalProps> = ({
  isOpen,
  onClose,
  connectionName,
  mutualCount,
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { addConnection, removeConnection, connections } = useConnectionStore();

  // Get random mutual connections from members
  const mutualConnections = members
    .filter((member) => member.id !== '55') // Filter out current user
    .slice(0, mutualCount)
    .map((member) => ({
      ...member,
      isConnected: connections.includes(member.id),
    }));

  const handleConnect = async (memberId: string, memberName: string) => {
    try {
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Add connection
      addConnection(memberId);
    } catch (error) {
      console.error('Failed to connect:', error);
    }
  };

  const handleDisconnect = async (memberId: string) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      removeConnection(memberId);
    } catch (error) {
      console.error('Failed to disconnect:', error);
    }
  };

  const handleProfileClick = (memberId: string) => {
    navigate(`/dashboard/members/${memberId}`);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-dark-900/80 backdrop-blur-sm">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <div
            className={cn(
              'relative w-full max-w-lg sm:max-w-xl rounded-xl sm:rounded-2xl p-4 sm:p-6 border',
              isDarkMode
                ? 'bg-dark-200 border-dark-300'
                : 'bg-white border-gray-200'
            )}
          >
            <button
              onClick={onClose}
              className={cn(
                'absolute top-3 right-3 sm:top-4 sm:right-4 p-2 rounded-lg transition-colors',
                isDarkMode
                  ? 'text-dark-800 hover:text-white hover:bg-dark-400'
                  : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
              )}
            >
              <X className="h-5 w-5" />
            </button>

            <h2
              className={cn(
                'text-lg sm:text-xl font-bold mb-1 sm:mb-2 pr-8',
                isDarkMode ? 'text-white' : 'text-gray-900'
              )}
            >
              Mutual Connections with {connectionName}
            </h2>
            <p
              className={cn(
                'mb-4 sm:mb-6 text-sm sm:text-base',
                isDarkMode ? 'text-dark-800' : 'text-gray-500'
              )}
            >
              {mutualCount} shared connection{mutualCount !== 1 ? 's' : ''}
            </p>

            <div className="space-y-3 sm:space-y-4 max-h-[60vh] overflow-y-auto pr-2">
              {mutualConnections.map((connection) => (
                <div
                  key={connection.id}
                  className={cn(
                    'rounded-lg sm:rounded-xl p-3 sm:p-4 flex items-center space-x-4',
                    isDarkMode
                      ? 'bg-dark-300 hover:bg-dark-400'
                      : 'bg-gray-50 hover:bg-gray-100'
                  )}
                >
                  <button
                    onClick={() => handleProfileClick(connection.id)}
                    className="flex-shrink-0"
                  >
                    <img
                      src={connection.image}
                      alt={connection.name}
                      className={cn(
                        'w-14 h-14 sm:w-16 sm:h-16 rounded-full object-cover border-2 transition-colors',
                        isDarkMode
                          ? 'border-dark-400 hover:border-gold-400'
                          : 'border-gray-200 hover:border-gold-600'
                      )}
                    />
                  </button>
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center justify-between">
                      <div>
                        <button
                          onClick={() => handleProfileClick(connection.id)}
                          className={cn(
                            'font-medium hover:text-gold-400 transition-colors text-sm sm:text-base truncate',
                            isDarkMode ? 'text-white' : 'text-gray-900'
                          )}
                        >
                          {connection.name}
                        </button>
                        <div
                          className={cn(
                            'text-xs sm:text-sm',
                            isDarkMode ? 'text-dark-800' : 'text-gray-500'
                          )}
                        >
                          {connection.jobTitle}
                        </div>
                        <div
                          className={cn(
                            'text-xs sm:text-sm',
                            isDarkMode ? 'text-dark-800' : 'text-gray-500'
                          )}
                        >
                          {connection.company}
                        </div>
                      </div>
                      {connection.isConnected ? (
                        <>
                          {/* Mobile Icon Button */}
                          <button
                            className={cn(
                              'block md:hidden w-8 h-8 flex items-center justify-center rounded-lg',
                              isDarkMode ? 'bg-dark-400' : 'bg-gray-200'
                            )}
                          >
                            <Check
                              className={
                                isDarkMode
                                  ? 'h-4 w-4 text-white'
                                  : 'h-4 w-4 text-gray-700'
                              }
                            />
                          </button>
                          {/* Desktop Button with Text */}
                          <button
                            className={cn(
                              'hidden md:flex items-center space-x-2 px-3 py-1.5 rounded-lg text-sm',
                              isDarkMode
                                ? 'bg-dark-400 text-white'
                                : 'bg-gray-200 text-gray-700'
                            )}
                          >
                            <Check className="h-4 w-4" />
                            <span>Connected</span>
                          </button>
                        </>
                      ) : (
                        <>
                          {/* Mobile Icon Button */}
                          <button
                            onClick={() =>
                              handleConnect(connection.id, connection.name)
                            }
                            className="block md:hidden w-8 h-8 flex items-center justify-center bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors"
                          >
                            <UserPlus className="h-4 w-4" />
                          </button>
                          {/* Desktop Button with Text */}
                          <button
                            onClick={() =>
                              handleConnect(connection.id, connection.name)
                            }
                            className="hidden md:flex items-center space-x-2 px-3 py-1.5 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors text-sm"
                          >
                            <UserPlus className="h-4 w-4" />
                            <span>Connect</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MutualConnectionsModal;
