import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../components/Header';
import Hero from '../components/Hero';
import { Features } from '../components/Features';
import { Stats } from '../components/Stats';
import { JoinClub } from '../components/JoinClub';
import { FloatingCTA } from '../components/FloatingCTA';
import Footer from '../components/Footer';
import LoginModal from '../components/LoginModal';
import { RequestAccessForm } from '../components/forms/request-access/RequestAccessForm';
import { useAuth } from '../contexts/AuthContext';

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRequestAccess, setShowRequestAccess] = useState(false);
  const [memberCount] = useState(10482);
  const [facilityCount] = useState(158);
  const [cityCount] = useState(12);
  const counterRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLoginSuccess = async (email: string, password: string, rememberMe: boolean) => {
    try {
      const { success, error } = await login(email, password);
      if (success) {
        setShowLoginModal(false);
        navigate('/dashboard');
      } else {
        throw new Error(error || 'Invalid email or password');
      }
    } catch (error) {
      throw error;
    }
  };

  const handleRequestAccess = () => {
    setShowRequestAccess(true);
  };

  return (
    <div className="dark dark-mode-lock min-h-screen bg-black text-white">
      <Header
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      <Hero
        onSignIn={() => setShowLoginModal(true)}
        onRequestAccess={handleRequestAccess}
      />
      <Stats
        memberCount={memberCount}
        facilityCount={facilityCount}
        cityCount={cityCount}
        counterRef={counterRef}
      />
      <Features />
      <JoinClub onRequestAccess={handleRequestAccess} />
      <FloatingCTA show={true} />
      <Footer />

      {/* Modals */}
      <LoginModal
        isOpen={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onSuccess={handleLoginSuccess}
        onRequestAccess={handleRequestAccess}
      />
      <RequestAccessForm
        isOpen={showRequestAccess}
        onClose={() => setShowRequestAccess(false)}
      />
    </div>
  );
};

export default LandingPage;
