import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PostCard from './PostCard';
import { Post } from '../../types/post';

interface PostListProps {
  posts: Post[];
  activeFilter: string;
  batchLikes?: Record<string, boolean>;
}

const PostList: React.FC<PostListProps> = ({ posts, activeFilter, batchLikes }) => {
  return (
    <div className="space-y-6">
      <AnimatePresence mode="popLayout">
        {posts.map((post, index) => (
          <motion.div
            key={`post-${post.id}-${index}`}
            layout
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.05, layout: { duration: 0.3 } }}
            className="w-full"
          >
            <PostCard {...post} batchLikes={batchLikes} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default PostList;
