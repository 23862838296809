import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';

interface ActivitySkeletonProps {
  count?: number;
  className?: string;
}

const ActivitySkeleton: React.FC<ActivitySkeletonProps> = ({ 
  count = 3,
  className 
}) => {
  return (
    <div className={cn("space-y-4", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <motion.div
          key={index}
          className={cn(
            "bg-dark-200 rounded-2xl overflow-hidden border border-dark-300",
            "hover:border-gold-400 transition-all duration-300"
          )}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3, delay: index * 0.1 }}
        >
          {/* Image Header */}
          <div className="relative h-72 lg:h-80">
            <div className="w-full h-full bg-dark-300" />
            <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
            
            <div className="absolute bottom-0 left-0 right-0 px-6 pb-6">
              <div className="flex flex-col items-center max-w-4xl mx-auto">
                <div className="h-8 w-64 bg-dark-300 rounded-lg mb-6" />
                <div className="relative">
                  <div className="w-32 h-32 rounded-full bg-dark-300" />
                </div>
              </div>
            </div>
          </div>

          <div className="px-6 pb-8">
            <div className="flex flex-col items-center max-w-4xl mx-auto">
              <div className="flex-1 min-w-0 w-full">
                {/* Player Info */}
                <div className="space-y-3 text-center mt-4">
                  <div className="flex items-center justify-center space-x-2">
                    <div className="h-4 w-4 bg-dark-300 rounded" />
                    <div className="h-4 w-32 bg-dark-300 rounded" />
                  </div>
                  <div className="flex items-center justify-center space-x-2">
                    <div className="h-4 w-4 bg-dark-300 rounded" />
                    <div className="h-4 w-48 bg-dark-300 rounded" />
                  </div>
                </div>

                {/* Sports Tags */}
                <div className="flex flex-wrap justify-center gap-1.5 mt-8">
                  {[1, 2, 3].map((i) => (
                    <div
                      key={i}
                      className="h-8 w-32 bg-dark-300 rounded-full"
                    />
                  ))}
                </div>

                {/* Action Buttons */}
                <div className="flex items-center justify-center gap-2 mt-8 pt-6 border-t border-dark-300">
                  <div className="h-10 w-32 bg-dark-300 rounded-xl" />
                  <div className="h-10 w-32 bg-dark-300 rounded-xl" />
                  <div className="h-10 w-10 bg-dark-300 rounded-xl" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default ActivitySkeleton;