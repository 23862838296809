import React from 'react';
import { cn } from '@/lib/utils';

interface ComboboxInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  error?: boolean;
}

export const ComboboxInput = React.forwardRef<HTMLInputElement, ComboboxInputProps>(
  ({ className, leftIcon, rightIcon, error, ...props }, ref) => {
    return (
      <div className="relative">
        {leftIcon && (
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
            {leftIcon}
          </div>
        )}
        <input
          ref={ref}
          className={cn(
            "w-full rounded-xl text-white",
            "focus:outline-none focus:ring-1 focus:ring-gold-400/50",
            leftIcon && "pl-10",
            rightIcon && "pr-10",
            error && "!border-red-500 focus:ring-red-500/50",
            className
          )}
          {...props}
        />
        {rightIcon && (
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 transition-opacity">
            {rightIcon}
          </div>
        )}
      </div>
    );
  }
);