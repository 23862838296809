import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Activity } from '../../types/activity';
import { updateActivityVenue } from '../../lib/supabase/activities';
import ActivityHeader from './ActivityHeader';
import ActivityDetails from './ActivityDetails';
import ActivityFooter from './ActivityFooter';
import DeclineRequestModal from './modals/DeclineRequestModal';
import { fetchActivityImage } from '@/lib/fetchActivityImage';
import { useTheme } from '@/contexts/ThemeContext';

import { useActivityLoadingStore } from '../../store/activityLoadingStore';
import { cn } from '@/lib/utils';

interface ActivityCardProps {
  activity: Activity;
  isOwnProfile: boolean;
  isPastActivity?: boolean;
  onManageSession: (activity: Activity) => void;
  onAccept: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: (activity: Activity, reason?: string) => void;
  onRate?: (activity: Activity) => void;
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  activity,
  isOwnProfile,
  isPastActivity = false,
  onManageSession,
  onAccept,
  onRematch,
  onDeclineRequest,
  onRate
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [isRated, setIsRated] = useState(activity.isRated || false);
  const [ratingScore, setRatingScore] = useState(activity.rating_overall);
  const [activityImage, setActivityImage] = useState<string | null>(null);
  const loadingStates = useActivityLoadingStore((state) =>
    state.loadingStates[activity.id] || { isAccepting: false, isDeclining: false, isRating: false }
  );

  useEffect(() => {
    setIsRated(activity.isRated || false);

    const hasRatings = activity.rating_overall !== undefined ||
      (activity.rating_punctuality !== undefined &&
        activity.rating_sportsmanship !== undefined &&
        activity.rating_skill_match !== undefined &&
        activity.rating_communication !== undefined);

    if (activity.rating_overall !== undefined) {
      setRatingScore(activity.rating_overall);
    } else if (hasRatings) {
      const avg = (
        (activity.rating_punctuality || 0) +
        (activity.rating_sportsmanship || 0) +
        (activity.rating_skill_match || 0) +
        (activity.rating_communication || 0)
      ) / 4;
      setRatingScore(Number(avg.toFixed(2)));
    } else {
      setRatingScore(undefined);
    }
  }, [activity]);

  // Fetch activity image
  useEffect(() => {
    const loadActivityImage = async () => {
      try {
        const imageUrl = await fetchActivityImage(activity.sport);
        setActivityImage(imageUrl);
      } catch (error) {
        console.warn('[ACTIVITY IMAGE] Failed to load:', error);
        // Use default image on error
        setActivityImage('https://images.unsplash.com/photo-1486218119243-13883505764c?auto=format&fit=crop&w=1600&q=80');
      }
    };
    loadActivityImage();
  }, [activity.sport]);

  const handleProfileClick = () => {
    navigate(`/dashboard/members/${activity.opponent.id}`);
  };

  const handleAccept = () => {
    onAccept(activity);
  };

  const handleDeclineClick = () => {
    setShowDeclineModal(true);
  };

  const handleDeclineConfirm = (reason: string) => {
    setShowDeclineModal(false);
    onDeclineRequest(reason);
  };

  const handleRateClick = () => {
    if (!isRated && onRate) {
      onRate(activity);
    }
  };

  const handleVenueUpdate = async (venue: { name: string; address: string; city: string }) => {
    try {
      await updateActivityVenue(activity.id, venue);
    } catch (err) {
      console.error('Failed to update venue:', err);
    }
  };

  return (
    <>
      <div
        className={cn(
          "relative rounded-2xl overflow-hidden border transition-all duration-300",
          "max-w-5xl mx-auto",
          "bg-dark-200 border-dark-300 hover:border-gold-400 group",
          "dark:bg-dark-200 dark:border-dark-300",
          "light:bg-white light:border-gray-200"
        )}
      >
        {/* Activity Image Header */}
        <div className="relative h-72 lg:h-80">
          <img
            src={activityImage || 'https://images.unsplash.com/photo-1486218119243-13883505764c?auto=format&fit=crop&w=1600&q=80'}
            alt={activity.sport}
            className="w-full h-full object-cover brightness-75"
          />
          <div className={cn(
            "absolute inset-0",
            isDarkMode
              ? [
                  "bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent",
                  "group-hover:via-dark-200/50"
                ]
              : [
                  "bg-gradient-to-t from-white via-white/10 to-transparent",
                  "group-hover:via-white/20"
                ],
            "transition-all duration-300"
          )} />
          
          <div className="absolute bottom-0 left-0 right-0 px-6 pb-6">
            <div className="flex flex-col items-center max-w-4xl mx-auto">
              <button
                onClick={handleProfileClick}
                className={cn(
                  "text-2xl sm:text-3xl lg:text-4xl font-bold mb-6",
                  "bg-gradient-to-r from-white via-gold-200 to-gold-400",
                  "bg-clip-text text-transparent drop-shadow-lg",
                  "tracking-tight",
                  "hover:scale-[1.02] transition-transform duration-200",
                  "focus:outline-none focus:ring-2 focus:ring-gold-400 focus:ring-offset-2 focus:ring-offset-dark-200 rounded-lg"
                )}
              >
                {activity.sport} with {activity.opponent.name}
              </button>
              <div className="relative group">
                <img
                  src={activity.opponent.image}
                  alt={activity.opponent.name}
                  onClick={handleProfileClick}
                  className={cn(
                    "w-32 h-32 rounded-full object-cover",
                    "border-4 border-dark-200 group-hover:border-gold-400",
                    "transition-all duration-300 shadow-xl",
                    "cursor-pointer"
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 pb-8">
          <div className="flex flex-col items-center max-w-4xl mx-auto">
            <div className="flex-1 min-w-0 w-full">
              <ActivityDetails
                activity={activity}
                isPastActivity={isPastActivity}
                onUpdateVenue={handleVenueUpdate}
                sport={activity.sport}
              />

              <ActivityFooter
                activity={activity}
                isOwnProfile={isOwnProfile}
                onManageSession={onManageSession}
                onRematch={onRematch}
                onDeclineRequest={handleDeclineClick}
                onAccept={() => onAccept(activity)}
                onRate={handleRateClick}
                isAccepting={loadingStates.isAccepting}
                isDeclining={loadingStates.isDeclining}
                isRating={loadingStates.isRating}
              />
            </div>
          </div>
        </div>
      </div>

      <DeclineRequestModal
        isOpen={showDeclineModal}
        onClose={() => setShowDeclineModal(false)}
        onConfirm={handleDeclineConfirm}
      />
    </>
  );
};

export default ActivityCard;