import React, { useState } from 'react';
import { Star, ChevronLeft, ChevronRight } from 'lucide-react';
import { VenueApiResult } from '../../../../../types/venue';
import { VenueGallery } from '../gallery/VenueGallery';
import { ReviewsModal } from '../modals/ReviewsModal';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from '@/hooks/useMediaQuery';

interface VenuePhotoProps {
  venue: VenueApiResult;
}

const SWIPE_THRESHOLD = 50;

export const VenuePhoto: React.FC<VenuePhotoProps> = ({ venue }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [showReviews, setShowReviews] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragStart, setDragStart] = useState(0);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const hasPhotos = venue.photos && venue.photos.length > 0;

  const handlePhotoClick = () => {
    if (isMobile) return;
    setShowGallery(true);
  };

  const handleRatingClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowReviews(true);
  };

  const handlePrevious = () => {
    setCurrentIndex(prev => 
      prev === 0 ? venue.photos!.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(prev => 
      prev === venue.photos!.length - 1 ? 0 : prev + 1
    );
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setDragStart(e.touches[0].clientX);
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    const dragEnd = e.changedTouches[0].clientX;
    const delta = dragEnd - dragStart;
    
    if (Math.abs(delta) > SWIPE_THRESHOLD) {
      if (delta > 0) {
        handlePrevious();
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <div className="relative w-full md:w-44 aspect-square md:aspect-auto flex-shrink-0 overflow-hidden cursor-default">
        {hasPhotos && (
          <div 
            onClick={handlePhotoClick}
            className={cn(
              "w-full h-full relative",
              !isMobile && "cursor-pointer group/photo"
            )}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <AnimatePresence initial={false} mode="wait">
              <motion.img
                key={currentIndex}
                src={venue.photos[currentIndex].url}
                alt={venue.name}
                className={cn(
                  "w-full h-full object-cover",
                  !isMobile && "transition-transform duration-300 group-hover/photo:scale-105"
                )}
                initial={{ opacity: 0, x: 0 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              />
            </AnimatePresence>

            {/* Mobile Navigation Controls */}
            {isMobile && venue.photos.length > 1 && (
              <>
                <button
                  onClick={(e) => { e.stopPropagation(); handlePrevious(); }}
                  className="absolute left-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full !bg-black/40 backdrop-blur-sm"
                >
                  <ChevronLeft className="h-4 w-4 !text-white" />
                </button>
                <button
                  onClick={(e) => { e.stopPropagation(); handleNext(); }}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full !bg-black/40 backdrop-blur-sm"
                >
                  <ChevronRight className="h-4 w-4 !text-white" />
                </button>

                {/* Progress Dots */}
                <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
                  {venue.photos.map((_, index) => (
                    <div
                      key={index}
                      className={cn(
                        "w-1.5 h-1.5 rounded-full transition-colors",
                        "!bg-white",
                        index === currentIndex ? "opacity-100" : "opacity-50"
                      )}
                    />
                  ))}
                </div>
              </>
            )}

            {/* Removed dark overlay */}
          </div>
        )}

        {/* Rating badge - Bottom left */}
        {venue.rating > 0 && (
          <div
            onClick={handleRatingClick}
            className={cn(
              "absolute bottom-2 left-2",
              "flex items-center",
              "bg-black/60 backdrop-blur-sm",
              "px-2 py-1 rounded-lg cursor-pointer",
              "hover:bg-black/80 transition-colors"
            )}
            role="button"
            tabIndex={0}
            aria-label="View reviews"
          >
            <Star className="h-4 w-4 text-gold-400 mr-1" />
            <span className="text-white/100 font-medium">
              {venue.rating}
            </span>
            {venue.user_ratings_total && (
              <span className="text-white/80 text-sm ml-1">
                ({venue.user_ratings_total})
              </span>
            )}
          </div>
        )}

        {/* Multiple photos indicator - Bottom right */}
        {!isMobile && venue.photos && venue.photos.length > 1 && (
          <div className={cn(
            "absolute bottom-2 right-2 px-2 py-1",
            "bg-black/60 backdrop-blur-sm",
            "text-white/100 text-xs rounded-lg"
          )}>
            +{venue.photos.length - 1} photos
          </div>
        )}
      </div>

      {/* Photo Gallery Modal - Only on desktop */}
      {!isMobile && hasPhotos && (
        <VenueGallery
          photos={venue.photos}
          isOpen={showGallery}
          onClose={() => setShowGallery(false)}
          venueName={venue.name}
        />
      )}

      {/* Reviews Modal */}
      <ReviewsModal
        venue={venue}
        isOpen={showReviews}
        onClose={() => setShowReviews(false)}
      />
    </>
  );
};
