import React from 'react';
import { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { ChevronDown, Volume2, VolumeX, Play, Pause, Loader2, Video } from 'lucide-react';

const TEXT_COLLAPSE_LENGTH = 180; // Show less text in preview for better readability

interface PostContentProps {
  content: string;
  formattedContent?: React.ReactNode;
  media?: string;
  video?: string;
  endorsements?: string[];
  isSocialFeed?: boolean; // Determines where text should be rendered
  className?: string;
}

const PostContent: React.FC<PostContentProps> = ({
  content,
  formattedContent,
  media,
  video,
  endorsements,
  isSocialFeed,
  className
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isVertical, setIsVertical] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  // Poster URL: if provided via media prop it’s used; otherwise a frame will be captured.
  const [posterUrl, setPosterUrl] = useState<string | undefined>(media);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);

  const shouldCollapse = useMemo(() => content.length > TEXT_COLLAPSE_LENGTH, [content]);
  const displayContent = useMemo(() => {
    if (!shouldCollapse || isExpanded) {
      return formattedContent || content;
    }
    return content.slice(0, TEXT_COLLAPSE_LENGTH) + '...';
  }, [content, formattedContent, shouldCollapse, isExpanded]);

  // IntersectionObserver: pause (and reset) video when offscreen, and play when onscreen.
  useEffect(() => {
    if (!video || !videoRef.current || !videoContainerRef.current) return;
    const videoEl = videoRef.current;
    const containerEl = videoContainerRef.current;

    const handleMetadataLoaded = () => {
      if (!videoEl) return;
      const { videoWidth, videoHeight } = videoEl;
      setIsVertical(videoHeight > videoWidth);
      setIsLoading(false);
    };

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleWaiting = () => setIsLoading(true);
    const handlePlaying = () => setIsLoading(false);

    videoEl.addEventListener('loadedmetadata', handleMetadataLoaded);
    videoEl.addEventListener('play', handlePlay);
    videoEl.addEventListener('pause', handlePause);
    videoEl.addEventListener('waiting', handleWaiting);
    videoEl.addEventListener('playing', handlePlaying);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // If at least 70% of the video is visible, play it
          if (entry.isIntersecting) {
            videoEl.play().catch((err) => console.warn('Autoplay prevented:', err));
          } else {
            // Pause and reset to the beginning when out of view
            videoEl.pause();
            videoEl.currentTime = 0;
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 0.7 }
    );
    observer.observe(containerEl);

    return () => {
      observer.unobserve(containerEl);
      observer.disconnect();
      videoEl.removeEventListener('loadedmetadata', handleMetadataLoaded);
      videoEl.removeEventListener('play', handlePlay);
      videoEl.removeEventListener('pause', handlePause);
      videoEl.removeEventListener('waiting', handleWaiting);
      videoEl.removeEventListener('playing', handlePlaying);
    };
  }, [video]);

  // If no media thumbnail is provided, try to capture a frame from the video.
  useEffect(() => {
    if (media || !videoRef.current) return;
    const videoEl = videoRef.current;
    const handleLoadedData = () => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = videoEl.videoWidth;
        canvas.height = videoEl.videoHeight;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
          const dataURL = canvas.toDataURL('image/png');
          setPosterUrl(dataURL);
        }
      } catch (e) {
        console.error('Error capturing poster frame', e);
      }
    };

    videoEl.addEventListener('loadeddata', handleLoadedData);
    return () => {
      videoEl.removeEventListener('loadeddata', handleLoadedData);
    };
  }, [media]);

  // Toggle play/pause on click.
  const handleVideoClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!videoRef.current) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  // Toggle mute.
  const toggleMute = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!videoRef.current) return;
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  return (
    <div className={cn("space-y-4", className)}>
      {/* Render media or video */}
      {(media || video) && (
        <motion.div
          ref={videoContainerRef}
          className={cn(
            "relative overflow-hidden group -mx-4 sm:mx-0",
            "max-w-2xl mx-auto" // Center and limit width on larger screens
          )}
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.2 }}
        >
          {video ? (
            <div
              className={cn(
                "relative flex items-center justify-center",
                "aspect-square", // Force square aspect ratio
                "bg-black", // Fallback background
                "overflow-hidden",
                "sm:rounded-xl" // Rounded corners on desktop
              )}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              {/* Optionally show a loading skeleton while video metadata loads */}
              {isLoading && (
                <div className="absolute inset-0 bg-dark-300">
                  <motion.div 
                    className="absolute inset-0 bg-dark-400/50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
                  />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="space-y-4 w-full max-w-sm px-4">
                      <div className="aspect-video w-full bg-dark-400/50 rounded-lg overflow-hidden">
                        <motion.div
                          className="w-full h-full bg-gradient-to-r from-dark-400/0 via-dark-400/20 to-dark-400/0"
                          initial={{ x: "-100%" }}
                          animate={{ x: "100%" }}
                          transition={{ repeat: Infinity, duration: 1.5, ease: "linear" }}
                        />
                      </div>
                      <div className="flex items-center justify-between">
                        <div className="w-24 h-2 bg-dark-400/50 rounded-full" />
                        <div className="w-8 h-8 bg-dark-400/50 rounded-full" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <video
                ref={videoRef}
                src={video}
                poster={posterUrl} // Show the poster until playback starts
                autoPlay           // Auto play when in view (as managed by the observer)
                playsInline        // Inline playback on iOS
                crossOrigin="anonymous" // Allow frame capture (if CORS headers permit)
                {...{ 'webkit-playsinline': 'true' }} // For extra iOS support
                className={cn(
                  "w-full h-full",
                  isVertical
                    ? ["object-cover object-center", "scale-[1.5] sm:scale-[1.2]", "origin-center"]
                    : ["object-cover"],
                  "transition-all duration-300 cursor-pointer",
                  "sm:max-h-[600px]"
                )}
                onClick={handleVideoClick}
                muted={isMuted}
                loop
                preload="metadata"
              >
                <source src={video} type="video/mp4" />
                <source src={video} type="video/webm" />
                Your browser does not support the video tag.
              </video>
              <div className={cn("absolute bottom-4 right-4 flex items-center space-x-2", "transition-opacity duration-200")}>
                <button
                  onClick={toggleMute}
                  className={cn(
                    "p-1 rounded-lg",
                    "bg-dark-900/60 hover:bg-dark-900/80",
                    "text-white transition-colors",
                    "backdrop-blur-sm",
                    "transform hover:scale-105 active:scale-95",
                    "shadow-lg"
                  )}
                >
                  {isMuted ? <VolumeX className="h-3.5 w-3.5" /> : <Volume2 className="h-3.5 w-3.5" />}
                </button>
              </div>
            </div>
          ) : (
            <img
              src={media}
              alt="Post content"
              className={cn(
                "w-full h-full aspect-square object-cover object-center",
                "transition-opacity duration-300",
                "group-hover:opacity-95",
                "sm:rounded-xl",
                "sm:max-h-[600px]"
              )}
              loading="lazy"
            />
          )}
        </motion.div>
      )}

      {/* Render text below media/video */}
      {(!video || isSocialFeed) && (
        <div className={cn("relative", (!media && !video) || isSocialFeed ? "px-4" : "px-0")}>
          <div className="space-y-2">
            <p className="text-white text-sm sm:text-base leading-relaxed">{displayContent}</p>
            {shouldCollapse && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="flex items-center space-x-1 text-dark-800 hover:text-gold-400 transition-colors text-sm"
              >
                <span>{isExpanded ? "Show less" : "Read more"}</span>
                <ChevronDown className={cn("h-4 w-4 transition-transform duration-200", isExpanded && "rotate-180")} />
              </button>
            )}
          </div>
        </div>
      )}

      {endorsements && endorsements.length > 0 && (
        <div className={cn(
          "flex flex-wrap gap-2 mt-3",
          "bg-dark-300 rounded-xl p-4",
          (!media && !video) || isSocialFeed ? "mx-4 sm:mx-4" : "mx-4 sm:mx-0"
        )}>
          {endorsements.map((endorsement, index) => (
            <span key={index} className="px-3 py-1.5 bg-dark-400 rounded-full text-sm text-white">
              {endorsement}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default PostContent;
