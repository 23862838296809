import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ChatHeader from './ChatHeader';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { useConnection } from '../../hooks/useConnection';
import { useConnectionStore } from '../../store/connectionStore';
import { Message, Conversation } from '../../types/chat';
import { BlockUserModal } from '../chat/modals/BlockUserModal';
import { BlockSuccess } from '../chat/modals/BlockSuccess';
import { ReportUserModal } from '../chat/modals/ReportUserModal';
import { ReportSuccess } from '../chat/modals/ReportSuccess';
import { supabase } from '../../lib/supabase';
import { cn } from '@/lib/utils';

interface ChatWindowProps {
  conversation?: Conversation;
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  messageInput: string;
  setMessageInput: (value: string) => void;
  isTyping: boolean;
  onSendMessage: () => void;
  onMarkAsRead: (messageId: string) => void;
  onBack: () => void;
  className?: string;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  conversation,
  messages,
  isLoading,
  error,
  messageInput,
  setMessageInput,
  isTyping,
  onSendMessage,
  onMarkAsRead,
  onBack,
  className,
}) => {
  // Hide navigation bars when chat is active on mobile
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    // Remove direct DOM manipulation - now handled by parent components
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showBlockSuccess, setShowBlockSuccess] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportSuccess, setShowReportSuccess] = useState(false);
  const { connections } = useConnectionStore();
  const { handleSendRequest } = useConnection();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isPending, setIsPending] = useState(false);

  // Check connection status
  const connection = useMemo(() => {
    console.log('Chat Window Conversation: ', conversation);

    if (!conversation?.otherUserId) {
      console.warn('No auth_id available for connection check');
      return null;
    }

    return connections.find((conn) => {
      return conn.other_user?.id === conversation.otherUserId;
    });
  }, [connections, conversation?.otherUserId]);

  const isConnected = connection?.status === 'accepted';

  // Update isPending state when connection status changes
  useEffect(() => {
    setIsPending(connection?.status === 'pending' || false);
  }, [connection?.status]);

  const handleConnect = async () => {
    console.log('handleConnect Conversation: ', conversation);

    if (!conversation?.otherUserId || !conversation?.name) {
      console.error('Missing conversation data for connection request');
      return;
    }

    setIsConnecting(true);
    try {
      const { data, error } = await supabase
        .from('users')
        .select('auth_id')
        .eq('id', conversation.otherUserId)
        .single();
      console.log('Fetched data from users table...', data);
      if (error || !data) {
        console.error('Error fetching receiver user ID:', error);
        setIsLoading(false);
        return false;
      }

      const success = await handleSendRequest(
        data.auth_id.toString(), // Use auth_id of other user for connection request
        conversation.name
      );

      if (success) {
        // Update local state to show pending status
        setIsPending(true);
      }
    } catch (error) {
      console.error('Failed to send connection request:', error);
    } finally {
      setIsConnecting(false);
    }
  };

  // Ensure Navigation Bars Reappear on Back Button
  const handleBackClick = () => {
    if (location.state?.from === 'profile') {
      navigate(`/dashboard/members/${conversation.otherUserId}`);
    } else {
      onBack();
    }
  };

  // If no conversation is selected, show a placeholder
  if (!conversation) {
    return (
      <div className="h-full flex items-center justify-center text-dark-800">
        Select a conversation to start messaging
      </div>
    );
  }

  const handleBlockUser = async () => {
    setShowBlockModal(false);
    setShowBlockSuccess(true);
  };

  const handleReportUser = async (reason: string, details: string) => {
    setShowReportModal(false);
    setShowReportSuccess(true);
  };

  return (
    <div className={cn(
      "relative h-full flex flex-col",
      "bg-white dark:bg-dark-200",
     "bg-[url('/white_chatback.png')] dark:bg-[url('/dark_chatback.png')]", 
     "bg-repeat bg-[length:400px_auto]",
      "before:absolute before:inset-0",
      "before:bg-white/45 dark:before:bg-dark-200/65",
      "before:pointer-events-none",
      "lg:max-h-none",
      "pb-safe", // Add safe area padding for iOS
      className
    )}>
      {/* Header */}
      <div className={cn(
        "flex-shrink-0 border-b border-gray-200 dark:border-dark-300 relative z-10 bg-white/50 dark:bg-dark-200/50 backdrop-blur-sm",
        "lg:relative" // Make header relative on larger screens
      )}>
        <ChatHeader
          conversation={{
            id: conversation.otherUserId, 
            name: conversation.name,
            image: conversation.image,
            location: conversation.city,
            online: false,
          }}
          isConnected={isConnected}
          isPending={isPending}
          onConnect={handleConnect}
          isConnecting={isConnecting}
          showMenu={showMenu}
          onMenuToggle={() => setShowMenu(!showMenu)}
          onBackClick={handleBackClick}
          onBlockUser={() => setShowBlockModal(true)}
          onReportUser={() => setShowReportModal(true)}
          onClearChat={() => {}}
        />
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto overflow-x-hidden">
        <MessageList
          messages={messages}
          isTyping={isTyping}
          typingName={conversation?.name || ''}
          conversation={conversation}
          onDelete={() => {}}
          onEdit={() => {}}
          onReply={() => {}}
          editingMessage={null}
        />
      </div>

      {/* Input */}
      <div className={cn(
        "flex-shrink-0 border-t border-gray-200 dark:border-dark-300 mt-auto relative z-10 bg-white/50 dark:bg-dark-200/50 backdrop-blur-sm",
        "lg:relative" // Make input relative on larger screens
      )}>
        <MessageInput
          message={messageInput}
          onMessageChange={setMessageInput}
          onSend={() => onSendMessage(conversation.id)}
          onEmojiToggle={() => {}}
        />
      </div>

      {/* Modals */}
      <BlockUserModal
        isOpen={showBlockModal}
        onClose={() => setShowBlockModal(false)}
        onConfirm={handleBlockUser}
        username={conversation.name}
      />

      <BlockSuccess
        isOpen={showBlockSuccess}
        onClose={() => setShowBlockSuccess(false)}
        username={conversation.name}
      />

      <ReportUserModal
        isOpen={showReportModal}
        onClose={() => setShowReportModal(false)}
        onSubmit={handleReportUser}
        username={conversation.name}
      />

      <ReportSuccess
        isOpen={showReportSuccess}
        onClose={() => setShowReportSuccess(false)}
        username={conversation.name}
      />
    </div>
  );
};

export default ChatWindow;