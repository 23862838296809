import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import { supabase } from '../lib/supabase';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { VenueGallery } from '../components/facilities/venues-api/components/gallery/VenueGallery';
import { VenueOpeningHours } from '../components/facilities/venues-api/components/VenueOpeningHours';
// Removed unused import for sportEmojis
import { VenueContent } from '../components/facilities/venues-api/components/VenueCard/VenueContent';
import LocationActions from '../components/facilities/LocationActions';
import MemberProfileSkeleton from '../components/members/MemberProfileSkeleton';

const ClubProfile: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [club, setClub] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showGallery, setShowGallery] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<'about' | 'members' | 'facilities'>('about');

  useEffect(() => {
    const fetchClubData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        if (!id) {
          throw new Error('Club ID is required');
        }

        // Fetch club data from Supabase clubs table
        const { data: clubData, error: clubError } = await supabase
          .from('clubs')
          .select(`
            *,
            photo_urls,
            opening_hours,
            activities,
            reviews
          `)
          .eq('club_id', id)
          .single();

        if (clubError) {
          throw clubError;
        }

        // Map activities using the sports table for emoji mapping.
        let mappedActivities = [];
        if (clubData.activities && clubData.activities.length > 0) {
          // Fetch all sports since the case-sensitive .in() was causing issues
          const { data: sportsData, error: sportsError } = await supabase
            .from('sports')
            .select('name, emoji');

          if (sportsError) {
            console.error('Error fetching sports data:', sportsError);
            mappedActivities = clubData.activities.map((activity: string) => ({
              name: activity,
              emoji: '🎯'
            }));
          } else {
            mappedActivities = clubData.activities.map((activity: string) => {
              const sport = sportsData.find((s: any) => s.name.toLowerCase() === activity.toLowerCase());
              return {
                name: activity,
                emoji: sport ? sport.emoji : '🎯'
              };
            });
          }
        }

        // Transform club data and apply the mapped activities
        const transformedClub = {
          ...clubData,
          name: clubData.club_name,
          coordinates: {
            lat: parseFloat(clubData.lat),
            lng: parseFloat(clubData.lng)
          },
          photos: clubData.photo_urls?.map((url: string) => ({ url })) || [],
          details: {
            website: clubData.website,
            phone: clubData.formatted_phone_number
          },
          amenities: mappedActivities
        };

        setClub(transformedClub);

        // Fetch members associated with this club
        const { data: clubMembers, error: membersError } = await supabase
          .from('users')
          .select('id, auth_id, full_name, avatar_url, city, job_type, company')
          .or(`club_id.eq.${id},custom_club.ilike.%${clubData.club_name}%`);

        if (membersError) throw membersError;
        setMembers(clubMembers || []);

      } catch (error) {
        console.error('Error fetching club data:', error);
        setError('Unable to load club profile. Please try again later.');
        // Clear club data on error
        setClub(null);
        setMembers([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchClubData();
    }
  }, [id]);

  if (isLoading) {
    return (
      <DashboardLayout>
        <MemberProfileSkeleton />
      </DashboardLayout>
    );
  }

  if (error || !club) {
    return (
      <DashboardLayout>
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
          <p>{error || 'Club not found'}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-dark-800 hover:text-white transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        {/* Club Header */}
        <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
          {/* Cover Image */}
          <div className="relative h-64">
            {club.photo_urls?.[0] && (
              <img
                src={club.photo_urls[0]}
                alt={club.club_name}
                className="w-full h-full object-cover"
              />
            )}
            <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
          </div>

          {/* Club Info */}
          <div className="relative px-6 pb-8">
            <div className="flex flex-col items-center -mt-16">
              {/* Club Logo */}
              <div className="relative">
                <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-dark-200 shadow-xl">
                  <img
                    src={club.logo || club.photo_urls?.[0]}
                    alt={club.club_name}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>

              <div className="mt-4 text-center">
                <h2 className="text-2xl font-bold text-white">{club.club_name}</h2>
                
                {/* Activities/Sports Tags */}
                {club.amenities?.length > 0 && (
                  <div className="flex flex-wrap justify-center gap-1.5 mt-2 mb-3">
                    {club.amenities.map((activity, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2.5 py-1 bg-dark-300 rounded-full text-sm text-white"
                      >
                        {activity.emoji} {activity.name}
                      </span>
                    ))}
                  </div>
                )}

                {/* Location */}
                <div className="text-dark-800 mt-1">{club.formatted_address}</div>

                {/* Rating */}
                {club.rating > 0 && (
                  <div className="flex items-center justify-center mt-2">
                    <div className="flex items-center bg-dark-300 rounded-full px-3 py-1">
                      <span className="text-gold-400 mr-1">★</span>
                      <span className="text-white">{club.rating}</span>
                      {club.user_ratings_total && (
                        <span className="text-dark-800 ml-1">
                          ({club.user_ratings_total} reviews)
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {/* Member Count */}
                <div className="text-dark-800 mt-2">
                  {members.length} {members.length === 1 ? 'Member' : 'Members'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Actions */}
        {club.coordinates && (
          <div className="bg-dark-200 rounded-xl p-4 border border-dark-300">
            <LocationActions
              venueName={club.club_name}
              address={club.formatted_address}
              coordinates={club.coordinates}
            />
          </div>
        )}

        {/* Tabs */}
        <div className="flex items-center space-x-4 border-b border-dark-300">
          <button
            onClick={() => setActiveTab('about')}
            className={cn(
              "px-4 py-2 -mb-px border-b-2 transition-colors",
              activeTab === 'about'
                ? "border-gold-400 text-gold-400"
                : "border-transparent text-dark-800 hover:text-white"
            )}
          >
            About
          </button>
          <button
            onClick={() => setActiveTab('members')}
            className={cn(
              "px-4 py-2 -mb-px border-b-2 transition-colors",
              activeTab === 'members'
                ? "border-gold-400 text-gold-400"
                : "border-transparent text-dark-800 hover:text-white"
            )}
          >
            Members
          </button>
          <button
            onClick={() => setActiveTab('facilities')}
            className={cn(
              "px-4 py-2 -mb-px border-b-2 transition-colors",
              activeTab === 'facilities'
                ? "border-gold-400 text-gold-400"
                : "border-transparent text-dark-800 hover:text-white"
            )}
          >
            Peek Inside
          </button>
        </div>

        {/* Tab Content */}
        <div className="space-y-6">
          {activeTab === 'about' && (
            <div className="space-y-6">
              {/* Description */}
              {club.description && (
                <div className="bg-dark-200 rounded-xl p-6 border border-dark-300">
                  <h3 className="text-lg font-semibold text-white mb-4">About {club.club_name}</h3>
                  <p className="text-dark-800">{club.description}</p>
                </div>
              )}

              {/* Contact Information */}
              {(club.details.website || club.details.phone) && (
                <div className="bg-dark-200 rounded-xl p-6 border border-dark-300">
                  <h3 className="text-lg font-semibold text-white mb-4">Contact Information</h3>
                  <div className="space-y-3">
                    {club.details.website && (
                      <a
                        href={club.details.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gold-400 hover:text-gold-500"
                      >
                        <span className="mr-2">🌐</span>
                        Website
                      </a>
                    )}
                    {club.details.phone && (
                      <a
                        href={`tel:${club.details.phone}`}
                        className="flex items-center text-gold-400 hover:text-gold-500"
                      >
                        <span className="mr-2">📞</span>
                        {club.details.phone}
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === 'members' && (
            <div className="space-y-4">
              {members.length > 0 ? (
                members.map((member) => (
                  <div
                    key={member.id}
                    className="bg-dark-200 rounded-xl p-4 border border-dark-300 hover:border-gold-400 transition-all duration-300 group"
                  >
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => navigate(`/dashboard/members/${member.auth_id}`)}
                        className="flex-shrink-0"
                      >
                        <img
                          src={member.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                          alt={member.full_name}
                          className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 group-hover:border-gold-400 transition-colors"
                        />
                      </button>
                      <div>
                        <button
                          onClick={() => navigate(`/dashboard/members/${member.auth_id}`)}
                          className="text-white font-medium hover:text-gold-400 transition-colors"
                        >
                          {member.full_name}
                        </button>
                        <p className="text-dark-800 text-sm">
                          {member.job_type} at {member.company}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center py-12 bg-dark-300 rounded-xl">
                  <p className="text-dark-800">No members found</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'facilities' && (
            <div className="space-y-6">
              {/* Photo Gallery */}
              {club.photo_urls && club.photo_urls.length > 0 && (
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                  {club.photo_urls.map((url: string, index: number) => (
                    <div
                      key={index}
                      className="relative aspect-square rounded-xl overflow-hidden cursor-pointer group"
                      onClick={() => setShowGallery(true)}
                    >
                      <img
                        src={url}
                        alt={`${club.club_name} facility ${index + 1}`}
                        className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <span className="text-white">View Gallery</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Photo Gallery Modal */}
        {showGallery && club.photo_urls && (
          <VenueGallery
            photos={club.photo_urls.map((url: string) => ({ url }))}
            isOpen={showGallery}
            onClose={() => setShowGallery(false)}
            venueName={club.club_name}
          />
        )}
      </div>
    </DashboardLayout>
  );
};

export default ClubProfile;
