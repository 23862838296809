import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useProfile } from '../../hooks/useProfile';
import { cn } from '@/lib/utils';
import { supabase } from '../../lib/supabase';
import { Edit2, Save, Building2, Briefcase, Users } from 'lucide-react';

/* -------------------- FieldGroup -------------------- */
interface FieldGroupProps {
  fields: Record<
    string,
    {
      label: string;
      icon: React.FC<{ className?: string }>;
      required?: boolean;
      placeholder: string;
    }
  >;
  formData: FormData;
  setFormData: (data: FormData) => void;
  validationErrors: Record<string, string>;
  isEditing: boolean;
}

const FieldGroup: React.FC<FieldGroupProps> = ({
  fields,
  formData,
  setFormData,
  validationErrors,
  isEditing,
}) => (
  <div className="space-y-6">
    {Object.entries(fields).map(([key, field]) => (
      <div key={key}>
        <label className="flex items-center text-dark-800 mb-2">
          <field.icon className="h-4 w-4 mr-2" />
          {field.label}
          {field.required && <span className="text-red-400 ml-1">*</span>}
        </label>

        {isEditing ? (
          <Input
            value={formData[key as keyof FormData] || ''}
            onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
            placeholder={field.placeholder}
            className={cn(
              'bg-dark-300 border-dark-400 text-white',
              validationErrors[key] && 'border-red-400'
            )}
          />
        ) : (
          /* Non-editable display: locked style */
          <div
            className={cn(
              'px-3 py-2 rounded-md border border-gray-300 bg-gray-50 text-gray-800',
              'pointer-events-none cursor-default'
            )}
          >
            {formData[key as keyof FormData] || field.placeholder}
          </div>
        )}

        {validationErrors[key] && (
          <p className="text-red-400 text-sm mt-1">{validationErrors[key]}</p>
        )}
      </div>
    ))}
  </div>
);

/* -------------------- Fields Definition -------------------- */
const PROFESSIONAL_FIELDS = {
  company: {
    label: 'Company',
    icon: Building2,
    required: true,
    placeholder: 'Enter your company name',
  },
  jobTitle: {
    label: 'Job Title',
    icon: Briefcase,
    required: true,
    placeholder: 'Enter your job title',
  },
};

/* -------------------- Form Data Interface -------------------- */
interface FormData {
  city: string; // If needed behind the scenes
  company: string;
  jobTitle: string;
  clubId: string | null;    // club_id is a UUID in your DB
  club_name: string;        // The user-facing name of the club
}

/* -------------------- Main Component -------------------- */
export const PersonalInfoCard: React.FC = () => {
  const { profile, error, fetchProfile } = useProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Our local form state
  const [formData, setFormData] = useState<FormData>({
    city: profile?.city || '',
    company: profile?.company || '',
    jobTitle: profile?.job_title || '',
    clubId: profile?.club_id || null,
    club_name: profile?.custom_club || '',
  });

  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [saveError, setSaveError] = useState<string | null>(null);

  // For the club dropdown
  const [clubs, setClubs] = useState<Array<{ club_id: string; club_name: string }>>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  /* -------------------- Fetch Profile on Mount -------------------- */
  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  /* -------------------- Sync with Profile Changes -------------------- */
  useEffect(() => {
    if (!profile) return;

    // If there's an official club_id and no custom_club, fetch the club's name from the DB
    if (profile.club_id && !profile.custom_club) {
      const fetchClubName = async () => {
        const { data, error } = await supabase
          .from('clubs')
          .select('club_name')
          .eq('club_id', profile.club_id)
          .single();

        if (error) {
          console.warn('No matching club found for user.club_id =', profile.club_id);
        }

        setFormData({
          city: profile.city || '',
          company: profile.company || '',
          jobTitle: profile.job_type || '',
          clubId: profile.club_id,
          club_name: data?.club_name || '', // fallback to empty string if not found
        });
      };
      fetchClubName();
    } else {
      // If custom_club is present or club_id is null
      setFormData({
        city: profile.city || '',
        company: profile.company || '',
        jobTitle: profile.job_type || '',
        clubId: profile.club_id || null,
        club_name: profile.custom_club || '',
      });
    }
  }, [profile]);

  /* -------------------- Debounced Club Search -------------------- */
  const fetchClubs = async (query: string) => {
    const { data, error: fetchError } = await supabase
      .from('clubs')
      .select('club_id, club_name')
      .ilike('club_name', `%${query}%`)
      .order('club_name')
      .limit(100);

    if (fetchError) {
      console.error('Error fetching clubs:', fetchError);
    } else {
      setClubs(data || []);
      setShowDropdown(true);
    }
  };

  const debouncedFetchClubs = useCallback(debounce(fetchClubs, 300), []);

  useEffect(() => {
    if (formData.club_name.trim().length > 1 && isEditing) {
      debouncedFetchClubs(formData.club_name);
    } else {
      setClubs([]);
      setShowDropdown(false);
    }
  }, [formData.club_name, isEditing, debouncedFetchClubs]);

  /* -------------------- Form Validation -------------------- */
  const validateForm = () => {
    const errors: Record<string, string> = {};
    // Check the "required" fields
    Object.entries(PROFESSIONAL_FIELDS).forEach(([key, field]) => {
      if (field.required && !formData[key as keyof FormData]?.toString().trim()) {
        errors[key] = `${field.label} is required`;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  /* -------------------- Handle Save -------------------- */
  const handleSave = async () => {
    if (!validateForm()) return;
    setIsSaving(true);
    setSaveError(null);

    try {
      const updates = {
        city: formData.city.trim(),
        company: formData.company.trim(),
        job_type: formData.jobTitle.trim(),
        club_id: formData.clubId,
        custom_club: formData.clubId ? null : formData.club_name.trim(),
      };

      console.log('Sending update payload to Supabase:', updates);

      const { error: updateError } = await supabase
        .from('users')
        .update(updates)
        .eq('id', profile?.id);

      if (updateError) {
        console.error('Supabase update error:', updateError.message);
        setSaveError(updateError.message);
      } else {
        console.log('Update successful.');
        await fetchProfile();
        setIsEditing(false);
      }
    } catch (err) {
      console.error('Error saving profile:', err);
      setSaveError(err instanceof Error ? err.message : 'Unknown error occurred');
    } finally {
      setIsSaving(false);
    }
  };

  /* -------------------- Handle Cancel -------------------- */
  const handleCancel = () => {
    setIsEditing(false);
    setValidationErrors({});

    if (profile) {
      setFormData({
        city: profile.city || '',
        company: profile.company || '',
        jobTitle: profile.job_type || '',
        clubId: profile.club_id || null,
        club_name: profile.custom_club || '',
      });
    }
  };

  /* -------------------- Error UI -------------------- */
  if (error) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
        <p>{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="text-sm underline mt-2 hover:text-red-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  /* -------------------- Render -------------------- */
  return (
    <div className="bg-dark-200 rounded-xl p-6 border border-dark-300">
      {/* Header */}
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl font-semibold text-white">My Details</h2>
        {!isEditing ? (
          <Button
            onClick={() => setIsEditing(true)}
            className="bg-gold-400 hover:bg-gold-500 text-dark-50"
          >
            <Edit2 className="h-4 w-4 mr-2" />
            Edit Profile
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Button onClick={handleCancel} variant="outline" className="border-dark-400">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              disabled={isSaving}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              {isSaving ? (
                'Saving...'
              ) : (
                <>
                  <Save className="h-4 w-4 mr-2" />
                  Save Changes
                </>
              )}
            </Button>
          </div>
        )}
      </div>

      {/* Display any save error */}
      {saveError && <p className="text-red-500 mb-4">Error: {saveError}</p>}

      {/* Company & Job Title Fields */}
      <div className="grid grid-cols-1 gap-6">
        <FieldGroup
          fields={PROFESSIONAL_FIELDS}
          formData={formData}
          setFormData={setFormData}
          validationErrors={validationErrors}
          isEditing={isEditing}
        />
      </div>

      {/* Private Members Club */}
      <div className="mt-6">
        <label className="flex items-center text-dark-800 mb-2">
          <Users className="h-4 w-4 mr-2" /> Private Members Club
        </label>
        {isEditing ? (
          <>
            <Input
              type="text"
              value={formData.club_name}
              onChange={(e) => {
                const value = e.target.value;
                setFormData({ ...formData, club_name: value, clubId: null });
                debouncedFetchClubs(value);
                setShowDropdown(true);
              }}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
              placeholder="Search or enter your club name"
              autoComplete="off"
              className="bg-dark-300 border-dark-400 text-white"
            />
            {showDropdown && clubs.length > 0 && (
              <ul className="shadow mt-2 rounded max-h-40 overflow-y-auto bg-white text-black dark:bg-dark-300 dark:text-white">
                {clubs.map((club) => (
                  <li
                    key={club.club_id}
                    className="p-2 hover:bg-gray-100 dark:hover:bg-dark-400 cursor-pointer"
                    onClick={() => {
                      setFormData({ ...formData, clubId: club.club_id, club_name: club.club_name });
                      setShowDropdown(false);
                    }}
                  >
                    {club.club_name}
                  </li>
                ))}
              </ul>
            )}
          </>
        ) : (
          // Non-editable display for the club field
          <div
            className={cn(
              'px-3 py-2 rounded-md border border-gray-300 bg-gray-50 text-gray-800',
              'pointer-events-none cursor-default'
            )}
          >
            {formData.club_name || 'No Club Selected'}
          </div>
        )}
      </div>
    </div>
  );
};
