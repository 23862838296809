// Map of activity emojis by gender
export const ACTIVITY_EMOJIS = {
  walk: {
    male: '🚶‍♂️',
    female: '🚶‍♀️'
  },
  run: {
    male: '🏃‍♂️',
    female: '🏃‍♀️'
  },
  wellness: {
    male: '🧘‍♂️',
    female: '🧘‍♀️'
  }
} as const;

export function getRandomGenderEmoji(activity: keyof typeof ACTIVITY_EMOJIS): string {
  const gender = Math.random() < 0.5 ? 'male' : 'female';
  return ACTIVITY_EMOJIS[activity][gender];
}