import React from 'react';
import { RefreshCw, List, MapIcon } from 'lucide-react';
import { Button } from '../../../ui/button';
import { Switch } from '../../../ui/switch';
import { Label } from '../../../ui/label';
import { LocationPopover } from './LocationPopover';
import { SortPopover } from './SortPopover';
import { cn } from '@/lib/utils';
import type { VenueSortOption } from '../../../../lib/utils/venue-sorting';

interface VenueListControlsProps {
  sortBy: VenueSortOption;
  onSortChange: (value: VenueSortOption) => void;
  isLoading: boolean;
  viewMode: 'list' | 'map';
  onViewModeChange: (mode: 'list' | 'map') => void;
  address: string;
  onAddressChange: (address: string) => void;
  onLocationSelect: (location: { lat: number; lng: number }) => void;
  isAddressLoading?: boolean;
  addressError?: string;
}

const VenueListControls: React.FC<VenueListControlsProps> = ({
  sortBy,
  onSortChange,
  isLoading,
  viewMode,
  onViewModeChange,
  address,
  onAddressChange,
  onLocationSelect,
  isAddressLoading,
  addressError
}) => {
  const [showLocationPopover, setShowLocationPopover] = React.useState(false);
  const [showSortPopover, setShowSortPopover] = React.useState(false);

  return (
    <div className={cn(
      "flex flex-wrap items-center gap-2"
    )}>
      {/* View mode toggles */}
      <div className="flex items-center space-x-1">
        <button
          onClick={() => onViewModeChange('list')}
          className={cn(
            "p-2 rounded-md transition-colors min-w-[40px] flex items-center justify-center",
            viewMode === 'list' 
              ? "bg-dark-400 text-gold-400" 
              : "text-dark-800 hover:text-white hover:bg-dark-300"
          )}
          aria-label="List view"
        >
          <List className="h-5 w-5" />
          <span className="hidden sm:inline ml-2">List</span>
        </button>
        <button
          onClick={() => onViewModeChange('map')}
          className={cn(
            "p-2 rounded-md transition-colors min-w-[40px] flex items-center justify-center",
            viewMode === 'map' 
              ? "bg-dark-400 text-gold-400" 
              : "text-dark-800 hover:text-white hover:bg-dark-300"
          )}
          aria-label="Map view"
        >
          <MapIcon className="h-5 w-5" />
          <span className="hidden sm:inline ml-2">Map</span>
        </button>
      </div>

      {/* Location button */}
      <LocationPopover
        address={address}
        onAddressChange={onAddressChange}
        onLocationSelect={onLocationSelect}
        isLoading={isAddressLoading}
        error={addressError}
        isOpen={showLocationPopover}
        onOpenChange={setShowLocationPopover}
      />

      {/* Sort button */}
      <SortPopover
        value={sortBy}
        onChange={onSortChange}
        isOpen={showSortPopover}
        onOpenChange={setShowSortPopover}
      />
    </div>
  );
};

export default VenueListControls;