import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

const Community = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 !bg-black !text-white">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center !text-white hover:text-gold-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className={cn(
            "text-4xl font-bold mb-2",
            "bg-gradient-to-r from-white via-gold-200 to-gold-400",
            "bg-clip-text text-transparent"
          )}>Community Guidelines</h1>
          <p className="!text-white/70 mb-8">Last Updated: March 25, 2025</p>

          <div className="space-y-12 !text-white/70">
            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">1. Respect & Professionalism</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Civility:</strong>
                  <p className="mt-1">
                    Always treat fellow members with courtesy. Harassment, hate speech, or discriminatory remarks are strictly prohibited.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Professional Communication:</strong>
                  <p className="mt-1">
                    Use clear, respectful language in all interactions—whether in messages, reviews, or during in-person engagements.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">2. Exclusivity & Trust</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Verified Membership:</strong>
                  <p className="mt-1">
                    Our community is exclusive. Sharing membership credentials or inviting unverified individuals is prohibited and may lead to immediate suspension.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Integrity:</strong>
                  <p className="mt-1">
                    Be honest in your profile, reviews, and communications. Misrepresentation or fraudulent behavior will not be tolerated.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">3. Safety & Security</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Personal Boundaries:</strong>
                  <p className="mt-1">
                    Respect others' privacy. Do not share personal data or confidential information without explicit consent.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Zero Tolerance for Abuse:</strong>
                  <p className="mt-1">
                    Any form of abuse, harassment, or threatening behavior will result in immediate disciplinary action, including removal from the platform.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Report Concerns:</strong>
                  <p className="mt-1">
                    Use the in-app reporting tools or contact support if you witness or experience any unsafe or inappropriate behavior.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">4. Constructive Engagement</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Positive Contributions:</strong>
                  <p className="mt-1">
                    Share feedback and recommendations constructively to help improve the member experience.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Community Support:</strong>
                  <p className="mt-1">
                    Engage collaboratively. Promote discussions that add value and reflect the high standards of our network.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">5. Compliance with Club Policies</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Adherence to Rules:</strong>
                  <p className="mt-1">
                    Follow both playclub.ai guidelines and the specific policies of your home and partner clubs. Violations may impact your standing.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Feedback & Accountability:</strong>
                  <p className="mt-1">
                    Respect moderation decisions. If you disagree, raise concerns respectfully through the proper channels.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">6. Consequences of Non-Compliance</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Enforcement:</strong>
                  <p className="mt-1">
                    Breaches of these guidelines may result in warnings, temporary suspensions, or permanent removal from the platform.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Moderation Authority:</strong>
                  <p className="mt-1">
                    Moderators have the final authority in enforcing these standards to maintain a safe and exclusive community.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
