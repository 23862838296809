import { supabase } from '../supabase';
import { Member } from '@/types/member';
import { Post } from '@/types/post';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (
      retries > 0 &&
      error instanceof Error &&
      error.message.includes('Failed to fetch')
    ) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

export async function search(
  query: string
): Promise<{ players: Member[]; posts: Post[]; clubs: any[] }> {
  try {
    console.log('📡 Performing search query:', query);

    // Sanitize search query to handle special characters
    const sanitizedQuery = query
      .replace(/['"]/g, '') // Remove quotes
      .replace(/[^\w\s]/g, ' ') // Replace special chars with space
      .replace(/\s+/g, ' ') // Normalize spaces
      .trim();

    // Get authenticated user
    const {
      data: { user },
      error: authError,
    } = await supabase.auth.getUser();

    if (authError) {
      console.error('❌ Error fetching authenticated user:', authError);
      throw authError;
    }

    const loggedInUserId = user?.id || null;

    const { data, error } = await fetchWithRetry(() => 
      supabase.rpc('search_global', { query: sanitizedQuery })
    );

    if (error) throw error;

    console.log('🔍 Raw API Response:', data);

    // Transform users (players)
    const players: Member[] = data
      .filter(
        (item: any) => item.type === 'user' && item.user_id !== loggedInUserId
      )
      .map((user: any) => ({
        id: user.user_id, // use auth_id for players
        name: user.name,
        city: user.city || '',
        jobTitle: user.job_type || '',
        company: user.company || '',
        image:
          user.avatar_url ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        sports: user.sports || [],
      }));

    // Transform posts
    const posts: Post[] = data
      .filter((item: any) => item.type === 'post')
      .map((post: any) => ({
        id: post.id,
        user_id: post.user_id,
        name: post.name, // UI expects a name field (from post content)
        content: post.content,
        author: {
          id: post.user_id,
          name: post.author_name || 'Unknown',
          image:
            post.author_avatar ||
            'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        },
        created_at: post.created_at,
        actionUrl: `/dashboard?post=${post.id}`,
      }));

    // Transform clubs – note: clubs return id equals club_id.
    const clubs = data
      .filter((item: any) => item.type === 'club')
      .map((club: any) => ({
        id: club.id, // club id (club_id) as uuid
        name: club.name,
        city: club.city || '',
        logo: club.avatar_url, // club logo
        sports: club.sports || [], // activities converted to text array
      }));

    console.log('✅ Transformed Search Results:', { players, posts, clubs });
    return { players, posts, clubs };
  } catch (error) {
    console.error('❌ Search API error:', error);
    return { players: [], posts: [], clubs: [] };
  }
}

export async function getSearchSuggestions() {
  try {
    const {
      data: { user },
      error: authError,
    } = await supabase.auth.getUser();
    if (authError) throw authError;

    const { data: popularMembers } = await supabase
      .from('users')
      .select('auth_id, full_name, avatar_url, city, points')
      .order('points', { ascending: false })
      .limit(5);

    const { data: cities } = await supabase
      .from('users')
      .select('city')
      .not('city', 'is', null)
      .limit(10);

    const processedCities =
      cities
        ?.map((c) => {
          const fullCity = c.city;
          return fullCity.split(',')[0].trim();
        })
        .filter(Boolean) || [];
    const uniqueCities = [...new Set(processedCities)];

    const { data: sports } = await supabase
      .from('sports')
      .select('name, emoji')
      .limit(10);

    // Fetch popular clubs
    const { data: popularClubs } = await supabase
      .from('clubs')
      .select('club_id, club_name, logo')
      .order('rating', { ascending: false })
      .not('rating', 'is', null)
      .limit(5);

    const { data: trendingPosts } = await supabase
      .from('posts')
      .select(`
        id,
        content,
        created_at,
        likes_count,
        users (
          auth_id,
          full_name,
          avatar_url
        )
      `)
      .order('likes_count', { ascending: false })
      .limit(5);

    const { data: industries } = await supabase
      .from('user_profiles')
      .select('preferences->industry')
      .not('preferences->industry', 'is', null)
      .limit(10);

    const { data: companiesData } = await supabase
      .from('users')
      .select('company')
      .not('company', 'is', null)
      .limit(100);

    const companyCounts: Record<string, number> = {};
    companiesData?.forEach(({ company }) => {
      if (company) {
        companyCounts[company] = (companyCounts[company] || 0) + 1;
      }
    });

    const sortedCompanies = Object.entries(companyCounts)
      .sort(([, countA], [, countB]) => countB - countA)
      .slice(0, 10)
      .map(([company]) => company);

    return {
      popularMembers:
        popularMembers?.map((member) => ({
          id: member.auth_id,
          name: member.full_name,
          image:
            member.avatar_url ||
            'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          city: member.city,
        })) || [],
      cities: uniqueCities || [],
      sports:
        sports?.map((sport) => ({
          name: sport.name,
          emoji: sport.emoji,
        })) || [],
      popularClubs:
        popularClubs?.map((club) => ({
          id: club.club_id,
          name: club.club_name,
          logo: club.logo || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
        })) || [],
      industries:
        [...new Set(industries?.map((i) => i.preferences?.industry).filter(Boolean))] ||
        [],
      trendingPosts:
        trendingPosts?.map((post) => ({
          id: post.id,
          content: post.content,
          author: {
            id: post.users.auth_id,
            name: post.users.full_name,
            image:
              post.users.avatar_url ||
              'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          },
          createdAt: post.created_at,
        })) || [],
      popularCompanies: sortedCompanies || [],
    };
  } catch (error) {
    console.error('Error fetching search suggestions:', error);
    return {
      popularMembers: [],
      cities: [],
      sports: [],
      popularClubs: [],
      industries: [],
      trendingPosts: [],
      popularCompanies: [],
    };
  }
}