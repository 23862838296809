import React from 'react';

interface ActivityStatusProps {
  status: string;
  direction?: 'sent' | 'received';
}

const ActivityStatus: React.FC<ActivityStatusProps> = ({ status, direction }) => {
  // Determine display text
  const getDisplayText = () => {
    if (status === 'new_request') {
      return direction === 'received' ? 'New Request' : 'Request Sent';
    }
    if (status === 'update_requested') return 'Update Requested';
    if (status === 'declined') return 'Declined';
    return status.charAt(0).toUpperCase() + status.slice(1);
  };

  return (
    <div className={`flex items-center px-3 py-2 rounded-lg text-sm ${
      status === 'new_request' && direction === 'received' ? 'bg-amber-100 dark:bg-yellow-400/20 text-amber-700 dark:text-yellow-400' :
      status === 'new_request' && direction === 'sent' ? 'bg-blue-100 dark:bg-blue-400/20 text-blue-700 dark:text-blue-400' :
      status === 'confirmed' ? 'bg-green-100 dark:bg-green-400/20 text-green-700 dark:text-green-400' :
      status === 'played' ? 'bg-gold-100 dark:bg-gold-400/20 text-gold-700 dark:text-gold-400' :
      status === 'declined' ? 'bg-red-100 dark:bg-red-400/20 text-red-700 dark:text-red-400' :
      status === 'update_requested' ? 'bg-amber-100 dark:bg-yellow-400/20 text-amber-700 dark:text-yellow-400' :
      status === 'disputed' ? 'bg-red-100 dark:bg-red-400/20 text-red-700 dark:text-red-400' :
      status === 'missed' ? 'bg-gray-100 dark:bg-gray-400/20 text-gray-700 dark:text-gray-400' :
      'bg-gray-100 dark:bg-dark-400/20 text-gray-700 dark:text-dark-800'
    } w-auto`}>
      {getDisplayText()}
    </div>
  );
};

export default ActivityStatus;