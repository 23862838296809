import React, { useState } from 'react';
import { Activity } from '../../types/activity';
import { formatActivityDateTime } from '../../lib/date';
import { getVenueCoordinates } from '../../lib/coordinates';
import LocationActions from '../facilities/LocationActions';
import CopyAddressButton from './buttons/CopyAddressButton';
import AddToCalendarButton from './buttons/AddToCalendarButton';
import UnifiedVenueSelector from '../facilities/venues-api/components/UnifiedVenueSelector';
import { Button } from '../ui/button';
import { MapPin } from 'lucide-react';

// Helper function to check if activity is in the past
const isPastDate = (date: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const activityDate = new Date(date);
  return activityDate < today;
};

interface ActivityDetailsProps {
  activity: Activity;
  isPastActivity?: boolean;
  onUpdateVenue?: (venue: { name: string; address: string; city: string }) => void;
  sport?: string;
}

const ActivityDetails: React.FC<ActivityDetailsProps> = ({
  activity,
  isPastActivity = false,
  onUpdateVenue,
  sport
}) => {
  const dateTimeStr = formatActivityDateTime(activity.date, activity.time);
  const coordinates = getVenueCoordinates(activity.venue.city);
  const [showVenueSelector, setShowVenueSelector] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(activity.venue);
  const isActivityInPast = isPastActivity || isPastDate(activity.date);
  const isMissedActivity = activity.status === 'missed';

  const handleVenueSelect = (venue: { name: string; address: string; city: string }) => {
    setSelectedVenue(venue);
  };

  const handleSaveVenue = () => {
    if (onUpdateVenue) {
      onUpdateVenue(selectedVenue);
      activity.venue = selectedVenue;
    }
    setShowVenueSelector(false);
  };

  const startDate = new Date(`${activity.date}T${activity.time}`);
  const endDate = new Date(startDate.getTime() + 60 * 60 * 1000);

  // Only show address if it exists and is different from venue name
  const shouldShowAddress = activity.venue.address?.trim() && 
    activity.venue.address.toLowerCase() !== activity.venue.name?.toLowerCase();

  const fullAddress = activity.venue.address?.trim()
    ? `${activity.venue.name}, ${activity.venue.address}`
    : `${activity.venue.name}, ${activity.venue.city}`;

  const calendarEvent = {
    title: `${activity.sport} with ${activity.opponent.name}`,
    description: `Sports session at ${activity.venue.name}`,
    location: fullAddress,
    startTime: startDate.toISOString(),
    endTime: endDate.toISOString(),
  };

  return (
    <div className="mt-4">
      <div className="space-y-2 mb-4">
        <div className="flex items-center gap-2 text-dark-800">
          <span className="text-gold-400 font-medium whitespace-nowrap">When?</span>
          {dateTimeStr}
        </div>
        {!isActivityInPast && !isMissedActivity && (
          <AddToCalendarButton event={calendarEvent} />
        )}
      </div>

      <div className="space-y-2">
        {!isMissedActivity && (activity.venue.name || activity.venue.address) ? (
          <div className="space-y-2 text-dark-800">
            <div className="flex items-center gap-2">
              <span className="text-gold-400 font-medium whitespace-nowrap">Where?</span>
              {activity.venue.name && (
                <span className="font-semibold text-white">{activity.venue.name}</span>
              )}
              {shouldShowAddress && <CopyAddressButton address={fullAddress} />}
            </div>
            {shouldShowAddress && (
              <div className="text-sm text-gray-400">{activity.venue.address}</div>
            )}
            {!isActivityInPast && activity.venue.name && !isMissedActivity && (
              <LocationActions
                venueName={activity.venue.name}
                address={activity.venue.address?.trim()
                  ? activity.venue.address
                  : `${activity.venue.name}, ${activity.venue.city}`
                }
                coordinates={coordinates}
                hideViewMap={true}
              />
            )}
          </div>
        ) : (
          !isActivityInPast &&
          !isMissedActivity && (
            <div className="space-y-4">
              {!showVenueSelector ? (
                <button
                  onClick={() => setShowVenueSelector(true)}
                  className="flex items-center space-x-2 px-4 py-2 bg-dark-300 hover:bg-dark-400 rounded-lg text-white transition-colors w-full"
                >
                  <MapPin className="h-4 w-4 text-gold-400" />
                  <span>Choose where to play</span>
                </button>
              ) : (
                <div className="space-y-4">
                  <UnifiedVenueSelector
                    city={activity.venue.city || activity.venue_city || ''}
                    value={selectedVenue.name}
                    onChange={(venue) => handleVenueSelect(venue)}
                  />
                  <div className="flex justify-end space-x-2">
                    <Button
                      variant="outline"
                      onClick={() => setShowVenueSelector(false)}
                      className="border-dark-400"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveVenue}
                      className="bg-gold-400 hover:bg-gold-500 text-dark-50"
                      disabled={!selectedVenue.name && !selectedVenue.address}
                    >
                      Save Location
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;
