import { useEffect } from 'react';

function GoogleAnalytics() {
    useEffect(() => {
        const trackingId = import.meta.env.VITE_GA_TRACKING_ID;
        if (!trackingId) return;

        // Inject Google Tag script
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        document.head.appendChild(script);

        // Initialize gtag
        const script2 = document.createElement('script');
        script2.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}');
        `;
        document.head.appendChild(script2);
    }, []);

    return null;
}

export default GoogleAnalytics;
