import React from 'react';
import { Plus, MessageCircle } from 'lucide-react';
import { useConnection } from '../../../hooks/useConnection';
import { useConnectionStore } from '../../../store/connectionStore';
import ConnectionButton from '../ConnectionButton';
import RequestMatchModal from '../../matchmaking/RequestMatchModal';
import { supabase } from '../../../lib/supabase';
import { useNavigate } from 'react-router-dom';

interface ProfileActionsProps {
  member: {
    id: string;
    name: string;
    image: string;
    city: string;
    sports: string[];
  };
  onMessage: () => void;
}

const ProfileActions: React.FC<ProfileActionsProps> = ({
  member,
  onMessage
}) => {
  const { handleSendRequest } = useConnection();
  const { connections } = useConnectionStore();
  const [isMatchModalOpen, setMatchModalOpen] = React.useState(false);
  const navigate = useNavigate();

  // Sanitize the sports array so that each string, when split,
  // produces an emoji and a proper sport name.
  const sanitizedMember = React.useMemo(() => ({
    ...member,
    sports: member.sports.map((sport, index) => {
      const parts = sport.split(' ');
      if (parts.length === 1) {
        // Provide a fallback sport name (with index to ensure uniqueness)
        return `${parts[0]} Sport ${index + 1}`;
      }
      return sport;
    })
  }), [member]);

  // Determine connection status
  const connectionStatus = React.useMemo(() => {
    const connection = connections.find(
      (conn) => conn.other_user?.auth_id === member.id
    );
    return connection?.status || null;
  }, [connections, member.id]);

  const buttonBaseClasses =
    "h-10 px-4 text-sm font-medium flex items-center justify-center space-x-2 rounded-xl transition-colors";
  const handleMessageClick = async () => {
    try {
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      // Get user's database ID
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');

      // Get member's database ID
      const { data: memberData, error: memberError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', member.id)
        .single();

      if (memberError) throw memberError;
      if (!memberData) throw new Error('Member not found');

      // Get or create conversation
      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: memberData.id
        }
      );

      if (conversationError) throw conversationError;

      // Navigate to messages with the conversation ID
      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: `Hi ${member.name}!`,
          showMobileList: false
        }
      });

    } catch (error) {
      console.error('Error handling message click:', error);
    }
  };

  return (
    <div className="flex flex-wrap items-center gap-2">
      {/* Primary Actions */}
      <div className="flex items-center gap-2">
        <ConnectionButton
          isConnected={connectionStatus === 'accepted'}
          memberId={member.id}
          memberName={member.name}
          memberImage={member.image}
          className={buttonBaseClasses}
        />
        <button
          onClick={() => setMatchModalOpen(true)}
          className={`${buttonBaseClasses} bg-gold-400 hover:bg-gold-500 text-dark-50`}
        >
          <Plus className="h-4 w-4" />
          <span>Play</span>
        </button>
        <button
          onClick={handleMessageClick}
          className={`${buttonBaseClasses} bg-dark-300 hover:bg-dark-400 text-white`}
        >
          <MessageCircle className="h-4 w-4" />
          <span>Message</span>
        </button>
      </div>

      {/* Match Request Modal */}
      {isMatchModalOpen && (
        <RequestMatchModal
          isOpen={isMatchModalOpen}
          onClose={() => setMatchModalOpen(false)}
          member={sanitizedMember}
        />
      )}
    </div>
  );
};

export default ProfileActions;
