import { City } from '../../../types/city';
import { normalizeText } from '../../utils/text';

const NOMINATIM_API = 'https://nominatim.openstreetmap.org/search';
const SEARCH_LIMIT = 10;

interface NominatimResult {
  place_id: number;
  lat: string;
  lon: string;
  display_name: string;
  type: string;
  class: string;
  importance: number;
  icon?: string;
  address?: {
    city?: string;
    town?: string;
    state?: string;
    country?: string;
    country_code?: string;
  };
}

export class NominatimService {
  static async searchCities(query: string): Promise<City[]> {
    if (!query.trim()) return [];

    try {
      const url = new URL(NOMINATIM_API);
      url.searchParams.append('q', query);
      url.searchParams.append('format', 'json');
      url.searchParams.append('limit', SEARCH_LIMIT.toString());
      url.searchParams.append('addressdetails', '1');
      url.searchParams.append('featuretype', 'city');
      url.searchParams.append('accept-language', 'en');

      const response = await fetch(url.toString(), {
        headers: {
          'User-Agent': 'Playclub City Search'
        }
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const results = await response.json() as NominatimResult[];
      return this.processResults(results);
    } catch (error) {
      console.error('NominatimService searchCities error:', error);
      return [];
    }
  }

  private static processResults(results: NominatimResult[]): City[] {
    return results
      .filter(result => this.isCityResult(result))
      .map(result => this.formatCity(result))
      .filter((city): city is City => city !== null);
  }

  private static isCityResult(result: NominatimResult): boolean {
    return (
      // Check if it's explicitly a city/town
      ['city', 'town', 'village', 'municipality'].includes(result.type) ||
      // Check address components
      Boolean(result.address?.city || result.address?.town)
    );
  }

  private static formatCity(result: NominatimResult): City | null {
    // Get just the city name without any extra info
    const name = (result.address?.city || result.address?.town || this.extractCityFromDisplay(result.display_name))?.split(',')[0].trim();
    if (!name) return null;

    return {
      name,
      country: result.address?.country,
      coordinates: {
        lat: parseFloat(result.lat),
        lng: parseFloat(result.lon)
      },
      active: true,
      importance: result.importance
    };
  }

  private static extractCityFromDisplay(display: string): string | null {
    // Display name format is typically: "City, State, Country"
    return display.split(',')[0].trim() || null;
  }
}