import { City } from '../../../types/city';
import { PhotonFeature } from './types';
import { PHOTON_CONFIG } from './constants';

export class PhotonService {
  static async searchCities(query: string): Promise<City[]> {
    if (!query?.trim()) {
      return [];
    }

    try {
      const url = new URL(PHOTON_CONFIG.API_URL);
      url.searchParams.append('q', query);
      url.searchParams.append('limit', PHOTON_CONFIG.SEARCH_LIMIT.toString());
      url.searchParams.append('lang', 'en');

      const response = await fetch(url.toString());
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return this.processResults(data.features || []);
    } catch (error) {
      console.error('PhotonService error:', error);
      return [];
    }
  }

  private static processResults(features: PhotonFeature[]): City[] {
    return features
      .filter(feature => {
        // Only include actual cities/towns
        if (!feature?.properties?.type) return false;
        return ['city', 'town', 'village', 'municipality'].includes(feature.properties.type);
      })
      .map(feature => {
        const { properties, geometry } = feature;
        const [lng, lat] = geometry.coordinates;

        // Extract just the city name without extra info
        const name = (properties.name || properties.city || '')
          .split(',')[0]
          .trim();

        if (!name) return null;

        return {
          name,
          country: properties.country,
          coordinates: { lat, lng },
          importance: properties.importance,
          active: true
        };
      })
      .filter((city): city is City => Boolean(city.name));
  }
}