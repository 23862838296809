import { PEXELS_CONFIG } from './config/pexels';

// List of words that signal an image is of a facility, court, or venue
const FACILITY_TERMS = [
  "court", "field", "arena", "studio", "facility", "pitch", "range", "club", "hall", "empty", "aerial", "interior"
];

// Helper function to remove all emoji characters and variation selectors
const removeEmojis = (str: string): string =>
  str
    .replace(/\p{Emoji}/gu, '')
    .replace(/[\uFE0F\u200D]/g, ''); // Removes variation selectors and zero-width joiners

// Enriched mapping of activity names to search keywords
const ACTIVITY_KEYWORDS: { [key: string]: string[] } = {
  "Squash": ["squash", "squash facility interior", "squash center court"],
  "Tennis": ["tennis", "tennis club court", "tennis facility interior"],
  "Padel": ["padel match", "padel tennis"],
  "Racquetball": ["racquetball", "racquetball facility interior", "indoor racquetball arena"],
  "Table Tennis": ["ping pong table", "ping pong", "indoor ping pong"],
  "Basketball": ["basketball", "basketball court", "basketball stadium"],
  "Football": ["soccer", "soccer stadium field", "soccer ground aerial"],
  "5-a-side football": ["5-a-side football", "small football field", "indoor 5-a-side facility"],
  "American Football": ["american football stadium", "football field aerial", "american football pitch"],
  "Volleyball": ["volleyball", "volleyball facility", "indoor volleyball court"],
  "Baseball": ["baseball", "baseball diamond aerial", "baseball stadium interior"],
  "Softball": ["softball", "softball diamond", "softball stadium aerial"],
  "Rugby": ["rugby", "rugby", "rugby stadium ground"],
  "Cricket": ["cricket pitch aerial", "cricket ground", "cricket stadium field"],
  "Handball": ["handball", "indoor handball arena", "handball facility interior"],
  "Handball (Beach)": ["beach handball", "beach handball facility", "sand handball pitch"],
  "Netball": ["netball", "netball facility interior", "indoor netball arena"],
  "Futsal": ["futsal", "indoor football"],
  "Pickleball": ["pickleball", "pickleball facility interior", "outdoor pickleball court"],
  "Sepak Takraw": ["sepak takraw", "sepak takraw facility", "takraw court aerial"],
  "Billiards": ["snooker", "billiard"],
  "Running": ["running track", "athletics stadium track", "indoor running facility"],
  "Hiking": ["hiking trail", "mountain trail landscape", "scenic hiking path"],
  "Luge": ["luge track", "winter luge facility", "luge course aerial"],
  "Clay Pigeon Shooting": ["clay pigeon shooting range", "shooting club facility", "clay pigeon trap setup"],
  "Snooker": ["snooker table", "snooker hall interior", "snooker club setup"],
  "Bowling": ["bowling alley", "bowling lane aerial", "indoor bowling facility"],
  "Racing": ["racing track", "motor racing circuit aerial", "race track facility"],
  "Fencing": ["fencing ", "fencing arena interior", "fencing hall setup"],
  "Archery": ["archery range", "archery facility", "bow and arrow target"],
  "Athletics": ["athletics track", "stadium athletics facility", "running track aerial"],
  "Trekking": ["trekking trail", "mountain trekking facility", "scenic trekking path"],
  "Skateboarding": ["skateboarding park", "skateboarding facility", "skateboard ramp"],
  "Triathlon": ["triathlon", "triathlon facility", "triathlon training venue"],
  "Parkour": ["parkour", "parkour facility", "urban parkour setting"],
  "Orienteering": ["orienteering trail", "orienteering course", "navigation sport facility"],
  "Speed Walking": ["speed walking path", "urban speed walking facility", "walking track aerial"],
    "Walk & talk": ["walking path", "walking", "walking track aerial"],
  "Trail Running": ["trail running path", "trail running facility", "mountain trail running"],
  "Darts": ["darts board", "darts", "indoor darts game"],
  "Trail Biking": ["trail biking path", "mountain biking facility", "trail biking course"],
  "Cycling": ["cycling", "bike track", "cycling track"],
  "Skiing": ["snow ski slope", "snowy skiing resort aerial", "skiing on snow facility interior"],
  "Roller Skating": ["roller skating rink", "roller skating facility", "indoor roller skating arena"],
  "Skating": ["roller skating rink", "roller skating facility", "indoor skating arena"],
  "BMX": ["bmx track", "bmx facility", "bmx stunt park"],
  "Handcycling": ["handcycling track", "handcycling facility", "adaptive cycling arena"],
  "Quad Biking": ["quad biking trail", "quad biking facility", "off-road quad bike course"],
  "Ski Jumping": ["ski jumping"],
  "Cross-Country Skiing": ["cross-country skiing trail", "snow skiing facility", "winter cross-country ski course"],
  "Biathlon": ["biathlon", "biathlon facility", "winter biathlon track"],
  "Ice Skating": ["ice skating rink", "ice skating facility", "indoor ice rink"],
  "Curling": ["curling rink", "curling facility", "indoor curling arena"],
  "Snowshoeing": ["snowshoeing trail", "winter snowshoeing facility", "snowshoeing landscape"],
  "Speed Skating": ["speed skating rink", "speed skating facility", "ice speed skating track"],
  "Heli-Skiing": ["heli-skiing mountain", "heli-skiing facility", "aerial heli-skiing scene"],
  "Snowmobiling": ["snowmobiling trail", "snowmobile facility", "winter snowmobiling landscape"],
  "Ice Climbing": ["ice climbing wall", "ice climbing facility", "frozen rock climbing setup"],
  "Skeleton": ["skeleton track", "winter skeleton facility", "skeleton racing course"],
  "Bobsledding": ["bobsledding track", "bobsledding facility", "winter bobsled course"],
  "swimming": ["aqua swimming pool"],
  "Surfing": ["ocean waves aerial", "surf break landscape", "beach break waves"],
  "Sailing": ["sailing marina aerial", "yacht harbor", "sailing club dock"],
  "Wakeboarding": ["wakeboarding ramp", "wakeboarding facility", "wakeboarding water sports setup"],
  "Wakesurfing": ["wakesurfing facility", "wakesurfing board setup", "wakeboarding alternative water sports"],
  "Sea Sports": ["sea sports facility", "ocean sports venue", "water sports marina"],
  "Aquatics": ["aquatics center", "swimming pool aquatics facility", "modern aquatics training room"],
  "Rowing": ["rowing regatta", "rowing boat facility", "rowing team training area"],
  "Scuba Diving": ["coral reef underwater", "ocean reef landscape", "tropical fish reef"],
  "Snorkeling": ["coral reef underwater", "tropical reef fish", "underwater seascape"],
  "Freediving": ["freediving underwater", "freediving facility", "underwater freediving environment"],
  "Kayaking": ["kayaking river", "kayak facility", "paddle sports kayak setup"],
  "Windsurfing": ["windsurfing board", "windsurfing facility", "ocean windsurfing setup"],
  "Kitesurfing": ["kitesurfing facility", "kitesurfing board aerial", "ocean kitesurfing scene"],
  "Fishing": ["fishing boat", "fishing dock facility", "fishing equipment setup"],
  "Jet Skiing": ["jet skiing water", "jet ski facility", "water sports jet ski setup"],
  "Parasailing": ["parasailing setup", "parasailing facility", "aerial parasailing scene"],
  "Water Polo": ["water polo pool", "water polo facility", "indoor water polo arena"],
  "Whitewater Rafting": ["whitewater rafting river", "rafting facility", "whitewater rafting adventure"],
  "Deep Sea Fishing": ["deep sea fishing boat", "fishing vessel facility", "ocean deep sea fishing"],
  "Kiteboarding": ["kiteboarding facility", "kiteboarding board aerial", "ocean kiteboarding scene"],
  "Canoeing": ["canoeing river", "canoe facility", "outdoor canoeing setup"],
  "Stand-Up Paddleboarding": ["sup paddleboarding", "stand-up paddleboarding facility", "sup board water scene"],
  "Bodyboarding": ["bodyboarding beach", "bodyboarding facility", "surfing bodyboard setup"],
  "Hydrofoil Surfing": ["hydrofoil surfing", "hydrofoil board facility", "ocean hydrofoil surfing scene"],
  "Flyboarding": ["flyboarding water", "flyboard facility", "extreme flyboarding setup"],
  "Skurfing": ["skurfing board", "skurfing facility", "ocean skurfing scene"],
  "Canoe Polo": ["canoe polo", "canoe polo facility", "water polo in canoe setup"],
  "Kneeboarding": ["kneeboarding", "kneeboard facility", "water sports kneeboard setup"],
  "Body Surfing": ["body surfing wave", "body surfing facility", "ocean body surfing scene"],
  
  // --- Added Missing Wellness & Fitness Activities ---
  "Spa": ["spa", "luxury spa", "spa center"],
  "Shooting": ["shooting range", "indoor shooting facility", "shooting club setup"],
  "AcroYoga": ["acroyoga studio interior", "acroyoga class", "acroyoga facility"],
  "Sauna": ["sauna room", "indoor sauna facility", "sauna club interior"],
  "Yoga": ["yoga studio", "indoor yoga facility", "yoga class setup"],
  "Pilates": ["pilates", "pilates class", "pilates studio"],
  "Fitness": ["fitness center", "modern fitness club", "gym fitness"],
  "Weight Training": ["weight training"],
  "Gym": ["gym facility", "indoor gym interior", "fitness club gym"],
  "Meditation": ["meditation room", "quiet meditation facility", "indoor meditation space"],
  "Aerobics": ["aerobics studio", "aerobics class facility", "indoor aerobics setup"],
  "Zumba": ["fitness aerobics studio dancing group action"],
  "Hot Yoga": ["hot yoga studio", "hot yoga facility interior", "indoor hot yoga class"],
  "Piloxing": ["piloxing studio", "piloxing class facility", "indoor piloxing setup"],
  "Chair Yoga": ["chair yoga class", "chair yoga studio interior", "adaptive yoga facility"],
  "TRX Training": ["trx training facility", "trx workout studio", "indoor trx setup"],
  "Rebounding": ["rebounding gym facility", "indoor rebound training", "rebounding class setup"],
  "Powerlifting": ["powerlifting gym facility", "powerlifting training room", "weightlifting facility"],
  "CrossFit": ["crossfit gym", "crossfit training facility", "indoor crossfit setup"],
  "Dance": ["dance studio", "dance class", "dancing"],
  "Stretching": ["stretching excercise"],
  "Tai Chi": ["tai chi park", "tai chi class facility", "outdoor tai chi setting"],
  "Breathwork": ["breath in"],
  "Mindfulness": ["mindfulness center", "indoor mindfulness space", "mindfulness facility interior"],
  "Barre": ["barre studio", "barre class facility", "indoor barre workout"],
  "Bodyweight Training": ["bodyweight training facility", "indoor bodyweight workout", "bodyweight training area"],
  "Functional Training": ["fitness training"],
  "Foam Rolling": ["foam rolling room", "foam rolling facility interior", "indoor foam rolling setup"],
  "Aqua Aerobics": ["aqua aerobics pool", "aquatic aerobics facility", "indoor aqua aerobics setup"],
  "Postnatal Fitness": ["postnatal fitness facility", "postnatal workout studio", "indoor postnatal fitness class"],
  "High-Intensity Interval Training (HIIT)": ["fitness training"],
  "Hot Pilates": ["pilates studio", "pilates class"],
  "Spinning / Indoor Cycling": ["indoor cycling studio", "spinning class facility", "indoor cycling setup"],
  "Aerial Yoga": ["yoga class", "aerial yoga"],
  "Aerial Silks": ["aerial silks studio", "aerial silks facility interior", "aerial silks class setup"],
  "Sound Bath": ["sound bath room", "indoor sound bath facility", "sound bath meditation venue"],
  "Aqua Zumba": ["aqua zumba pool", "aquatic zumba facility", "indoor aqua zumba setup"],
  "Cryotherapy": ["cryotherapy chamber", "cryotherapy facility interior", "cold cryotherapy room"],
  "Infrared Sauna": ["infrared sauna room", "infrared sauna facility", "indoor infrared sauna setup"],
  "Cold Plunge Therapy": ["ice bath"],
  
  // --- Added Missing Combat Sports ---
  "Martial Arts": ["martial arts"],
  "Boxing": ["boxingfight", "boxing ring", "boxing arena"],
  "Karate": ["karate dojo", "karate training facility", "indoor karate class"],
  "Taekwondo": ["taekwondo dojo", "taekwondo training facility", "indoor taekwondo class"],
  "Aikido": ["aikido dojo", "aikido training facility", "indoor aikido class"],
  "Judo": ["judo dojo", "judo training facility", "indoor judo class"],
  "Brazilian Jiu-Jitsu": ["bjj dojo", "brazilian jiu-jitsu facility", "indoor bjj class"],
  "Muay Thai": ["muay thai ring", "muay thai training facility", "indoor muay thai class"],
  "Kickboxing": ["kickboxing gym", "kickboxing training facility", "indoor kickboxing class"],
  "Krav Maga": ["krav maga training facility", "indoor krav maga class", "krav maga dojo"],
  "Capoeira": ["capoeira class", "capoeira studio interior", "indoor capoeira facility"],
  "Wrestling": ["wrestling mat", "wrestling facility interior", "indoor wrestling arena"],
  "Sambo": ["sambo training facility", "sambo dojo interior", "indoor sambo class"],
  "Hapkido": ["hapkido dojo", "hapkido training facility", "indoor hapkido class"],
  "Kung Fu": ["kung fu school", "kung fu training facility", "indoor kung fu dojo"],
  "Jujitsu": ["jujitsu dojo", "jujitsu training facility", "indoor jujitsu class"],
  "Savate": ["savate training facility", "indoor savate class", "savate dojo setup"],
  "Sumo Wrestling": ["sumo wrestling arena", "sumo training facility", "indoor sumo setup"],
  "Kendo": ["kendo dojo", "kendo training facility", "indoor kendo class"],
  "Iaido": ["iaido dojo", "iaido training facility", "indoor iaido class"],
  "Systema": ["systema training facility", "indoor systema class", "systema dojo"],
  
  // --- Added Missing Other Sports ---
  "Hockey": ["hockey rink", "indoor hockey facility", "hockey arena setup"],
  "Boating": ["boating marina", "boating facility interior", "boat club venue"],
  "Floorball": ["floorball", "indoor floorball facility", "floorball arena"],
  "Bocce": ["bocce", "bocce facility interior", "outdoor bocce court"],
  "Petanque": ["petanque", "petanque facility interior", "outdoor petanque setup"],
  "Ultimate Frisbee": ["ultimate frisbee", "ultimate frisbee facility", "frisbee field aerial"],
  "Lacrosse": ["lacrosse", "lacrosse facility interior", "lacrosse stadium setup"],
  "Spikeball": ["spikeball", "spikeball facility interior", "indoor spikeball setup"],
  "Dodgeball": ["dodgeball", "dodgeball facility interior", "indoor dodgeball arena"],
  "Speedball": ["speedball", "speedball facility interior", "indoor speedball setup"],
  "Badminton": ["badminton", "badminton facility interior", "indoor badminton arena"],
  "Ice Hockey": ["ice hockey rink", "indoor ice hockey facility", "ice hockey arena"],
  "Karting": ["karting track", "karting facility interior", "outdoor karting circuit"],
  "Car Racing": ["car racing track", "car racing facility interior", "motor racing circuit"],
  "Motorcycle Racing": ["motorcycle racing track", "motorcycle racing facility", "indoor motorcycle racing arena"],
  "Off-Road Driving": ["off-road driving", "off-road facility interior", "off-road track setup"],
  "Rally Racing": ["rally racing track", "rally facility interior", "rally racing circuit"],
  "Motocross": ["motocross track", "motocross facility interior", "outdoor motocross course"],
  "Go-Karting": ["go-kart track", "go-karting facility interior", "indoor go-kart circuit"],
  "Formula Racing": ["formula racing track", "formula racing facility interior", "indoor formula racing setup"],
  "Drag Racing": ["drag racing ", "drag racing facility interior", "indoor drag racing setup"],
  "Off-Roading": ["off-roading trail", "off-road facility interior", "outdoor off-roading setup"],
  "Drifting": ["drifting track", "drifting facility interior", "indoor drifting setup"],
  "Golf": ["golf", "golf facility interior", "golf club venue"],
  "Snowboarding": ["snowboarding slope", "snowboarding facility interior", "snowboarding resort setup"],
  "Horse Riding": ["horse riding arena", "equestrian facility interior", "horse riding stable setup"],
  "Polo": ["polo", "polo facility interior", "polo club venue"],
  "Horse Racing": ["horse racing track", "horse racing facility interior", "horse racing stadium setup"],
  "Equestrian": ["equestrian arena", "equestrian facility interior", "indoor equestrian setup"],
  "Dressage": ["dressage arena", "dressage facility interior", "indoor dressage setup"],
  "Show Jumping": ["show jumping arena", "show jumping facility interior", "indoor show jumping setup"],
  "Eventing": ["eventing", "eventing facility interior", "outdoor eventing setup"],
  "Vaulting": ["vaulting arena", "vaulting facility interior", "indoor vaulting setup"]
};

const DEFAULT_IMAGE = 'https://images.unsplash.com/photo-1486218119243-13883505764c?auto=format&fit=crop&w=1600&q=80';

const EXCLUDE_TERMS = [
  'person',
  'portrait',
  'people',
  'player',
  'players',
  'crowd',
  'audience',
  'spectator',
  'spectators',
  'athlete',
  'athletes',
  'trainer',
  'coach',
  'instructor',
  'student',
  'class',
  'team',
  'group',
  'selfie',
  'face',
  'body',
  'human',
  'child',
  'children',
  'family'
];

// Create a lowercase version of ACTIVITY_KEYWORDS for case-insensitive lookup
const LOWERCASE_ACTIVITY_KEYWORDS = Object.keys(ACTIVITY_KEYWORDS).reduce((acc, key) => {
  acc[key.toLowerCase()] = ACTIVITY_KEYWORDS[key];
  return acc;
}, {} as { [key: string]: string[] });

const getActivityKeywords = (activity: string): string[] => {
  // Remove emojis and extra spaces from the activity name
  const cleanedActivity = activity.split(' ').map(removeEmojis).join(' ').trim();
  const lowerCleaned = cleanedActivity.toLowerCase();
  return LOWERCASE_ACTIVITY_KEYWORDS[lowerCleaned] ||
    [`${cleanedActivity} sports facility`, `${cleanedActivity} venue`];
};

// Helper function to filter photos based on keyword matching and facility terms
const filterPhotos = (photos: any[], keyword: string, getDescription: (photo: any) => string): any[] => {
  // Strict filtering: require keyword match and a facility term
  let filtered = photos.filter((photo: any) => {
    const desc = getDescription(photo).toLowerCase();
    const hasExcludedTerms = [...EXCLUDE_TERMS, ...PEXELS_CONFIG.EXCLUDE_KEYWORDS.split(' -')]
      .some(term => desc.includes(term.toLowerCase()));
    const keywordMatch = keyword.toLowerCase().split(' ').some(word => desc.includes(word));
    const hasFacilityTerms = FACILITY_TERMS.some(term => desc.includes(term));
    return !hasExcludedTerms && keywordMatch && hasFacilityTerms;
  });
  // If no photos pass the strict filter, relax the facility term requirement
  if (filtered.length === 0) {
    filtered = photos.filter((photo: any) => {
      const desc = getDescription(photo).toLowerCase();
      const hasExcludedTerms = [...EXCLUDE_TERMS, ...PEXELS_CONFIG.EXCLUDE_KEYWORDS.split(' -')]
        .some(term => desc.includes(term.toLowerCase()));
      const keywordMatch = keyword.toLowerCase().split(' ').some(word => desc.includes(word));
      return !hasExcludedTerms && keywordMatch;
    });
  }
  return filtered;
};

export const fetchActivityImage = async (activity: string): Promise<string> => {
  // Remove emojis and normalize the activity name
  const cleanedActivity = activity.split(' ').map(removeEmojis).join(' ').trim();
  const lowerActivity = cleanedActivity.toLowerCase();
  
  try {
    const keywords = getActivityKeywords(activity);
    console.log('Fetching image for activity:', {
      original: activity,
      normalized: lowerActivity,
      keywords
    });
    
    // Loop through each keyword and gather valid images from all services
    for (const keyword of keywords) {
      const validPhotosAll: { url: string }[] = [];
      
      // Pexels
      try {
        const res = await fetch(
          `https://api.pexels.com/v1/search?query=${encodeURIComponent(keyword)}&per_page=30&orientation=landscape`,
          {
            headers: { 
              Authorization: import.meta.env.VITE_PEXELS_API_KEY || '',
              'Content-Type': 'application/json'
            }
          }
        );
        if (res.ok) {
          const data = await res.json();
          const photos = data.photos || [];
          const validPhotos = filterPhotos(photos, keyword, (photo) => photo.alt || '');
          validPhotosAll.push(...validPhotos.map((photo: any) => ({ url: photo.src.large2x })));
        }
      } catch (e) {
        console.warn('[ACTIVITY IMAGE] Pexels fetch error:', e);
      }
      
      // Unsplash
      try {
        const unsplashResponse = await fetch(
          `https://api.unsplash.com/search/photos?query=${encodeURIComponent(keyword)}&orientation=landscape&per_page=30`,
          {
            headers: { 'Authorization': `Client-ID ${import.meta.env.VITE_UNSPLASH_ACCESS_KEY}` }
          }
        );
        if (unsplashResponse.ok) {
          const unsplashData = await unsplashResponse.json();
          const photos = unsplashData.results || [];
          const validPhotos = filterPhotos(photos, keyword, (photo) => (photo.description || photo.alt_description || ''));
          validPhotosAll.push(...validPhotos.map((photo: any) => ({ url: photo.urls.regular })));
        }
      } catch (e) {
        console.warn('[ACTIVITY IMAGE] Unsplash fetch error:', e);
      }
      
      // Pixabay
      try {
      const pixabayResponse = await fetch(
  `https://pixabay.com/api/?key=${import.meta.env.VITE_PIXABAY_API_KEY}&q=${encodeURIComponent(keyword)}&image_type=photo&orientation=horizontal&safesearch=true&per_page=30`
);

        if (pixabayResponse.ok) {
          const pixabayData = await pixabayResponse.json();
          const photos = pixabayData.hits || [];
          const validPhotos = filterPhotos(photos, keyword, (photo) => photo.tags || '');
          validPhotosAll.push(...validPhotos.map((photo: any) => ({ url: photo.largeImageURL })));
        }
      } catch (e) {
        console.warn('[ACTIVITY IMAGE] Pixabay fetch error:', e);
      }
      
      // If any valid photos were found from any service for this keyword, return a random one
      if (validPhotosAll.length > 0) {
        const randomPhoto = validPhotosAll[Math.floor(Math.random() * validPhotosAll.length)];
        return randomPhoto.url;
      }
    }
    
    return DEFAULT_IMAGE;
  } catch (e) {
    console.warn('[ACTIVITY IMAGE] Fetch failed:', e);
    return DEFAULT_IMAGE;
  }
};
