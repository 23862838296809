import React from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import { useEffect, useState } from 'react';
import { Search, Filter, Loader2, FrownIcon } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useConnectionStore } from '../store/connectionStore';
import { PLAYERS_CACHE_DURATION, playersCache } from '../lib/cache/players';
import { getUserLocation } from '../lib/utils/location';
import { Player } from '../types/matchmaking';
import { calculateLocationDistance } from '../lib/utils/coordinates';
import PlayerSkeleton from '../components/matchmaking/PlayerSkeleton';
import PlayerCardList from '../components/matchmaking/PlayerCardList';
import WelcomeMessage from '../components/matchmaking/WelcomeMessage';
import { Button } from '../components/ui/button';
import { cn } from '@/lib/utils';

interface LocationState {
  searchKey?: number;
  preSelectedSport?: string;
  preSelectedCompany?: string;
  city?: string;
}

const MatchMaking = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSport, setSelectedSport] = useState('all');
  const [selectedCity, setSelectedCity] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [sports, setSports] = useState<Set<string>>(new Set());
  const [cities, setCities] = useState<Set<string>>(new Set());
  const { connections, fetchConnections } = useConnectionStore();
  const [cacheKey] = useState(`players-${Date.now()}`);
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const location = useLocation();
  const state = location.state as LocationState;

  // Set initial sport from state if provided
  useEffect(() => {
    if (state?.preSelectedSport) {
      setSelectedSport(state.preSelectedSport);
    }
    if (state?.preSelectedCompany) {
      setSearchTerm(state.preSelectedCompany);
    }
  }, [state?.preSelectedSport]);

  // Fetch connections when component mounts
  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  const fetchWithRetry = async (operation: () => Promise<any>, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        const result = await operation();
        return result;
      } catch (error) {
        if (
          i === retries - 1 ||
          !(error instanceof Error) ||
          !error.message.includes('Failed to fetch')
        ) {
          throw error;
        }
        // Wait before retrying (exponential backoff)
        await new Promise((resolve) =>
          setTimeout(resolve, Math.pow(2, i) * 1000)
        );
        console.log(`Retry attempt ${i + 1} of ${retries}...`);
      }
    }
    throw new Error('Failed to fetch after retries');
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        // Get user's location
        try {
          const location = await getUserLocation();
          setUserLocation(location);
        } catch (error) {
          console.warn('Failed to get user location:', error);
        }

        setIsLoading(true);
        setError(null);

        // Check cache first
        const cached = playersCache.get('players');
        console.log('Cached Players: ', cached);
        if (cached && Date.now() - cached.timestamp < PLAYERS_CACHE_DURATION) {
          console.log('Using cached players');
          setPlayers(cached.data);
          // Also restore sports and cities from cache
          if (cached.data.length > 0) {
            const cachedSports = new Set(cached.data.flatMap((p) => p.sports));
            const cachedCities = new Set(cached.data.map((p) => p.location));
            setSports(cachedSports);
            setCities(cachedCities);
          }
          setIsLoading(false);
          return;
        }

        // Get current user's ID with retry
        const {
          data: { user },
          error: userError,
        } = await fetchWithRetry(() => supabase.auth.getUser());

        if (userError) throw userError;
        if (!user) throw new Error('Not authenticated');

        // Get current user's city
        const { data: currentUserData, error: currentUserError } =
          await fetchWithRetry(() =>
            supabase
              .from('users')
              .select('city')
              .eq('auth_id', user.id)
              .single()
          );

        if (currentUserError) throw currentUserError;
        if (!currentUserData) throw new Error('User data not found');

        // Fetch users with their sports (removed user_profiles join)
        const { data, error } = await fetchWithRetry(() =>
          supabase
            .from('users')
            .select(
              `
              *,
              user_sports (
                sports (
                  name,
                  emoji
                )
              )
            `
            )
            .neq('auth_id', user.id)
        );

        if (error) throw error;

        // Transform data to match Player type
        const transformedPlayers = data.map((user) => {
          // Since user_profiles data is removed, use default values
          const userSports =
            user.user_sports?.map(
              (us) => `${us.sports.emoji} ${us.sports.name}`
            ) || [];

          // Update sports and cities sets
          setSports((prev) => new Set([...prev, ...userSports]));
          setCities((prev) => new Set([...prev, user.city]));

          // Calculate distance using geolocation if available, otherwise fall back to city-based
          const distance = userLocation
            ? calculateLocationDistance(userLocation, user.city)
            : calculateLocationDistance(currentUserData.city, user.city);

          // Check if user is connected
          const connection = connections.find(
            (conn) => conn.other_user?.auth_id === user.auth_id
          );
          const isConnected = connection?.status === 'accepted';
          return {
            id: user.auth_id,
            name: user.full_name,
            age: 0, // default age
            location: user.city,
            sports: userSports,
            rating: 4.5, // default rating
            matchPercentage: Math.floor(Math.random() * 15 + 85),
            availability: ['morning', 'afternoon', 'evening'],
            image:
              user.avatar_url ||
              'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
            company: {
              name: user.company || 'Company',
              position: user.job_type || 'Role',
              industry: user.industry, // updated from preferences.industry
            },
            distance,
            isConnected,
            recentActivity: 'Recently active',
          };
        });

        setPlayers(transformedPlayers);

        // Cache the results
        playersCache.set('players', {
          data: transformedPlayers,
          timestamp: Date.now(),
        });
      } catch (error) {
        console.error('Error fetching players:', error);
        if (error instanceof Error && error.message === 'Failed to fetch') {
          setError(
            'Unable to connect to server. Please check your connection.'
          );
        } else {
          setError(
            error instanceof Error ? error.message : 'Failed to load players'
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlayers();
  }, [cacheKey]);

  // Filter players based on search and filters
  const filteredPlayers = players.filter((player) => {
    const matchesSearch =
      searchTerm === '' ||
      (state?.preSelectedCompany
        ? player.company.name.toLowerCase() === searchTerm.toLowerCase()
        : [
            player.name,
            player.location,
            ...player.sports,
            player.company.name,
            player.company.position
          ].some(field =>
            field.toLowerCase().includes(searchTerm.toLowerCase())
          ));

    const matchesSport =
      selectedSport === 'all' || 
      player.sports.some(sport => sport === selectedSport);

    const matchesCity =
      selectedCity === 'all' || player.location === selectedCity;

    return matchesSearch && matchesSport && matchesCity;
  });

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">Find & Play</h1>
              <p className="text-dark-800 mt-1">
                Find your perfect sports partner
              </p>
            </div>
          </div>
        </div>

        {/* Search and Filters */}
        <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
          <div className="flex flex-col space-y-4">
            {/* Search Bar */}
            <div className="flex items-center gap-4">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                <input
                  type="text"
                  placeholder="Search players, sports, locations, companies..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 bg-dark-300 border border-dark-400 rounded-xl text-white placeholder-dark-800"
                />
              </div>
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="flex items-center space-x-2 px-3 py-2 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors"
              >
                <Filter className="h-5 w-5 text-dark-800" />
                <span className="text-white text-sm">
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </span>
              </button>
            </div>

            {/* Filters */}
            {showFilters && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <select
                  value={selectedSport}
                  onChange={(e) => setSelectedSport(e.target.value)}
                  className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                >
                  <option value="all">All Sports</option>
                  {[...sports].sort().map((sport) => (
                    <option key={sport} value={sport}>
                      {sport}
                    </option>
                  ))}
                </select>

                <select
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                  className="bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                >
                  <option value="all">All Cities</option>
                  {[...cities].sort().map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Loading State */}
        {isLoading && <PlayerSkeleton count={5} />}

        {/* Error State */}
        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
            <p className="text-center mb-2">
              {error === 'Failed to fetch'
                ? 'Unable to connect to server. Please check your connection.'
                : error}
            </p>
            <button
              onClick={() => window.location.reload()}
              className="text-sm underline mt-2 hover:text-red-400 mx-auto block"
            >
              Try Again
            </button>
          </div>
        )}

        {/* Players List */}
        {!isLoading && !error && (
          <div className="space-y-4">
            {filteredPlayers.length > 0 ? (
              <PlayerCardList
                players={filteredPlayers}
                onMatchSelect={() => {}}
              />
            ) : (
              <div className="bg-dark-200 rounded-2xl p-6 sm:p-8 border border-dark-300 text-center">
                <div className="flex justify-center mb-4">
                  <FrownIcon className="h-12 w-12 text-dark-800" />
                </div>
                <h3 className="text-lg font-medium text-white mb-2">
                  No Players Found
                </h3>
                <p className="text-dark-800 mb-6 max-w-md mx-auto">
                  Try adjusting your filters or search criteria to find more
                  players.
                </p>
                <Button
                  onClick={() => {
                    setSearchTerm('');
                    setSelectedSport('all');
                    setSelectedCity('all');
                  }}
                  className="inline-flex items-center space-x-2 px-6 py-2 bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-xl transition-colors"
                >
                  Reset Filters
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default MatchMaking;
