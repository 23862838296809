import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ChatLayout from '../components/chat/layout/ChatLayout';
import { useChat } from '../hooks/useChat';

const Messages: React.FC = () => {
    const location = useLocation();
    // Initialize activeChat from location.state if available.
    const [activeChat, setActiveChat] = useState<string | null>(location.state?.activeChat || null);
    // Chat is considered active if activeChat is non-null.
    const isChatActive = !!activeChat;

    const {
        messages,
        isLoading,
        error,
        messageInput,
        setMessageInput,
        isTyping,
        handleSendMessage,
        handleMarkAsRead,
        conversations,
        createConversation,
        isInitialized,
    } = useChat(activeChat);

    // Update activeChat and set initial message if provided via navigation state.
    useEffect(() => {
        if (location.state?.activeChat) {
            setActiveChat(location.state.activeChat);
        }
        if (location.state?.initialMessage) {
            setMessageInput(location.state.initialMessage);
        }
    }, [location.state, setMessageInput]);

    // Compute whether to hide navigation on mobile.
    const [shouldHideNav, setShouldHideNav] = useState(false);

    useEffect(() => {
        const updateHideNav = () => {
            const isMobile = window.innerWidth < 768;
            setShouldHideNav(isMobile && isChatActive);
        };

        updateHideNav();
        window.addEventListener('resize', updateHideNav);
        return () => window.removeEventListener('resize', updateHideNav);
    }, [isChatActive]);

    // Callback from ChatLayout when chat state changes.
    const handleChatStateChange = (isActive: boolean) => {
        if (!isActive) {
            setActiveChat(null);
        }
    };

    return (
        <DashboardLayout hideNav={shouldHideNav}>
            <ChatLayout
                activeChat={activeChat}
                onChatSelect={setActiveChat}
                onChatStateChange={handleChatStateChange}
                messages={messages}
                isLoading={isLoading}
                error={error}
                messageInput={messageInput}
                setMessageInput={setMessageInput}
                isTyping={isTyping}
                onSendMessage={(chatId: string) => {
                    if (chatId && messageInput?.trim()) {
                        handleSendMessage(chatId, messageInput);
                    }
                }}
                onMarkAsRead={handleMarkAsRead}
                showMobileList={!isChatActive}
                conversations={conversations}
                createConversation={createConversation}
                isInitialized={isInitialized}
            />
        </DashboardLayout>
    );
};

export default Messages;
