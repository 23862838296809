import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { Post } from '../../../types/post';
import EmptyPostsState from '../../social/EmptyPostsState';
import MomentsSkeleton from '../../social/MomentsSkeleton';
import PostComments from '../../social/post/PostComments';
import PostComposer from '../../social/PostComposer';
import { motion, AnimatePresence } from 'framer-motion';
import { Grid, LayoutList } from 'lucide-react';
import { Heart, MessageCircle, Share2 } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../../ui/dialog';
import { Button } from '../../ui/button';
import PostMenu from '../../social/post/PostMenu';
import PostContent from '../../social/post/PostContent';
import {
  getCachedPosts,
  setCachedPosts,
  initializePostsCache,
} from '../../../lib/cache/posts';
import { cn } from '@/lib/utils';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const POSTS_PER_PAGE = 5;

interface PostsTabProps {
  memberName: string;
  memberId?: string;
}

const PostsTab: React.FC<PostsTabProps> = ({ memberName, memberId }) => {
  const { user } = useAuth();
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observerRef = useRef<IntersectionObserver>();
  const loadingRef = useRef<HTMLDivElement>(null);
  const [viewMode, setViewMode] = useState<'list' | 'grid'>('grid');
  const [isCached, setIsCached] = useState(false);
  const [likedPosts, setLikedPosts] = useState<Set<string>>(new Set());
  const [expandedPost, setExpandedPost] = useState<string | null>(null);
  const postRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const commentsRef = useRef<Record<string, HTMLDivElement | null>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState('');

  // Handle edit submission
  const handleEditSubmit = async () => {
    if (!selectedPost) return;

    try {
      const { error } = await supabase
        .from('posts')
        .update({ content })
        .eq('id', selectedPost.id);

      if (error) throw error;

      // Update local state
      setPosts((prev) =>
        prev.map((post) =>
          post.id === selectedPost.id ? { ...post, content } : post
        )
      );

      // Reset edit state
      setIsEditing(false);
      setSelectedPost(null);
      setContent('');
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  const handleEdit = async (post: Post) => {
    setSelectedPost(post);
    setContent(post.content);
    setIsEditing(true);
  };

  const handleDelete = async (post: Post) => {
    setSelectedPost(post);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (!selectedPost) return;

    try {
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', selectedPost.id);

      if (error) throw error;

      // Remove post from state
      setPosts((prev) => prev.filter((p) => p.id !== selectedPost.id));
      setShowDeleteDialog(false);
      setSelectedPost(null);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };
  const fetchWithRetry = async function<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
    try {
      return await operation();
    } catch (error) {
      if (
        retries > 0 &&
        error instanceof Error &&
        error.message.includes('Failed to fetch')
      ) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        return fetchWithRetry(operation, retries - 1);
      }
      throw error;
    }
  }

  const isOwnProfile = !memberId || memberId === user?.id;

  const handleLike = async (postId: string) => {
    try {
      const isLiked = likedPosts.has(postId);

      if (!user) {
        throw new Error('Must be logged in to like posts');
      }

      // Optimistically update UI
      setLikedPosts((prev) => {
        const newSet = new Set(prev);
        if (isLiked) {
          newSet.delete(postId);
        } else {
          newSet.add(postId);
        }
        return newSet;
      });

      setPosts((prev) =>
        prev.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              likes: Math.max(0, post.likes + (isLiked ? -1 : 1)),
            };
          }
          return post;
        })
      );

      // Call API
      // Get user's database ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user?.id)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('User profile not found');

      if (isLiked) {
        // Delete like
        const { error } = await supabase.from('post_likes').delete().match({
          post_id: postId,
          user_id: userData.id,
        });
        if (error) throw error;
      } else {
        // Insert like
        const { error } = await supabase.from('post_likes').insert({
          post_id: postId,
          user_id: userData.id,
        });
        if (error) throw error;
      }
    } catch (error) {
      console.error('Error toggling like:', error);

      // Revert optimistic update on error
      setLikedPosts((prev) => {
        const newSet = new Set(prev);
        if (likedPosts.has(postId)) {
          newSet.add(postId);
        } else {
          newSet.delete(postId);
        }
        return newSet;
      });

      setPosts((prev) =>
        prev.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              likes: post.likes + (likedPosts.has(postId) ? 1 : -1),
            };
          }
          return post;
        })
      );
    }
  };

  const handleComment = async (postId: string) => {
    const isExpanding = expandedPost !== postId;

    // If we're expanding a different post, close the current one first
    if (expandedPost && expandedPost !== postId) {
      setExpandedPost(null);
      await new Promise((resolve) => setTimeout(resolve, 300)); // Wait for collapse animation
    }

    setExpandedPost(isExpanding ? postId : null);

    if (isExpanding) {
      // Wait for expansion animation before scrolling
      await new Promise((resolve) => setTimeout(resolve, 100));

      const postElement = postRefs.current[postId];
      const commentsElement = commentsRef.current[postId];

      if (postElement) {
        const headerHeight = 64; // Adjust based on your header height
        const windowHeight = window.innerHeight;
        const postRect = postElement.getBoundingClientRect();
        const scrollTop = window.pageYOffset + postRect.top - headerHeight;

        window.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });

        // Focus comments input after scrolling
        setTimeout(() => {
          const input = commentsElement?.querySelector('input');
          if (input) {
            input.focus();
          }
        }, 500);
      }
    }
  };

  const handleShare = async (postId: string) => {
    try {
      const shareData = {
        title: 'Check out this post on Playclub',
        text: 'Check out this post on Playclub',
        url: `${window.location.origin}/dashboard?post=${postId}`,
      };

      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback to clipboard copy
        await navigator.clipboard.writeText(shareData.url);
      }

      // Update share count
      setPosts((prev) =>
        prev.map((post) => {
          if (post.id === postId) {
            return {
              ...post,
              shares: post.shares + 1,
            };
          }
          return post;
        })
      );
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  // Initialize cache on mount
  useEffect(() => {
    initializePostsCache();
  }, []);

  // Set up infinite scroll
  useEffect(() => {
    if (isLoading || !hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoadingMore) {
          setPage((prev) => prev + 1);
        }
      },
      { threshold: 0.5 }
    );

    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [hasMore, isLoading, isLoadingMore]);

  const handleNewPost = async (content: string, image?: File) => {
    try {
      setIsSubmitting(true);

      const {
        data: { user: currentUser },
      } = await supabase.auth.getUser();
      if (!currentUser) throw new Error('Not authenticated');

      let mediaUrl: string | null = null;

      // Handle image upload if provided
      if (image) {
        try {
          // Validate file size and type
          if (image.size > 5 * 1024 * 1024) {
            throw new Error('Image size must be less than 5MB');
          }

          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
          if (!allowedTypes.includes(image.type)) {
            throw new Error('Only JPEG, PNG and GIF images are allowed');
          }

          // Generate unique filename
          const extension = image.name.split('.').pop()?.toLowerCase() || 'jpg';
          const sanitizedName = image.name
            .replace(/[^a-zA-Z0-9]/g, '_')
            .toLowerCase();
          const filePath = `user_directory/${
            currentUser.id
          }/${Date.now()}-${sanitizedName}.${extension}`;

          if (uploadError) throw uploadError;

          // Get public URL
          const {
            data: { publicUrl },
          } = supabase.storage.from('social-feed').getPublicUrl(filePath);

          mediaUrl = publicUrl;
        } catch (uploadError) {
          console.error('Error uploading image:', uploadError);
          throw new Error(
            uploadError instanceof Error
              ? uploadError.message
              : 'Failed to upload image'
          );
        }
      }

      // Get user's database ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', currentUser.id)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('User profile not found');

      // Create post
      const { data: newPost, error: postError } = await supabase
        .from('posts')
        .insert({
          user_id: userData.id,
          content,
          [image?.type.startsWith('video/') ? 'video_url' : 'media_url']:
            mediaUrl,
        })
        .select(
          `
          id,
          content,
          media_url,
          video_url,
          likes_count,
          comments_count,
          shares_count,
          created_at,
          users (
            id,
            auth_id,
            full_name,
            avatar_url,
            city
          )
        `
        )
        .single();

      if (postError) throw postError;

      // Add new post to state
      const transformedPost: Post = {
        id: newPost.id,
        author: {
          id: newPost.users.auth_id,
          name: newPost.users.full_name,
          image: newPost.users.avatar_url || '',
          location: newPost.users.city,
        },
        content: newPost.content,
        media: newPost.media_url,
        video: newPost.video_url,
        likes: newPost.likes_count,
        comments: newPost.comments_count,
        shares: newPost.shares_count,
        timeAgo: newPost.created_at,
      };

      setPosts((prev) => [transformedPost, ...prev]);
    } catch (error) {
      console.error('Error creating post:', error);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchPosts = React.useCallback(async () => {
    try {
      const targetUserId = memberId || user?.id;
      if (!targetUserId) {
        console.warn('No user ID available');
        return;
      }

      // Check cache first
      const cached = getCachedPosts(targetUserId);
      if (cached && page === 1) {
        console.log('Using cached posts');
        setPosts(cached);
        setIsCached(true);
        setIsLoading(false);
        return;
      }

      if (page === 1) {
        setIsLoading(true);
      } else {
        setIsLoadingMore(true);
      }
      setError(null);

      // Get target user's database ID
      const { data: targetUser, error: targetUserError } = await fetchWithRetry(
        () =>
          supabase
            .from('users')
            .select('id')
            .eq('auth_id', targetUserId)
            .single()
      );

      if (targetUserError) throw targetUserError;
      if (!targetUser) throw new Error('User not found');

      // Fetch posts with pagination
      const { data: postsData, error: postsError } = await fetchWithRetry(() =>
        supabase
          .from('posts')
          .select(
            `
            id,
            content,
            media_url,
            video_url,
            likes_count,
            comments_count,
            shares_count,
            created_at,
            users!inner (
              id,
              auth_id,
              full_name,
              avatar_url,
              city
            )
          `
          )
          .eq('user_id', targetUser.id)
          .order('created_at', { ascending: false })
          .range((page - 1) * POSTS_PER_PAGE, page * POSTS_PER_PAGE - 1)
      );

      if (postsError) throw postsError;

      const transformedPosts: Post[] = (postsData || []).map((post) => ({
        id: post.id,
        author: {
          id: post.users.auth_id,
          name: post.users.full_name,
          image: post.users.avatar_url || '',
          location: post.users.city,
        },
        content: post.content,
        media: post.media_url,
        video: post.video_url,
        likes: post.likes_count,
        comments: post.comments_count,
        shares: post.shares_count,
        timeAgo: post.created_at,
      }));

      setPosts((prev) =>
        page === 1 ? transformedPosts : [...prev, ...transformedPosts]
      );

      // Cache first page results
      if (page === 1) {
        setCachedPosts(targetUserId, transformedPosts);
      }

      setHasMore(transformedPosts.length >= POSTS_PER_PAGE);
    } catch (error) {
      console.error('Error fetching posts:', error);
      if (error instanceof Error && error.message.includes('Failed to fetch')) {
        // Use cached data if available on network error
        const cached = getCachedPosts(memberId || user?.id || '');
        if (cached) {
          console.log('Network error, using cached posts');
          setPosts(cached);
          setError('Unable to refresh posts. Using cached data.');
          return;
        }
        setError('Unable to load posts. Please check your connection.');
      } else {
        setError(
          error instanceof Error ? error.message : 'Failed to load posts'
        );
      }
    } finally {
      if (page === 1) {
        setIsLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  }, [memberId, user?.id, page]);

  // Clear cache when unmounting
  useEffect(() => {
    return () => {
      if (isCached) {
        setIsCached(false);
      }
    };
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  // Separate effect for fetching liked posts
  useEffect(() => {
    const fetchLikedPosts = async () => {
      try {
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user?.id)
          .single();

        if (!userData) return;

        const { data: likes } = await supabase
          .from('post_likes')
          .select('post_id')
          .eq('user_id', userData.id);

        if (likes) {
          setLikedPosts(new Set(likes.map((like) => like.post_id)));
        }
      } catch (error) {
        console.error('Error fetching liked posts:', error);
      }
    };

    if (user?.id) {
      fetchLikedPosts();
    }
  }, [user?.id]);

  if (isLoading) {
    return (
      <div className="space-y-6">
        <MomentsSkeleton count={6} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
        <p>{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="text-sm underline mt-2 hover:text-red-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* View Mode Toggle */}
      <div className="flex justify-between items-center">
        <button
          onClick={() =>
            setViewMode((prev) => (prev === 'list' ? 'grid' : 'list'))
          }
          className="flex items-center space-x-2 px-3 py-1.5 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors text-dark-800 hover:text-white"
          title={`Switch to ${viewMode === 'grid' ? 'list' : 'grid'} view`}
        >
          {viewMode === 'grid' ? (
            <>
              <LayoutList className="h-4 w-4" />
              <span className="text-sm">List View</span>
            </>
          ) : (
            <>
              <Grid className="h-4 w-4" />
              <span className="text-sm">Grid View</span>
            </>
          )}
        </button>
      </div>

      {isOwnProfile && (
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <button
              onClick={() =>
                setViewMode((prev) => (prev === 'list' ? 'grid' : 'list'))
              }
              className="flex items-center space-x-2 px-3 py-1.5 bg-dark-300 rounded-lg hover:bg-dark-400 transition-colors text-dark-800 hover:text-white"
              title={`Switch to ${viewMode === 'grid' ? 'list' : 'grid'} view`}
            >
              {viewMode === 'grid' ? (
                <>
                  <LayoutList className="h-4 w-4" />
                  <span className="text-sm">List View</span>
                </>
              ) : (
                <>
                  <Grid className="h-4 w-4" />
                  <span className="text-sm">Grid View</span>
                </>
              )}
            </button>
          </div>
          <PostComposer onPost={handleNewPost} isSubmitting={isSubmitting} />
        </div>
      )}
      {posts.length === 0 ? (
        <EmptyPostsState memberName={memberName} />
      ) : (
        <div
          className={cn(
            viewMode === 'list'
              ? 'space-y-6 max-w-2xl mx-auto'
              : 'grid grid-cols-2 md:grid-cols-3 gap-4'
          )}
        >
          <AnimatePresence mode="popLayout" initial={false}>
            {posts.map((post, index) => (
              <motion.div
                key={`post-${post.id}-${index}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                layout
                transition={{
                  duration: 0.3,
                  delay: expandedPost === post.id ? 0 : index * 0.05,
                  layout: {
                    type: 'spring',
                    bounce: 0.2,
                    duration: 0.6,
                  },
                }}
                ref={(el) => (postRefs.current[post.id] = el)}
                onClick={(e) => {
                  // Don't expand if clicking like button or share button
                  const target = e.target as HTMLElement;
                  // Don't expand if clicking any interactive element
                  if (
                    target.closest('button') ||
                    target.closest('input') ||
                    target.closest('textarea') ||
                    target.closest('.comments-section')
                  )
                    return;
                  handleComment(post.id);
                }}
                className={cn(
                  'bg-dark-200 rounded-xl overflow-hidden border border-dark-300',
                  'hover:border-gold-400 transition-all duration-300',
                  viewMode === 'grid' && 'flex flex-col',
                  viewMode === 'grid' && 'flex flex-col',
                  expandedPost === post.id && [
                    'col-span-full max-w-2xl mx-auto',
                    'z-10 relative',
                    'transform transition-all duration-500 ease-out',
                    'shadow-xl',
                  ],
                  'transition-all duration-500',
                  'w-full max-w-[100vw]' // Remove all padding
                )}
              >
                {/* Media Content */}
                {(post.media || post.video) && (
                  <div className="relative">
                    {post.video ? (
                      <PostContent
                        content={post.content}
                        video={post.video}
                        className="max-w-full overflow-hidden" // Ensure content doesn't overflow
                      />
                    ) : (
                      post.media && (
                        <img
                          src={post.media}
                          alt="Post"
                          className={cn(
                            'w-full object-cover',
                            viewMode === 'grid' && expandedPost !== post.id
                              ? 'aspect-square'
                              : 'max-h-[600px]',
                            'max-w-full' // Ensure image doesn't overflow
                          )}
                          loading="lazy"
                        />
                      )
                    )}
                  </div>
                )}

                {/* Post Content & Actions Container */}
                <div
                  className={cn(
                    'p-0 flex-1 flex flex-col', // Remove padding from content container
                    expandedPost === post.id ? 'pb-4' : '',
                    'relative z-10' // Ensure content stays above any overlays
                  )}
                >
                  {/* Post Header */}
                  <div className="flex justify-between items-start p-4">
                    <div className="text-dark-800 text-sm">
                      {new Date(post.timeAgo).toLocaleDateString()}
                    </div>
                    {user?.id === post.author.id && (
                      <PostMenu
                        onEdit={() => handleEdit(post)}
                        onDelete={() => handleDelete(post)}
                        isOwner={true}
                        className="max-w-full break-words" // Ensure text wraps properly
                      />
                    )}
                  </div>

                  {/* ✅ Post Content - Ensuring Space for Actions Below */}
                  <div className="flex-1">
                    {post.content && <PostContent content={post.content} />}{' '}
                    {/* ✅ Use PostContent */}
                  </div>

                  {/* ✅ Persistent Post Actions - Ensure Always Visible */}
                  <div
                    className={cn(
                      'flex flex-col mt-0 p-4 border-t border-dark-300', // Add padding to actions container
                      'transition-all duration-300',
                      'bg-dark-200', // Match parent background
                      expandedPost === post.id && 'sticky bottom-0' // Stick to bottom when expanded
                    )}
                  >
                    <div className="flex items-center justify-between w-full">
                      {/* Like Button */}
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleLike(post.id)}
                          className={cn(
                            'relative transition-transform active:scale-75 duration-200',
                            'group overflow-visible'
                          )}
                        >
                          {/* Background heart (always visible) */}
                          <Heart
                            className={cn(
                              'h-6 w-6 transition-all duration-300',
                              likedPosts.has(post.id)
                                ? 'text-red-500 fill-red-500 scale-110'
                                : 'text-white group-hover:text-red-500/50'
                            )}
                          />

                          {/* Like animation overlay */}
                          {likedPosts.has(post.id) && (
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{
                                scale: [0, 1.8, 1],
                                opacity: [0, 1, 1],
                              }}
                              transition={{
                                duration: 0.4,
                                times: [0, 0.2, 1],
                                ease: 'easeInOut',
                              }}
                              className="absolute inset-0 pointer-events-none"
                            >
                              <Heart className="h-6 w-6 text-red-500 fill-red-500" />

                              {/* Burst particles */}
                              {[...Array(8)].map((_, i) => (
                                <motion.div
                                  key={i}
                                  initial={{ scale: 0, opacity: 1 }}
                                  animate={{
                                    scale: 2,
                                    opacity: 0,
                                    x: Math.cos((i * Math.PI) / 4) * 10,
                                    y: Math.sin((i * Math.PI) / 4) * 10,
                                  }}
                                  transition={{
                                    duration: 0.6,
                                    ease: 'easeOut',
                                  }}
                                  className="absolute inset-0 flex items-center justify-center"
                                >
                                  <div
                                    className="w-1 h-1 rounded-full bg-red-500"
                                    style={{
                                      transform: `rotate(${
                                        i * 45
                                      }deg) translateY(-10px)`,
                                    }}
                                  />
                                </motion.div>
                              ))}
                            </motion.div>
                          )}
                        </button>

                        <span className="text-sm text-white font-medium">
                          {post.likes}
                        </span>
                      </div>

                      {/* Comment Button */}
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => handleComment(post.id)}
                          className="text-white hover:text-gold-400 transition-colors"
                        >
                          <MessageCircle
                            className={cn(
                              'h-6 w-6',
                              expandedPost === post.id && 'text-gold-400'
                            )}
                          />
                        </button>
                        <span className="text-sm text-white font-medium">
                          {post.comments}
                        </span>
                      </div>

                      {/* Share Button */}
                      <button
                        onClick={() => handleShare(post.id)}
                        className="text-white hover:text-gold-400 transition-colors"
                      >
                        <Share2 className="h-6 w-6" />
                      </button>
                    </div>
                  </div>

                  {/* ✅ Comments Section */}
                  {expandedPost === post.id && (
                    <motion.div
                      onClick={(e) => e.stopPropagation()}
                      className={cn(
                        'mt-4 pt-4 border-t border-dark-300 comments-section',
                        'bg-dark-200' // Match parent background
                      )}
                      ref={(el) => (commentsRef.current[post.id] = el)}
                      initial={{ opacity: 0, height: 0, scale: 0.95 }}
                      animate={{ opacity: 1, height: 'auto', scale: 1 }}
                      exit={{ opacity: 0, height: 0, scale: 0.95 }}
                      transition={{
                        opacity: { duration: 0.2 },
                        height: { duration: 0.4 },
                        scale: { duration: 0.3, ease: 'easeOut' },
                      }}
                    >
                      <PostComments
                        postId={post.id}
                        className="max-w-full overflow-hidden" // Ensure comments don't overflow
                        onCommentAdded={() => {
                          setPosts((prev) =>
                            prev.map((p) =>
                              p.id === post.id
                                ? { ...p, comments: p.comments + 1 }
                                : p
                            )
                          );
                        }}
                      />
                    </motion.div>
                  )}
                </div>
              </motion.div>
            ))}
          </AnimatePresence>

          {/* Spacer for expanded post */}
          {expandedPost && viewMode === 'grid' && (
            <motion.div
              key={`spacer-${expandedPost}`}
              layout
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
              className="col-span-full"
              transition={{
                duration: 0.4,
                ease: 'easeInOut',
              }}
            />
          )}

          {/* Loading indicator */}
          {hasMore && (
            <div ref={loadingRef} className="flex justify-center py-4">
              {isLoadingMore ? (
                <div className="h-6 w-6 border-2 border-gold-400 border-t-transparent rounded-full animate-spin" />
              ) : (
                <div className="h-6 w-6" aria-hidden="true" />
              )}
            </div>
          )}
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent className="bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Delete Post</DialogTitle>
          </DialogHeader>
          <p className="text-dark-800">
            Are you sure you want to delete this post? This action cannot be
            undone.
          </p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowDeleteDialog(false)}
              className="border-dark-400"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              className="bg-red-500 hover:bg-red-600"
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog
        open={isEditing}
        onOpenChange={(open) => !open && setIsEditing(false)}
      >
        <DialogContent className="bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Edit Post</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl p-4 text-white resize-none"
              placeholder="What's on your mind?"
            />
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsEditing(false);
                setSelectedPost(null);
                setContent('');
              }}
              className="border-dark-400"
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditSubmit}
              disabled={!content.trim() || content === selectedPost?.content}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50"
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostsTab;
