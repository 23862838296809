import React from 'react';
import { Globe } from 'lucide-react';
import CityLink from '../../ui/CityLink';

interface UserStatusProps {
  location: string;
  isOnline: boolean;
}

export const UserStatus: React.FC<UserStatusProps> = ({ location, isOnline }) => (
  <div className="flex items-center text-dark-800 text-sm">
    <CityLink 
      city={location}
      showIcon={false}
      className="flex items-center"
    />
    {isOnline && (
      <span className="ml-2 text-green-400">● Online</span>
    )}
  </div>
);