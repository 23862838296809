import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Menu, X, Dumbbell, Users,
  Settings, UserPlus, Plus, Sun, Moon, MapPin,
  ScrollText, Search, MessageSquareText,
  HelpCircle, LogOut, PanelLeftClose, PanelLeft
} from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useTheme } from '../contexts/ThemeContext';
import { InviteModal } from './InviteModal';
import NotificationsDropdown from './notifications/NotificationsDropdown';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';
import BlurOverlay from './ui/BlurOverlay';
import { useAuth } from '../contexts/AuthContext';
import FeedbackModal from './feedback/FeedbackModal';
import SupportModal from './support/SupportModal';
import { avatarCache } from '@/lib/utils/avatarCache';
import ProfileButton from './header/ProfileButton';
import { cn } from '@/lib/utils';
import BottomNavigation from './navigation/BottomNavigation';
import { motion } from 'framer-motion';

// Lazy load QuickMatchModal
const QuickMatchModal = React.lazy(() => import('./matchmaking/QuickMatchModal'));

interface DashboardLayoutProps {
  children: React.ReactNode;
  hideNav?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children, hideNav }) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showQuickMatch, setShowQuickMatch] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useAuth();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const lastScrollY = useRef(0);
  const scrollThreshold = 100; // Distance to trigger fade
  const [isVisible, setIsVisible] = useState(true);
  const [opacity, setOpacity] = useState(1);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const toggleButtonsRef = useRef<NodeListOf<HTMLButtonElement> | null>(null);

  // Reinitialize sidebar based on screen size and route change
  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 1024);
    };

    handleResize(); // Run immediately on mount/navigation change
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, [location.pathname]);

  // Initialize toggle buttons and event listeners
  useEffect(() => {
    // Get all toggle buttons after component mount
    toggleButtonsRef.current = document.querySelectorAll('.sidebar-toggle');

    const handleToggle = (e: MouseEvent) => {
      e.stopPropagation();
      setIsSidebarOpen(prev => !prev);
    };

    // Add click listeners to all toggle buttons
    toggleButtonsRef.current.forEach(btn => {
      btn.removeEventListener('click', handleToggle); // Remove any existing listeners
      btn.addEventListener('click', handleToggle);
    });
    
    return () => {
      // Clean up listeners on unmount
      if (toggleButtonsRef.current) {
        toggleButtonsRef.current.forEach(btn => {
          btn.removeEventListener('click', handleToggle);
        });
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollingUp = currentScrollY < lastScrollY.current;
      const scrollDelta = Math.abs(currentScrollY - lastScrollY.current);
      
      // Show header when scrolling up or near top
      if (scrollingUp || currentScrollY < scrollThreshold) {
        setIsVisible(true);
        setOpacity(1);
      } else if (currentScrollY > scrollThreshold && scrollDelta > 5) {
        setIsVisible(false);
        setOpacity(0);
      }
      
      lastScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const toggleButton = document.querySelector('[aria-label="Open sidebar"]');
      
      if (
        sidebarRef.current && 
        !sidebarRef.current.contains(event.target as Node) && 
        !toggleButton?.contains(event.target as Node) &&
        window.innerWidth < 1024
      ) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMenuToggle = () => {
    // Only toggle on mobile
    if (window.innerWidth < 1024) {
      setIsSidebarOpen(prev => !prev);
    }
  };

  const handleSearchClick = () => {
    navigate('/dashboard/search');
  };

  const handleNavigate = (path: string, options?: { state?: any }) => {
    try {
      // Close sidebar on mobile
      if (window.innerWidth < 1024) {
        setIsSidebarOpen(false);
      }
      
      if (path === '/dashboard') {
        navigate(path, {
          replace: true,
          state: { 
            ...options?.state,
            searchKey: Date.now(),
            default: true,
            forceRefresh: Date.now()
          }
        });
        return;
      }
      
      navigate(path, {
        ...options,
        state: {
          ...options?.state,
          default: false,
          forceRefresh: Date.now()
        }
      });
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const handleLogout = () => {
    Promise.resolve(logout()).then(() => {
      navigate('/', { replace: true });
    });
  };

  const navigationItems = [
    { name: 'Players', icon: Users, path: '/dashboard/match', state: { default: false } },
    { name: 'Invite', icon: UserPlus, path: '', onClick: () => setShowInviteModal(true) },
    { name: 'Feedback', icon: MessageSquareText, onClick: () => setShowFeedback(true) },
    { name: 'Settings', icon: Settings, onClick: () => handleNavigate('/dashboard/settings') }
  ];

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Header */}
      <motion.header
        className={cn(
          "fixed top-0 left-0 right-0 bg-app/80 backdrop-blur-md border-b border-dark-300 z-50",
          "h-[calc(env(safe-area-inset-top)+56px)]",
          "pt-[env(safe-area-inset-top)]",
          hideNav && "md:flex hidden"
        )}
        style={{ 
          transform: `translateY(${isVisible ? '0' : '-100%'})`,
          opacity,
          transition: `transform 0.3s ease-in-out, opacity ${isVisible ? '0.15s' : '0.3s'} ease-in-out`
        }}>
        <div className="h-full flex items-center">
          {/* Logo Section */}
          <div className={cn("hidden lg:flex items-center px-6 w-64")}>
            <ThemeAwareLogo
              height={32}
              className="cursor-pointer"
              onClick={() => handleNavigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>
          {/* Mobile logo */}
          <div className="lg:hidden flex items-center px-4 flex-1">
            <ThemeAwareLogo
              height={28}
              className="cursor-pointer"
              onClick={() => handleNavigate('/dashboard')}
              colorScheme={isDarkMode ? 'white' : 'color'}
            />
          </div>
          {/* Right section */}
          <div className="flex items-center gap-2 px-4 ml-auto">
            <button
              onClick={handleSearchClick}
              className="p-2 text-dark-800 hover:text-white hover:bg-dark-400 rounded-lg transition-colors"
              aria-label="Search"
            >
              <Search className="h-5 w-5" />
            </button>
            <NotificationsDropdown />
            <ProfileButton />
            <button
              onClick={handleMenuToggle}
              className="sidebar-toggle lg:hidden p-2 text-dark-800 hover:text-white"
              aria-label="Toggle sidebar"
            >
              {isSidebarOpen ? <X className="h-5 w-5" /> : <Menu className="h-5 w-5" />}
            </button>
          </div>
        </div>
      </motion.header>
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={cn(
          "fixed inset-y-0 left-0 z-40 bg-app border-r border-dark-300 transform transition-all duration-300 ease-in-out mt-16",
          "w-64",
          "lg:translate-x-0",
          !isSidebarOpen && "!-translate-x-full lg:!translate-x-0",
          !isSidebarOpen && "lg:!w-20"
        )}
      >
        {/* Sidebar Toggle Button */}
        <button
          onClick={handleMenuToggle}
          className="sidebar-toggle absolute right-0 top-2 p-2 transform translate-x-1/2 bg-app border border-dark-300 rounded-full hover:bg-app-darker transition-colors lg:flex hidden"
          aria-label={isSidebarOpen ? "Close sidebar" : "Open sidebar"}
        >
          {isSidebarOpen ? (
            <PanelLeftClose className="h-4 w-4" />
          ) : (
            <PanelLeft className="h-4 w-4" />
          )}
        </button>
        <div className="h-full flex flex-col">
          {/* Navigation */}
          <nav className="flex-1 px-4 py-6 space-y-1">
            {/* Lucy AI Assistant */}
            <button
              onClick={() => {
                const handleLucyChat = async () => {
                  try {
                    const { data: { user }, error: userError } = await supabase.auth.getUser();
                    if (userError) throw userError;
                    if (!user) throw new Error('Not authenticated');
                    const { data: userData, error: userDataError } = await supabase
                      .from('users')
                      .select('id')
                      .eq('auth_id', user.id)
                      .single();
                    if (userDataError) throw userDataError;
                    if (!userData) throw new Error('User not found');
                    const { data: conversation, error: conversationError } = await supabase.rpc(
                      'get_or_create_conversation',
                      {
                        user1_id: userData.id,
                        user2_id: 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600'
                      }
                    );
                    if (conversationError) throw conversationError;
                    navigate('/dashboard/messages', {
                      state: {
                        activeChat: conversation,
                        initialMessage: 'Hi Lucy!',
                        showMobileList: false
                      }
                    });
                  } catch (error) {
                    console.error('Error starting chat with Lucy:', error);
                  }
                };
                handleLucyChat();
              }}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl transition-colors",
                isDarkMode
                  ? "text-dark-800 hover:bg-dark-300 hover:text-white"
                  : "text-light-600 hover:bg-light-200 hover:text-light-900",
                !isSidebarOpen && "justify-center",
                isDarkMode ? "bg-dark-300/50" : "bg-light-200/50",
                "mb-4"
              )}
            >
            <div className="w-10 h-10 rounded-full overflow-hidden flex-shrink-0">
  <img
    src="https://kbcdxlfnjjpofgfubesh.supabase.co/storage/v1/object/public/avatars/user_directory/a515d5df-8a26-409f-98e3-ea5e70c223f1/lucy_ai.png"
    alt="Lucy AI"
    className="w-full h-full object-cover"
  />
</div>

              {isSidebarOpen && <span className="ml-3">Ask Lucy (beta)</span>}
            </button>
            {navigationItems.map((item) => (
              <button
                key={item.name}
                onClick={() => item.onClick ? item.onClick() : handleNavigate(item.path)}
                className={cn(
                  "w-full flex items-center space-x-3 px-4 py-3 rounded-xl transition-colors",
                  location.pathname === item.path
                    ? "bg-dark-300 text-gold-400"
                    : "text-dark-800 hover:bg-dark-300 hover:text-white",
                  !isSidebarOpen && "justify-center"
                )}
              >
                <item.icon className="h-5 w-5" />
                {isSidebarOpen && <span>{item.name}</span>}
              </button>
            ))}
            {/* Theme Toggle */}
            <button
              onClick={toggleTheme}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl text-dark-800 hover:bg-dark-300 hover:text-white transition-colors",
                !isSidebarOpen && "justify-center"
              )}
            >
              {isDarkMode ? <Sun className="h-5 w-5" /> : <Moon className="h-5 w-5" />}
              {isSidebarOpen && <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>}
            </button>
            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className={cn(
                "w-full flex items-center space-x-3 px-4 py-3 rounded-xl text-red-400 hover:bg-dark-300 transition-colors",
                !isSidebarOpen && "justify-center"
              )}
            >
              <LogOut className="h-5 w-5" />
              {isSidebarOpen && <span>Logout</span>}
            </button>
          </nav>
        </div>
      </div>
      {/* Main Content */}
      <main className={cn(
       "pb-24 transition-all duration-300 overflow-visible",
        "lg:pb-0",
        isSidebarOpen ? "lg:pl-64" : "lg:pl-20", 
        "min-h-screen"
      )}>
        <div className={cn(
          "relative",
          location.pathname === '/dashboard' && !location.state?.searchKey ? "pt-8" : "pt-16"
        )}>
          <div className="p-4 lg:p-8 pt-4">
            {React.isValidElement(children) && typeof children.type !== 'string'
              ? React.cloneElement(children, { isSidebarOpen })
              : children}
          </div>
        </div>
      </main>
      {/* Bottom Navigation */}
      {!hideNav && (
        <div className={cn(
          "fixed inset-x-0 bottom-0 z-50 block fixed-nav",
          "bg-app/80 border-t border-dark-300 backdrop-blur-md",
          "pb-[env(safe-area-inset-bottom)]"
        )}>
          <BottomNavigation />
        </div>
      )}
      {/* Modals */}
      <InviteModal 
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      />
      {showQuickMatch && (
        <React.Suspense fallback={null}>
          <QuickMatchModal
            isOpen={showQuickMatch}
            onClose={() => setShowQuickMatch(false)}
          />
        </React.Suspense>
      )}
      <FeedbackModal
        isOpen={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
        {/* Invisible 1px spacer to trigger iOS bounce */}
      <div style={{
        height: '1px',
        width: '100%',
        flexShrink: 0,
        pointerEvents: 'none'
      }} />
    </div>
  );
};

export default DashboardLayout;
