// Default location (Valencia, Spain)
export const DEFAULT_LOCATION = {
  latitude: 39.4699,
  longitude: -0.3763
};

// Cache location for 5 minutes
const LOCATION_CACHE_DURATION = 5 * 60 * 1000;
const LOCATION_CACHE_KEY = 'user_location_cache';

interface CachedLocation {
  latitude: number;
  longitude: number;
  timestamp: number;
}

function getCachedLocation(): CachedLocation | null {
  try {
    const cached = localStorage.getItem(LOCATION_CACHE_KEY);
    if (!cached) return null;
    
    const location = JSON.parse(cached);
    if (Date.now() - location.timestamp > LOCATION_CACHE_DURATION) {
      localStorage.removeItem(LOCATION_CACHE_KEY);
      return null;
    }
    
    return location;
  } catch (error) {
    console.warn('Error reading location cache:', error);
    return null;
  }
}

function setCachedLocation(location: { latitude: number; longitude: number }) {
  try {
    localStorage.setItem(LOCATION_CACHE_KEY, JSON.stringify({
      ...location,
      timestamp: Date.now()
    }));
  } catch (error) {
    console.warn('Error caching location:', error);
  }
}

/**
 * Function to get the user's geolocation.
 * Returns a Promise resolving to an object with latitude and longitude.
 */
export async function getUserLocation(): Promise<{ latitude: number; longitude: number }> {
  return new Promise(async (resolve, reject) => {
    try {
      // First check cache
      const cached = getCachedLocation();
      if (cached) {
        console.log('Using cached location');
        return resolve(cached);
      }

      // Check if geolocation is supported
      if (!navigator.geolocation) {
        console.warn("Geolocation not supported, using default location");
        return resolve(DEFAULT_LOCATION);
      }

      // Check permission status
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
      
      if (permissionStatus.state === "denied") {
        console.warn("Geolocation permission denied, using default location");
        return resolve(DEFAULT_LOCATION);
      }

      // Request location with timeout
      const timeoutId = setTimeout(() => {
        console.warn("Geolocation request timed out, using default location");
        resolve(DEFAULT_LOCATION);
      }, 10000); // 10 second timeout

      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(timeoutId);
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };
          
          // Cache the successful location
          setCachedLocation(location);
          
          resolve(location);
        },
        (error) => {
          clearTimeout(timeoutId);
          console.warn("Geolocation error:", error.message);
          
          // Check for specific error cases
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.warn("Geolocation permission denied");
              break;
            case error.POSITION_UNAVAILABLE:
              console.warn("Location information unavailable");
              break;
            case error.TIMEOUT:
              console.warn("Geolocation request timed out");
              break;
          }
          
          resolve(DEFAULT_LOCATION);
        },
        {
          enableHighAccuracy: false, // Use lower accuracy for faster response
          timeout: 5000,
          maximumAge: 300000 // 5 minutes
        }
      );
    } catch (error) {
      console.error("Unexpected error getting location:", error);
      resolve(DEFAULT_LOCATION);
    }
  });
}