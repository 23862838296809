import React, { useEffect, useState } from 'react';
import { MapPin, Compass, Shield, Loader2 } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { cn } from '@/lib/utils';
import { CityVenueService } from '@/lib/services/venues/cityVenue.service';
import { supabase } from '@/lib/supabase';
import { searchVenuesWithGeocode } from '@/lib/venue';
import { useTheme } from '@/contexts/ThemeContext';
import { getDistance } from 'geolib';

interface Venue {
  name: string;
  address: string;
  city: string;
  coordinates?: { lat: number; lng: number };
  type?: string;
  source?: 'city' | 'club' | 'external';
  distanceKm?: number;
}

interface VenueSelectorProps {
  city: string;
  value: string;
  onChange: (venue: Venue) => void;
  placeholder?: string;
  className?: string;
}

const UnifiedVenueSelector: React.FC<VenueSelectorProps> = ({
  city,
  value,
  onChange,
  placeholder = 'Enter venue name or address',
  className
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [searchResults, setSearchResults] = useState<Venue[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [userCoords, setUserCoords] = useState<{ lat: number; lng: number } | null>(null);
  const [debouncedInput] = useDebounce(inputValue, 300);
  const [showDropdown, setShowDropdown] = useState(false);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setUserCoords({ lat: pos.coords.latitude, lng: pos.coords.longitude });
      },
      (err) => {
        console.warn('Geolocation not available:', err.message);
      }
    );
  }, []);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!city) return;
      setIsLoading(true);

      try {
        const [cityVenues, externalVenues, clubVenues] = await Promise.all([
          CityVenueService.getVenues(city),
          searchVenuesWithGeocode(debouncedInput, city),
          fetchSupabaseClubs(city)
        ]);

        const formattedClubs: Venue[] = clubVenues.map((club: any) => ({
          name: club.club_name,
          address: club.formatted_address,
          city: club.city,
          coordinates: club.lat && club.lng ? { lat: club.lat, lng: club.lng } : undefined,
          type: 'Private Club',
          source: 'club'
        }));

        const formattedCityVenues: Venue[] = cityVenues.map((venue: any) => ({
          name: venue.name || venue.address,
          address: venue.address,
          city,
          coordinates: venue.coordinates,
          type: 'Address',
          source: 'city'
        }));

        const formattedExternal: Venue[] = externalVenues.map((venue: any) => ({
          name: venue.name || venue.address,
          address: venue.address,
          city,
          coordinates: venue.coordinates,
          type: 'Address',
          source: 'external'
        }));

        let combined: Venue[] = [];

        if (!debouncedInput.trim()) {
          combined = [...formattedClubs, ...formattedCityVenues];
        } else {
          const input = debouncedInput.toLowerCase();
          combined = [
            ...formattedClubs.filter(
              (v) =>
                v.name.toLowerCase().includes(input) ||
                v.address.toLowerCase().includes(input)
            ),
            ...formattedCityVenues.filter(
              (v) =>
                v.name.toLowerCase().includes(input) ||
                v.address.toLowerCase().includes(input)
            ),
            ...formattedExternal.filter(
              (v) =>
                v.name.toLowerCase().includes(input) ||
                v.address.toLowerCase().includes(input)
            )
          ];
        }

        const dedupedMap = new Map<string, Venue>();
        for (const v of combined) {
          const key = v.name.toLowerCase();
          if (!dedupedMap.has(key)) {
            dedupedMap.set(key, v);
          } else {
            const existing = dedupedMap.get(key);
            if (existing?.source !== 'club' && v.source === 'club') {
              dedupedMap.set(key, v);
            }
          }
        }
        const deduped = Array.from(dedupedMap.values());

        const withDistance = deduped.map((v) => {
          if (userCoords && v.coordinates) {
            const meters = getDistance(userCoords, v.coordinates);
            return { ...v, distanceKm: +(meters / 1000).toFixed(1) };
          }
          return v;
        });

        setSearchResults(withDistance);
      } catch (err) {
        console.error('Error fetching venues:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestions();
  }, [debouncedInput, city, userCoords]);

  const fetchSupabaseClubs = async (city: string) => {
    const { data, error } = await supabase
      .from('clubs')
      .select('club_name, formatted_address, city, lat, lng')
      .ilike('city', `%${city}%`);

    if (error) {
      console.error('Supabase error:', error);
      return [];
    }

    return data;
  };

  const handleGetCurrentLocation = async () => {
    if (!navigator.geolocation) return;
    setIsLocating(true);

    try {
      const pos = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const coords = { lat: pos.coords.latitude, lng: pos.coords.longitude };
      setUserCoords(coords);

      const res = await fetch(
        `https://photon.komoot.io/reverse?lat=${coords.lat}&lon=${coords.lng}`
      );
      const data = await res.json();
      const props = data.features?.[0]?.properties;

      if (props) {
        const fullAddress = [props.name, props.street, props.housenumber, props.city, props.postcode]
          .filter(Boolean)
          .join(', ');

        const venue: Venue = {
          name: fullAddress,
          address: fullAddress,
          city: props.city || city,
          coordinates: coords,
          type: 'Address',
          source: 'external'
        };

        setInputValue(fullAddress);
        onChange(venue);
        setShowDropdown(false);
      }
    } catch (err) {
      console.error('Error with geolocation:', err);
    } finally {
      setIsLocating(false);
    }
  };

  const handleManualInputConfirm = () => {
    if (inputValue.trim()) {
      const fallbackVenue: Venue = {
        name: inputValue.trim(),
        address: inputValue.trim(),
        city,
        source: 'external'
      };
      onChange(fallbackVenue);
    }
  };

  const renderGroup = (title: string, icon: React.ReactNode, items: Venue[]) => {
    if (items.length === 0) return null;

    return (
      <div>
        <div className={cn('px-3 py-1 text-xs font-semibold', isDarkMode ? 'text-dark-800' : 'text-gray-500')}>
          {icon} {title}
        </div>
        {items.map((venue, index) => (
          <li
            key={`${venue.name}-${index}`}
            className={cn(
              'px-3 py-2 cursor-pointer hover:bg-gray-100',
              isDarkMode && 'hover:bg-dark-300 text-white'
            )}
            onClick={() => {
              setInputValue(venue.name);
              onChange(venue);
              setShowDropdown(false);
            }}
          >
            <div className="flex items-center gap-2">
              {venue.source === 'club' ? (
                <Shield size={16} className="text-gold-400" />
              ) : (
                <MapPin size={16} className="text-gray-400" />
              )}
              <span>{venue.name}</span>
              {venue.distanceKm !== undefined && (
                <span className="text-xs text-gray-400 ml-auto">{venue.distanceKm} km</span>
              )}
            </div>
            <div className={cn('text-xs', isDarkMode ? 'text-dark-800' : 'text-gray-500')}>
              {venue.address}
            </div>
          </li>
        ))}
      </div>
    );
  };

  const clubs = searchResults.filter((v) => v.source === 'club');
  const addresses = searchResults.filter((v) => v.source === 'external' || v.source === 'city');

  return (
    <div className={cn('relative w-full', className)}>
      <div className="relative">
        <MapPin className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-dark-800" />
        <input
          type="text"
          value={inputValue}
          onFocus={() => setShowDropdown(true)}
          onChange={(e) => {
            setInputValue(e.target.value);
            setShowDropdown(true);
          }}
          onBlur={handleManualInputConfirm}
          placeholder={placeholder}
          className={cn(
            'w-full pl-10 pr-12 py-2 border rounded-xl',
            'focus:outline-none focus:ring-2 focus:ring-gold-400',
            isDarkMode
              ? 'bg-dark-300 border-dark-400 text-white placeholder-dark-800'
              : 'bg-white border-gray-200 text-gray-900 placeholder-gray-400'
          )}
        />
        <button
          onClick={handleGetCurrentLocation}
          disabled={isLocating}
          className={cn(
            'absolute right-3 top-1/2 -translate-y-1/2 p-1.5 rounded-lg transition-colors',
            isDarkMode
              ? 'hover:bg-dark-400 text-dark-800 hover:text-white'
              : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700',
            'disabled:opacity-50 disabled:cursor-not-allowed'
          )}
          title="Use current location"
        >
          {isLocating ? <Loader2 className="h-4 w-4 animate-spin" /> : <Compass className="h-4 w-4" />}
        </button>
      </div>

      {searchResults.length > 0 && showDropdown && (
        <ul
          className={cn(
            'absolute z-[999] w-full mt-1 border rounded-xl shadow max-h-80 overflow-auto',
            isDarkMode ? 'bg-dark-200 border-dark-400' : 'bg-white border-gray-200'
          )}
        >
          {renderGroup('Clubs', '🏛', clubs)}
          {renderGroup('Venues & Addresses', '📍', addresses)}
        </ul>
      )}
    </div>
  );
};

export default UnifiedVenueSelector;
