import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '../ui/dialog';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { extractCityName } from '../../lib/utils/text';
import { Button } from '../ui/button';
import PostHeader from './post/PostHeader';
import PostContent from './post/PostContent';
import PostActions from './post/PostActions';
import PostComments from './post/PostComments';
import PostMenu from './post/PostMenu';
import SharePopover from './SharePopover';
import { Post } from '../../types/post';
import { usePostInteractions } from '../../hooks/social/usePostInteractions';
import { formatPostTime } from '../../lib/utils/time';
import { storage } from '../../lib/supabase/storage';
import { subscribeToComments, getAllUsers } from '../../lib/supabase/social';
import { supabase } from '../../lib/supabase';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';

interface PostCardProps extends Post {
  className?: string;
  batchLikes?: Record<string, boolean>;
  endorsements?: string[];
}

const ProgressiveImage: React.FC<{ src: string; alt: string }> = ({
  src,
  alt,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imageRef.current) return;

    // Use Intersection Observer for lazy loading
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && imageRef.current) {
            imageRef.current.src = src;
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    observer.observe(imageRef.current);

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [src]);

  return (
    <div className="relative aspect-video w-full overflow-hidden bg-dark-300">
      {!isLoaded && !isError && (
        <div className="absolute inset-0 animate-pulse bg-dark-300" />
      )}
      <img
        ref={imageRef}
        data-src={src} // Use data-src for lazy loading
        alt={alt}
        loading="lazy" // Lazy loading attribute added
        className={cn(
          'w-full h-full object-cover transition-opacity duration-300',
          isLoaded ? 'opacity-100' : 'opacity-0'
        )}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
      />
    </div>
  );
};

const PostCard: React.FC<PostCardProps> = ({
  id,
  author,
  content,
  media,
  video,
  likes: initialLikes,
  comments: initialComments,
  shares: initialShares,
  timeAgo,
  achievement,
  endorsements,
  batchLikes,
  className
}) => {
  const [shouldLoadMedia, setShouldLoadMedia] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const subscriptionRef = useRef<() => void>();

  useEffect(() => {
    if (!cardRef.current) return;
    
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldLoadMedia(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    observer.observe(cardRef.current);
    return () => observer.disconnect();
  }, []);

  const navigate = useNavigate();
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(initialComments);
  const { user } = useAuth();

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState<string>(content);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [formattedContent, setFormattedContent] = useState<React.ReactNode>(
    content
  );
  const [formattedTime, setFormattedTime] = useState(
    formatPostTime(timeAgo)
  );

  const handleCommentAdded = useCallback(() => {
    setCommentCount(prev => prev + 1);
  }, []);

  // Format content to make @mentions clickable
  useEffect(() => {
    const formatContent = async () => {
      // Skip if no @ mentions
      if (!content.includes('@')) {
        setFormattedContent(content);
        return;
      }

      // Skip if no @ mentions
      if (!content.includes('@')) {
        setFormattedContent(content);
        return;
      }
      
      try {
        // Skip if no @ mentions
        if (!content.includes('@')) {
          setFormattedContent(content);
          return;
        }

        // Get all users to match mentions against
        const users = await getAllUsers();
        if (!users) {
          setFormattedContent(content);
          return;
        }
        if (!users?.length) {
          setFormattedContent(content);
          return;
        }

        // Split content by @mentions
        const parts = content.split(/(@\S+)/g);

        const formattedParts = parts.map((part, index) => {
          if (part.startsWith('@')) {
            const name = part.slice(1); // Remove @ symbol
            const user = users.find((u) => u.full_name === name);

            if (user) {
              return (
                <Link
                  key={index}
                  to={`/dashboard/members/${user.auth_id}`}
                  className="text-gold-400 hover:text-gold-500 transition-colors"
                >
                  {part}
                </Link>
              );
            }
          }
          return part;
        });

        setFormattedContent(formattedParts);
      } catch (error) {
        console.error('Error formatting mentions:', error);
        // Fallback to unformatted content on error
        // Fallback to unformatted content on error
        setFormattedContent(content);
      }
    };

    // Only process content with @ mentions
    if (content.includes('@')) {
      formatContent();
    } else {
      setFormattedContent(content);
    }
  }, [content]);

  const {
    isLiked,
    likesCount,
    sharesCount,
    isLoading,
    error: interactionError,
    handleLike,
    handleShare,
  } = usePostInteractions({ 
    postId: id, 
    initialLikes, 
    initialShares,
    batchLikes 
  });

  // Check post ownership
  useEffect(() => {
    const checkOwnership = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setIsOwner(user?.id === author.id);
    };
    checkOwnership();
  }, [author.id]);

  // Update formatted time periodically
  useEffect(() => {
    const timer = setInterval(() => {
      setFormattedTime(formatPostTime(timeAgo));
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [timeAgo]);

  useEffect(() => {
    // Initialize comment count
    setCommentCount(initialComments);
  }, [id, initialComments]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (editedContent === content) {
      setIsEditing(false);
      return;
    }

    setIsUpdating(true);
    try {
      const { error } = await supabase
        .from('posts')
        .update({ content: editedContent })
        .eq('id', id);

      if (error) throw error;
      content = editedContent;
    } catch (error) {
      console.error('Failed to update post:', error);
    } finally {
      setIsUpdating(false);
      setIsEditing(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Handle image deletion
      if (media) {
        const imagePath = media.split('/').pop();
        if (imagePath) {
          await storage.deleteFile(
            'social-feed',
            `user_directory/${user.id}/${imagePath}`
          );
        }
      }

      // Handle video deletion
      if (video) {
        const videoPath = video.split('/').pop();
        if (videoPath) {
          await storage.deleteFile(
            'videos',
            `user_directory/${user.id}/${videoPath}`
          );
        }
      }
      
      const { error: deleteError } = await supabase
        .from('posts')
        .delete()
        .eq('id', id);

      if (deleteError) {
        console.error('Delete error:', deleteError);
        throw deleteError;
      }

      setShowDeleteDialog(false);
      // Emit custom event to notify parent components
      window.dispatchEvent(new CustomEvent('post-deleted', { 
        detail: { postId: id }
      }));
    } catch (error) {
      console.error('Failed to delete post:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleAuthorClick = () => {
    // Navigate to profile page if it's the current user's post
    if (user?.id === author.id) {
      navigate('/dashboard/profile');
    } else {
      navigate(`/dashboard/members/${author.id}`);
    }
  };

  return (
    <motion.div
      layout
      initial={id.startsWith('temp-') ? { opacity: 0.8, scale: 0.98 } : { opacity: 0, y: 20 }}
      animate={id.startsWith('temp-') ? { opacity: 1, scale: 1 } : { opacity: 1, y: 0 }}
      className={cn(
        'bg-dark-200 rounded-2xl border border-dark-300 overflow-hidden hover:border-gold-400 transition-all duration-300 w-full flex flex-col',
        id.startsWith('temp-') && 'border-gold-400/50'
      )}
    >
      <div className="p-6 flex-1 flex flex-col">
        <div className="flex items-start justify-between">
          <PostHeader
            author={author}
            timeAgo={formattedTime}
            achievement={achievement}
            onAuthorClick={handleAuthorClick}
          />
          <PostMenu
            isOwner={isOwner}
            onEdit={handleEdit}
            onDelete={() => setShowDeleteDialog(true)}
          />
        </div>

        {isEditing ? (
          <div className="mt-4">
            <textarea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              className="w-full h-32 bg-dark-300 border border-dark-400 rounded-xl p-4 text-white resize-none"
            />
            <div className="flex justify-end space-x-2 mt-2">
              <Button
                variant="outline"
                onClick={() => setIsEditing(false)}
                className="border-dark-400"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveEdit}
                disabled={isUpdating}
                className="bg-gold-400 hover:bg-gold-500 text-dark-50"
              >
                {isUpdating ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        ) : (
          <PostContent
            className="flex-1"
            content={content}
            formattedContent={formattedContent}
            media={media}
            endorsements={endorsements}
            video={video}
            isSocialFeed={true}
          />
        )}

        {/* Fixed position action buttons */}
        <div className="mt-2">
          <PostActions
            isLiked={isLiked}
            likesCount={likesCount}
            commentsCount={commentCount}
            sharesCount={sharesCount}
            showComments={showComments}
            onLike={handleLike}
            onComment={() => setShowComments(!showComments)}
            shareButton={
              <SharePopover
                post={{ id, author, content, media, video, likes: initialLikes, comments: initialComments, shares: initialShares, timeAgo, achievement }}
                shareCount={sharesCount}
                onShare={handleShare}
              />
            }
          />
        </div>
      </div>

      {showComments && (
        <motion.div
          onClick={(e) => e.stopPropagation()}
          className="border-t border-dark-300"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          transition={{ duration: 0.3 }}
        >
          <PostComments
            postId={id}
            className="max-w-full overflow-hidden"
            onCommentAdded={handleCommentAdded}
          />
        </motion.div>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent className="bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>Delete Post</DialogTitle>
          </DialogHeader>
          <p className="text-dark-800">
            Are you sure you want to delete this post? This action cannot be
            undone.
          </p>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowDeleteDialog(false)}
              className="border-dark-400"
            >
              Cancel
            </Button>
            <Button
              onClick={handleDelete}
              disabled={isDeleting}
              className="bg-red-500 hover:bg-red-600"
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </motion.div>
  );
};

export default PostCard;