import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Member } from '../../types/member';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';
import { useProfile } from '../../hooks/useProfile';
import { useAuth } from '../../contexts/AuthContext';
import CitiesPlayedModal from '../profile/modals/CitiesPlayedModal';

interface ProfileStatsProps {
  member: Member;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowConnections,
  onShowReviews
}) => {
  const { user } = useAuth();
  const [connectionCount, setConnectionCount] = useState(member.connections);
  const [isLoadingConnections, setIsLoadingConnections] = useState(false);
  const [isLoadingMatches, setIsLoadingMatches] = useState(false);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [localMatchesPlayed, setLocalMatchesPlayed] = useState(member.matchesPlayed);
  const [citiesPlayed, setCitiesPlayed] = useState<number>(member.citiesPlayed.length);
  const [citiesList, setCitiesList] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { matchesPlayed: profileMatchesPlayed } = useProfile();
  const navigate = useNavigate();

  const handleMatchesClick = () => {
    navigate('/dashboard/activities', {
      state: { 
        activeTab: 'past',
        selectedStatus: 'confirmed'
      }
    });
  };

  const handleRatingClick = () => {
    navigate('/dashboard/profile?tab=ratings', { replace: true });
  };

  useEffect(() => {
    const fetchStats = async () => {
      if (!user?.id) return;

      try {
        setIsLoadingMatches(true);
        setIsLoadingCities(true);

        // Get user's database ID
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData?.id) {
          console.warn('User not found, using cached data if available');
          return;
        }

        const today = new Date().toISOString().split('T')[0];
        const { count: playedCount, error: countError } = await supabase
          .from('activities')
          .select('*', { count: 'exact', head: true })
          .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
          .eq('status', 'confirmed')
          .lt('date', today);

        if (countError) {
          console.warn('Error fetching matches count:', countError);
        } else {
          setLocalMatchesPlayed(playedCount || 0);
        }

        // Get unique cities
        const { data: cityData, error: cityError } = await supabase
          .from('activities')
          .select('venue_city')
          .or(`player_1_id.eq.${userData.id},player_2_id.eq.${userData.id}`)
          .eq('status', 'confirmed')
          .lt('date', today);

        if (cityError) {
          console.warn('Error fetching cities:', cityError);
        } else {
          const uniqueCities = new Set(
            cityData
              ?.map(activity => activity.venue_city?.split(',')[0].trim())
              .filter(Boolean)
          );
          setCitiesPlayed(uniqueCities.size);
          setCitiesList(Array.from(uniqueCities));
        }

      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoadingMatches(false);
        setIsLoadingCities(false);
      }
    };

    fetchStats();
  }, [user?.id]);

  // Fetch connection count
  useEffect(() => {
    const fetchConnectionCount = async () => {
      if (!user?.id) return;

      try {
        setIsLoadingConnections(true);
        const { data: userData } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (!userData?.id) return;

        const { count } = await supabase
          .from('connections')
          .select('*', { count: 'exact', head: true })
          .or(`initiator_id.eq.${userData.id},receiver_id.eq.${userData.id}`)
          .eq('status', 'accepted');

        setConnectionCount(count || 0);
      } catch (error) {
        console.error('Error fetching connections:', error);
      } finally {
        setIsLoadingConnections(false);
      }
    };

    fetchConnectionCount();
  }, [user?.id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {/* Matches Played */}
      <button
        onClick={handleMatchesClick}
        className={cn(
          "rounded-xl p-4 text-center",
          isLoadingMatches ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400",
          "transition-colors"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {member.id === 'current-user' ? profileMatchesPlayed || localMatchesPlayed : localMatchesPlayed}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Matches Played
        </div>
      </button>

      {/* Cities Played */}
      <button
        onClick={() => setIsModalOpen(true)}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {citiesPlayed}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Cities Played
        </div>
      </button>

      {/* Connections */}
      <button
        onClick={onShowConnections}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          isLoadingConnections ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400"
        )}>
          {connectionCount}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-light-600",
          "dark:text-dark-800"
        )}>
          Connections
        </div>
      </button>

      {/* Rating */}
      <button 
        onClick={handleRatingClick}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className="flex items-center justify-center text-2xl font-bold text-gold-600 dark:text-gold-400">
          {member.rating}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className="text-sm mt-1 text-light-600 dark:text-dark-800">
          Rating
        </div>
      </button>

      {/* Cities Played Modal */}
      <CitiesPlayedModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cities={citiesList}
        memberName={member.name}
      />
    </div>
  );
};

export default ProfileStats;