import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ImagePlus, Send, X, Loader2, Smile, Search } from 'lucide-react';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { cn } from "@/lib/utils";
import { avatarCache } from '../lib/utils/avatarCache';
import { supabase } from '../../lib/supabase';
import { motion, AnimatePresence } from "framer-motion";
import { useTheme } from '@/contexts/ThemeContext';
//import { useNotificationStore } from '../../store/notificationStore';
import { addNotification } from '../../lib/supabase/notifications';

interface Member {
  id: string;
  auth_id: string;
  full_name: string;
  avatar_url: string | null;
  city: string;
}

// Loading placeholders for post submission
const placeholders = [ 
  "🎾 Serving up your moment...",       
  "⚽ Setting up the perfect play...",   
  "🏃‍♂️ Sprinting to the finish line...", 
  "⛳ Lining up the perfect shot...",    
  "🏊‍♂️ Making waves with your post...",  
  "🏋️‍♂️ Lifting your story to new heights...", 
  "🎯 Aiming for excellence...",         
  "🏆 Preparing your victory lap...",    
  "⚡ Charging up your game...",         
  "🌟 Getting you in the zone...",       
  "🏃‍♀️ Racing to share your moment...",  
  "🎭 Warming up the crowd...",          
  "🎪 Setting up center court...",       
  "🎯 Perfecting your form...",          
  "🌊 Riding the wave of inspiration...", 
  "🏔️ Reaching new heights...",          
  "🎮 Loading your next level...",       
  "🚀 Accelerating to match speed...",   
  "💫 Channeling your inner champion...", 
  "🥇 Creating your winning moment..."    
];

interface PostComposerProps {
  onPost: (content: string, media?: { type: 'image' | 'video', file: File }) => Promise<void>;
  isSubmitting?: boolean;
  onCancel?: () => void;
  placeholder?: string;
  error?: string | null;
}

function PostComposer({ 
  onPost,
  isSubmitting = false,
  onCancel,
  placeholder = "What's on your mind?", 
  error: externalError = null
}: PostComposerProps) {
  const [content, setContent] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<{ type: 'image' | 'video', file: File } | null>(null);
  const [mediaPreview, setMediaPreview] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState('');
  const loadingInterval = useRef<NodeJS.Timeout>();
  const [internalError, setInternalError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const [mentionSearch, setMentionSearch] = useState('');
  const [showMentions, setShowMentions] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [members, setMembers] = useState<Member[]>([]);
  const { isDarkMode } = useTheme();
  //const { addNotification } = useNotificationStore();
  const mentionedUsers = useRef<Set<Member>>(new Set());

  // Handle loading state animations
  useEffect(() => {
    if (isSubmitting) {
      // Start with random placeholder
      const randomIndex = Math.floor(Math.random() * placeholders.length);
      setLoadingMessage(placeholders[randomIndex]);

      // Progress bar animation
      const progressDuration = 2000; // 2 seconds total
      const progressInterval = setInterval(() => {
        setUploadProgress(prev => Math.min(prev + 1, 99));
      }, progressDuration / 100);

      // Rotate placeholders
      loadingInterval.current = setInterval(() => {
        setLoadingMessage(prevMsg => {
          const currentIndex = placeholders.indexOf(prevMsg);
          const nextIndex = (currentIndex + 1) % placeholders.length;
          return placeholders[nextIndex];
        });
      }, 2000);

      return () => {
        clearInterval(progressInterval);
        if (loadingInterval.current) {
          clearInterval(loadingInterval.current);
        }
        setUploadProgress(0);
        setLoadingMessage('');
      };
    }
  }, [isSubmitting]);

  const currentPlaceholder = isSubmitting ? loadingMessage : placeholder;

  // Handle text change and mention detection
  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContent(newContent);
    const curPos = e.target.selectionStart;
    setCursorPosition(curPos);

    const lastAtSymbol = newContent.lastIndexOf('@', curPos);
    if (lastAtSymbol !== -1) {
      const textAfterAt = newContent.slice(lastAtSymbol + 1, curPos);
      const hasSpace = /\s/.test(textAfterAt);

      if (!hasSpace) {
        const textBeforeAt = newContent.slice(0, lastAtSymbol);
        const lines = textBeforeAt.split('\n');
        const lineNumber = lines.length - 1;
        const lineStart = textBeforeAt.lastIndexOf('\n') + 1;

        const span = document.createElement('span');
        span.style.font = window.getComputedStyle(textareaRef.current!).font;
        span.style.visibility = 'hidden';
        span.style.position = 'absolute';
        span.textContent = textBeforeAt.slice(lineStart);
        document.body.appendChild(span);

        const textWidth = span.offsetWidth;
        document.body.removeChild(span);

        const textareaRect = textareaRef.current!.getBoundingClientRect();
        const lineHeight = parseInt(window.getComputedStyle(textareaRef.current!).lineHeight);

        setMentionPosition({
          top: lineNumber * lineHeight,
          left: textWidth
        });

        setMentionSearch(textAfterAt);
        setShowMentions(true);
        fetchMembers(textAfterAt);
        return;
      }
    }
    setShowMentions(false);
    setMentionSearch('');
  };

  // Fetch members for mention suggestions
  const fetchMembers = async (search: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('users')
        .select('id, auth_id, full_name, avatar_url, city')
        .neq('auth_id', user.id)
        .ilike('full_name', `%${search}%`)
        .limit(5);

      if (error) throw error;
      setMembers(data || []);
    } catch (error) {
      console.error('Error fetching members:', error);
    }
  };

  // Insert mention into content
  const insertMention = (member: Member) => {
    if (!textareaRef.current) return;
    mentionedUsers.current.add(member);
    const textBeforeCursor = content.slice(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf('@');
    const textAfterCursor = content.slice(cursorPosition);
    const newContent = textBeforeCursor.slice(0, lastAtSymbol) + `@${member.full_name} ` + textAfterCursor;
    setContent(newContent);
    setShowMentions(false);
    setMentionSearch('');
    textareaRef.current.focus();
  };

  // File change handler with integrity checks and logging
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      console.log(`Selected file: name=${file.name}, type=${file.type}, size=${file.size}`);
      // Save file if it has valid properties
      if (!file.name || !file.type) {
        console.error("Invalid file: Missing name or type.");
        setInternalError("Invalid file: Missing name or type.");
        return;
      }
      setSelectedMedia({ type: file.type.startsWith("video/") ? 'video' : 'image', file });
    } else {
      console.error("No file selected.");
    }
  };

  // Submit handler with file integrity check
  const handleSubmit = async () => {
    if ((!content.trim() && !selectedMedia) || isSubmitting) {
      return;
    }

    // Validate file integrity if media exists
    if (selectedMedia && (!selectedMedia.file.name || !selectedMedia.file.type)) {
      console.error("Invalid file: Missing name or type.");
      setInternalError("Invalid file: Missing name or type.");
      return;
    }

    const submissionContent = content.trim();
    const submissionMedia = selectedMedia;

    try {
      await onPost(submissionContent, submissionMedia ? { type: submissionMedia.type, file: submissionMedia.file } : undefined);
      
      // Send notifications to mentioned users
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Get current user's name
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id, full_name')
        .eq('auth_id', user.id)
        .single();

      if (userError) throw userError;
      if (!userData) throw new Error('User not found');

      // Get the newly created post's ID
      const { data: postData, error: postError } = await supabase
        .from('posts')
        .select('id')
        .eq('content', submissionContent)
        .eq('user_id', userData.id)
        .order('created_at', { ascending: false })
        .limit(1)
        .maybeSingle();

      if (postError) throw postError;
      if (!postData) throw new Error('Post not found');

      const mentionedUsersArray = Array.from(mentionedUsers.current);
      // Send notification to each mentioned user
      for (const mentionedUser of mentionedUsersArray) {
        await addNotification({
          type: 'mention',
          content: `${userData.full_name} mentioned you in a post`,
          user_id: mentionedUser.id,
          sender_id: userData.id,
          metadata: {
            postId: postData.id,
            activeTab: 'posts'
          }
        });
      }

      // Reset form state
      setContent("");
      setSelectedMedia(null);
      setMediaPreview(null);
      mentionedUsers.current.clear();
      setInternalError(null);
      if (fileInputRef.current) fileInputRef.current.value = "";
      if (videoInputRef.current) videoInputRef.current.value = "";
      setIsExpanded(false);
      
    } catch (err) {
      console.error("Error submitting post:", {
        error: err,
        message: err instanceof Error ? err.message : 'Unknown error',
        content: submissionContent,
        hasMedia: !!submissionMedia
      });
      // Restore form state on error
      setContent(submissionContent);
      setSelectedMedia(submissionMedia);
      if (submissionMedia) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setMediaPreview(reader.result as string);
        };
        reader.readAsDataURL(submissionMedia.file);
      }
      setIsExpanded(true);
      if (err instanceof Error) {
        if (err.message.includes('foreign key constraint')) {
          setInternalError("Please try logging out and back in to fix this issue.");
        } else if (err.message.includes('Failed to fetch')) {
          setInternalError("Unable to create post. Please check your connection.");
        } else if (err.message.includes('Not authenticated')) {
          setInternalError("Please sign in to create posts.");
        } else {
          setInternalError(err.message);
        }
      } else {
        setInternalError("Failed to create post. Please try again.");
      }
    }
  };

  // File selection and drag/drop handlers
  const handleFileSelect = (file: File) => {
    let isVideo = ['video/mp4', 'video/webm', 'video/ogg', 'video/quicktime', 'video/mov'].includes(file.type);
    const isImage = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    
    if (!isImage && !isVideo) {
      setInternalError('Invalid file type. Please select an image (JPEG, PNG, GIF) or video (MP4, MOV).');
      return;
    }
    const maxSize = isVideo ? 100 * 1024 * 1024 : 10 * 1024 * 1024;
    if (file.size > maxSize) {
      setInternalError(`File size exceeds ${isVideo ? '100MB' : '10MB'} limit.`);
      return;
    }
    setSelectedMedia({ type: isImage ? 'image' : 'video', file });
    const reader = new FileReader();
    reader.onloadend = () => {
      setMediaPreview(reader.result as string);
    };
    reader.readAsDataURL(file);
    setIsExpanded(true);
    setInternalError(null);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleFileChangeInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    await handleFileSelect(file);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileSelect(file);
    }
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    setContent(prev => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div 
      className={cn(
        "bg-dark-200 rounded-xl p-4 border transition-all duration-300 relative overflow-hidden", 
        isDragging ? "border-gold-400 shadow-lg" : showMentions ? "border-gold-400" : "border-dark-300", 
        selectedMedia ? "pb-20" : ""
      )}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className={cn(
        "relative",
        isSubmitting && "after:absolute after:inset-0 after:bg-dark-300/30"
      )}>
        <Textarea
          ref={textareaRef}
          value={content}
          onChange={handleTextChange}
          placeholder={currentPlaceholder}
          readOnly={isSubmitting}
          className={cn(
            "w-full resize-none bg-dark-300 border-dark-400 text-white",
            "placeholder:text-dark-800 focus:ring-1 focus:ring-gold-400",
            isSubmitting && "cursor-not-allowed placeholder:text-gold-400/70",
            "transition-all duration-300",
            isExpanded ? "h-32" : "h-16"
          )}
          onFocus={() => setIsExpanded(true)}
        />
        {isSubmitting && (
          <div className="absolute inset-x-0 bottom-0 p-2 bg-dark-300/80">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Loader2 className="h-4 w-4 animate-spin text-gold-400" />
                <span className="text-sm text-gold-400">{loadingMessage}</span>
              </div>
              <div className="h-1 w-24 bg-dark-400 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gold-400 transition-all duration-300"
                  style={{ width: `${uploadProgress}%` }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
        
      <div className="flex items-center justify-between mt-4 pb-4 border-b border-dark-300">
        <div className="flex items-center gap-4">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*,video/*"
            onChange={handleFileChangeInput}
            className="hidden"
            id="post-media"
          />
          <label 
            htmlFor="post-media"
            className={cn(
              "p-2 rounded-lg transition-colors cursor-pointer",
              "hover:bg-dark-400 text-dark-800 hover:text-white",
              "flex items-center gap-2",
              isSubmitting && "opacity-50 cursor-not-allowed"
            )}
          >
            <ImagePlus className="h-5 w-5" />
            <span className="text-sm">Photo/Video</span>
          </label>
          <div className="relative">
            <button 
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className={cn(
                "p-2 rounded-lg transition-colors",
                "hover:bg-dark-400 text-dark-800 hover:text-white",
                showEmojiPicker && "bg-dark-400 text-white"
              )}
            >
              <Smile className="h-5 w-5" />
            </button>
            {showEmojiPicker && (
              <div className="absolute bottom-12 left-0 z-50">
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  theme={isDarkMode ? Theme.DARK : Theme.LIGHT}
                  searchPlaceHolder="Search emoji..."
                  width={300}
                  height={400}
                />
              </div>
            )}
          </div>
        </div>
        <Button
          onClick={handleSubmit}
          disabled={(!content.trim() && !selectedMedia) || isSubmitting}
          className={cn(
            "min-w-[100px]",
            "bg-gold-400 hover:bg-gold-500 text-dark-50 font-medium",
            "flex items-center justify-center gap-2",
            "px-4 py-2 rounded-lg",
            "transition-all duration-200",
            "transform hover:scale-105 active:scale-95",
            isSubmitting && "pointer-events-none bg-dark-400 text-dark-800"
          )}
        >
          {isSubmitting ? (
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin text-dark-800" />
              <span className="relative">
                Posting
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0, 1, 0] }}
                  transition={{ duration: 1.5, repeat: Infinity, times: [0, 0.5, 1] }}
                >
                  ...
                </motion.span>
              </span>
            </div>
          ) : (
            <>
              <Send className="h-4 w-4" />
              <span>Post</span>
            </>
          )}
        </Button>
      </div>
      
      {/* Mentions Dropdown */}
      {showMentions && members.length > 0 && (
        <div 
          className={cn(
            "fixed w-64 bg-dark-300 rounded-lg border border-dark-400 shadow-lg overflow-hidden z-[100]"
          )}
          style={{
            top: textareaRef.current 
              ? textareaRef.current.getBoundingClientRect().top + mentionPosition.top + window.scrollY + 30
              : 0,
            left: textareaRef.current
              ? textareaRef.current.getBoundingClientRect().left + mentionPosition.left
              : 0
          }}
        >
          {members.map((member) => (
            <button
              key={member.id}
              onClick={() => insertMention(member)}
              className={cn(
                "w-full flex items-center space-x-3 p-3 transition-colors text-left",
                "hover:bg-dark-400 focus:bg-dark-400 outline-none",
                "border-l-2 border-transparent hover:border-gold-400"
              )}
            >
              <img
                src={member.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'}
                alt={member.full_name}
                className="w-8 h-8 rounded-full object-cover"
              />
              <div>
                <div className="text-white font-medium">{member.full_name}</div>
                <div className="text-dark-800 text-xs">{member.city}</div>
              </div>
            </button>
          ))}
        </div>
      )}

      {selectedMedia && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="relative rounded-lg overflow-hidden bg-dark-300 mt-4"
        >
          {selectedMedia.type === 'video' ? (
            <video
              src={mediaPreview || ''}
              className="w-full object-contain"
              style={{ maxHeight: 'calc(100vh - 400px)' }}
              controls
              autoPlay
              muted
              loop
            />
          ) : (
            <img
              src={mediaPreview || ''}
              alt="Preview"
              className="w-full object-contain"
              style={{ maxHeight: 'calc(100vh - 400px)' }}
            />
          )}
          <button
            onClick={() => {
              setSelectedMedia(null);
              setMediaPreview(null);
              if (fileInputRef.current) fileInputRef.current.value = '';
            }}
            className="absolute top-2 right-2 p-2 bg-dark-900/80 hover:bg-dark-900 rounded-full text-white transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        </motion.div>
      )}

      <AnimatePresence>
        {(externalError || internalError) && (
          <motion.div
            key="error-message"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-4 text-red-400 text-sm bg-red-400/10 p-4 rounded-lg border border-red-400/20 flex items-start"
          >
            <div className="flex-1">{externalError || internalError}</div>
            <button
              onClick={() => setInternalError(null)}
              className="ml-2 text-red-400 hover:text-red-300 transition-colors"
            >
              <X className="h-4 w-4" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default PostComposer;