import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { cities } from '../../data/cities';
import { cn } from '@/lib/utils'; 
import { SearchBar } from './venues-api/components/search/SearchBar';
import { PEXELS_CONFIG } from '../../lib/config/pexels';
import { calculateHaversineDistance, getCityCoordinates } from '../../lib/coordinates';
import { VenueService } from '../../lib/services/venues/venue.service';
import { getUserLocation } from '../../lib/utils/location';
import { calculateLocationDistance } from '../../lib/utils/coordinates';
import { supabase } from '../../lib/supabase';
import CityCardList from './CityCardList';
import CitySkeleton from './CitySkeleton';
import CityTabs from './CityTabs';
import CityMembersList from './CityMembersList';
import CityVenuesList from './CityVenuesList';
import { VenueApiList } from './venues-api';
import CityMembersModal from './CityMembersModal';
import BlurOverlay from '../ui/BlurOverlay';

interface LocationState {
  selectedCity?: string;
  showFacilityDetails?: boolean;
  facilityId?: string;
  activeTab?: 'clubs' | 'players' | 'trainers';
}

const FacilityManagement: React.FC = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  
  const [selectedCity, setSelectedCity] = useState<string | null>(state?.selectedCity || null);
  const [activeTab, setActiveTab] = useState<'clubs' | 'players' | 'trainers'>(state?.activeTab || 'clubs');
  const [showMembersModal, setShowMembersModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [cityImage, setCityImage] = useState<string | null>(null);
  const [sortedCities, setSortedCities] = useState<City[]>([]);
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [userCity, setUserCity] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [citiesCache, setCitiesCache] = useState<{
    data: typeof sortedCities;
    timestamp: number;
  } | null>(null);
  const [imageScale, setImageScale] = useState(1);
  const [imageOpacity, setImageOpacity] = useState(1);
  const [contentTransform, setContentTransform] = useState(0);
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  // Add scroll listener for image animation
  useEffect(() => {
    if (!selectedCity) return;

    const handleScroll = () => {
      if (!headerRef.current) return;
      
      const scrollPosition = window.scrollY;
      const headerHeight = headerRef.current.offsetHeight;
      const scrollPercentage = Math.min(scrollPosition / headerHeight, 1);
      const parallaxAmount = Math.min(scrollPosition * 0.4, headerHeight * 0.5); // Adjust multiplier for parallax intensity
      
      // Calculate scale (1 -> 0.8)
      const newScale = 1 - (scrollPercentage * 0.2);
      // Calculate opacity (1 -> 0)
      const newOpacity = 1 - scrollPercentage;
      // Calculate content transform
      setContentTransform(-parallaxAmount);

      setImageScale(newScale);
      setImageOpacity(newOpacity);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [selectedCity]);

  // Update city status based on members and venues
  useEffect(() => {
    const updateCityStatus = async () => {
      // Check cache first
      const now = Date.now();
      if (citiesCache && (now - citiesCache.timestamp < CACHE_DURATION)) {
        setSortedCities(citiesCache.data);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const processedCities = await Promise.all(cities.map(async (city) => {
        try {
          let isActive = false;
          
          // Check for members first
          const { count: memberCount, error: memberError } = await supabase
            .from('users')
            .select('*', { count: 'exact', head: true })
            .ilike('city', `%${city.name}%`);

          if (memberError) {
            console.warn(`Warning: Error fetching members for ${city.name}:`, memberError.message);
          } else {
            isActive = isActive || (memberCount || 0) > 0;
          }
      
          // Check for venues using VenueService
          try {
            const venues = await VenueService.getVenues(city.name);
            console.log(`Venues for ${city.name}:`, venues.length);
            isActive = isActive || (venues?.length || 0) > 0;
          } catch (venueError) {
            console.warn(`Warning: Error fetching venues for ${city.name}:`, venueError.message);
          }

          console.log(`City ${city.name}: Active=${isActive}, Members=${memberCount || 0}`);

          return {
            ...city,
            active: isActive
          };
        } catch (error) {
          console.warn(`Warning: Error processing ${city.name}:`, error);
          return city;
        }
      }));

      // First sort by active status
      const initialSorted = processedCities.sort((a, b) => {
        if (a.active === b.active) {
          return a.name.localeCompare(b.name);
        }
        return a.active ? -1 : 1;
      });

      setSortedCities(initialSorted);
      // Cache the sorted results
      setCitiesCache({
        data: initialSorted,
        timestamp: now
      });

      setIsLoading(false);
    };

    updateCityStatus();
  }, []);

  // Fetch user's location and city
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Get user's location
        const location = await getUserLocation();
        setUserLocation(location);

        // Get user's city from profile
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data: userData } = await supabase
            .from('users')
            .select('city')
            .eq('auth_id', user.id)
            .single();
          
          if (userData?.city) {
            setUserCity(userData.city);
          }
        }
      } catch (error) {
        console.warn('Error fetching user location:', error);
      }
    };

    fetchUserData();
  }, []);

  // Sort cities by distance after we have user location
  useEffect(() => {
    if (!sortedCities.length) return;

    const sortCitiesByDistance = () => {
      let citiesWithDistance = sortedCities.map(city => {
        let distance: number;

        if (userLocation) {
          const cityCoords = getCityCoordinates(city.name);
          if (cityCoords) {
            // Use calculateDistance for precise coordinate-based calculation
            distance = calculateHaversineDistance(
              userLocation.latitude,
              userLocation.longitude,
              cityCoords.lat,
              cityCoords.lng
            );
          } else {
            // Fallback to string-based city comparison if coordinates not found
            distance = -1;
          }
        } else if (userCity) {
          // Use string-based city comparison when only city name is available
          distance = calculateLocationDistance(
            userCity,
            city.name
          );
        } else {
          distance = -1;
        }

        console.log(`Distance to ${city.name}: ${distance}km`);
        return {
          ...city,
          distance
        };
      });

      // First sort by active status
      citiesWithDistance = citiesWithDistance.sort((a, b) => {
        if (a.active === b.active) {
          // If both active or both inactive, sort by distance
          const distanceA = a.distance >= 0 ? a.distance : Infinity;
          const distanceB = b.distance >= 0 ? b.distance : Infinity;
          
          if (distanceA === distanceB) {
            return a.name.localeCompare(b.name);
          }
          return distanceA - distanceB;
        }
        return a.active ? -1 : 1;
      });

      // Only update if the order has changed
      const hasOrderChanged = citiesWithDistance.some((city, index) => 
        city.name !== sortedCities[index].name || 
        city.active !== sortedCities[index].active
      );

      if (hasOrderChanged) {
        setSortedCities(citiesWithDistance);
        // Update cache with new order
        setCitiesCache({
          data: citiesWithDistance,
          timestamp: Date.now()
        });
      }
    };

    sortCitiesByDistance();
  }, [userLocation, userCity, sortedCities]);

  // Fetch city image from Pexels when a city is selected
  useEffect(() => {
    if (!selectedCity) {
      setCityImage(null);
      return;
    }

    const fetchCityImage = async () => {
      const cityLower = selectedCity.toLowerCase();

      try {
        // Check if we have a predefined image for this city
        const predefinedCity = cities.find(c => c.name === selectedCity);
        if (predefinedCity?.image) {
          setCityImage(predefinedCity.image);
          return;
        }

        // Check for Unsplash fallback first
        if (PEXELS_CONFIG.CITY_FALLBACKS[cityLower]) {
          setCityImage(PEXELS_CONFIG.CITY_FALLBACKS[cityLower]);
          return;
        }

        // Construct search query with city name variations
        const searchQueries = [
          `${selectedCity} skyline`,
          `${selectedCity} downtown`,
          `${selectedCity} panorama`,
          selectedCity
        ];

        // Try each query until we find good results
        for (const query of searchQueries) {
          const response = await fetch(
            `https://api.pexels.com/v1/search?query=${encodeURIComponent(query)} ${PEXELS_CONFIG.CITYSCAPE_KEYWORDS} -${PEXELS_CONFIG.EXCLUDE_KEYWORDS}&per_page=${PEXELS_CONFIG.RESULTS_PER_PAGE}&orientation=landscape&size=large`,
            {
              headers: { 
                Authorization: import.meta.env.VITE_PEXELS_API_KEY || '',
                'Content-Type': 'application/json'
              }
            }
          );

          if (!response.ok) throw new Error('Failed to fetch image');
          
          const data = await response.json();
          if (data.photos && data.photos.length > 0) {
            // Filter photos to find good cityscapes
            const cityscapes = data.photos.filter(photo => {
              const alt = photo.alt?.toLowerCase() || '';
              const title = photo.title?.toLowerCase() || '';
              const description = photo.description?.toLowerCase() || '';
              
              // Check all text fields for relevant keywords
              const textToCheck = `${alt} ${title} ${description}`;
              
              const hasGoodKeywords = PEXELS_CONFIG.CITYSCAPE_KEYWORDS.split(' ')
                .some(keyword => textToCheck.includes(keyword.toLowerCase()));
              const hasBadKeywords = PEXELS_CONFIG.EXCLUDE_KEYWORDS.split(' -')
                .some(keyword => textToCheck.includes(keyword.toLowerCase()));
              
              // Additional check for city name to ensure relevance
              const hasCityName = textToCheck.includes(selectedCity.toLowerCase());
              
              return hasGoodKeywords && !hasBadKeywords && hasCityName;
            });
            
            // Get up to 5 unique cityscape images
            const selectedPhotos = cityscapes.length > 0 ? cityscapes : data.photos;
            const uniquePhotos = Array.from(new Set(selectedPhotos.map(p => p.src.large2x)));
            const validPhotos = uniquePhotos.slice(0, PEXELS_CONFIG.NUM_IMAGES);

            if (validPhotos.length > 0) {
              // Randomly select one image
              const randomIndex = Math.floor(Math.random() * validPhotos.length);
              setCityImage(validPhotos[randomIndex]);
              return;
            }
          }
        }

        // If no good images found, try Unsplash as fallback
        const unsplashResponse = await fetch(
          `https://api.unsplash.com/search/photos?query=${encodeURIComponent(selectedCity)}+cityscape&orientation=landscape&per_page=30`,
          {
            headers: {
              'Authorization': `Client-ID ${import.meta.env.VITE_UNSPLASH_ACCESS_KEY}`
            }
          }
        );

        if (unsplashResponse.ok) {
          const unsplashData = await unsplashResponse.json();
          if (unsplashData.results?.length > 0) {
            const cityPhotos = unsplashData.results.filter(photo => 
              photo.alt_description?.toLowerCase().includes(selectedCity.toLowerCase()) ||
              photo.description?.toLowerCase().includes(selectedCity.toLowerCase())
            );
            if (cityPhotos.length > 0) {
              const randomPhoto = cityPhotos[Math.floor(Math.random() * cityPhotos.length)];
              setCityImage(randomPhoto.urls.regular);
              return;
            }
          }
        }

        // Use fallback if no suitable images found
        setCityImage(PEXELS_CONFIG.FALLBACK_IMAGES[
          Math.floor(Math.random() * PEXELS_CONFIG.FALLBACK_IMAGES.length)
        ]);
      } catch (error) {
        console.warn('Error fetching city image:', error);
        setCityImage(PEXELS_CONFIG.FALLBACK_IMAGES[
          Math.floor(Math.random() * PEXELS_CONFIG.FALLBACK_IMAGES.length)
        ]);
      }
    };

    fetchCityImage();
  }, [selectedCity]);

  const handleCityClick = useCallback((city: string) => {
    setSelectedCity(city);
    // Scroll to top with smooth animation
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleBack = () => {
    setSelectedCity(null);
    setActiveTab('clubs');
  };

  // Filter cities based on search term
  const filteredCities = sortedCities.filter(city =>
    city.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleTabChange = (tab: 'venues-api' | 'players' | 'trainers') => {
    setActiveTab(tab);
  };

  return (
    <div className="space-y-6">
      {/* Search Filter */}
      {!selectedCity && (
        <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 mb-6">
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search for Cities..."
          />
        </div>
      )}

      {selectedCity ? (
        <>
          <div className="relative">
            <div ref={headerRef} className="relative h-64 overflow-hidden rounded-t-2xl">
              {/* Back Button Overlay */}
              <button
                onClick={handleBack}
                className={cn(
                  "absolute top-6 left-6 z-10",
                  "flex items-center justify-center",
                  "w-10 h-10",
                  "bg-black/40 hover:bg-black/60",
                  "backdrop-blur-sm rounded-full",
                  "text-white",
                  "transition-all duration-200",
                  "transform hover:scale-105"
                )}
              >
               <ChevronLeft className="h-5 w-5 text-white" stroke="white" />
             
              </button>

              <img
                src={cityImage || PEXELS_CONFIG.FALLBACK_IMAGES[0]}
                alt={`${selectedCity} cityscape`}
                className="w-full h-full object-cover origin-top"
                style={{
                  transform: `scale(${imageScale})`,
                  opacity: imageOpacity,
                  transition: 'transform 0.1s ease-out'
                }}
              />
              <div 
                className="absolute inset-0 bg-gradient-to-t from-dark-100/90 via-dark-900/10 to-transparent"
                style={{ opacity: imageOpacity }}
              />
              <div 
                className="absolute bottom-6 left-6"
                style={{ opacity: imageOpacity }}
              >
                <h2 className="text-2xl font-bold text-white !text-white">
                  {selectedCity}
                </h2>
              </div>
            </div>
            
            {/* Content section that overlaps with header */}
            <div 
              ref={contentRef}
              className="relative bg-dark-200 rounded-2xl border border-dark-300 -mt-6"
              style={{
                transform: `translateY(${contentTransform}px)`,
                transition: 'transform 0.1s ease-out'
              }}
            >
              <div className="p-6">
              <CityTabs activeTab={activeTab} onTabChange={handleTabChange} />
              
              <div className="mt-6">
                {activeTab === 'clubs' ? (
                  <VenueApiList city={selectedCity} />
                ) : activeTab === 'players' ? (
                  <CityMembersList city={selectedCity} />
                ) : (
                  <div className="relative min-h-[600px]">
                    {/* Scrollable content with blur */}
                    <div className="absolute inset-0 overflow-y-auto">
                      <div className="blur-sm pointer-events-none">
                        <CityVenuesList city={selectedCity} />
                      </div>
                    </div>

                    {/* Centered overlay */}
                    <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
                      <div className="pointer-events-auto">
                        <BlurOverlay 
                          componentId="training"
                          title="Elite Training Network"
                          description="Connect with elite trainers worldwide. Available exclusively for Elite members."
                          mode="contained"
                          className="rounded-xl"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col space-y-6">
          {isLoading ? (
            <CitySkeleton count={6} />
          ) : (
          <CityCardList
            cities={filteredCities}
            onCityClick={handleCityClick}
          />
          )}
        </div>
      )}

      {showMembersModal && selectedCity && (
        <CityMembersModal
          city={selectedCity}
          isOpen={showMembersModal}
          onClose={() => setShowMembersModal(false)}
        />
      )}
    </div>
  );
};

export default FacilityManagement;