import React from 'react';
import { cn } from '@/lib/utils';
import SocialLinks from '../../social/SocialLinks';
import CityLink from '../../ui/CityLink';

interface PlayerInfoProps {
  location: string;
  distance?: number;
  company: {
    name: string;
    position: string;
  };
  matchPercentage: number;
  mutualConnections?: number;
  onShowMutuals?: () => void;
  linkedinUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  invitedBy?: {
    name: string;
    date: string;
  };
}

const PlayerInfo: React.FC<PlayerInfoProps> = ({
  location,
  distance,
  company,
  matchPercentage,
  mutualConnections,
  onShowMutuals,
  linkedinUrl,
  facebookUrl,
  instagramUrl,
  invitedBy
}) => {
  return (
    <div className={cn(
      "space-y-3",
      "text-center w-full mt-4"
    )}>
      <div className="space-y-2">
        <div className="flex items-center justify-center text-dark-800 gap-1 text-lg">
          <CityLink city={location} />
          {typeof distance === 'number' && distance !== 0 && (
            <span className="text-dark-800">
              • {distance === -1 ? 'Distance not available' : `${distance}km away`}
            </span>
          )}
        </div>

        <div className="text-dark-800 text-lg flex items-center justify-center gap-2">
          <span className="inline-block truncate align-bottom">
            {company.position}
          </span> 
          <span className="mx-1">@</span>
          <span className="inline-block truncate align-bottom">
            {company.name}
          </span>
        </div>

        {invitedBy && (
          <div className="text-dark-800 text-sm text-center mt-2">
            Invited by {invitedBy.name} • Member since {new Date(invitedBy.date).toLocaleDateString()}
          </div>
        )}

        {mutualConnections && mutualConnections > 0 && (
          <button
            onClick={onShowMutuals}
            className={cn(
              "text-dark-800 hover:text-gold-400",
              "transition-colors text-sm",
              "hover:underline"
            )}
          >
            {mutualConnections} mutual connection{mutualConnections !== 1 ? 's' : ''}
          </button>
        )}

        <div className="mt-3 flex justify-center">
          <SocialLinks
            linkedinUrl={linkedinUrl}
            facebookUrl={facebookUrl}
            instagramUrl={instagramUrl}
          />
        </div>

        <div className="flex justify-center mt-3">
          <span className="inline-flex items-center px-3 py-1 bg-gold-400/10 text-gold-400 rounded-full text-sm">
            {matchPercentage}% Match
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlayerInfo;