// City coordinates lookup grouped by region
export const cityCoordinatesByRegion: Record<string, Record<string, { lat: number; lng: number }>> = {
  Europe: {
    'Valencia': { lat: 39.4699, lng: -0.3763 },
    'Monte Carlo': { lat: 43.7384, lng: 7.4246 },
    'Nice': { lat: 43.7102, lng: 7.2620 },
    'Paris': { lat: 48.8566, lng: 2.3522 },
    'London': { lat: 51.5074, lng: -0.1278 },
    'Rome': { lat: 41.9028, lng: 12.4964 },
    'Barcelona': { lat: 41.3851, lng: 2.1734 },
    'Madrid': { lat: 40.4168, lng: -3.7038 },
    'Luxembourg': { lat: 49.6116, lng: 6.1319 },
    'Vienna': { lat: 48.2082, lng: 16.3738 },
    'Zurich': { lat: 47.3769, lng: 8.5417 },
    'Geneva': { lat: 46.2044, lng: 6.1432 },
    'Frankfurt': { lat: 50.1109, lng: 8.6821 },
    'Milano': { lat: 45.4642, lng: 9.1900 },
    'Milan': { lat: 45.4642, lng: 9.1900 },
    'Munich': { lat: 48.1351, lng: 11.5820 },
    'München': { lat: 48.1351, lng: 11.5820 },
    'Dublin': { lat: 53.3498, lng: -6.2603 },
    'Amsterdam': { lat: 52.3676, lng: 4.9041 },
    'Benicassim': { lat: 40.0575, lng: 0.0642 },
    'Warsaw': { lat: 52.2297, lng: 21.0122 },
    'Berlin': { lat: 52.5200, lng: 13.4050 },
    'Moscow': { lat: 55.7558, lng: 37.6173 },
    'Istanbul': { lat: 41.0082, lng: 28.9784 },
    'Prague': { lat: 50.0755, lng: 14.4378 },
    'Budapest': { lat: 47.4979, lng: 19.0402 },
    'Lisbon': { lat: 38.7223, lng: -9.1393 },
    'Stockholm': { lat: 59.3293, lng: 18.0686 },
    'Oslo': { lat: 59.9139, lng: 10.7522 },
    'Helsinki': { lat: 60.1699, lng: 24.9384 },
    'Copenhagen': { lat: 55.6761, lng: 12.5683 },
    'Edinburgh': { lat: 55.9533, lng: -3.1883 },
    'Glasgow': { lat: 55.8642, lng: -4.2518 },
    'St. Petersburg': { lat: 59.9311, lng: 30.3609 },
    'Hamburg': { lat: 53.5511, lng: 9.9937 },
    'Bucharest': { lat: 44.4268, lng: 26.1025 },
    'Sofia': { lat: 42.6977, lng: 23.3219 },
    'Athens': { lat: 37.9838, lng: 23.7275 }
  },
  Asia: {
    'Singapore': { lat: 1.3521, lng: 103.8198 },
    'Dubai': { lat: 25.2048, lng: 55.2708 },
    'Tokyo': { lat: 35.6762, lng: 139.6503 },
    'Tel Aviv': { lat: 32.0853, lng: 34.7818 },
    'Beijing': { lat: 39.9042, lng: 116.4074 },
    'New Delhi': { lat: 28.6139, lng: 77.2090 },
    'Seoul': { lat: 37.5665, lng: 126.9780 },
    'Riyadh': { lat: 24.7136, lng: 46.6753 },
    'Ankara': { lat: 39.9334, lng: 32.8597 },
    'Islamabad': { lat: 33.6844, lng: 73.0479 },
    'Baghdad': { lat: 33.3128, lng: 44.3615 },
    'Tehran': { lat: 35.6892, lng: 51.3890 },
    'Jerusalem': { lat: 31.7683, lng: 35.2137 },
    'Abu Dhabi': { lat: 24.4539, lng: 54.3773 },
    'Hanoi': { lat: 21.0278, lng: 105.8342 },
    'Jakarta': { lat: -6.2088, lng: 106.8456 },
    'Kuala Lumpur': { lat: 3.1390, lng: 101.6869 },
    'Manila': { lat: 14.5995, lng: 120.9842 },
    'Mumbai': { lat: 19.0760, lng: 72.8777 },
    'Bangalore': { lat: 12.9716, lng: 77.5946 },
    'Hong Kong': { lat: 22.3193, lng: 114.1694 },
    'Osaka': { lat: 34.6937, lng: 135.5023 },
    'Chennai': { lat: 13.0827, lng: 80.2707 },
    'Karachi': { lat: 24.8607, lng: 67.0011 }
  },
  'North America': {
    'Washington DC': { lat: 38.8977, lng: -77.0365 },
    'Ottawa': { lat: 45.4215, lng: -75.6972 },
    'New York': { lat: 40.7128, lng: -74.0060 },
    'Los Angeles': { lat: 34.0522, lng: -118.2437 },
    'Chicago': { lat: 41.8781, lng: -87.6298 },
    'Houston': { lat: 29.7604, lng: -95.3698 },
    'Phoenix': { lat: 33.4484, lng: -112.0740 },
    'Philadelphia': { lat: 39.9526, lng: -75.1652 },
    'San Antonio': { lat: 29.4241, lng: -98.4936 },
    'San Diego': { lat: 32.7157, lng: -117.1611 },
    'Dallas': { lat: 32.7767, lng: -96.7970 },
    'San Jose': { lat: 37.3382, lng: -121.8863 },
    'Montréal': { lat: 45.5017, lng: -73.5673 },
    'Vancouver': { lat: 49.2827, lng: -123.1207 },
    'Toronto': { lat: 43.6532, lng: -79.3832 },
    'Detroit': { lat: 42.3314, lng: -83.0458 },
    'Austin': { lat: 30.2672, lng: -97.7431 },
    'San Francisco': { lat: 37.7749, lng: -122.4194 },
    'Boston': { lat: 42.3601, lng: -71.0589 },
    'Seattle': { lat: 47.6062, lng: -122.3321 },
    'Denver': { lat: 39.7392, lng: -104.9903 },
    'Atlanta': { lat: 33.7490, lng: -84.3880 },
    'Miami': { lat: 25.7617, lng: -80.1918 },
    'Las Vegas': { lat: 36.1699, lng: -115.1398 },
    'Orlando': { lat: 28.5383, lng: -81.3792 }
  },
  'South America': {
    'Brasília': { lat: -15.7939, lng: -47.8828 },
    'Buenos Aires': { lat: -34.6037, lng: -58.3816 },
    'São Paulo': { lat: -23.5505, lng: -46.6333 },
    'Rio de Janeiro': { lat: -22.9068, lng: -43.1729 },
    'Lima': { lat: -12.0464, lng: -77.0428 },
    'Bogotá': { lat: 4.7110, lng: -74.0721 },
    'Santiago': { lat: -33.4489, lng: -70.6693 }
  },
  Africa: {
    'Cairo': { lat: 30.0444, lng: 31.2357 },
    'Johannesburg': { lat: -26.2041, lng: 28.0473 },
    'Cape Town': { lat: -33.9249, lng: 18.4241 }
  },
  Oceania: {
    'Canberra': { lat: -35.2809, lng: 149.1300 },
    'Sydney': { lat: -33.8688, lng: 151.2093 },
    'Melbourne': { lat: -37.8136, lng: 144.9631 },
    'Brisbane': { lat: -27.4698, lng: 153.0251 },
    'Perth': { lat: -31.9505, lng: 115.8605 }
  }
};

// Merge all region entries into a flat lookup for backward compatibility
export const cityCoordinates: Record<string, { lat: number; lng: number }> = {
  ...cityCoordinatesByRegion.Europe,
  ...cityCoordinatesByRegion.Asia,
  ...cityCoordinatesByRegion['North America'],
  ...cityCoordinatesByRegion['South America'],
  ...cityCoordinatesByRegion.Africa,
  ...cityCoordinatesByRegion.Oceania
};

// Get coordinates for a city
export function getCityCoordinates(city: string): { lat: number; lng: number } | null {
  return cityCoordinates[city] || null;
}

// Calculate distance between two points using the Haversine formula
export function calculateHaversineDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.round(R * c); // Rounded to the nearest kilometer
}

// Get venue coordinates by city
export const getVenueCoordinates = (city: string) => {
  return cityCoordinates[city] || { lat: 0, lng: 0 };
};

// Calculate distance between two cities
export function calculateCityDistance(city1: string, city2: string): number {
  // Normalize city names for comparison
  const normalizeCity = (city: string) => {
    const cityName = city.split(',')[0].trim().toLowerCase();
    if (cityName === 'tel aviv-yafo') return 'tel aviv';
    return cityName;
  };

  const city1Normalized = normalizeCity(city1);
  const city2Normalized = normalizeCity(city2);

  // If cities are the same, return 0
  if (city1Normalized === city2Normalized) {
    return 0;
  }

  // Find coordinates using either the normalized or original keys
  const coords1 = cityCoordinates[city1Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city1Normalized) || ''];
  const coords2 = cityCoordinates[city2Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city2Normalized) || ''];

  if (!coords1 || !coords2) {
    console.warn(`Could not find coordinates for: ${city1Normalized} and/or ${city2Normalized}`);
    return -1; // Indicate error in distance calculation
  }

  return calculateHaversineDistance(coords1.lat, coords1.lng, coords2.lat, coords2.lng);
}