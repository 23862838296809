import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { ACTIVITY_SHORTCUTS } from '../../constants/activities';

interface ActivityTagsProps {
  selectedTags: string[];
  availableActivities: string[];
  onTagSelect: (tag: string) => void;
  className?: string;
}

export const ActivityTags: React.FC<ActivityTagsProps> = ({
  selectedTags,
  availableActivities,
  onTagSelect,
  className
}) => {
  const [showAll, setShowAll] = useState(false);

  // Filter ACTIVITY_SHORTCUTS to only show activities that exist in venues
  const filteredActivities = ACTIVITY_SHORTCUTS.filter(activity =>
    availableActivities.some(available => 
      available.toLowerCase().includes(activity.name.toLowerCase())
    )
  );

  const visibleActivities = showAll
    ? filteredActivities
    : filteredActivities.slice(0, 6);

  return (
    <div className={cn("flex flex-wrap gap-2", className)}>
      {visibleActivities.map((activity) => (
        <button
          key={activity.name}
          onClick={() => onTagSelect(activity.name)}
          className={cn(
            "flex items-center space-x-1 px-2 py-1 rounded-full text-xs",
            "hover:shadow-md transform hover:scale-105 transition-all",
            selectedTags.includes(activity.name)
              ? "bg-gold-400 text-dark-50 hover:bg-gold-500"
              : "bg-dark-300 text-white hover:bg-dark-400"
          )}
        >
          <span>{activity.emoji}</span>
          <span>{activity.name}</span>
        </button>
      ))}
      
      {filteredActivities.length > 6 && (
        <button
          onClick={() => setShowAll(!showAll)}
          className={cn(
            "flex items-center space-x-1 px-2 py-1 rounded-full text-xs",
            "bg-dark-300 text-white hover:bg-dark-400",
            "hover:shadow-md transform hover:scale-105 transition-all"
          )}
        >
          <span>{showAll ? 'Less' : 'More'}</span>
          <ChevronDown className={cn(
            "h-3 w-3 transition-transform",
            showAll && "rotate-180"
          )} />
        </button>
      )}
    </div>
  );
};