import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CitiesPlayedModal from '../profile/modal/CitiesPlayedModal';
import MemberProfileSkeleton from '../members/MemberProfileSkeleton';
import NetworkTab from '../profile/tabs/NetworkTab';
import PostsTab from '../profile/tabs/PostsTab';
import ReviewsSection from './ReviewsSection';
import ProfileTabs from './ProfileTabs';
import AboutTab from './AboutTab';
import ProfileHeader from '../profile/sections/ProfileHeader';
import ProfileStats from './ProfileStats';
import { cityLandmarks } from '../../data/cities';
import { useProfile } from '../../hooks/useProfile';
import { storage } from '../../lib/supabase/storage';
import { supabase } from '@/lib/supabase';
import { useConnectionStore } from '../../store/connectionStore';
import { fetchCityImageFromMultipleSources } from '@/lib/fetchCityImage';
import { Post } from '../../types/post';
import { Member } from '../../types/member';
import { useTheme } from '@/contexts/ThemeContext';

interface ProfileSectionProps {
  defaultTab?: string;
  hideDetails?: boolean;
  onTabChange?: (tab: string) => void;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  defaultTab = 'about',
  hideDetails = false,
  onTabChange
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get('tab') || defaultTab;

  const [activeTab, setActiveTab] = useState(initialTab as any);
  const [showCitiesModal, setShowCitiesModal] = useState(false);
  const [memberPosts, setMemberPosts] = useState<Post[]>([]);
  const [overallRating, setOverallRating] = useState(0);
  const isLoading = useConnectionStore((state) => state.isLoading);

  const {
    profile,
    isLoading: profileLoading,
    error: profileError,
    fetchProfile,
    updateProfile,
  } = useProfile();

  const { pendingRequests, fetchConnections } = useConnectionStore();
  useEffect(() => {
    fetchConnections();
  }, [fetchConnections]);

  const { isDarkMode } = useTheme();

  useEffect(() => {
    fetchProfile();
  }, []);

  // ✅ Ensure the active tab updates when the URL changes
  useEffect(() => {
    const urlTab = searchParams.get('tab');
    if (urlTab && urlTab !== activeTab) {
      setActiveTab(urlTab);
    }
  }, [location.search]); 

  // ✅ Fetch overall rating from Supabase
  useEffect(() => {
    if (profile?.id) {
      fetchOverallRating(profile.id);
    }
  }, [profile?.id]);

  const fetchOverallRating = async (userId: string) => {
    try {
      const { data: ratingsData, error: ratingsError } = await supabase
        .from('user_ratings')
        .select('overall_rating')
        .eq('user_id', userId)
        .maybeSingle();

      if (ratingsError) {
        console.error('Error fetching rating:', ratingsError);
        return;
      }

      setOverallRating(ratingsData?.overall_rating ? parseFloat(ratingsData.overall_rating.toFixed(1)) : 0);
    } catch (error) {
      console.error('Unexpected error fetching rating:', error);
    }
  };

  // Extract Social Media Links
  const socialLinks = {
    linkedinUrl: profile?.linkedin_url || null,
    facebookUrl: profile?.facebook_url || null,
    instagramUrl: profile?.instagram_url || null,
  };

  // Build the currentUser object using data from the profile.
  const currentUser: Member = {
    id: profile?.id || '',
    name: profile?.full_name || 'User',
    image:
      profile?.avatar_url?.startsWith('data:image')
        ? 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
        : profile?.avatar_url ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
    city: profile?.city || 'Location',
    jobTitle: profile?.job_type || 'Role',
    company: profile?.company || 'Company',
    skillLevel: 'Advanced',
    connections: 0,
    matchesPlayed: 0,
    citiesPlayed: ['Valencia', 'London', 'Paris'],
    achievements: ['Tournament Winner', 'Elite Status'],
    rating: overallRating,
    joinDate: '2024-10-01',
    industry: 'Technology',
    points: {
      matches: 6200,
      training: 4500,
      connections: 16200,
      cities: 1800,
      events: 3200,
      achievements: 5000,
      total: 36900,
    },
  };

const [cityImage, setCityImage] = useState<string | null>(null);

useEffect(() => {
  const loadCityImage = async () => {
    if (currentUser.city) {
      try {
        const url = await fetchCityImageFromMultipleSources(currentUser.city);
        setCityImage(url);
      } catch (err) {
        console.warn('[CITY IMAGE] Profile fallback used for:', currentUser.city, err);
        setCityImage('https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=format&fit=crop&w=1600&q=80');
      }
    }
  };

  loadCityImage();
}, [currentUser.city]);


  if (isLoading || profileLoading) {
    return <MemberProfileSkeleton />;
  }

  if (profileError) {
    return (
      <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20 text-center">
        <p>
          {profileError === 'Failed to fetch'
            ? 'Unable to connect to server. Please check your connection.'
            : profileError}
        </p>
        <button
          onClick={() => fetchProfile()}
          className="text-sm underline mt-2 hover:text-red-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  return (
    <div className="space-y-6">
      <ProfileHeader
        member={currentUser}
        profile={profile}
        cityImage={cityImage}
        socialLinks={socialLinks}
        onImageChange={async (file) => {
          try {
            const uploadData = await storage.uploadFile({
              bucket: 'avatars',
              file,
              path: `avatars/${currentUser.id}/${file.name}`,
            });
            console.log('Upload Data: ', uploadData.url);
            if (uploadData?.url) {
              const newUrl = `${uploadData.url}?t=${Date.now()}`;
              await updateProfile({ avatar_url: newUrl });
              await fetchProfile();
            }
          } catch (error) {
            console.error('Failed to update profile image:', error);
          }
        }}
      />

      <ProfileStats
        member={currentUser}
        onShowConnections={() => handleTabChange('network')}
        onShowReviews={() => handleTabChange('ratings')}
      />

      <ProfileTabs
        activeTab={activeTab}
        onTabChange={handleTabChange}
        pendingCount={pendingRequests?.length || 0}
      />

      {activeTab === 'about' && <AboutTab member={currentUser} profile={profile} />}
      {activeTab === 'posts' && (
        <PostsTab posts={memberPosts || []} memberName={currentUser.name} />
      )}
      {activeTab === 'network' && <NetworkTab />}
      {activeTab === 'ratings' && currentUser.id && (
        <ReviewsSection
          memberId={currentUser.id}
          memberName={currentUser.name}
          sports={[]} 
        />
      )}
    </div>
  );
};

export default ProfileSection;
