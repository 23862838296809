import React, { useState } from 'react';
import { Users, Clock, UserX } from 'lucide-react';
import ConnectionsList from '../connections/ConnectionsList';
import PendingConnectionsList from '../connections/PendingConnectionsList';
import BlockedMembersTab from '../tabs/BlockedMembersTab';
import { useConnectionStore } from '@/store/connectionStore';

const NetworkTab = () => {
  // ✅ Remove unnecessary fetch call and just use stored connections
  const { connections, isLoading } = useConnectionStore();

  const [activeTab, setActiveTab] = useState<
    'connections' | 'pending' | 'blocked'
  >('connections');

  // ✅ Use memoized filtering to prevent re-renders
  const pendingRequests = connections.filter(
    (conn) => conn.status === 'pending'
  );
  const acceptedConnections = connections.filter(
    (conn) => conn.status === 'accepted'
  );

  return (
    <div className="space-y-6">
      {/* Tab Navigation */}
      <div className="flex space-x-4 border-b border-gray-300">
        <button
          onClick={() => setActiveTab('connections')}
          className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors border-gold-400 text-gold-400 ${
            activeTab === 'connections'
              ? 'border-border-gold-400 text-gold-400'
              : 'border-transparent text-gray-700'
          }`}
        >
          <Users className="h-5 w-5" />
          <span>Connected ({acceptedConnections.length})</span>
        </button>

        <button
          onClick={() => setActiveTab('pending')}
          className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors border-gold-400 text-gold-400 ${
            activeTab === 'pending'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-gray-700'
          }`}
        >
          <Clock className="h-5 w-5" />
          <span>Pending ({pendingRequests.length})</span>
        </button>

        <button
          onClick={() => setActiveTab('blocked')}
          className={`flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 transition-colors border-gold-400 text-gold-400 ${
            activeTab === 'blocked'
              ? 'border-gold-400 text-gold-400'
              : 'border-transparent text-gray-700'
          }`}
        >
          <UserX className="h-5 w-5" />
          <span>Blocked</span>
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'connections' && (
        <ConnectionsList
          connections={acceptedConnections}
          isLoading={isLoading}
        />
      )}
      {activeTab === 'pending' && (
        <PendingConnectionsList connections={pendingRequests} />
      )}
      {activeTab === 'blocked' && <BlockedMembersTab />}
    </div>
  );
};

export default NetworkTab;
