import React from 'react';
import { Activity } from '../../types/activity';
import ActivityActions from './ActivityActions';
import ActivityStatus from './ActivityStatus';

interface ActivityFooterProps {
  activity: Activity;
  isOwnProfile: boolean;
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: () => void;
  onAccept: () => void;
  onRate?: () => void;
  isAccepting?: boolean;
  isDeclining?: boolean;
  isRating?: boolean;
}

const ActivityFooter: React.FC<ActivityFooterProps> = ({
  activity,
  isOwnProfile,
  onManageSession,
  onRematch,
  onDeclineRequest,
  onAccept,
  onRate,
  isAccepting,
  isDeclining,
  isRating
}) => {
  return (
    <div className="flex items-center justify-between mt-4 pt-4 border-t border-dark-300">
      <div className="flex-1">
        <ActivityActions
          activity={activity}
          onManageSession={onManageSession}
          onRematch={onRematch}
          onDeclineRequest={onDeclineRequest}
          onAccept={onAccept}
          onRate={onRate}
          isAccepting={isAccepting}
          isDeclining={isDeclining}
          isRating={isRating}
        />
      </div>
      <div className="flex-shrink-0 ml-2">
        {isOwnProfile && (
          <ActivityStatus 
            status={activity.status} 
            direction={activity.direction} 
          />
        )}
      </div>
    </div>
  );
};

export default ActivityFooter;