import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import { subscribeToNotifications } from '../lib/supabase/notifications';
import { useNotificationStore } from './notificationStore';

interface SubscriptionState {
  isSubscribed: boolean;
  unsubscribe: (() => void) | null;
  initializeSubscription: () => Promise<void>;
  cleanupSubscription: () => void;
}

export const useSubscriptionStore = create<SubscriptionState>((set) => ({
  isSubscribed: false,
  unsubscribe: null,

  initializeSubscription: async () => {
    try {
      // Only initialize if not already subscribed
      const state = useSubscriptionStore.getState();
      if (state.isSubscribed) {
        console.log('🔔 Notification subscription already active');
        return;
      }

      // Clean up any existing subscription
      if (state.unsubscribe) {
        state.unsubscribe();
      }

      // Get current user first
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        console.warn('No authenticated user for subscription');
        return;
      }

      console.log('🔔 Initializing notification subscription...');
      const unsubscribe = await subscribeToNotifications((notification) => {
        // Handle notification via notificationStore
        const notificationStore = useNotificationStore.getState();
        notificationStore.addNotificationToState(notification);
      });

      if (!unsubscribe) {
        throw new Error('Failed to initialize notification subscription');
      }

      set({ 
        isSubscribed: true,
        unsubscribe 
      });
      console.log('✅ Notification subscription active');
    } catch (error) {
      console.error('❌ Error initializing notification subscription:', error);
      set({
        isSubscribed: false,
        unsubscribe: null
      });
      throw error; // Re-throw to allow error handling in components
    }
  },

  cleanupSubscription: () => {
    const { unsubscribe } = useSubscriptionStore.getState();
    if (unsubscribe) {
      unsubscribe();
      set({ 
        isSubscribed: false,
        unsubscribe: null 
      });
      console.log('🔔 Notification subscription cleaned up');
    }
  }
}));