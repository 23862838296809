import { VenueApiResult } from '../../../types/venue';
import { formatVenueData } from './venue.formatter';

const GITHUB_BASE_URL = 'https://raw.githubusercontent.com/uri100/json_pc/refs/heads/main';

export async function fetchCityVenues(city: string): Promise<VenueApiResult[]> {
  try {
    // Convert city name to lowercase and replace spaces with underscores
    const formattedCity = city.toLowerCase().replace(/\s+/g, '_');
    const url = `${GITHUB_BASE_URL}/${formattedCity}.json`;

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch venues for ${city}`);
    }

    const data = await response.json();
    return Object.values(data).map((venue: any) => ({
      ...formatVenueData(venue),
      city: city
    }));
  } catch (error) {
    console.error(`Error fetching venues for ${city}:`, error);
    return [];
  }
}

export class VenueService {
  static async getVenues(city: string): Promise<VenueApiResult[]> {
    try {
      if (!city) {
        throw new Error('City parameter is required');
      }

      const normalizedCity = city.toLowerCase();
      
      return await fetchCityVenues(city);

    } catch (error) {
      console.error('Error fetching venues:', error);
      return [];
    }
  }
}