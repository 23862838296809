import React from 'react';
import { Plus } from 'lucide-react';
import { cn } from '@/lib/utils';

const QuickPlayWelcome = () => {
  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300 hover:border-gold-400/30 transition-all duration-300">
      
      <button
        onClick={() => {
          localStorage.setItem('quickplay_welcomed', 'true');
          window.location.reload();
        }}
        className={cn(
          "w-full bg-dark-300 rounded-xl p-4 text-left hover:bg-dark-400 transition-colors",
          "relative overflow-hidden group"
        )}
      >
        <div className="flex items-center space-x-3">
          <div className="bg-gold-400 p-2.5 rounded-full hover:bg-gold-500 transition-colors">
            <Plus className="h-5 w-5 text-dark-50" />
          </div>
          <div>
            <h3 className="text-white text-lg font-semibold tracking-tight">Start Playing! 🎾 ⚽ 🧘‍♀️</h3>
            <p className="text-dark-800 text-sm mt-1">
              Meet club members in town for sports or wellness. It's that simple!
            </p>
          </div>
        </div>
        {/* Pulsing highlight effect */}
        <div className="absolute -inset-1 bg-gradient-to-r from-gold-400/0 via-gold-400/30 to-gold-400/0 z-0 animate-[shimmer_2s_infinite]" />
      </button>
    </div>
  );
};

export default QuickPlayWelcome;