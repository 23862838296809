import React, { useState, useEffect } from 'react';
import { Users, Users2 } from 'lucide-react';
import ConnectionsList from './network/ConnectionsList';
import PendingConnectionsList from '../../profile/connections/PendingConnectionsList';
import { useAuth } from '../../../contexts/AuthContext';
import { supabase } from '@/lib/supabase';
import { useConnectionStore } from '@/store/connectionStore';

interface NetworkTabProps {
  memberId?: string;
}

const NetworkTab: React.FC<NetworkTabProps> = ({ memberId }) => {
  const { user } = useAuth();
  const [connectionsData, setConnectionsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { acceptRequest, declineRequest } = useConnectionStore();

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        setIsLoading(true);

        // Step 1: Get userId from users table using memberId
        const { data: userRecord, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', memberId)
          .single();

        if (userError || !userRecord) {
          throw new Error('Failed to fetch user ID');
        }

        const userId = userRecord.id;

        // Step 2: Fetch accepted connections only
        const { data: connectionsData, error: connectionsError } =
          await supabase.rpc('getmemberconnections', { _user_id: userId });

        if (connectionsError) {
          throw new Error('Failed to fetch connections');
        }

        // Filter only accepted connections
        const acceptedConnections =
          connectionsData?.filter((c: any) => c.status === 'accepted') || [];

        setConnectionsData(acceptedConnections);
      } catch (err: any) {
        setError(err.message);
        console.error('❌ Error fetching connections:', err);
      } finally {
        setIsLoading(false);
      }
    };

    if (memberId) {
      fetchConnections();
    }
  }, [memberId]);

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="text-sm underline mt-2 hover:text-red-400"
          >
            Try Again
          </button>
        </div>
      )}

      <div className="flex space-x-4 border-b border-dark-300">
        <button className="flex items-center space-x-2 px-4 py-2 -mb-px border-b-2 border-gold-400 text-gold-400">
          <Users className="h-4 w-4" />
          <span>Connections ({connectionsData.length})</span>
        </button>
      </div>
      
      {!isLoading && !error && connectionsData.length === 0 && (
        <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
          <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-white mb-2">No Connections</h3>
          <p className="text-dark-800 max-w-sm mx-auto">
            This member hasn't made any connections yet.
          </p>
        </div>
      )}

      <div className="space-y-6">
        {/* Pending Requests */}
        {connectionsData.filter(c => c.status === 'pending').length > 0 && (
          <div className="space-y-4">
            <h3 className="text-lg font-medium text-white">Pending Requests</h3>
            <PendingConnectionsList
              connections={connectionsData.filter(c => c.status === 'pending')}
            />
          </div>
        )}

        {/* Accepted Connections */}
        <div className="space-y-4">
          <ConnectionsList
            connections={connectionsData.filter(c => c.status === 'accepted')}
            isLoading={isLoading}
            onShowMutuals={() => {}}
            onMessageClick={() => {}}
            onAccept={acceptRequest}
            onDecline={declineRequest}
          />
        </div>
      </div>
    </div>
  );
};

export default NetworkTab;