import React from 'react';
import { Mail } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={cn(
      "!bg-black !border-t !border-dark-300",
      "relative z-10 mt-auto pb-safe"
    )}>
      <div className="container mx-auto px-4 py-6">
        <div className={cn(
          "flex flex-col sm:flex-row items-center justify-between gap-4 flex-wrap"
        )}>
          {/* Copyright Notice */}
          <div className="!text-white/70 text-sm">
            © {currentYear} Playclub™. All rights reserved.
          </div>

          {/* Links */}
          <div className={cn(
            "flex items-center gap-4 sm:gap-6 flex-wrap justify-center"
          )}>
            <Link 
              to="/privacy" 
              className="!text-white/70 hover:!text-gold-400 transition-colors text-sm"
            >
              Privacy Policy
            </Link>
            <Link 
              to="/terms" 
              className="!text-white/70 hover:!text-gold-400 transition-colors text-sm"
            >
              Terms of Service
            </Link>
            <Link 
              to="/community" 
              className="!text-white/70 hover:!text-gold-400 transition-colors text-sm"
            >
              Community Guidelines
            </Link>
            <Link 
              to="/contact" 
              className="!text-white/70 hover:!text-gold-400 transition-colors text-sm"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
