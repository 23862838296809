import { cityImageOverrides } from '@/data/cityImageOverrides';

const CITYSCAPE_KEYWORDS = [
  'skyline', 'scenic', 'aerial', 'architecture', 'historic', 'landmark', 'coast', 'mountain', 'panorama', 'city view'
];

const NEGATIVE_KEYWORDS = [
  'night', 'dark', 'portrait', 'selfie', 'people', 'indoor', 'interior'
];

const fallbackImage =
  'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=format&fit=crop&w=1600&q=80';

const matchesKeyword = (text: string, keywords: string[]) =>
  keywords.some(kw => text.includes(kw));

// ✅ Clean and normalize the city string (remove accents, slashes, casing)
const extractCityKey = (rawCity: string): string => {
  const cleaned = rawCity.split('/')[0].trim(); // Use first part before "/"
  return cleaned.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase(); // Strip accents
};

export const fetchCityImageFromMultipleSources = async (city: string): Promise<string> => {
  const cityKey = extractCityKey(city);

  // ✅ 0. Manual override
  if (cityImageOverrides[cityKey]) {
    return cityImageOverrides[cityKey];
  }

  // 1. Try Unsplash
  try {
    const res = await fetch(
      `https://api.unsplash.com/search/photos?query=${encodeURIComponent(city)}&orientation=landscape&per_page=15`,
      {
        headers: {
          Authorization: `Client-ID ${import.meta.env.VITE_UNSPLASH_API_KEY || ''}`,
        },
      }
    );
    const data = await res.json();
    const valid = (data.results || []).filter((item: any) => {
      const alt = (item.alt_description || item.description || '').toLowerCase();
      return (
        alt.includes(cityKey) || matchesKeyword(alt, CITYSCAPE_KEYWORDS)
      ) && !matchesKeyword(alt, NEGATIVE_KEYWORDS);
    });

    if (valid.length > 0) {
      const random = valid[Math.floor(Math.random() * valid.length)];
      return random.urls.regular;
    }
  } catch (e) {
    console.warn('[CITY IMAGE] Unsplash failed:', e);
  }

  // 2. Try Pixabay
  try {
    const key = import.meta.env.VITE_PIXABAY_API_KEY || '';
    const res = await fetch(
      `https://pixabay.com/api/?key=${key}&q=${encodeURIComponent(city)}&image_type=photo&orientation=horizontal&per_page=15&safesearch=true`
    );
    const data = await res.json();
    const valid = (data.hits || []).filter((item: any) => {
      const tags = item.tags.toLowerCase();
      return (
        tags.includes(cityKey) || matchesKeyword(tags, CITYSCAPE_KEYWORDS)
      ) && !matchesKeyword(tags, NEGATIVE_KEYWORDS);
    });

    if (valid.length > 0) {
      const random = valid[Math.floor(Math.random() * valid.length)];
      return random.largeImageURL;
    }
  } catch (e) {
    console.warn('[CITY IMAGE] Pixabay failed:', e);
  }

  // 3. Try Pexels
  try {
    const res = await fetch(
      `https://api.pexels.com/v1/search?query=${encodeURIComponent(city)}&per_page=15&orientation=landscape`,
      {
        headers: { Authorization: import.meta.env.VITE_PEXELS_API_KEY || '' },
      }
    );
    const data = await res.json();
    const valid = (data.photos || []).filter((photo: any) => {
      const alt = (photo.alt || '').toLowerCase();
      return (
        alt.includes(cityKey) || matchesKeyword(alt, CITYSCAPE_KEYWORDS)
      ) && !matchesKeyword(alt, NEGATIVE_KEYWORDS);
    });

    if (valid.length > 0) {
      const random = valid[Math.floor(Math.random() * valid.length)];
      return random.src.large2x;
    }
  } catch (e) {
    console.warn('[CITY IMAGE] Pexels failed:', e);
  }

  return fallbackImage;
};
