import React, { useRef, useState } from 'react';
import { Send, Image, Smile } from 'lucide-react';
import { Button } from '../ui/button';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface MessageInputProps {
  message: string;
  onMessageChange: (message: string) => void;
  onSend: () => void;
  onEmojiToggle: () => void;
}

const MessageInput: React.FC<MessageInputProps> = ({
  message,
  onMessageChange,
  onSend,
  onEmojiToggle,
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { isDarkMode } = useTheme();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Auto-resize textarea as content grows
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`; // Max 120px height
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (message?.trim()) {
        onSend();
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      }
    }
  };

  const handleSendClick = () => {
    if (message?.trim()) {
      onSend();
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  };

  const onEmojiClick = (emojiData: EmojiClickData) => {
    onMessageChange(message + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <div className="p-2 sm:p-4 border-t border-gray-200 dark:border-dark-300 w-full relative z-10 backdrop-blur-sm">
      <div className="flex items-end gap-2 w-full bg-white/80 dark:bg-dark-300/80 rounded-xl p-2 backdrop-blur-sm">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <Button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              variant="ghost"
              size="icon"
              className={cn(
                "text-gray-500 hover:text-gold-600",
                "dark:text-dark-800 dark:hover:text-gold-400",
                "light:text-gray-500 light:hover:text-gold-600"
              )}
            >
              <Smile className="h-5 w-5" />
            </Button>
            {showEmojiPicker && (
              <div className="absolute bottom-12 left-0 z-50">
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  theme={isDarkMode ? Theme.DARK : Theme.LIGHT}
                  searchPlaceHolder="Search emoji..."
                  width={300}
                  height={400}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <textarea
            ref={textareaRef}
            type="text"
            value={message}
            onChange={(e) => {
              onMessageChange(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyPress={handleKeyPress}
            placeholder="Type a message..."
            className={cn(
              "w-full rounded-lg px-3 py-2 max-h-[120px]",
              "text-sm",
              "focus:outline-none",
              "resize-none overflow-y-auto",
              "bg-white/50 text-gray-900 placeholder-gray-500",
              "dark:bg-dark-300/50 dark:text-white dark:placeholder-dark-800"
            )}
            rows={1}
          />
        </div>
        <Button
          onClick={handleSendClick}
          className={cn(
            "bg-gold-400 hover:bg-gold-500 text-dark-50 p-2 rounded-lg transition-colors flex-shrink-0"
          )}
        >
          <Send className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;