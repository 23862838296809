import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { Notification } from '../types/notification';
import {
  getNotifications,
  markAsRead,
  markAllAsRead as markAllAsReadInDb,
} from '../lib/supabase/notifications';

interface NotificationStore {
  notifications: Notification[];
  unreadCount: number;
  isLoading: boolean;
  error: string | null;
  addNotificationToState: (notification: Notification) => void;
  markAsRead: (id: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
  removeNotification: (id: string) => void;
  fetchNotifications: () => Promise<void>;
  // lastFetch is used for debouncing fetches
  lastFetch?: number;
}

export const useNotificationStore = create<NotificationStore>((set, get) => ({
  notifications: [],
  unreadCount: 0,
  isLoading: false,
  error: null,

  // This method solely updates the UI state and handles duplicate checks
  addNotificationToState: (notification) => {
    const state = get();
    const duplicate = state.notifications.some(n =>
      n.id === notification.id ||
      (n.type === notification.type &&
       n.content === notification.content &&
       Date.now() - new Date(n.timestamp).getTime() < 5000)
    );
    if (duplicate) {
      console.log('Duplicate notification detected, skipping');
      return;
    }
    set((state) => ({
      notifications: [notification, ...state.notifications].slice(0, 50),
      unreadCount: state.unreadCount + (notification.read_status ? 0 : 1)
    }));
  },

  markAsRead: async (id) => {
    try {
      await markAsRead(id);
      set((state) => ({
        notifications: state.notifications.map((n) =>
          n.id === id ? { ...n, read_status: true } : n
        ),
        unreadCount: state.notifications.filter((n) => !n.read_status && n.id !== id).length,
      }));
      console.log(`Marked notification ${id} as read`);
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  },

  markAllAsRead: async () => {
    try {
      await markAllAsReadInDb();
      set(() => ({
        notifications: [],
        unreadCount: 0,
      }));
      console.log('Marked all notifications as read');
    } catch (error) {
      console.error('Failed to mark all notifications as read:', error);
    }
  },

  removeNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter((n) => n.id !== id),
      unreadCount: state.notifications.filter((n) => !n.read_status && n.id !== id).length,
    }));
    console.log(`Removed notification ${id}`);
  },

  fetchNotifications: async () => {
    if (get().isLoading) return;
    const now = Date.now();
    const lastFetch = get().lastFetch || 0;
    if (now - lastFetch < 1000) return;
    
    const currentNotifications = get().notifications;
    set({ isLoading: true, error: null });

    try {
      const notifications = await getNotifications();
      if (JSON.stringify(notifications) !== JSON.stringify(currentNotifications)) {
        set({
          notifications,
          unreadCount: notifications.filter((n) => !n.read_status).length,
          isLoading: false,
          lastFetch: now,
        });
      } else {
        set({ isLoading: false, lastFetch: now });
      }
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
      set({ isLoading: false, error: 'Failed to fetch notifications', lastFetch: now });
    }
  },
}));
