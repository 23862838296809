import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext'; 
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { useSubscriptionStore } from './store/subscriptionStore';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './GoogleAnalytics';
import { useSwipeGesture } from './hooks/useSwipeGesture';

import LandingPage from './pages/LandingPage';
import Features from './pages/Features';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Community from './pages/Community';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import MatchMaking from './pages/MatchMaking';
import QuickPlayWrapper from './pages/QuickPlayWrapper';
import QuickPlayTransition from './pages/QuickPlayTransition';
import FacilityBooking from './pages/FacilityBooking';
import Training from './pages/Training';
import Events from './pages/Events';
import Messages from './pages/Messages';
import ClubProfile from './pages/ClubProfile';
import Profile from './pages/Profile';
import Activities from './pages/Activities';
import Leaderboard from './pages/Leaderboard';
import MemberProfile from './pages/MemberProfile';
import OnboardingFlow from './components/onboarding/OnboardingFlow';
import PrivateRoute from './components/PrivateRoute';
import Settings from './pages/Settings';
import Search from './pages/Search';

const AppRoutes = () => {
  const { isAuthenticated } = useAuth();
  const { initializeSubscription, cleanupSubscription } = useSubscriptionStore();
  const location = useLocation();
  const navigate = useNavigate();

  useSwipeGesture(); // 🧼 clean, extracted swipe logic

  useEffect(() => {
    if (isAuthenticated) {
      initializeSubscription().catch(console.error);
      return () => cleanupSubscription();
    }
  }, [isAuthenticated, initializeSubscription, cleanupSubscription]);

  

  return (
    <Routes>
  <Route
  path="/"
  element={
    isAuthenticated ? (
      <Navigate
        to="/dashboard/quick-play"
        replace
        state={{ fromNav: true, searchKey: Date.now() }}
      />
    ) : (
      <LandingPage />
    )
  }
/>


      <Route path="/features" element={<Features />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/community" element={<Community />} />
      <Route path="/onboarding" element={<OnboardingFlow onComplete={() => {}} />} />
      <Route path="/dashboard/feed" element={<PrivateRoute><Dashboard defaultView="feed" /></PrivateRoute>} />
      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      <Route path="/dashboard/search" element={<PrivateRoute><Search /></PrivateRoute>} />
      <Route path="/dashboard/match" element={<PrivateRoute><MatchMaking /></PrivateRoute>} />
      <Route path="/dashboard/quick-play" element={<PrivateRoute><QuickPlayWrapper /></PrivateRoute>} />
      <Route path="/dashboard/quick-play-transition" element={<PrivateRoute><QuickPlayTransition /></PrivateRoute>} />
      <Route path="/dashboard/facilities" element={<PrivateRoute><FacilityBooking /></PrivateRoute>} />
      <Route path="/dashboard/training" element={<PrivateRoute><Training /></PrivateRoute>} />
      <Route path="/dashboard/events" element={<PrivateRoute><Events /></PrivateRoute>} />
      <Route path="/dashboard/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
      <Route path="/dashboard/clubs/:id" element={<PrivateRoute><ClubProfile /></PrivateRoute>} />
      <Route path="/dashboard/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
      <Route path="/dashboard/activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
      <Route path="/dashboard/leaderboard" element={<PrivateRoute><Leaderboard /></PrivateRoute>} />
      <Route path="/dashboard/members/:id" element={<PrivateRoute><MemberProfile /></PrivateRoute>} />
      <Route path="/dashboard/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router basename="/">
          <GoogleAnalytics />
          <ScrollToTop />
          <AppRoutes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}