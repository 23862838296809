import { supabase } from '../supabase';
import { OnboardingData } from '../../types/onboarding';
import { insertUserSports } from './sports';

export async function signUpWithEmail(
  email: string,
  password: string,
  userData: OnboardingData,
  inviterId?: string
) {
  console.log('🔍 Starting signup process:', { email, userData });

  try {
    // Validate required fields
    if (!email || !password || !userData.fullName || !userData.city || !userData.phoneNumber) {
      throw new Error('Missing required user data');
    }

    console.log(`Auth.ts Email: "${email}"`);
    console.log(`Auth.ts Password: "${password}"`);

    // Check if the user is already authenticated (from the invite flow)
    const { data: getUserData, error: getUserError } = await supabase.auth.getUser();
    let confirmedUser = null;
    if (getUserError) {
      console.error('❌ Error getting user:', getUserError);
      throw getUserError;
    }

    if (getUserData && getUserData.user) {
      // User already exists – update their password using updateUser
      confirmedUser = getUserData.user;
      console.log('ℹ️ User already exists. Updating password...');
      const { data: updateData, error: updateError } = await supabase.auth.updateUser({ password });
      if (updateError) {
        console.error('❌ Error updating password:', updateError);
        throw updateError;
      }
      // Use updated user data if returned
      confirmedUser = updateData.user ? updateData.user : confirmedUser;
    } else {
      // User does not exist – proceed with normal signUp
      const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: { display_name: userData.fullName },
          emailRedirectTo: window.location.origin,
        },
      });
      if (signUpError) {
        console.error('❌ Signup error:', signUpError);
        throw signUpError;
      }

      // Wait for email confirmation (polling up to 10 times, 3 seconds apart)
      for (let i = 0; i < 10; i++) {
        const { data: userCheck, error: userError } = await supabase.auth.getUser();
        if (userError) {
          console.error('❌ Error checking user status:', userError);
          throw userError;
        }
        if (userCheck?.user?.email_confirmed_at) {
          confirmedUser = userCheck.user;
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
      if (!confirmedUser) {
        console.error('❌ Email confirmation timeout.');
        throw new Error('Email not confirmed within expected time.');
      }
    }

    console.log('✅ Email confirmed:', confirmedUser);

    // Immediately try signing in with the provided password
    const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (signInError) {
      console.error('Immediate sign-in failed:', signInError);
      throw signInError;
    }
    console.log('✅ Immediate sign-in succeeded:', signInData.user);

    // Upsert user profile in the "users" table
    const { data: profileData, error: profileError } = await supabase
      .from('users')
      .upsert(
        {
          auth_id: confirmedUser.id,
          full_name: userData.fullName,
          city: userData.city,
          company: userData.company,
          job_type: userData.jobTitle,
          phone_number: userData.phoneNumber,
          club_id: userData.club_id || null,
          custom_club: userData.custom_club || null,
          gender: userData.gender,
          avatar_url: userData.profilePic,
          skill_level: 'beginner',
          facebook_url: userData.facebookUrl || null,
          linkedin_url: userData.linkedinUrl || null,
          instagram_url: userData.instagramUrl || null,
        },
        { onConflict: 'auth_id' }
      )
      .select()
      .single();
    if (profileError) {
      console.error('❌ Profile creation error:', profileError);
      // Optionally, you might sign out the user if profile creation fails
      throw new Error('Failed to create user profile');
    }
    console.log('✅ User profile created:', profileData);

    // If an inviter exists, create connection and conversation
    if (inviterId) {
      await supabase.from('connections').insert({
        initiator_id: inviterId,
        receiver_id: profileData.id,
        status: 'accepted',
      });
      await supabase.rpc('get_or_create_conversation', {
        user1_id: inviterId,
        user2_id: profileData.id,
      });
    }

    // Always create connection with Lucy AI agent
    await supabase.from('connections').insert({
      initiator_id: 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600',
      receiver_id: profileData.id,
      status: 'accepted',
    });
    await supabase.rpc('get_or_create_conversation', {
      user1_id: 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600',
      user2_id: profileData.id,
    });

    // Insert selected sports if any
    if (userData.sports?.length) {
      await insertUserSports(confirmedUser.id, userData.sports);
    }

    return { user: confirmedUser };
  } catch (error) {
    console.error('❌ Error in signUpWithEmail:', error);
    throw error instanceof Error ? error : new Error('Unexpected error during signup');
  }
}

export async function signInWithEmail(email: string, password: string) {
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) {
    console.error('❌ Login error:', error);
    throw error;
  }
  const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
  if (sessionError || !sessionData.session) {
    console.error('❌ Failed to retrieve session after login:', sessionError);
    throw new Error('Session not established after login');
  }
  console.log('✅ Login successful, session active:', sessionData);
  localStorage.setItem('supabaseSession', JSON.stringify(sessionData.session));
  return sessionData;
}
