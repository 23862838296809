import React, { useState, useCallback } from 'react';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils'; 
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface SportsGridProps {
  sports: string[];
  onSportClick: (sport: string) => void;
  className?: string;
}

const SportsGrid: React.FC<SportsGridProps> = ({
  sports,
  onSportClick,
  className
}) => {
  const { isDarkMode } = useTheme();
  const [showAll, setShowAll] = useState(false);
  
  const visibleSports = showAll ? sports : sports.slice(0, 4);
  const hasMoreSports = sports.length > 4;
  
  return (
    <div className={cn(
      "flex flex-wrap gap-2",
      "justify-center lg:justify-start",
      className
    )}>
      {visibleSports.map((sport, idx) => (
        <div
          key={`${sport}-${idx}`}
          className={cn(
            "flex items-center space-x-2 px-4 py-2.5 rounded-full cursor-pointer",
            "transition-all duration-200 cursor-default group",
            "transform hover:scale-105",
            "shadow-md hover:shadow-lg",
            isDarkMode 
              ? "bg-dark-300/90 hover:bg-dark-400 border border-dark-400/50" 
              : "bg-gray-100 hover:bg-gray-200 border border-gray-200/50",
            "text-sm"
          )}
          onClick={() => onSportClick(sport)}
          role="button"
          tabIndex={0}
        >
          <span className={cn(
            "text-base font-medium transition-colors",
            isDarkMode 
              ? "text-dark-800 group-hover:text-white" 
              : "text-gray-600 group-hover:text-gray-900"
          )}>
            {sport}
          </span>
        </div>
      ))}
      {hasMoreSports && !showAll && (
        <button
          onClick={() => setShowAll(true)}
          className={cn(
            "flex items-center space-x-2 px-3 py-1.5 rounded-full",
            "transition-all duration-200",
            "transform hover:scale-105",
            "shadow-md hover:shadow-lg",
            isDarkMode 
              ? "bg-dark-300/90 hover:bg-dark-400 border border-dark-400/50" 
              : "bg-gray-100 hover:bg-gray-200 border border-gray-200/50",
            "text-sm font-medium",
            isDarkMode
              ? "text-gold-400 hover:text-gold-300"
              : "text-gold-600 hover:text-gold-500"
          )}
        >
          <Plus className="h-4 w-4" />
          <span>{sports.length - 4} more</span>
        </button>
      )}
    </div>
  );
};

export default SportsGrid;