import React from 'react';
import { MapPin, Users, Shield } from 'lucide-react';
import { cn } from '@/lib/utils';

interface CitySkeletonProps {
  count?: number;
  className?: string;
}

const CitySkeleton: React.FC<CitySkeletonProps> = ({
  count = 3,
  className
}) => {
  return (
    <div className={cn("space-y-6", className)}>
      {Array.from({ length: count }).map((_, index) => (
        <div
          key={index}
          className="relative w-full h-64 rounded-2xl overflow-hidden group animate-pulse"
        >
          {/* Background */}
          <div className="w-full h-full bg-dark-300" />
          
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-transparent" />
          
          {/* Content */}
          <div className="absolute bottom-0 left-0 right-0 p-6">
            <div className="flex items-center space-x-2 mb-2">
              <MapPin className="h-5 w-5 text-gold-400" />
              <div className="h-6 w-32 bg-dark-400 rounded" />
            </div>
            
            <div className="flex items-center space-x-3">
              <div className="flex items-center px-3 py-1 bg-black/60 backdrop-blur-sm rounded-full">
                <Users className="h-4 w-4 text-gold-400 mr-2" />
                <div className="h-4 w-24 bg-dark-400 rounded" />
              </div>
              <div className="flex items-center px-3 py-1 bg-black/60 backdrop-blur-sm rounded-full">
                <Shield className="h-4 w-4 text-gold-400 mr-2" />
                <div className="h-4 w-20 bg-dark-400 rounded" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CitySkeleton;