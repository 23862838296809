import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Check, X, Mail, Loader2, Shield, Users, Star, Info, Heart } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useInviteStore } from '../store/inviteStore';
import { useTheme } from '../contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface InviteInput {
  name: string;
  email: string;
}

export const InviteModal: React.FC<InviteModalProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const { isDarkMode } = useTheme();
  const { remainingInvites, isLoading, error, sendInvite, getRemainingInvites } = useInviteStore();
  const [invites, setInvites] = useState<InviteInput[]>([{ name: '', email: '' }]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch remaining invites when modal opens
  useEffect(() => {
    if (isOpen) {
      getRemainingInvites();
    }
  }, [isOpen, getRemainingInvites]);

  const handleAddInvite = () => {
    if (remainingInvites !== null && invites.length < remainingInvites) {
      setInvites([...invites, { name: '', email: '' }]);
    }
  };

  const handleRemoveInvite = (index: number) => {
    setInvites(invites.filter((_, i) => i !== index));
  };

  const handleInviteChange = (index: number, field: keyof InviteInput, value: string) => {
    const newInvites = [...invites];
    newInvites[index][field] = field === 'email' ? value.toLowerCase() : value;
    setInvites(newInvites);
  };

  const handleSubmit = async () => {
    try {
      // Validate input
      const validInvites = invites.filter(invite => 
        invite.name.trim() && invite.email.trim() && invite.email.includes('@')
      );

      if (validInvites.length === 0) {
        throw new Error('Please provide at least one valid invite');
      }

      await sendInvite(validInvites.map(invite => ({
        name: invite.name,
        email: invite.email,
        inviteMethod: 'email'
      })));

      setSuccessMessage(`Successfully sent ${validInvites.length} invitation${validInvites.length === 1 ? '' : 's'}`);
      setShowSuccess(true);
      
      // Reset form after delay
      setTimeout(() => {
        setInvites([{ name: '', email: '' }]);
        setShowSuccess(false);
        onClose();
      }, 2000);
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to send invites';
      console.error('Invite error:', message);
      return;
    }
  };

  if (showSuccess) {
    return (
      <Dialog open={true} onOpenChange={onClose}>
        <DialogContent className={cn(
          "sm:max-w-[425px] border",
          isDarkMode 
            ? "bg-dark-200 text-white border-dark-300" 
            : "bg-white text-gray-900 border-gray-200"
        )}>
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-green-400/20 p-3 rounded-full">
                <Check className="h-6 w-6 text-green-400" />
              </div>
            </div>
            <DialogTitle className="text-center">Invitations Sent!</DialogTitle>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div className="text-center text-dark-800">
              {successMessage}
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <Button
              onClick={onClose}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  if (remainingInvites === 0) {
    return (
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className={cn(
          "sm:max-w-[425px] border",
          isDarkMode 
            ? "bg-dark-200 text-white border-dark-300" 
            : "bg-white text-gray-900 border-gray-200"
        )}>
          <DialogHeader>
            <div className="flex justify-center mb-4">
              <div className="bg-dark-300 p-3 rounded-full">
                <X className="h-6 w-6 text-gold-400" />
              </div>
            </div>
            <DialogTitle className="text-center">No Invites Available</DialogTitle>
          </DialogHeader>
          <div className="flex justify-end space-x-3">
            <Button onClick={onClose} className="bg-gold-400 hover:bg-gold-500 text-dark-50">
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className={cn(
        "w-[calc(100%-2rem)] max-w-[500px] border backdrop-blur-sm p-4 sm:p-6",
        isDarkMode 
          ? "bg-dark-200/95 text-white border-dark-300/50" 
          : "bg-white/95 text-gray-900 border-gray-200/50"
      )}>
        <DialogHeader>
          <div className="flex justify-center mb-4 sm:mb-6">
            <div className={cn(
              "p-3 sm:p-4 rounded-full shadow-xl",
              isDarkMode
                ? "bg-gradient-to-br from-gold-400/20 to-gold-400/10 border border-gold-400/30"
                : "bg-gradient-to-br from-gold-600/10 to-gold-600/5 border border-gold-600/20"
            )}>
              <Shield className="h-6 w-6 sm:h-8 sm:w-8 text-gold-400" />
            </div>
          </div>
          <DialogTitle className={cn(
            "text-xl sm:text-2xl font-bold text-center bg-clip-text text-transparent",
            isDarkMode
              ? "bg-gradient-to-r from-white via-gold-200 to-gold-400"
              : "bg-gradient-to-r from-gray-900 via-gold-600 to-gold-800"
          )}>
            Exclusive Member Invitations
          </DialogTitle>
        </DialogHeader>

        {/* Community Values */}
        <div className="grid grid-cols-3 gap-2 mb-4">
          {[
            { icon: Users, title: 'Trust' },
            { icon: Star, title: 'Excellence' },
            { icon: Heart, title: 'Authentic' }
          ].map(({ icon: Icon, title }) => (
            <div className={cn(
              "p-2 sm:p-3 rounded-lg border backdrop-blur-sm",
              isDarkMode
                ? "bg-dark-300/50 border-dark-400/30"
                : "bg-light-100/50 border-light-200/30"
            )}>
              <div className="flex items-center justify-center gap-2">
                <Icon className={cn(
                  "h-3.5 w-3.5 sm:h-4 sm:w-4",
                  isDarkMode ? "text-gold-400" : "text-gold-600"
                )} />
                <h3 className={cn(
                  "text-xs sm:text-sm font-medium",
                  isDarkMode ? "text-white" : "text-gray-900"
                )}>{title}</h3>
              </div>
            </div>
          ))}
        </div>

        <div className="space-y-4 py-4">
          <div className="text-dark-800 text-center">
            <span className={cn(
              "px-3 sm:px-4 py-1.5 sm:py-2 rounded-full text-xs sm:text-sm",
              isDarkMode
                ? "bg-dark-300/50 border border-dark-400/30 text-dark-800"
                : "bg-light-100 border border-light-300 text-gray-700"
            )}>
              {remainingInvites} Invitation{remainingInvites === 1 ? '' : 's'} Available
            </span>
          </div>

          {error && (
            <div className="bg-red-500/10 text-red-500 p-3 rounded-lg text-sm">
              {error}
            </div>
          )}

          {invites.map((invite, index) => (
            <div key={index} className="space-y-4">
              {index > 0 && (
                <div className="border-t border-dark-300/30 pt-4">
                  <button
                    type="button"
                    onClick={() => handleRemoveInvite(index)}
                    className="text-dark-800 hover:text-red-400 text-sm transition-colors"
                  >
                    Remove
                  </button>
                </div>
              )}
              <Input
                id={`name-${index}`}
                value={invite.name}
                onChange={(e) => handleInviteChange(index, 'name', e.target.value)}
                className={cn(
                  "w-full transition-colors",
                  isDarkMode
                    ? "bg-dark-300/50 border-dark-400/30 text-white placeholder-dark-800/50 focus:border-gold-400/50"
                    : "bg-light-100 border-light-300 text-gray-900 placeholder-gray-500 focus:border-gold-600/50"
                )}
                placeholder="John Smith"
                required
              />
              <div className="relative">
                <Mail className={cn(
                  "absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4",
                  isDarkMode ? "text-dark-800" : "text-gray-500"
                )} />
                <Input
                  id={`email-${index}`}
                  type="email"
                  value={invite.email}
                  onChange={(e) => handleInviteChange(index, 'email', e.target.value)}
                  className={cn(
                    "pl-10 transition-colors",
                    isDarkMode
                      ? "bg-dark-300/50 border-dark-400/30 text-white placeholder-dark-800/50 focus:border-gold-400/50"
                      : "bg-light-100 border-light-300 text-gray-900 placeholder-gray-500 focus:border-gold-600/50"
                  )}
                  placeholder="john@company.com"
                  required
                />
              </div>
            </div>
          ))}

          {/* Message moved above buttons */}
          <div className="flex items-center justify-center gap-2 mt-2 mb-4">
            <Info className={cn(
              "h-3.5 w-3.5 sm:h-4 sm:w-4",
              isDarkMode ? "text-dark-800" : "text-gray-600"
            )} />
            <p className={cn(
              "text-xs sm:text-sm text-center",
              isDarkMode ? "text-dark-800" : "text-gray-600"
            )}>
              Invite accomplished professionals who will enrich our community
            </p>
          </div>

          <div className="flex flex-col sm:flex-row gap-2 sm:gap-3">
            <Button onClick={onClose} disabled={isLoading} variant="outline">
              Cancel
            </Button>
            <Button 
              onClick={handleSubmit} 
              disabled={isLoading} 
              className={cn(
                isDarkMode
                  ? "bg-gold-400 hover:bg-gold-500 text-dark-50"
                  : "bg-gold-600 hover:bg-gold-700 text-white"
              )}
            >
              {isLoading ? <Loader2 className="h-4 w-4 mr-2 animate-spin" /> : 'Invite'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};