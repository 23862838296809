import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

const Privacy = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex-1 !bg-black !text-white">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center !text-white hover:text-gold-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className={cn(
            "text-4xl font-bold mb-2",
            "bg-gradient-to-r from-white via-gold-200 to-gold-400",
            "bg-clip-text text-transparent"
          )}>Privacy Policy</h1>
          <p className="!text-white/70 mb-8">Last Updated: March 25, 2025</p>

          <div className="space-y-12 !text-white/70 text-base leading-relaxed">
            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">1. Introduction</h2>
              <p>
                At playclub.ai, your privacy and data security are our top priorities. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you access or use our platform and services. By using playclub.ai, you agree to the practices described here.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">2. Information We Collect</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong className="!text-white">Personal Information:</strong> Name, email address, and profession.</li>
                <li><strong className="!text-white">Sports Preferences & Skill Levels:</strong> Used to help match users with compatible partners and clubs.</li>
                <li><strong className="!text-white">Location Data:</strong> Only used for location-based features like facility discovery or travel suggestions.</li>
                <li><strong className="!text-white">Usage & Activity Data:</strong> Information on how you use the app, features you engage with, and interactions within the community.</li>
                <li><strong className="!text-white">Communication Data:</strong> Messages, feedback, and chat interactions (including with AI assistants like Lucy).</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">3. How We Use Your Information</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong className="!text-white">Personalizing Your Experience:</strong> Tailoring content, suggestions, and partner matching based on your profile and behavior.</li>
                <li><strong className="!text-white">Matchmaking:</strong> Connecting you with like-minded members, trainers, and clubs.</li>
                <li><strong className="!text-white">Service Delivery:</strong> Enabling bookings, memberships, facility access, and in-app communication.</li>
                <li><strong className="!text-white">Continuous Improvement:</strong> Analyzing usage trends to improve our features and user experience.</li>
                <li><strong className="!text-white">Communication:</strong> Sending transactional emails, updates, and (with consent) occasional promotions.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">4. Legal Basis for Processing (GDPR)</h2>
              <p>
                For users in the European Union, our legal basis for collecting and processing personal data includes:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Performance of a contract (e.g. membership management)</li>
                <li>Consent (e.g. marketing communication)</li>
                <li>Legitimate interest (e.g. improving the service or fraud prevention)</li>
              </ul>
              <p className="mt-2">You have the right to withdraw consent at any time.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">5. Data Protection</h2>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong className="!text-white">Encryption:</strong> All sensitive data is encrypted in transit and at rest.</li>
                <li><strong className="!text-white">Access Controls:</strong> Only authorized personnel can access user data, with role-based access control.</li>
                <li><strong className="!text-white">Regular Audits:</strong> We perform internal reviews and audits to maintain high security standards.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">6. Retention Policy</h2>
              <p>We retain your data only for as long as necessary to fulfill the purposes outlined in this policy or to comply with legal obligations. You may request account deletion at any time.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">7. Data Sharing and Third Parties</h2>
              <p>
                We do <strong className="!text-white">not sell</strong> your personal data. We may share limited information with trusted third parties such as:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Analytics providers (e.g. Google Analytics)</li>
                <li>Payment processors</li>
                <li>Infrastructure and hosting providers</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">8. Your Rights and Choices</h2>
              <p className="mb-2">You have full control over your data, including the right to:</p>
              <ul className="list-disc pl-6 space-y-2">
                <li><strong className="!text-white">Access:</strong> Request a copy of your personal data.</li>
                <li><strong className="!text-white">Rectification:</strong> Request corrections to inaccurate data.</li>
                <li><strong className="!text-white">Erasure:</strong> Request deletion of your data.</li>
                <li><strong className="!text-white">Data Portability:</strong> Receive your data in a machine-readable format.</li>
                <li><strong className="!text-white">Opt-Out:</strong> Manage your communication preferences.</li>
                <li><strong className="!text-white">Submit Requests:</strong> Email us at <a href="mailto:info@playclub.ai" className="!text-gold-400 hover:!text-gold-500">info@playclub.ai</a> or use the in-app feedback option.</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">9. Cookies and Tracking Technologies</h2>
              <p>We use cookies to:</p>
              <ul className="list-disc pl-6 mt-2 space-y-1">
                <li>Enhance your experience</li>
                <li>Analyze how our platform is used</li>
                <li>Serve relevant content</li>
              </ul>
              <p className="mt-2">You may adjust cookie preferences in your browser settings.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">10. International Data Transfers</h2>
              <p>
                Your data may be stored or processed in countries outside of your own. We ensure adequate safeguards are in place in compliance with GDPR.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">11. Children’s Privacy</h2>
              <p>
                Playclub is not intended for users under 16 years of age. We do not knowingly collect data from minors. If you believe a child has provided us data, please contact us for immediate removal.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">12. Data Breach Procedures</h2>
              <p>
                In the event of a data breach, we will notify affected users and regulatory authorities within 72 hours in compliance with GDPR obligations.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">13. Changes to This Privacy Policy</h2>
              <p>
                We may update this policy. Significant changes will be communicated via in-app alerts or email. Continued use of the service after changes implies acceptance.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">14. Contact Us</h2>
              <p>
                For any questions, reach out to us at: <a href="mailto:info@playclub.ai" className="!text-gold-400 hover:!text-gold-500">info@playclub.ai</a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
