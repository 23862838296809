import React from 'react';
import { VenueApiResult } from '../../../../types/venue';
import VenueCard from '../VenueCard';

interface VenueListItemProps {
  venue: VenueApiResult;
  distance?: number;
  isLast: boolean;
  lastElementRef: (node: HTMLElement | null) => void;
}

const VenueListItem: React.FC<VenueListItemProps> = ({
  venue,
  distance,
  isLast,
  lastElementRef
}) => {
  return (
    <div 
      ref={isLast ? lastElementRef : null}
      className="mb-4"
    >
      <VenueCard 
        venue={venue}
        distance={distance}
      />
    </div>
  );
};

export default VenueListItem;