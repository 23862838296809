import React, { useState } from 'react';
import { UserPlus, UserMinus, Clock, Check } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { useConnection } from '../../hooks/useConnection';
import { useConnectionStore } from '../../store/connectionStore';

interface ConnectionButtonProps {
  isConnected: boolean;
  memberId: string;
  memberName: string;
  memberImage: string;
  className?: string;
}

const ConnectionButton: React.FC<ConnectionButtonProps> = ({
  isConnected,
  memberId,
  memberName,
  memberImage,
  className,
}) => {
  const { isDarkMode } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);
  const [showWithdrawConfirm, setShowWithdrawConfirm] = useState(false);
  const {
    handleSendRequest,
    handleAcceptRequest,
    handleDeclineRequest,
    handleCancelRequest,
  } = useConnection();
  const { connections, fetchConnections } = useConnectionStore();

  // Get connection status
  const connection = React.useMemo(
    () => connections.find((conn) => conn.other_user?.auth_id === memberId),
    [connections, memberId]
  );

  const connectionStatus = connection?.status;
  const isPendingRequest = connectionStatus === 'pending';
  const isConnectedStatus = connectionStatus === 'accepted';

  const handleClick = async () => {
    console.log('Member connection button clicked:', {
      isConnected: isConnectedStatus,
      memberId,
      memberName,
      isPending: isPendingRequest,
      isLoading,
    });

    if (isConnectedStatus) {
      setShowDisconnectConfirm(true);
      return;
    }

    if (isPendingRequest) {
      setShowWithdrawConfirm(true);
      return;
    }

    setIsLoading(true);
    try {
      console.log('Initiating member connection request...');
      if (!memberId) {
        throw new Error('Member ID is required');
      }
      if (!memberName) {
        throw new Error('Member name is required');
      }

      const success = await handleSendRequest(memberId, memberName);
      if (success) {
        console.log('Connection request sent successfully.');
        // Refresh connections to get updated state
        await fetchConnections();
      }
    } catch (error) {
      console.error('Connection request failed:', error);
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Failed to send connection request';
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnect = async () => {
    setIsLoading(true);
    try {
      if (!connection?.connection_id) {
        throw new Error('Connection ID not found');
      }

      const success = await handleDeclineRequest(connection.connection_id);
      if (success) {
        console.log('Member disconnected successfully');
        await fetchConnections(); // Refresh connections list
      } else {
        throw new Error('Failed to disconnect');
      }
    } catch (error) {
      console.error('Failed to disconnect:', error);
    } finally {
      setIsLoading(false);
      setShowDisconnectConfirm(false);
    }
  };

  const handleWithdraw = async () => {
    setIsLoading(true);
    try {
      if (!connection?.connection_id) {
        throw new Error('Connection ID not found');
      }

      const success = await handleCancelRequest(connection.connection_id);
      if (success) {
        console.log('Connection request withdrawn successfully');
        await fetchConnections(); // Refresh connections list
      } else {
        throw new Error('Failed to withdraw request');
      }
    } catch (error) {
      console.error('Failed to withdraw request:', error);
    } finally {
      setIsLoading(false);
      setShowWithdrawConfirm(false);
    }
  };

  if (showWithdrawConfirm) {
    return (
      <div className="flex items-center gap-2">
        <button
          onClick={handleWithdraw}
          disabled={isLoading}
          className={cn(
            'flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            'bg-red-500 hover:bg-red-600 text-white',
            className
          )}
        >
          {isLoading ? (
            <span>Withdrawing...</span>
          ) : (
            <>
              <UserMinus className="h-4 w-4" />
              <span>Withdraw Request</span>
            </>
          )}
        </button>
        <button
          onClick={() => setShowWithdrawConfirm(false)}
          className={cn(
            'px-4 py-2 rounded-lg',
            'bg-dark-300 hover:bg-dark-400 text-white'
          )}
        >
          Cancel
        </button>
      </div>
    );
  }

  if (showDisconnectConfirm) {
    return (
      <div className="flex items-center gap-2">
        <button
          onClick={handleDisconnect}
          disabled={isLoading}
          className={cn(
            'flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors',
            'bg-red-500 hover:bg-red-600 text-white',
            className
          )}
        >
          {isLoading ? (
            <span>Disconnecting...</span>
          ) : (
            <>
              <UserMinus className="h-4 w-4" />
              <span>Disconnect</span>
            </>
          )}
        </button>
        <button
          onClick={() => setShowDisconnectConfirm(false)}
          className={cn(
            'px-4 py-2 rounded-lg',
            'bg-dark-300 hover:bg-dark-400 text-white'
          )}
        >
          Cancel
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={handleClick}
      disabled={isLoading}
      className={cn(
        'flex items-center justify-center space-x-2 px-4 py-2 rounded-lg transition-colors',
        isConnectedStatus
          ? 'bg-green-500 text-dark-50'
          : isPendingRequest
          ? 'bg-yellow-400 text-dark-50'
          : 'bg-gold-400 hover:bg-gold-500 text-dark-50',
        className
      )}
    >
      {isLoading ? (
        <span>Connecting...</span>
      ) : isPendingRequest ? (
        <>
          <Clock className="h-4 w-4" />
          <span>Pending</span>
        </>
      ) : isConnectedStatus ? (
        <>
          <Check className="h-4 w-4" />
          <span>Connected</span>
        </>
      ) : (
        <>
          <UserPlus className="h-4 w-4" />
          <span>Connect</span>
        </>
      )}
    </button>
  );
};

export default ConnectionButton;
