import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Star } from 'lucide-react';
// Removed: import { cityLandmarks } from '../../data/cities';
import { useTheme } from '@/contexts/ThemeContext';
import { useAuth } from '@/contexts/AuthContext';
import CityLink from '../ui/CityLink';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';
import { Player } from '../../types/matchmaking';
import PlayerAvatar from './card/PlayerAvatar';
import PlayerInfo from './card/PlayerInfo';
import SportsGrid from './card/SportsGrid';
import ActionButtons from './card/ActionButtons';
import RequestMatchModal from './RequestMatchModal';
import MutualConnectionsModal from '../members/modals/MutualConnectionsModal';
import SocialLinks from '@/components/social/SocialLinks';
import { fetchCityImageFromMultipleSources } from '@/lib/fetchCityImage'; // ✅ NEW

interface PlayerCardProps {
  player: Player;
  sports: string[];
  dates: string[];
  timeSlots: string[];
  distance: number;
  onMatchSelect: (match: any) => void;
}

const PlayerCard: React.FC<PlayerCardProps> = ({
  player,
  sports,
  dates,
  timeSlots,
  distance,
  onMatchSelect,
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const { user } = useAuth();
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showMutuals, setShowMutuals] = useState(false);
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const city = player.location.split(',')[0].trim();

  const [cityImage, setCityImage] = useState<string | null>(null); // ✅ STATE

  // ✅ Fetch dynamic image
  useEffect(() => {
    const loadCityImage = async () => {
      const url = await fetchCityImageFromMultipleSources(city);
      setCityImage(url);
    };
    loadCityImage();
  }, [city]);

  const handleProfileClick = () => {
    navigate(`/dashboard/members/${player.id}`);
  };

  const handleDisconnect = async () => {
    await new Promise(resolve => setTimeout(resolve, 1500));
  };

  const handleMessageClick = async () => {
    try {
      if (!user) throw new Error('Not authenticated');

      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');

      const { data: memberData, error: memberError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', player.id)
        .single();

      if (memberError) throw memberError;
      if (!memberData) throw new Error('Member not found');

      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: memberData.id
        }
      );

      if (conversationError) throw conversationError;

      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: `Hi ${player.name}!`,
          showMobileList: false
        }
      });
    } catch (error) {
      console.error('Error handling message click:', error);
    }
  };

  const handlePlayClick = () => {
    setShowMatchModal(true);
  };

  const handleSportClick = (sport: string) => {
    setSelectedSport(sport);
    setShowMatchModal(true);
  };

  return (
    <>
      <div className={cn(
        "relative rounded-2xl transition-all duration-300 overflow-hidden",
        "hover:transform hover:scale-[1.01]",
        "max-w-5xl mx-auto",
        isDarkMode
          ? "bg-dark-200 border-dark-400 hover:border-gold-400"
          : "bg-white border-gray-200 hover:border-gold-600",
        "border shadow-lg hover:shadow-xl group"
      )}>
        <div className="relative h-72 lg:h-80">
          <img
            src={cityImage || 'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=format&fit=crop&w=1600&q=80'} // ✅ Fallback
            alt={player.location}
            className="w-full h-full object-cover brightness-75"
          />
          <div className={cn(
            "absolute inset-0 bg-gradient-to-b",
            isDarkMode 
              ? "from-dark-100/10 via-dark-200/55 to-dark-200" 
              : "from-dark-200/100 via-black/30 to-white"
          )} />
          
          <div className="absolute bottom-0 left-0 right-0 px-6 pb-6 pt-20">
            <div className="flex flex-col items-center max-w-4xl mx-auto">
              <button
                onClick={handleProfileClick}
                className={cn(
                  "text-3xl sm:text-4xl lg:text-5xl font-bold mb-6",
                  "bg-gradient-to-r from-white via-gold-200 to-gold-400",
                  "bg-clip-text text-transparent drop-shadow-lg",
                  "font-display tracking-tight",
                  "hover:scale-[1.02] transition-transform duration-200",
                  "focus:outline-none focus:ring-2 focus:ring-gold-400 focus:ring-offset-2 focus:ring-offset-dark-200 rounded-lg"
                )}
              >
                {player.name}
              </button>
              <PlayerAvatar
                image={player.image}
                name={player.name}
                rating={player.rating}
                onClick={handleProfileClick}
              />
            </div>
          </div>
        </div>

        <div className="px-6 pb-8">
          <div className="flex flex-col items-center max-w-4xl mx-auto">
            <div className="flex-1 min-w-0 w-full">
              <PlayerInfo
                location={player.location}
                distance={player.distance}
                company={player.company}
                matchPercentage={player.matchPercentage}
                mutualConnections={player.mutualConnections}
                onShowMutuals={() => setShowMutuals(true)}
                linkedinUrl={player.linkedinUrl}
                facebookUrl={player.facebookUrl}
                instagramUrl={player.instagramUrl}
                invitedBy={player.invitedBy}
                skillLevel={player.skillLevel}
              />

              <SportsGrid 
                sports={player.sports}
                onSportClick={handleSportClick}
                className="mt-8"
              />

              <ActionButtons
                memberId={player.id}
                memberName={player.name}
                memberImage={player.image}
                skillLevel={player.skillLevel}
                onMessage={handleMessageClick}
                onPlay={handlePlayClick}
                className="mt-8"
              />
            </div>
          </div>
        </div>
      </div>

      {showMatchModal && (
        <RequestMatchModal
          isOpen={showMatchModal}
          member={{
            id: player.id,
            name: player.name,
            city: player.location,
            sports: player.sports
          }}
          onClose={() => {
            setShowMatchModal(false);
            setSelectedSport(null);
          }}
          preSelectedSport={selectedSport}
          dates={[new Date().toISOString().split('T')[0]]}
          timeSlots={['morning', 'afternoon', 'evening']}
          distance={player.distance}
          onMatchSelect={() => onMatchSelect(player)}
        />
      )}

      {showMutuals && (
        <MutualConnectionsModal
          isOpen={true}
          onClose={() => setShowMutuals(false)}
          connectionName={player.name}
          mutualCount={player.mutualConnections || 0}
        />
      )}
    </>
  );
};

export default PlayerCard;