import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ThemeAwareLogo } from './brand/ThemeAwareLogo';

type HeaderProps = {
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
};

export const Header: React.FC<HeaderProps> = ({
  isMenuOpen,
  setIsMenuOpen
}) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  // Track scroll position to show/hide the header dynamically
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setShowHeader(false); // Hide when scrolling down
      } else {
        setShowHeader(true); // Show when scrolling up
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  return (
    <header
      className={`fixed left-0 right-0 z-50 transition-transform duration-300 ${
        isLandingPage ? 'bg-dark-200/50 backdrop-blur-sm' : 'bg-opacity-80 backdrop-blur-md bg-app border-b border-dark-300'
      }`}
      style={{
        top: "0", // Ensures the navbar starts from the top
        height: "calc(env(safe-area-inset-top, 20px) + 56px)", // Extends into the status bar
        paddingTop: "env(safe-area-inset-top, 20px)", // Pushes navbar content below status bar
        transition: "top 0.3s ease-in-out"
      }}
    >
      <div className="max-w-[1400px] mx-auto px-2 sm:px-3">
        <div className="h-16 flex items-center justify-between">
          {/* Logo section */}
          <Link to="/" className="flex-shrink-0 flex items-center space-x-2">
            <ThemeAwareLogo height={36} colorScheme="white" platform="web" />
          </Link>
        </div>
      </div>
    </header>
  );
};
