import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { OnboardingStepProps } from '../types';
import { supabase } from '@/lib/supabase';
import { ChevronLeft, ChevronRight, Search, X } from 'lucide-react';
import { Input } from '@/components/ui/input';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { cn } from '@/lib/utils';

interface Sport {
  id: string;
  name: string;
  emoji: string;
  category: string;
}

interface CategoryTabProps {
  category: string;
  isActive: boolean;
  onClick: () => void;
  emoji: string;
}

const CategoryTab: React.FC<CategoryTabProps> = ({
  category,
  isActive,
  onClick,
  emoji
}) => (
  <button
    onClick={onClick}
    className={cn(
      'flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-300',
      'whitespace-nowrap text-sm font-medium',
      isActive
        ? 'bg-gold-400 text-dark-50'
        : 'bg-dark-300/30 text-white hover:bg-dark-400/50'
    )}
  >
    <span className="text-lg">{emoji}</span>
    <span>{category}</span>
  </button>
);

const SportsSelectionStep: React.FC<OnboardingStepProps> = ({
  data,
  onUpdate,
  onNext,
  onBack,
}) => {
  const selectedSports = data?.sports || [];
  const [sports, setSports] = useState<Sport[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [categoryEmojis, setCategoryEmojis] = useState<Record<string, string>>({});
  const [groupedSports, setGroupedSports] = useState<Record<string, Sport[]>>({});
  const [activeCategory, setActiveCategory] = useState<string>('');
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isSearching, setIsSearching] = useState(false);

  // Handle tab scrolling
  const handleScroll = () => {
    if (!tabsRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 5);
  };

  useEffect(() => {
    const tabsElement = tabsRef.current;
    if (tabsElement) {
      tabsElement.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => tabsElement.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scrollTabs = (direction: 'left' | 'right') => {
    if (!tabsRef.current) return;
    const scrollAmount = direction === 'left' ? -200 : 200;
    tabsRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

useEffect(() => {
  async function fetchData() {
    try {
      // Fetch sports with categories, category order, and category emoji
      const { data: fetchedSports, error } = await supabase
        .from('sports')
        .select('id, name, emoji, category, category_order, category_emoji')
        .order('category_order', { ascending: true }) // Order categories
        .order('name', { ascending: true }); // Order sports alphabetically

      if (error) throw error;
      setSports(fetchedSports);

      // Get unique categories and their defined emojis
      const categoryMap = new Map<string, string>();
      const uniqueCategories = new Set<string>();

      fetchedSports.forEach((sport) => {
        if (sport.category) {
          uniqueCategories.add(sport.category);
          categoryMap.set(sport.category, sport.category_emoji || '🏅'); // Default emoji if missing
        }
      });

      const sortedCategories = Array.from(uniqueCategories);
      setCategories(sortedCategories);
      setCategoryEmojis(Object.fromEntries(categoryMap));
      setActiveCategory(sortedCategories[0] || '');

      // Group sports by category
      const groups = fetchedSports.reduce((acc, sport) => {
        const cat = sport.category || 'Others';
        if (!acc[cat]) acc[cat] = [];
        acc[cat].push(sport);
        return acc;
      }, {} as Record<string, Sport[]>);
      
      setGroupedSports(groups);
    } catch (error) {
      console.error('Error fetching sports:', error);
    }
  }
  fetchData();
}, []);


  const toggleSport = (sportId: string) => {
    const newSelection = selectedSports.includes(sportId)
      ? selectedSports.filter((id) => id !== sportId)
      : [...selectedSports, sportId];
    onUpdate?.({ sports: newSelection });
  };

  // Filter sports based on search across all categories or show category sports
  const displayedSports = React.useMemo(() => {
    const searchLower = searchTerm.toLowerCase();

    // If searching, show results from all categories
    if (searchTerm) {
      return sports.filter(
        (sport) =>
          sport.name.toLowerCase().includes(searchLower) ||
          sport.emoji.includes(searchTerm)
      );
    }

    // Otherwise show sports from active category
    return groupedSports[activeCategory] || [];
  }, [sports, groupedSports, activeCategory, searchTerm]);

  // Handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setIsSearching(!!value);
  };

  return (
    // 1) Full-height container with overflow hidden
    <div className="flex flex-col h-full bg-dark-200 overflow-hidden">
      {/* 2) Sticky Header */}
      <header className="flex-shrink-0 sticky top-0 z-10 bg-dark-200 pt-6 pb-4 border-b border-dark-300">
        <div className="text-center mb-6 px-4">
          <motion.h2
            className="text-2xl font-semibold text-gold-400 mb-2"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            What do you play?
          </motion.h2>
          <p className="text-dark-800 text-sm">
            {selectedSports.length}{' '}
            {selectedSports.length === 1 ? 'sport' : 'sports'} selected
          </p>
        </div>

        {/* Search Input */}
        <div className="px-4 mb-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-dark-800" />
            <Input
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search sports..."
              className="pl-9 bg-dark-300 border-dark-400 text-white"
            />
            {searchTerm && (
              <button
                onClick={() => {
                  setSearchTerm('');
                  setIsSearching(false);
                }}
                className="absolute right-3 top-1/2 -translate-y-1/2 p-1 rounded-full hover:bg-dark-400 text-dark-800 hover:text-white transition-colors"
              >
                <X className="h-4 w-4" />
              </button>
            )}
          </div>
        </div>

        {/* Categories */}
        <div className="relative px-4 max-w-[1200px] mx-auto">
          {canScrollLeft && (
            <button
              onClick={() => scrollTabs('left')}
              className="absolute left-0 top-1/2 -translate-y-1/2 z-10 p-1 bg-dark-300 rounded-full"
            >
              <ChevronLeft className="w-5 h-5 text-white" />
            </button>
          )}

          <div
            ref={tabsRef}
            className="flex gap-2 overflow-x-auto scrollbar-none scroll-smooth"
          >
            {categories.map((category) => (
              <CategoryTab
                key={category}
                category={category}
                isActive={activeCategory === category}
                onClick={() => setActiveCategory(category)}
                emoji={categoryEmojis[category] || ''}
              />
            ))}
          </div>

          {canScrollRight && (
            <button
              onClick={() => scrollTabs('right')}
              className="absolute right-0 top-1/2 -translate-y-1/2 z-10 p-1 bg-dark-300 rounded-full"
            >
              <ChevronRight className="w-5 h-5 text-white" />
            </button>
          )}
        </div>
      </header>

      {/* 3) Scrollable Sports List in the remaining space */}
      <main className="flex-1 overflow-y-auto px-4 max-w-[1200px] mx-auto pb-20">
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-3 py-4 content-start"
          >
            {displayedSports.length === 0 ? (
              <div className="col-span-3 text-center py-8 text-dark-800">
                No sports found matching &quot;{searchTerm}&quot;
              </div>
            ) : (
              displayedSports.map((sport) => (
                <motion.button
                  key={sport.id}
                  onClick={() => toggleSport(sport.id)}
                  className={cn(
                    'relative group p-3 rounded-lg transition-all duration-300',
                    selectedSports.includes(sport.id)
                      ? 'bg-gold-400/20 text-gold-400'
                      : 'bg-dark-300/30 text-white hover:bg-dark-400/50'
                  )}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <div className="flex flex-col items-center space-y-2">
                    <motion.span
                      className="text-2xl block"
                      whileHover={{ scale: 1.2 }}
                      transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                    >
                      {sport.emoji}
                    </motion.span>
                    <span className="text-sm font-medium text-center">
                      {sport.name}
                    </span>
                  </div>
                </motion.button>
              ))
            )}
          </motion.div>
        </AnimatePresence>
      </main>

      {/* 4) FloatingNavigation stays at the bottom (unchanged) */}
      <FloatingNavigation
        onBack={onBack}
        onNext={onNext}
        isNextDisabled={selectedSports.length === 0}
      />
    </div>
  );
};

export default SportsSelectionStep;
