import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Mail, MessageSquare } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '../components/ui/button';
import { cn } from '@/lib/utils';

const Contact = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@playclub.ai';
  };

  const handleAskLucy = async () => {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();
      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');

      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600'
        }
      );
      if (conversationError) throw conversationError;

      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: 'Hi Lucy!',
          showMobileList: false
        }
      });
    } catch (error) {
      console.error('Error starting chat with Lucy:', error);
    }
  };

  return (
    <div className="flex-1 !bg-black !text-white">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center !text-white hover:text-gold-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className={cn(
            "text-4xl font-bold mb-2",
            "bg-gradient-to-r from-white via-gold-200 to-gold-400",
            "bg-clip-text text-transparent"
          )}>Contact Us</h1>
          <p className="!text-white/70 mb-12">We're here to help with any questions or concerns.</p>

          <div className="grid gap-8 md:grid-cols-2">
            <div className="!bg-dark-200 !border-dark-300 rounded-2xl p-8 border hover:border-gold-400/30 transition-all duration-300">
              <div className="!bg-dark-300 p-4 rounded-xl w-fit mb-6">
                <Mail className="h-6 w-6 !text-gold-400" />
              </div>
              <h2 className="text-xl font-semibold !text-white mb-4">Email Us</h2>
              <p className="!text-white/70 mb-6">
                Send us an email anytime. We aim to respond within 24 hours during business days.
              </p>
              <Button
                onClick={handleEmailClick}
                className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50"
              >
                <Mail className="h-4 w-4 mr-2" />
                Email info@playclub.ai
              </Button>
            </div>

            <div className="!bg-dark-200 !border-dark-300 rounded-2xl p-8 border hover:border-gold-400/30 transition-all duration-300">
              <div className="!bg-dark-300 p-4 rounded-xl w-fit mb-6">
                <MessageSquare className="h-6 w-6 !text-gold-400" />
              </div>
              <h2 className="text-xl font-semibold !text-white mb-4">Ask Lucy (AI Assistant)</h2>
              <p className="!text-white/70 mb-6">
                Get instant help from Lucy, our AI assistant. Available 24/7 for members.
              </p>
              {isAuthenticated ? (
                <Button
                  onClick={handleAskLucy}
                  className="w-full bg-gold-400 hover:bg-gold-500 text-dark-50"
                >
                  <MessageSquare className="h-4 w-4 mr-2" />
                  Chat with Lucy
                </Button>
              ) : (
                <Button
                  onClick={() => navigate('/')}
                  className="w-full !bg-dark-300 hover:!bg-dark-400 !text-white"
                >
                  Sign in to Chat with Lucy
                </Button>
              )}
            </div>
          </div>

          <div className="mt-12 p-8 !bg-dark-200 !border-dark-300 rounded-2xl border">
            <h2 className="text-xl font-semibold !text-white mb-4">Support Hours</h2>
            <div className="grid gap-4 md:grid-cols-2">
              <div>
                <h3 className="!text-gold-400 font-medium mb-2">Email Support</h3>
                <p className="!text-white/70">
                  Monday - Friday<br />
                  9:00 AM - 6:00 PM CET
                </p>
              </div>
              <div>
                <h3 className="!text-gold-400 font-medium mb-2">Lucy AI Assistant</h3>
                <p className="!text-white/70">
                  Available 24/7<br />
                  Instant responses
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
