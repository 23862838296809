import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, MessageCircle, Clock, Users2, Briefcase } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { extractCityName } from '../../../../lib/utils/text';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';

const ConnectionsList = ({ connections, isLoading }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const defaultAvatar =
    'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  if (isLoading) return <p className="text-dark-800">Loading connections...</p>;

  if (!connections.length) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Connections Yet</h3>
        <p className="text-dark-800 max-w-sm mx-auto">
          This member hasn't made any connections yet.
        </p>
      </div>
    );
  }

  const handleStartChat = async (otherUserId: string, otherUserName: string) => {
    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError || !user) throw userError || new Error('Not authenticated');

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();

      const { data: memberData } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', otherUserId)
        .single();

      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: memberData.id,
        }
      );

      if (conversationError) throw conversationError;

      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: `Hi ${otherUserName}!`,
          showMobileList: false,
        },
      });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  return (
    <div className="space-y-4 max-w-3xl mx-auto">
      {connections.map((connection) => {
        const user = connection.other_user;
        return (
          <div
            key={connection.connection_id}
            className={cn(
              'bg-dark-200 rounded-2xl overflow-hidden border border-dark-300',
              'hover:border-gold-400 transition-all duration-300',
              'shadow-lg hover:shadow-xl transform hover:scale-[1.01]'
            )}
          >
            <div className="p-4 flex items-center space-x-4">
              <button
                onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                className="flex-shrink-0"
              >
                <img
                  src={user.avatar_url || defaultAvatar}
                  alt={user.full_name}
                  className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-all duration-300 shadow-xl"
                />
              </button>

              <div className="flex-1 min-w-0">
                <button
                  onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                  className="text-white font-medium hover:text-gold-400 transition-colors block truncate"
                >
                  {user.full_name}
                </button>

                <div className="flex items-center text-dark-800 text-sm mt-0.5">
                  <MapPin className="h-4 w-4 mr-1" />
                  {extractCityName(user.city)}
                </div>

                {(user.job_type || user.company) && (
                  <div className="flex items-center text-dark-700 text-sm mt-1">
                    <Briefcase className="h-4 w-4 mr-1.5 shrink-0" />
                    {user.job_type}
                    {user.job_type && user.company && ' at '}
                    {user.company}
                  </div>
                )}

                {user.sports?.length > 0 && (
                  <div className="flex flex-wrap gap-1.5 mt-2">
                    {user.sports.map((sport: any, idx: number) => (
                      <span
                        key={idx}
                        className="text-xs px-2.5 py-1 rounded-full bg-dark-400 text-white"
                      >
                        {sport.emoji} {sport.name}
                      </span>
                    ))}
                  </div>
                )}

                {connection.mutual_connections_count > 0 && (
                  <div className="text-sm mt-2 text-dark-700">
                    {connection.mutual_connections_count} mutual connection
                    {connection.mutual_connections_count !== 1 ? 's' : ''}
                  </div>
                )}

                <div className="text-xs text-dark-700 mt-2 flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  Connected since {new Date(connection.created_at).toLocaleDateString()}
                </div>
              </div>

              <div className="ml-auto">
                <button
                  onClick={() => handleStartChat(user.auth_id, user.full_name)}
                  className="bg-gold-400 text-black px-3 py-2 rounded-lg flex items-center gap-2 text-sm hover:bg-gold-500"
                >
                  <MessageCircle className="w-4 h-4" />
                  Message
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ConnectionsList;
