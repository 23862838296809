import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

// Function to update viewport height and safe area insets
function updateViewportHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.documentElement.style.setProperty(
    '--safe-bottom',
    `${window.visualViewport?.height ? window.visualViewport.height - window.innerHeight : 0}px`
  );
}

// Google Analytics measurement ID
const GA_MEASUREMENT_ID = 'G-E3V4GQTDSQ';

const Root = () => {
  useEffect(() => {
    // ✅ Show loading overlay during reload
    window.addEventListener("beforeunload", () => {
      document.body.classList.add("reloading");
    });

    // ✅ iOS WebView scroll fix
    const isIOSWebView = !!window.webkit?.messageHandlers;
    if (isIOSWebView) {
      document.body.style.minHeight = "100vh";
      document.body.style.overscrollBehaviorY = "auto";
      document.body.style.webkitOverflowScrolling = "touch";

      // ✅ Add invisible 1px bounce anchor
      const anchor = document.createElement('div');
      anchor.style.height = '1px';
      anchor.style.width = '100%';
      anchor.style.pointerEvents = 'none';
      anchor.style.flexShrink = '0';
      document.body.appendChild(anchor);
    }

    // Initial viewport height calculation
    updateViewportHeight();

    // Add event listeners for viewport updates
    window.addEventListener('resize', updateViewportHeight);
    window.addEventListener('orientationchange', () => {
      setTimeout(updateViewportHeight, 100);
    });

    // Inject Google Analytics script only if it hasn't been added
    if (!document.querySelector(`script[src*="gtag/js?id=${GA_MEASUREMENT_ID}"]`)) {
      const script = document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(...args: any[]) {
          window.dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', GA_MEASUREMENT_ID);
      };
    }

    return () => {
      window.removeEventListener('resize', updateViewportHeight);
      window.removeEventListener('orientationchange', updateViewportHeight);
    };
  }, []);

  return (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

// Mount the app
createRoot(rootElement).render(<Root />);

// ✅ Fade out and remove splash screen
const splash = document.getElementById('splash-screen');
if (splash) {
  splash.classList.add('fade-out');
  setTimeout(() => splash.remove(), 400);
}
