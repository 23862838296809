import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import PostComposer from './PostComposer';
import PostCard from './PostCard';
import { createPost, getPosts } from '../../lib/supabase/posts';
import { Post } from '../../types/post';
import PostSkeleton from './PostSkeleton';
import PostList from './PostList';
import InfiniteScroll from 'react-infinite-scroll-component';
import WelcomeMessage from '../dashboard/WelcomeMessage';
import { TrendingUp, Clock, Star, PenSquare } from 'lucide-react';
import { cn } from '@/lib/utils';
import { getPostLikesBatch } from '../../lib/supabase/social';

const SocialFeed: React.FC<{ focusedPost?: Post | null }> = ({ focusedPost }) => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState('recent');
  const [showComposer, setShowComposer] = useState(false);

  // Tracks the last post's ID for cursor-based pagination
  const lastPostIdRef = useRef<string | undefined>(undefined);

  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const postsRef = useRef<Post[]>([]);
  const isLoadingRef = useRef(false);
  const [isPosting, setIsPosting] = useState(false);
  const [postLikes, setPostLikes] = useState<Record<string, boolean>>({});

  // Listen for post deletion events
  useEffect(() => {
    const handlePostDeleted = (event: CustomEvent<{ postId: string }>) => {
      setPosts(prev => {
        const updatedPosts = prev.filter(post => post.id !== event.detail.postId);
        postsRef.current = updatedPosts;
        return updatedPosts;
      });
    };

    window.addEventListener('post-deleted', handlePostDeleted as EventListener);
    return () => {
      window.removeEventListener('post-deleted', handlePostDeleted as EventListener);
    };
  }, []);

  const loadPosts = useCallback(async () => {
    if (isLoadingRef.current) return;
    isLoadingRef.current = true;

    const isFirstLoad = !lastPostIdRef.current;
    if (isFirstLoad) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }
    setError(null);

    try {
      const { posts: fetchedPosts, hasMore: moreAvailable } = await getPosts(lastPostIdRef.current);

      // Batch fetch likes for new posts
      if (fetchedPosts.length > 0) {
        const postIds = fetchedPosts.map(p => p.id);
        const likes = await getPostLikesBatch(postIds);
        setPostLikes(prev => ({ ...prev, ...likes }));
      }

      if (fetchedPosts.length > 0) {
        setPosts(prev => {
          const existingIds = new Set(prev.map(p => p.id));
          const newUniquePosts = fetchedPosts.filter(p => !existingIds.has(p.id));
          const updatedPosts = isFirstLoad
            ? newUniquePosts
            : [...postsRef.current, ...newUniquePosts];

          postsRef.current = updatedPosts;
          if (updatedPosts.length > 0) {
            lastPostIdRef.current = updatedPosts[updatedPosts.length - 1].id;
          }
          return updatedPosts;
        });
      }
      setHasMore(moreAvailable);
    } catch (err) {
      console.error("Error fetching posts:", err);
      setError("Error fetching posts.");
    } finally {
      setIsInitialLoad(false);
      setIsLoading(false);
      setIsLoadingMore(false);
      isLoadingRef.current = false;
    }
  }, []);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  // Filter/sort posts using createdAt instead of timeAgo
  const filteredPosts = useMemo(() => {
    if (!posts.length) return [];
    const sortedPosts = [...posts];

    switch (activeFilter) {
      case 'trending':
        sortedPosts.sort(
          (a, b) => (b.likes + b.comments + b.shares) - (a.likes + a.comments + a.shares)
        );
        break;
      case 'recent':
        // Sort by createdAt descending (using a real timestamp)
        sortedPosts.sort(
          (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        break;
      case 'top':
        sortedPosts.sort((a, b) => b.likes - a.likes);
        break;
      default:
        break;
    }
    return sortedPosts;
  }, [posts, activeFilter]);

  const handleNewPost = async (
    content: string,
    media?: { type: 'image' | 'video'; file: File }
  ) => {
    try {
      setIsPosting(true);
      const newPost = await createPost(content, media);
      setPosts(prev => {
        const updatedPosts = [newPost, ...prev];
        postsRef.current = updatedPosts;
        return updatedPosts;
      });
      setShowComposer(false);
    } catch (error) {
      console.error('Error creating post:', error);
      setError(error instanceof Error ? error.message : 'Failed to create post');
    } finally {
      setIsPosting(false);
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="space-y-6 w-full">
      {focusedPost && (
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="mb-8">
          <PostCard {...focusedPost} />
          <div className="border-b border-dark-300 my-8" />
        </motion.div>
      )}

      <WelcomeMessage />

      <div className="w-full max-w-[1400px] mx-auto">
        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-dark-200 rounded-xl p-4 border border-dark-300 mb-6">
            <div className="flex items-center justify-between gap-4">
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setActiveFilter('trending')}
                  className={cn(
                    'flex items-center justify-center h-10 px-4',
                    'hover:scale-105 active:scale-95',
                    'rounded-lg transition-all duration-200',
                    activeFilter === 'trending'
                      ? 'bg-gold-400 text-dark-50'
                      : 'bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white'
                  )}
                >
                  <TrendingUp className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Trending</span>
                </button>
                <button
                  onClick={() => setActiveFilter('recent')}
                  className={cn(
                    'flex items-center justify-center h-10 px-4',
                    'hover:scale-105 active:scale-95',
                    'rounded-lg transition-all duration-200',
                    activeFilter === 'recent'
                      ? 'bg-gold-400 text-dark-50'
                      : 'bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white'
                  )}
                >
                  <Clock className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Recent</span>
                </button>
                <button
                  onClick={() => setActiveFilter('top')}
                  className={cn(
                    'flex items-center justify-center h-10 px-4',
                    'hover:scale-105 active:scale-95',
                    'rounded-lg transition-all duration-200',
                    activeFilter === 'top'
                      ? 'bg-gold-400 text-dark-50'
                      : 'bg-dark-300 text-dark-800 hover:bg-dark-400 hover:text-white'
                  )}
                >
                  <Star className="h-5 w-5" />
                  <span className="ml-2 hidden sm:inline">Top</span>
                </button>
              </div>
              <button
                onClick={() => setShowComposer(true)}
                className={cn(
                  'flex items-center justify-center h-10 w-10',
                  'bg-gold-400 hover:bg-gold-500 text-dark-50',
                  'rounded-lg transition-colors',
                  'hover:scale-105 active:scale-95'
                )}
              >
                <PenSquare className="h-5 w-5" />
              </button>
            </div>
          </div>
        </motion.div>

        {showComposer && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
            <PostComposer
              onPost={handleNewPost}
              onCancel={() => setShowComposer(false)}
              placeholder="What's on your mind?"
              isSubmitting={isPosting}
            />
          </motion.div>
        )}

        {error && (
          <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
            <p>{error}</p>
            <button onClick={loadPosts} className="text-sm underline mt-2 hover:text-red-400">
              Try Again
            </button>
          </div>
        )}

        {posts.length === 0 && isLoading ? (
          <PostSkeleton count={3} />
        ) : (
          <div className="relative min-h-[200px]">
            <InfiniteScroll
              dataLength={posts.length}
              next={loadPosts}
              hasMore={hasMore}
              loader={isLoadingMore && <PostSkeleton count={1} />}
              endMessage={
                <p className="text-center text-dark-800 py-4">
                  No more posts to show
                </p>
              }
              scrollThreshold={0.8}
              style={{ overflow: 'visible' }}
              className="space-y-6"
            >
              <PostList
                posts={filteredPosts}
                activeFilter={activeFilter}
                batchLikes={postLikes}
              />
            </InfiniteScroll>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default SocialFeed;