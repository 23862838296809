// src/pages/QuickPlayWrapper.tsx
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuickPlay from "./QuickPlay";

const QuickPlayWrapper = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isNavigated = location.state?.fromNav;

  // ✅ Force re-navigation on first load (to initialize layout properly)
  useEffect(() => {
    if (!isNavigated) {
      navigate("/dashboard/quick-play", {
        replace: true,
        state: { fromNav: true },
      });
    }
  }, [isNavigated, navigate]);

  if (!isNavigated) return null;

  return <QuickPlay />;
};

export default QuickPlayWrapper;
