import { City } from '../types/city';

export const cityLandmarks: Record<string, string> = {
  // Active cities
  'valencia': 'https://images.unsplash.com/photo-1575540203949-54ccd7a66d98?auto=format&fit=crop&w=1600&q=80',
  'asuncion': 'https://images.unsplash.com/photo-1732357284464-c9e438f2934e?auto=format&fit=crop&w=1600&q=80',
  'luxembourg': 'https://images.unsplash.com/photo-1592906209472-a36b1f3782ef?auto=format&fit=crop&w=1600&q=80',
  'mumbai': 'https://images.unsplash.com/photo-1529253355930-ddbe423a2ac7?auto=format&fit=crop&w=1600&q=80',
  'singapore': 'https://images.unsplash.com/photo-1525625293386-3f8f99389edd?auto=format&fit=crop&w=1600&q=80',
  'rome': 'https://images.unsplash.com/photo-1552832230-c0197dd311b5?auto=format&fit=crop&w=1600&q=80',
  'madrid': 'https://images.unsplash.com/photo-1543783207-ec64e4d95325?auto=format&fit=crop&w=1600&q=80',
  'washington dc': 'https://images.unsplash.com/photo-1501466044931-62695aada8e9?auto=format&fit=crop&w=1600&q=80',
  'london': 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?auto=format&fit=crop&w=1600&q=80',
  'dubai': 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=1600&q=80',
  'paris': 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?auto=format&fit=crop&w=1600&q=80',
  'barcelona': 'https://images.unsplash.com/photo-1583422409516-2895a77efded?auto=format&fit=crop&w=1600&q=80',
  'monte carlo': 'https://images.unsplash.com/photo-1612698093158-e07ac200d44e?auto=format&fit=crop&w=1600&q=80',
  'tokyo': 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?auto=format&fit=crop&w=1600&q=80',
  'vienna': 'https://images.unsplash.com/photo-1516550893923-42d28e5677af?auto=format&fit=crop&w=1600&q=80',
  'dublin': 'https://images.unsplash.com/photo-1549918864-48ac978761a4?auto=format&fit=crop&w=1600&q=80',
  'munich': 'https://images.unsplash.com/photo-1595867818082-083862f3d630?auto=format&fit=crop&w=1600&q=80',
  'warsaw': 'https://images.unsplash.com/photo-1519197924294-4ba991a11128?auto=format&fit=crop&w=1600&q=80',
  'milano': 'https://images.unsplash.com/photo-1520440229-6469a149ac59?auto=format&fit=crop&w=1600&q=80',
  'frankfurt': 'https://images.unsplash.com/photo-1467269204594-9661b134dd2b?auto=format&fit=crop&w=1600&q=80',
  'amsterdam': 'https://images.unsplash.com/photo-1512470876302-972faa2aa9a4?auto=format&fit=crop&w=1600&q=80',
  'moscow': 'https://images.unsplash.com/photo-1513326738677-b964603b136d?auto=format&fit=crop&w=1600&q=80',
  'hong kong': 'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=1600&q=80',
  'seoul': 'https://images.unsplash.com/photo-1538485399081-7c8ed7c8982c?auto=format&fit=crop&w=1600&q=80',
  'manila': 'https://images.unsplash.com/photo-1501890664351-4ef399c1524f?auto=format&fit=crop&w=1600&q=80',
  'stockholm': 'https://images.unsplash.com/photo-1509356843151-3e7d96241e11?auto=format&fit=crop&w=1600&q=80',
  'oslo': 'https://images.unsplash.com/photo-1433757741270-94a3bcadc2f3?auto=format&fit=crop&w=1600&q=80',
  'copenhagen': 'https://images.unsplash.com/photo-1513622470522-26c3c8a854bc?auto=format&fit=crop&w=1600&q=80',
  'prague': 'https://images.unsplash.com/photo-1541849546-216549ae216d?auto=format&fit=crop&w=1600&q=80',
  'brussels': 'https://images.unsplash.com/photo-1559113202-c916b8e44373?auto=format&fit=crop&w=1600&q=80',
  'lisbon': 'https://images.unsplash.com/photo-1555881400-74d7acaacd8b?auto=format&fit=crop&w=1600&q=80',
  'são paulo': 'https://images.unsplash.com/photo-1554168848-228452c09d60?auto=format&fit=crop&w=1600&q=80',
  'rio de janeiro': 'https://images.unsplash.com/photo-1483729558449-99ef09a8c325?auto=format&fit=crop&w=1600&q=80',
  'mexico city': 'https://images.unsplash.com/photo-1518659526054-190340b32735?auto=format&fit=crop&w=1600&q=80',
  'miami': 'https://images.unsplash.com/photo-1506966953602-c20cc11f75e3?auto=format&fit=crop&w=1600&q=80',
  'sydney': 'https://images.unsplash.com/photo-1506973035872-a4ec16b8e8d9?auto=format&fit=crop&w=1600&q=80',
  'melbourne': 'https://images.unsplash.com/photo-1514395462725-fb4566210144?auto=format&fit=crop&w=1600&q=80',
  'abu dhabi': 'https://images.unsplash.com/photo-1512632578888-169bbbc64f33?auto=format&fit=crop&w=1600&q=80',
  'riyadh': 'https://images.unsplash.com/photo-1586724237569-f3d0c1dee8c6?auto=format&fit=crop&w=1600&q=80',
  'st. moritz': 'https://images.unsplash.com/photo-1611186269812-eaaae17c4e7a?auto=format&fit=crop&w=1600&q=80',
  'san francisco': 'https://images.unsplash.com/photo-1501594907352-04cda38ebc29?auto=format&fit=crop&w=1600&q=80',

  // North America
  'new york': 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?auto=format&fit=crop&w=1600&q=80',
  'los angeles': 'https://images.unsplash.com/photo-1534190760961-74e8c1c5c3da?auto=format&fit=crop&w=1600&q=80',
  'chicago': 'https://images.unsplash.com/photo-1494522855154-9297ac14b55f?auto=format&fit=crop&w=1600&q=80',
  'san francisco': 'https://images.unsplash.com/photo-1501594907352-04cda38ebc29?auto=format&fit=crop&w=1600&q=80',
  'toronto': 'https://images.unsplash.com/photo-1517090504586-fde19ea6066f?auto=format&fit=crop&w=1600&q=80',
  'vancouver': 'https://images.unsplash.com/photo-1560813962-ff3d8fcf59ba?auto=format&fit=crop&w=1600&q=80',
  'montreal': 'https://images.unsplash.com/photo-1519178614-68673b201f36?auto=format&fit=crop&w=1600&q=80',
  'seattle': 'https://images.unsplash.com/photo-1502175353174-a7a70e73b362?auto=format&fit=crop&w=1600&q=80',
  'boston': 'https://images.unsplash.com/photo-1501979376754-2ff867a4f659?auto=format&fit=crop&w=1600&q=80',
  'las vegas': 'https://images.unsplash.com/photo-1605833556294-ea5c7a74f57d?auto=format&fit=crop&w=1600&q=80',

  // Asia
  'shanghai': 'https://images.unsplash.com/photo-1545893835-abaa50cbe628?auto=format&fit=crop&w=1600&q=80',
  'beijing': 'https://images.unsplash.com/photo-1537410056906-d336ddf64024?auto=format&fit=crop&w=1600&q=80',
  'bangkok': 'https://images.unsplash.com/photo-1508009603885-50cf7c579365?auto=format&fit=crop&w=1600&q=80',
  'kuala lumpur': 'https://images.unsplash.com/photo-1596422846543-75c6fc197f07?auto=format&fit=crop&w=1600&q=80',
  'delhi': 'https://images.unsplash.com/photo-1587474260584-136574528ed5?auto=format&fit=crop&w=1600&q=80',
  'taipei': 'https://images.unsplash.com/photo-1470004914212-05527e49370b?auto=format&fit=crop&w=1600&q=80',

  // Middle East
  'muscat': 'https://images.unsplash.com/photo-1621680696874-edd80ce57b72?auto=format&fit=crop&w=1600&q=80',
  'manama': 'https://images.unsplash.com/photo-1548755212-734fccb42001?auto=format&fit=crop&w=1600&q=80',
  'kuwait city': 'https://images.unsplash.com/photo-1621647017805-7d08d0a38c8a?auto=format&fit=crop&w=1600&q=80',
  'tel aviv': 'https://images.unsplash.com/photo-1544971587-b842c27f8e14?auto=format&fit=crop&w=1600&q=80',
  'jerusalem': 'https://images.unsplash.com/photo-1552423314-cf29ab68ad73?auto=format&fit=crop&w=1600&q=80',

  // Europe
  'berlin': 'https://images.unsplash.com/photo-1560969184-10fe8719e047?auto=format&fit=crop&w=1600&q=80',
  'hamburg': 'https://images.unsplash.com/photo-1569150216991-aba1feb19ac5?auto=format&fit=crop&w=1600&q=80',
  'athens': 'https://images.unsplash.com/photo-1555993539-1732b0258235?auto=format&fit=crop&w=1600&q=80',
  'budapest': 'https://images.unsplash.com/photo-1551867633-194f125bddfa?auto=format&fit=crop&w=1600&q=80',
  'bucharest': 'https://images.unsplash.com/photo-1584646098378-0874589d76b1?auto=format&fit=crop&w=1600&q=80',
  'krakow': 'https://images.unsplash.com/photo-1606992894456-799462dacd65?auto=format&fit=crop&w=1600&q=80',
  'porto': 'https://images.unsplash.com/photo-1555881400-74d7acaacd8b?auto=format&fit=crop&w=1600&q=80',
  'seville': 'https://images.unsplash.com/photo-1559682468-a6a29e7d9517?auto=format&fit=crop&w=1600&q=80',
  'florence': 'https://images.unsplash.com/photo-1541370976299-4d24ebbc9077?auto=format&fit=crop&w=1600&q=80',
  'venice': 'https://images.unsplash.com/photo-1514890547357-a9ee288728e0?auto=format&fit=crop&w=1600&q=80',
  'nice': 'https://images.unsplash.com/photo-1491166617655-0723a0999cfc?auto=format&fit=crop&w=1600&q=80',
  'lyon': 'https://images.unsplash.com/photo-1602087594298-706ccc894bfd?auto=format&fit=crop&w=1600&q=80',
  'marseille': 'https://images.unsplash.com/photo-1576244348464-c7d393b6dfc0?auto=format&fit=crop&w=1600&q=80',

  // Oceania
  'brisbane': 'https://images.unsplash.com/photo-1566734904496-9309bb1798ae?auto=format&fit=crop&w=1600&q=80',
  'perth': 'https://images.unsplash.com/photo-1524586410818-196d249560e4?auto=format&fit=crop&w=1600&q=80',
  'auckland': 'https://images.unsplash.com/photo-1507699622108-4be3abd695ad?auto=format&fit=crop&w=1600&q=80',
  'wellington': 'https://images.unsplash.com/photo-1589871973318-9ca1258faa5d?auto=format&fit=crop&w=1600&q=80',

  // South America
  'buenos aires': 'https://images.unsplash.com/photo-1589909202802-8f4aadce1849?auto=format&fit=crop&w=1600&q=80',
  'santiago': 'https://images.unsplash.com/photo-1478827387698-1527781a4887?auto=format&fit=crop&w=1600&q=80',
  'lima': 'https://images.unsplash.com/photo-1531968455001-5c5272a41129?auto=format&fit=crop&w=1600&q=80',
  'bogota': 'https://images.unsplash.com/photo-1568632234157-ce7aecd03d0d?auto=format&fit=crop&w=1600&q=80',
  'montevideo': 'https://images.unsplash.com/photo-1583270425012-1de82718620e?auto=format&fit=crop&w=1600&q=80',

  // Africa
  'cairo': 'https://images.unsplash.com/photo-1572252009286-268acec5ca0a?auto=format&fit=crop&w=1600&q=80',
  'cape town': 'https://images.unsplash.com/photo-1580060839134-75a5edca2e99?auto=format&fit=crop&w=1600&q=80',
  'johannesburg': 'https://images.unsplash.com/photo-1639400398977-c44e771882c6?auto=format&fit=crop&w=1600&q=80',
  'casablanca': 'https://images.unsplash.com/photo-1538230575309-59dfc388ae36?auto=format&fit=crop&w=1600&q=80',
  'nairobi': 'https://images.unsplash.com/photo-1611348524140-53c9a25263d6?auto=format&fit=crop&w=1600&q=80',
  'lagos': 'https://images.unsplash.com/photo-1618828665011-0abd973f7bb8?auto=format&fit=crop&w=1600&q=80',
  'marrakech': 'https://images.unsplash.com/photo-1597212618440-806262de4f6b?auto=format&fit=crop&w=1600&q=80',

  // Luxury Resort Towns & Wealthy Enclaves
  'gstaad': 'https://images.unsplash.com/photo-1625502104917-6a4911943a38?auto=format&fit=crop&w=1600&q=80',
  'verbier': 'https://images.unsplash.com/photo-1578085570465-a79aea722f05?auto=format&fit=crop&w=1600&q=80',
  'courchevel': 'https://images.unsplash.com/photo-1649421811395-5bfcf16cc37a?auto=format&fit=crop&w=1600&q=80',
  'zermatt': 'https://images.unsplash.com/photo-1605750454112-f2f3f696eeb0?auto=format&fit=crop&w=1600&q=80',
  'porto cervo': 'https://images.unsplash.com/photo-1596394516093-501ba68a0ba6?auto=format&fit=crop&w=1600&q=80',
  'saint-tropez': 'https://images.unsplash.com/photo-1586773884654-92e5e76c2427?auto=format&fit=crop&w=1600&q=80',
  'cannes': 'https://images.unsplash.com/photo-1627458205595-9c26ede46aab?auto=format&fit=crop&w=1600&q=80',
  'monaco': 'https://images.unsplash.com/photo-1518123522418-a0c837fd10ca?auto=format&fit=crop&w=1600&q=80',
  'dubrovnik': 'https://images.unsplash.com/photo-1555990793-da11153b2473?auto=format&fit=crop&w=1600&q=80',
  'capri': 'https://images.unsplash.com/photo-1539981853402-2c15d913424b?auto=format&fit=crop&w=1600&q=80',
  'positano': 'https://images.unsplash.com/photo-1583844056361-4418a8f2a985?auto=format&fit=crop&w=1600&q=80',
  'palm beach': 'https://images.unsplash.com/photo-1580494766979-ccfecebfbc8a?auto=format&fit=crop&w=1600&q=80',
  'aspen': 'https://images.unsplash.com/photo-1544198365-f5d60b6d8190?auto=format&fit=crop&w=1600&q=80',
  'beverly hills': 'https://images.unsplash.com/photo-1626060446672-5128ec04f992?auto=format&fit=crop&w=1600&q=80',
  'hamptons': 'https://images.unsplash.com/photo-1639184169179-f49d22fdfcce?auto=format&fit=crop&w=1600&q=80',

  // Additional Global Financial Centers
  'zurich': 'https://images.unsplash.com/photo-1515488764276-beab7607c1e6?auto=format&fit=crop&w=1600&q=80',
  'geneva': 'https://images.unsplash.com/photo-1574904935745-7e40279f589d?auto=format&fit=crop&w=1600&q=80',

  'macau': 'https://images.unsplash.com/photo-1561958635-a4f95d86c771?auto=format&fit=crop&w=1600&q=80',

  // Mediterranean Hotspots
  'ibiza': 'https://images.unsplash.com/photo-1526761122248-c31c93f8b2b9?auto=format&fit=crop&w=1600&q=80',
  'mykonos': 'https://images.unsplash.com/photo-1530841377377-3ff06c0ca713?auto=format&fit=crop&w=1600&q=80',
  'santorini': 'https://images.unsplash.com/photo-1469796466635-455ede028aca?auto=format&fit=crop&w=1600&q=80',
  'mallorca': 'https://images.unsplash.com/photo-1566993850067-bb8df9c9807e?auto=format&fit=crop&w=1600&q=80',
  'marbella': 'https://images.unsplash.com/photo-1589642073293-d0d511afb66e?auto=format&fit=crop&w=1600&q=80',
  'antibes': 'https://images.unsplash.com/photo-1499678329028-101435549a4e?auto=format&fit=crop&w=1600&q=80',

  // Emerging Luxury Markets
  'baku': 'https://images.unsplash.com/photo-1596306499398-8d88944a5ec4?auto=format&fit=crop&w=1600&q=80',
  'nur-sultan': 'https://images.unsplash.com/photo-1578301978162-7aae4d755744?auto=format&fit=crop&w=1600&q=80',
  'bali': 'https://images.unsplash.com/photo-1537953773345-d172ccf13cf1?auto=format&fit=crop&w=1600&q=80',
  'phuket': 'https://images.unsplash.com/photo-1589394815804-964ed0be2eb5?auto=format&fit=crop&w=1600&q=80',
  'ho chi minh city': 'https://images.unsplash.com/photo-1583417319070-4a69db38a482?auto=format&fit=crop&w=1600&q=80',


  // Additional European Cities
  'kitzbühel': 'https://images.unsplash.com/photo-1700142571812-21097b1f635a?auto=format&fit=crop&w=1600&q=80',
  'davos': 'https://images.unsplash.com/photo-1581196659434-70bf4293b04f?auto=format&fit=crop&w=1600&q=80'
};

export const getCityStatus = async (city: string): Promise<boolean> => {
  const formattedCity = city.replace(/\s+/g, '_').toLowerCase();
  const cityVenuesUrl = `https://raw.githubusercontent.com/uri100/json_pc/refs/heads/main/${formattedCity}.json`;

  try {
    const response = await fetch(cityVenuesUrl);
    if (!response.ok) return false;

    const data = await response.json();
    return Array.isArray(data) && data.length > 0;
  } catch {
    return false;
  }
};

// List of cities with dynamic active status
export const cities: City[] = [
  { name: 'Luxembourg', image: cityLandmarks['luxembourg'], active: await getCityStatus('Luxembourg') },
  { name: 'Singapore', image: cityLandmarks['singapore'], active: await getCityStatus('Singapore') },
  { name: 'Mumbai', image: cityLandmarks['mumbai'], active: await getCityStatus('Mumbai') },
  { name: 'Berlin', image: cityLandmarks['berlin'], active: await getCityStatus('Berlin') },
  { name: 'Asuncion', image: cityLandmarks['asuncion'], active: await getCityStatus('Asuncion') },
  { name: 'Tel Aviv', image: cityLandmarks['tel aviv'], active: await getCityStatus('Tel Aviv') },
  { name: 'Rome', image: cityLandmarks['rome'], active: await getCityStatus('Rome') },
  { name: 'Madrid', image: cityLandmarks['madrid'], active: await getCityStatus('Madrid') },
  { name: 'Washington DC', image: cityLandmarks['washington dc'], active: await getCityStatus('Washington DC') },
  { name: 'London', image: cityLandmarks['london'], active: await getCityStatus('London') },
  { name: 'Dubai', image: cityLandmarks['dubai'], active: await getCityStatus('Dubai') },
  { name: 'Paris', image: cityLandmarks['paris'], active: await getCityStatus('Paris') },
  { name: 'Barcelona', image: cityLandmarks['barcelona'], active: await getCityStatus('Barcelona') },
  { name: 'Tokyo', image: cityLandmarks['tokyo'], active: await getCityStatus('Tokyo') },
  { name: 'Vienna', image: cityLandmarks['vienna'], active: await getCityStatus('Vienna') },
  { name: 'Dublin', image: cityLandmarks['dublin'], active: await getCityStatus('Dublin') },
  { name: 'Munich', image: cityLandmarks['munich'], active: await getCityStatus('Munich') },
  { name: 'Warsaw', image: cityLandmarks['warsaw'], active: await getCityStatus('Warsaw') },
  { name: 'Milano', image: cityLandmarks['milano'], active: await getCityStatus('Milano') },
  { name: 'Frankfurt', image: cityLandmarks['frankfurt'], active: await getCityStatus('Frankfurt') },
   { name: 'New York', image: cityLandmarks['new york'], active: await getCityStatus('New York') },
  { name: 'Hong Kong', image: cityLandmarks['hong kong'], active: await getCityStatus('Hong Kong') },
  { name: 'Amsterdam', image: cityLandmarks['amsterdam'], active: await getCityStatus('Amsterdam') },
  { name: 'San Francisco', image: cityLandmarks['san francisco'], active: await getCityStatus('San Francisco') },
  { name: 'Valencia', image: cityLandmarks['valencia'], active: await getCityStatus('Valencia') }
];