import React, { useState } from 'react';
import { Star, MapPin } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';

interface RatingStep {
  title: string;
  category: string;
}

const RATING_STEPS: RatingStep[] = [
  {
    title: 'Punctuality',
    category: 'rating_punctuality'
  },
  {
    title: 'Sportsmanship',
    category: 'rating_sportsmanship'
  },
  {
    title: 'Skill Match',
    category: 'rating_skill_match'
  },
  {
    title: 'Communication',
    category: 'rating_communication'
  },
];

interface NewRatingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (ratings: {
    rating_overall: number;
    rating_punctuality: number;
    rating_sportsmanship: number;
    rating_skill_match: number;
    rating_communication: number;
    rating_feedback?: string;
    rating_categories: {
      punctuality: boolean;
      sportsmanship: boolean;
      skillLevelMatch: boolean;
      communication: boolean;
    };
  }) => Promise<void>;
  playerName: string;
  venueName: string;
  isSubmitting?: boolean;
}

const NewRatingModal: React.FC<NewRatingModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  playerName,
  venueName,
  isSubmitting = false
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [ratings, setRatings] = useState<Record<string, number>>({});
  const [feedback, setFeedback] = useState('');
  const [hoveredRatings, setHoveredRatings] = useState<Record<string, number | null>>({});

  const handleRatingSelect = (rating: number, category: string) => {
    setRatings(prev => ({
      ...prev,
      [category]: rating
    }));
  };

  const handleSubmit = async () => {
    try {
      // Calculate overall rating (average of all ratings)
      const overallRating = Object.values(ratings).reduce((sum, rating) => sum + rating, 0) / Object.keys(ratings).length;
      
      // Prepare rating categories (true if rating >= 4)
      const ratingCategories = {
        punctuality: (ratings.rating_punctuality || 0) >= 4,
        sportsmanship: (ratings.rating_sportsmanship || 0) >= 4,
        skillLevelMatch: (ratings.rating_skill_match || 0) >= 4,
        communication: (ratings.rating_communication || 0) >= 4
      };
      
     await onSubmit(
    {
        rating_punctuality: ratings.rating_punctuality,
        rating_sportsmanship: ratings.rating_sportsmanship,
        rating_skill_match: ratings.rating_skill_match,
        rating_communication: ratings.rating_communication,
        rating_categories: ratingCategories
    },
    feedback // ✅ Now passing feedback as a separate argument
);

      onClose();
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };

  const isComplete = RATING_STEPS.every(step => ratings[step.category]);

  const getRatingLabel = (rating: number, category: string) => {
    const labels: Record<string, string[]> = {
      rating_punctuality: ['Very Late', 'Late', 'On Time', 'Early', 'Very Punctual'],
      rating_sportsmanship: ['Poor', 'Fair', 'Good', 'Great', 'Excellent'],
      rating_skill_match: ['Mismatched', 'Slightly Off', 'Fair Match', 'Good Match', 'Perfect Match'],
      rating_communication: ['Poor', 'Fair', 'Good', 'Great', 'Excellent']
    };
    return labels[category]?.[rating - 1] || '';
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
        <div className="p-4">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold text-white">
              Rate {playerName}
            </DialogTitle>
            <div className="flex items-center text-dark-800 mt-0.5">
              <MapPin className="h-4 w-4 mr-1" />
              {venueName}
            </div>
          </DialogHeader>

          <div className="mt-4 space-y-3">
            {RATING_STEPS.map((step, index) => (
              <motion.div
                key={step.category}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="space-y-1"
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium text-white">{step.title}</h3>
                  <div className="flex items-center">
                    {[1, 2, 3, 4, 5].map((rating) => (
                      <button
                        key={`${step.category}-${rating}`}
                        onClick={() => handleRatingSelect(rating, step.category)}
                        className="p-0.5 transition-transform hover:scale-110"
                        onMouseEnter={() => setHoveredRatings(prev => ({ ...prev, [step.category]: rating }))}
                        onMouseLeave={() => setHoveredRatings(prev => ({ ...prev, [step.category]: null }))}
                      >
                        <Star 
                          className={cn(
                            "h-5 w-5 transition-colors",
                            rating <= ((hoveredRatings[step.category] ?? ratings[step.category]) || 0)
                              ? "text-gold-400 fill-gold-400"
                              : "text-dark-800"
                          )}
                        />
                      </button>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}

            {/* Feedback Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="space-y-1 pt-3 border-t border-dark-300"
            >
              <h3 className="text-sm font-medium">Additional Feedback</h3>
              <textarea
                placeholder="Share more about your experience..."
                className="w-full h-20 bg-dark-300 border border-dark-400 rounded-lg p-2 text-white placeholder:text-dark-800 resize-none text-sm"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
            </motion.div>

            {/* Submit Button */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="pt-3"
            >
              <button
                onClick={handleSubmit}
                disabled={!isComplete || isSubmitting}
                className={cn(
                  "w-full px-4 py-2.5 rounded-lg font-medium transition-all text-sm",
                  "transform hover:scale-[1.02] active:scale-[0.98]",
                  isComplete && !isSubmitting
                    ? "bg-gold-400 hover:bg-gold-500 text-dark-50"
                    : "bg-dark-300 text-dark-800 cursor-not-allowed"
                )}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Rating'}
              </button>
            </motion.div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewRatingModal;