import React from 'react';
import { Star } from 'lucide-react';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

interface PlayerAvatarProps {
  image: string;
  name: string;
  rating: number;
  onClick: () => void;
}

const PlayerAvatar: React.FC<PlayerAvatarProps> = ({ image, name, rating, onClick }) => {
  const { isDarkMode } = useTheme();
  
  return (
    <div className="flex flex-col items-center gap-2">
      <button onClick={onClick} className="relative">
        <div className={cn(
          "relative w-32 h-32 sm:w-36 sm:h-36 lg:w-40 lg:h-40 rounded-full",
          "transform hover:scale-105 active:scale-95 transition-all duration-300 z-10",
          "shadow-lg hover:shadow-xl group/avatar",
          isDarkMode 
            ? "bg-gradient-to-br from-dark-300 to-dark-400" 
            : "bg-gradient-to-br from-light-100 to-light-200"
        )}>
          <img
            src={image}
            alt={name}
            className={cn(
              "w-full h-full rounded-full object-cover",
              "ring-4 sm:ring-6 transition-colors duration-300",
              isDarkMode
                ? "ring-dark-400 group-hover/avatar:ring-gold-400"
                : "ring-light-300 group-hover/avatar:ring-gold-600"
            )}
          />
          {/* Rating Badge */}
          <div className={cn(
            "absolute -bottom-4 left-1/2 -translate-x-1/2",
            "flex items-center gap-1 px-3 py-1 rounded-full",
            isDarkMode
              ? "bg-dark-200/90 backdrop-blur-sm border border-dark-300/50"
              : "bg-white/90 backdrop-blur-sm border border-light-300/50",
            "shadow-lg transform transition-transform group-hover/avatar:scale-110"
          )}>
            <Star className={cn(
              "h-3.5 w-3.5",
              isDarkMode ? "text-gold-400" : "text-gold-600"
            )} />
            <span className={cn(
              "text-sm font-medium",
              isDarkMode ? "text-white" : "text-gray-900"
            )}>{rating}</span>
          </div>
        </div>
        {/* Outer Glow Effect */}
        <div className={cn(
          "absolute inset-0 rounded-full -z-10",
          "opacity-0 group-hover/avatar:opacity-100",
          "transition-opacity duration-300 scale-110",
          "bg-gradient-to-r",
          isDarkMode
            ? "from-gold-400/20 via-gold-400/30 to-gold-500/20"
            : "from-gold-500/20 via-gold-500/30 to-gold-600/20",
          "blur-xl"
        )} />
      </button>
    </div>
  );
};

export default PlayerAvatar;