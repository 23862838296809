import React, { useState, useEffect } from 'react';
import { MapPin, Users, Shield, Clock } from 'lucide-react';
import { CityVenueService } from '@/lib/services/venues/cityVenue.service';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';

interface CityCardProps {
  city: string;
  image: string;
  active: boolean;
  onClick: () => void;
}

const CityCard: React.FC<CityCardProps> = ({ city, image, active, onClick }) => {
  const [venueCount, setVenueCount] = useState<number>(0);
  const [memberCount, setMemberCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch member count from Supabase
  useEffect(() => {
    const fetchMemberCount = async () => {
      try {
        setIsLoading(true);
        const { count, error } = await supabase
          .from('users')
          .select('*', { count: 'exact', head: true })
          .ilike('city', `%${city}%`);

        if (error) throw error;
        setMemberCount(count || 0);
      } catch (error) {
        console.error('Error fetching member count:', error);
        setMemberCount(0);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMemberCount();
  }, [city]);

  // Fetch venue count using the new CityVenueService
  useEffect(() => {
    const loadVenueCount = async () => {
      try {
        if (active) {
          const venues = await CityVenueService.getVenues(city);
          setVenueCount(venues.length);
        } else {
          setVenueCount(0);
        }
      } catch (error) {
        console.error(`Failed to fetch venues for ${city}:`, error);
        setVenueCount(0);
      }
    };

    loadVenueCount();
  }, [city, active]);

  return (
    <button
      onClick={active ? onClick : undefined}
      className={cn(
        "relative w-full h-64 rounded-2xl overflow-hidden group",
        active ? "cursor-pointer" : "cursor-default"
      )}
    >
      <img
        src={image}
        alt={`${city} landmark`}
        className={cn(
          "w-full h-full object-cover transition-transform duration-300 group-hover:scale-105",
          !active && "grayscale brightness-50"
        )}
      />

      {/* Gradient overlay for visibility */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent group-hover:from-black/90 group-hover:via-black/60 transition-all duration-300" />

      <div className="absolute bottom-0 left-0 right-0 p-6">
        <div className="flex items-center space-x-2 mb-3">
          <MapPin className="h-5 w-5 text-gold-400 flex-shrink-0" />
          <h3 className="text-xl font-semibold text-white !text-white">
            {city}
          </h3>
        </div>

        {/* Display member and venue count for active cities */}
        {active ? (
          <div className="flex flex-wrap items-center gap-3">
            <div className="flex items-center px-3 py-1.5 bg-black/80 backdrop-blur-sm rounded-full shadow-sm border border-white/10">
              <Users className="h-4 w-4 text-gold-400 mr-2 flex-shrink-0" />
              <span className="text-white font-medium !text-white">
                {isLoading ? '...' : `${memberCount} ${memberCount === 1 ? 'Player' : 'Players'}`}
              </span>
            </div>

            <div className="flex items-center px-3 py-1.5 bg-black/80 backdrop-blur-sm rounded-full shadow-sm border border-white/10">
              <Shield className="h-4 w-4 text-gold-400 mr-2 flex-shrink-0" />
              <span className="text-white font-medium !text-white">
                {`${venueCount} ${venueCount === 1 ? 'Club' : 'Clubs'}`}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex items-center px-3 py-1.5 bg-black/80 backdrop-blur-sm rounded-full shadow-sm border border-white/10">
            <Clock className="h-4 w-4 text-gold-400 mr-2 flex-shrink-0" />
            <span className="text-white font-medium !text-white">
              Coming Soon
            </span>
          </div>
        )}
      </div>
    </button>
  );
};

export default CityCard;