import React from 'react';
import { Star, CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Review } from '../../types/review';
import { formatDistanceToNow } from 'date-fns';

interface ReviewListProps {
  reviews: Review[];
}

const ReviewList: React.FC<ReviewListProps> = ({
  reviews,
}) => {
  const navigate = useNavigate();

  const handleProfileClick = (authorId: string) => {
    navigate(`/dashboard/members/${authorId}`);
  };

  return (
    <div className="space-y-6">
      {reviews.length === 0 ? (
        <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
          <Star className="h-12 w-12 text-dark-800 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-white mb-2">No Ratings Yet</h3>
          <p className="text-dark-800 max-w-sm mx-auto">
            Complete activities to receive ratings from other members
          </p>
        </div>
      ) : (
        reviews.map((review) => (
          <div
            key={review.id}
            className="bg-dark-300 rounded-xl p-4 hover:bg-dark-400 transition-colors"
          >
            <div className="flex items-start justify-between">
              <div className="flex items-start space-x-3">
                <button
                  onClick={() => handleProfileClick(review.authorId)}
                  className="flex-shrink-0 hover:opacity-80 transition-opacity"
                >
                  <img
                    src={review.authorImage}
                    alt={review.authorName}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </button>
                <div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleProfileClick(review.authorId)}
                      className="font-medium text-white hover:text-gold-400 transition-colors"
                    >
                      {review.authorName}
                    </button>
                    {review.isVerified && (
                      <CheckCircle className="h-4 w-4 text-green-400" />
                    )}
                  </div>
                  <div className="flex items-center space-x-2 mt-1">
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={star}
                          className={`h-4 w-4 ${
                            star <= review.rating
                              ? 'fill-gold-400 text-gold-400'
                              : 'text-dark-800'
                          }`}
                        />
                      ))}
                    </div>
                    <span className="text-dark-800 text-sm">
                      {formatDistanceToNow(new Date(review.timestamp), { addSuffix: true })}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-white mt-3">{review.content}</p>

            {(review.sport || review.venue) && (
              <div className="flex flex-wrap gap-2 mt-3">
                {review.sport && (
                  <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                    {review.sport}
                  </span>
                )}
                {review.venue && (
                  <span className="px-3 py-1 bg-dark-400 rounded-full text-xs text-white">
                    {review.venue}
                  </span>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ReviewList;