import React from 'react';
import { Link } from 'react-router-dom';
import { BackButton } from './header/BackButton';
import { UserAvatar } from './header/UserAvatar';
import { UserStatus } from './header/UserStatus';
import { ChatMenu } from './menus/ChatMenu';

interface ChatHeaderProps {
  conversation: {
    id: string;
    name: string;
    image: string;
    location: string;
    online: boolean;
  };
  isConnected?: boolean;
  isPending?: boolean;
  onConnect?: () => void;
  isConnecting?: boolean;
  showMenu: boolean;
  onMenuToggle: () => void;
  onBackClick: () => void;
  onBlockUser: () => void;
  onReportUser: () => void;
  onClearChat: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  conversation,
  isConnected,
  isPending,
  onConnect,
  isConnecting,
  showMenu,
  onMenuToggle,
  onBackClick,
  onBlockUser,
  onReportUser,
  onClearChat
}) => {
  return (
    <div className="p-4 border-b border-dark-300 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <BackButton onClick={onBackClick} />
        <Link to={`/dashboard/members/${conversation.id}`} className="flex items-center space-x-4">
          <UserAvatar image={conversation.image} name={conversation.name} />
          <div>
            <h4 className="text-base font-medium text-white">{conversation.name}</h4>
            <UserStatus location={conversation.location} isOnline={conversation.online} />
          </div>
        </Link>
      </div>
      
      <div className="flex items-center space-x-2">
        {!isConnected && onConnect && (
          <button
            onClick={onConnect}
            className="bg-gold-400 hover:bg-gold-500 text-dark-50 px-3 py-1.5 rounded-lg text-sm font-medium transition-colors"
            disabled={isConnecting}
          >
            {isConnecting ? "Connecting..." : isPending ? "Pending" : "Connect"}
          </button>
        )}
      <ChatMenu
        show={showMenu}
        onToggle={onMenuToggle}
        onBlock={onBlockUser}
        onReport={onReportUser}
      />
      </div>
    </div>
  );
};

export default ChatHeader;
