import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin, MessageCircle, Clock, X, Users2 } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { useConnectionStore } from '@/store/connectionStore';
import { cn } from '@/lib/utils';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '../../ui/dialog';
import { Button } from '../../ui/button';
import { CheckCircle2 } from 'lucide-react';
import { supabase } from '@/lib/supabase';

const ConnectionsList = ({ connections, isLoading }) => {
  const { user } = useAuth();
  const { removeConnection } = useConnectionStore();
  const navigate = useNavigate();
  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmDisconnect, setShowConfirmDisconnect] = useState(false);

  if (isLoading) return <p className="text-dark-800">Loading connections...</p>;

  if (!connections.length) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Connections Yet</h3>
        <p className="text-dark-800 max-w-sm mx-auto">
          Start connecting with other members to grow your professional sports network.
        </p>
      </div>
    );
  }

  const handleStartChat = async (otherUserId, otherUserName) => {
    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError || !user) throw userError || new Error('Not authenticated');

      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();
      if (userDataError || !userData) throw userDataError || new Error('User not found');

      const { data: memberData, error: memberError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', otherUserId)
        .single();
      if (memberError || !memberData) throw memberError || new Error('Member not found');

      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: memberData.id,
        }
      );
      if (conversationError) throw conversationError;

      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: `Hi ${otherUserName}!`,
          showMobileList: false,
        },
      });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  return (
    <div className="space-y-4 max-w-3xl mx-auto">
      {connections.map((connection) => {
        const user = connection.other_user;
        return (
          <div
            key={connection.connection_id}
            className={cn(
              'bg-dark-200 rounded-2xl overflow-hidden border border-dark-300',
              'hover:border-gold-400 transition-all duration-300',
              'shadow-lg hover:shadow-xl transform hover:scale-[1.01]'
            )}
          >
            <div className="p-4 flex items-center space-x-4">
              <button
                onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                className="flex-shrink-0"
              >
                <img
                  src={user.avatar_url || defaultAvatar}
                  alt={user.full_name}
                  className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-all duration-300 shadow-xl"
                />
              </button>

              <div className="flex-1 min-w-0">
                <button
                  onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                  className="text-white font-medium hover:text-gold-400 transition-colors block truncate"
                >
                  {user.full_name}
                </button>

                <div className="flex items-center text-dark-800 text-sm mt-0.5">
                  <MapPin className="h-4 w-4 mr-1" />
                  {user.city}
                </div>

                {user.sports?.length > 0 && (
                  <div className="flex flex-wrap gap-1.5 mt-2">
                    {user.sports.map((sport, idx) => (
                      <span
                        key={idx}
                        className="text-xs px-2.5 py-1 rounded-full bg-dark-400 text-white"
                      >
                        {sport.emoji} {sport.name}
                      </span>
                    ))}
                  </div>
                )}

                <div className="text-xs text-dark-700 mt-2 flex items-center gap-1">
                  <Clock className="w-4 h-4" />
                  Connected since {new Date(connection.created_at).toLocaleDateString()}
                </div>
              </div>

              <div className="flex flex-col gap-2 ml-auto">
                <Button
                  size="sm"
                  className="bg-gold-400 hover:bg-gold-500 text-dark-50"
                  onClick={() => handleStartChat(user.auth_id, user.full_name)}
                >
                  <MessageCircle className="w-4 h-4 mr-1" />
                  Message
                </Button>
                <Button
                  size="sm"
                  variant="outline"
                  className="text-dark-800 hover:bg-dark-300 border-dark-400"
                  onClick={() => {
                    setSelectedConnection(connection);
                    setShowDeleteModal(true);
                  }}
                >
                  Manage
                </Button>
              </div>
            </div>
          </div>
        );
      })}

      <Dialog open={showDeleteModal} onOpenChange={setShowDeleteModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle className="text-center">
              Manage Connection
            </DialogTitle>
          </DialogHeader>
          <div className="py-6">
            <div className="flex items-center justify-center mb-6">
              <img
                src={selectedConnection?.other_user?.avatar_url || defaultAvatar}
                alt={selectedConnection?.other_user?.full_name || "User"}
                className="w-16 h-16 rounded-full border-2 border-dark-300"
              />
            </div>
            <h3 className="text-lg font-medium text-center mb-2">
              {selectedConnection?.other_user?.full_name}
            </h3>
            <p className="text-dark-800 text-center mb-6">
              You've been connected since {new Date(selectedConnection?.created_at || "").toLocaleDateString()}.
            </p>
            <div className="space-y-3">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteModal(false)}
                  className="w-full border-dark-400 text-white hover:bg-dark-400"
                >
                  Cancel
                </Button>
                <Button
                  variant="outline"
                  onClick={async () => {
                    if (!selectedConnection) return;
                    setShowConfirmDisconnect(true);
                  }}
                  className="w-full border-red-500/30 text-red-400 hover:bg-red-500/10 hover:border-red-500/50"
                >
                  Remove Connection
                </Button>
              </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Disconnect Confirmation Modal */}
      <Dialog open={showConfirmDisconnect} onOpenChange={setShowConfirmDisconnect}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle className="text-center">Confirm Disconnection</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <p className="text-center mb-6">
              Are you sure you want to disconnect from{' '}
              <span className="font-medium text-white">{selectedConnection?.other_user?.full_name}</span>?
            </p>
            <p className="text-dark-800 text-sm mb-6 text-center">
              This will remove them from your connections. You can always reconnect later.
            </p>
            <div className="flex flex-col sm:flex-row gap-3 justify-center">
              <Button
                variant="outline"
                onClick={() => setShowConfirmDisconnect(false)}
                className="border-dark-400 text-white hover:bg-dark-400"
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  if (!selectedConnection) return;
                  await removeConnection(selectedConnection.connection_id);
                  setShowConfirmDisconnect(false);
                  setShowDeleteModal(false);
                  setShowSuccessModal(true);
                }}
                className="bg-red-500 hover:bg-red-600 text-white"
              >
                Yes, Disconnect
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showSuccessModal} onOpenChange={setShowSuccessModal}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <div className="text-center py-6">
            <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
              <CheckCircle2 className="h-8 w-8 text-dark-50" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">
              Connection Removed
            </h3>
            <p className="text-dark-800 mb-6">
              You have successfully removed your connection with{' '}
              {selectedConnection?.other_user?.full_name}.
            </p>
            <Button
              onClick={() => setShowSuccessModal(false)}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ConnectionsList;