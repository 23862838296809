export function formatDateForInput(date: string | Date): string {
    const d = typeof date === 'string' ? new Date(date) : date;
    const year = d.getUTCFullYear();
    const month = String(d.getUTCMonth() + 1).padStart(2, '0');
    const day = String(d.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function formatDateForDisplay(date: string): string {
  const d = new Date(date + 'T00:00:00Z'); // Append 'Z' for UTC

  return d.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC' // Use the user's timezone
  });
}

/* OLD VERSION
export function formatDateForDisplay(date: string): string {
 
    const d = new Date(Date.UTC(
        parseInt(date.substring(0, 4), 10),
        parseInt(date.substring(5, 7), 10) - 1,
        parseInt(date.substring(8, 10), 10)
    ));
  
    return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
}
*/