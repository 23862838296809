import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Users, Trophy, Bot, Building2, Crown } from 'lucide-react';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';

interface WelcomeStepProps {
  onNext: () => void;
  inviterName?: string;
}

const WelcomeStep: React.FC<WelcomeStepProps> = ({ onNext, inviterName }) => {

  const features = [
    {
      icon: Users,
      title: "Elite Network",
      description: "Connect with top professionals and passionate players from around the globe."
    },
    {
      icon: Trophy,
      title: "Quick Play",
      description: "Find local players and start playing at premium venues."
    },
    {
      icon: Bot,
      title: "Lucy AI",
      description: "Ask our AI agent anything and get instant advice."
    },
    {
      icon: Building2,
      title: "Exclusive Clubs",
      description: "Browse private member clubs in major cities worldwide."
    }
  ];

  return (
    <div className="h-full flex flex-col justify-center items-center relative px-4 sm:px-6 md:px-8 py-4">
      {/* Headline */}
      <motion.h1 
        className={cn(
          "text-xl xs:text-2xl sm:text-3xl md:text-4xl",
          "font-bold text-transparent bg-clip-text",
          "bg-gradient-to-r from-white via-gold-200 to-gold-400",
          "text-center mb-6 sm:mb-8"
        )}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Welcome to Playclub
      </motion.h1>

      <motion.div 
        className="text-center max-w-xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {inviterName && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className={cn(
              "mb-8 max-w-lg mx-auto w-full",
              "bg-gradient-to-r from-gold-400/20 via-gold-400/10 to-gold-400/20",
              "border border-gold-400/30 rounded-xl p-6"
            )}
          >
            <Crown className="h-6 w-6 text-gold-400 mx-auto mb-3" />
            <p className="text-lg text-white/90">
              You've been personally invited by
            </p>
            <p className="text-xl font-medium text-gold-400 mt-1 mb-2">
              {inviterName}
            </p>
          </motion.div>
        )}
        
        <h2 className="text-3xl sm:text-4xl font-bold text-white mb-6 bg-gradient-to-r from-white via-gold-200 to-gold-400 bg-clip-text text-transparent">
          Ready to Play the World?
        </h2>
        
        <p className="text-lg text-gray-400 mb-8">
Playclub is an exclusive community for private club members and business travellers passionate about sports and wellness. Connect, play, and network with like-minded individuals worldwide.
        </p>

        <motion.button
          onClick={onNext}
          className={cn(
            "px-8 py-3 bg-gradient-to-r from-gold-400 to-gold-500",
            "hover:from-gold-500 hover:to-gold-600",
            "text-dark-50 rounded-xl transition-all duration-300",
            "transform hover:scale-105 shadow-lg hover:shadow-xl",
            "text-lg font-medium"
          )}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Let's Play!
        </motion.button>
      </motion.div>
    </div>
  );
};

export default WelcomeStep;