import { useState, useEffect, useMemo } from 'react';
import DashboardLayout from '../components/DashboardLayout';
import ActivityList from '../components/activities/ActivityList';
import ActivityFilters from '../components/activities/ActivityFilters';
import { createActivity } from '../lib/supabase/activities';
import { useActivities } from '../hooks/useActivities';
import ActivitySkeleton from '../components/activities/ActivitySkeleton';
import { ActivityErrorBoundary } from '../components/activities/ActivityErrorBoundary';
import ManageActivityModal from '../components/members/modals/ManageActivityModal';
import { RefreshCw } from 'lucide-react';
import { cn } from '@/lib/utils';
//import { useNotificationStore } from '../store/notificationStore';
import { useActivityStore } from '../store/activityStore';
import { useActivityLoadingStore } from '../store/activityLoadingStore';
import { supabase } from '@/lib/supabase';

const Activities: React.FC = () => {
  //const { addNotification } = useNotificationStore();
  const {
    activities,
    isLoading,
    error,
    fetchActivities,
    updateActivity,
    deleteActivity,
    acceptActivityRequest,
    declineActivityRequest,
    rateActivityRequest,
  } = useActivityStore();
  const [showFilters, setShowFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedSport, setSelectedSport] = useState<string>('all');
  const [selectedCity, setSelectedCity] = useState<string>('all');
  const [selectedStatus, setSelectedStatus] = useState<string>(
    location.state?.selectedStatus || 'all'
  );
  const [activeTab, setActiveTab] = useState<'current' | 'past'>(
    location.state?.activeTab || 'current'
  );
  const [sortOrder, setSortOrder] = useState<'recent' | 'oldest'>('recent');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { loadingStates, setLoadingState } = useActivityLoadingStore();

  useEffect(() => {
    fetchActivities();
  }, [fetchActivities]);

  // Handle incoming state
  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveTab(location.state.activeTab);
    }
    if (location.state?.selectedStatus) {
      setSelectedStatus(location.state.selectedStatus);
    }
    // Clear location state after using it
    window.history.replaceState({}, document.title);
  }, [location.state]);
  const handleRefresh = async () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    try {
      await fetchActivities();
    } catch (error) {
      console.error('Failed to refresh activities:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const filteredActivities = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Early return if no activities
    if (!activities.length) {
      return [];
    }

    // First, process all activities to update their status
    const processedActivities = activities.map((activity) => {
      const activityDate = new Date(activity.date);
      const isPastActivity = activityDate.getTime() < today.getTime();

      // Update status for past activities
      if (
        isPastActivity &&
        activity.status !== 'played' &&
        activity.status !== 'missed' &&
        activity.status !== 'declined'
      ) {
        return {
          ...activity,
          status: activity.status === 'confirmed' ? 'played' : 'missed',
          isPast: true,
        };
      }

      return {
        ...activity,
        isPast: isPastActivity,
      };
    });
    // Then filter based on current/past tab
    const filteredByTab = processedActivities.filter((activity) => {
      if (activeTab === 'current') {
        return !activity.isPast && activity.status !== 'declined';
      } else {
        return activity.isPast || activity.status === 'declined';
      }
    });

    // Apply search and other filters
    const searchTermLower = searchTerm.toLowerCase();
    const filtered = filteredByTab.filter((activity) => {
      const matchesSearch =
        !searchTerm ||
        [activity.opponent?.name, activity.sport, activity.venue?.city].some(
          (field) => field?.toLowerCase()?.includes(searchTermLower)
        );

      const matchesSport =
        selectedSport === 'all' || activity.sport === selectedSport;
      const matchesCity =
        selectedCity === 'all' || activity.venue?.city === selectedCity;
      const matchesStatus =
        selectedStatus === 'all' || activity.status === selectedStatus;

      return matchesSearch && matchesSport && matchesCity && matchesStatus;
    });

    // Return early if no matches
    if (!filtered.length) {
      return [];
    }

    // Sort the filtered results
    return filtered.sort((a, b) => {
      const dateA = new Date(`${a.date} ${a.time}`).getTime();
      const dateB = new Date(`${b.date} ${b.time}`).getTime();

      return activeTab === 'current'
        ? sortOrder === 'recent'
          ? dateA - dateB
          : dateB - dateA
        : sortOrder === 'recent'
        ? dateB - dateA
        : dateA - dateB;
    });
  }, [
    activities,
    searchTerm,
    selectedSport,
    selectedCity,
    selectedStatus,
    sortOrder,
    activeTab,
  ]);

  const {
    selectedMatch,
    showManageModal,
    showDeclineModal,
    setSelectedMatch,
    setShowManageModal,
    handleManageSession,
    handleDeclineRequest,
  } = useActivities(filteredActivities, {
    searchTerm,
    selectedSport,
    selectedCity,
    selectedStatus,
    sortOrder,
  });

  const handleUpdateActivity = async (
    activity: Activity,
    updates: Partial<Activity>
  ) => {
    try {
      await updateActivity(activity.id, updates);
      setShowManageModal(false);
      setSelectedMatch(null);
    } catch (error) {
      console.error('Failed to update activity:', error);
    }
  };

  const handleAcceptActivity = async (activity: Activity) => {
    try {
      setLoadingState(activity.id, 'accepting', true);

      // Get current user's name
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      /* Get user's full name from users table
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('full_name')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');*/

      await acceptActivityRequest(activity.id);
      
      /* Moved to backend
      addNotification({
        type: 'match_accepted',
        title: 'Activity Accepted',
        content: `${userData.full_name} accepted your ${activity.sport} request`,
        user_id: activity.opponent.id,
        metadata: {
          activityId: activity.id,
          activeTab: 'activities',
        },
      });*/
    } catch (error) {
      console.error('Failed to accept activity:', error);
    } finally {
      setLoadingState(activity.id, 'accepting', false);
    }
  };

  const handleDeclineActivity = async (activity: Activity, reason?: string) => {
    try {
      setLoadingState(activity.id, 'declining', true);

      // Get current user's name
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      /* Get user's full name from users table
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('full_name')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');*/

      await declineActivityRequest(activity.id, reason);
      
      /* Moved to backend
      addNotification({
        type: 'match_declined',
        title: 'Activity Declined',
        content: `${userData.full_name} declined your ${activity.sport} request`,
        user_id: activity.opponent.id,
        metadata: {
          activityId: activity.id,
          activeTab: 'activities',
        },
      });*/
    } catch (error) {
      console.error('Failed to decline activity:', error);
    } finally {
      setLoadingState(activity.id, 'declining', false);
    }
  };

  const handleRateActivity = async (
    activity: Activity,
    rating: number,
    feedback: string,
    categories: any
  ) => {
    try {
      setLoadingState(activity.id, 'rating', true);

      // Get current user's name
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      /* Get user's full name from users table
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('full_name')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');*/

      await rateActivityRequest(activity.id, rating, feedback, categories);
      
      /* Moved to backend
      addNotification({
        type: 'new_message',
        title: 'Activity Rated',
        content: `${userData.full_name} rated your ${activity.sport} activity`,
        user_id: activity.opponent.id,
        metadata: {
          activityId: activity.id,
          activeTab: 'activities',
        },
      });*/
    } catch (error) {
      console.error('Failed to rate activity:', error);
    } finally {
      setLoadingState(activity.id, 'rating', false);
    }
  };

  const handleRematch = async (activity: Activity) => {
    try {
      // Get current user's name
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      /* Get user's full name from users table
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('full_name')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');*/

      const result = await createActivity(activity);

      // Refresh activities using the store
      await fetchActivities();

      /* Moved to backend
      addNotification({
        type: 'match_request',
        title: 'Rematch Request',
        content: `${userData.full_name} wants a ${activity.sport} rematch`,
        user_id: activity.opponent.id,
        metadata: {
          activityId: activity.id,
          activeTab: 'activities',
        },
      });*/
    } catch (error) {
      console.error('Failed to create rematch:', error);
      // Error will be handled by the store
    }
  };

  return (
    <DashboardLayout>
      <div className="space-y-6">
        {/* Header */}
        <div className="bg-dark-200 rounded-2xl p-6 lg:p-8 border border-dark-300">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-bold text-white">My Activities</h1>
              <p className="text-dark-800 mt-1">
                Track your sports activities and matches
              </p>
            </div>
            <button
              onClick={handleRefresh}
              className={cn(
                'p-2 rounded-lg transition-colors',
                'hover:bg-dark-300 text-dark-800 hover:text-white',
                'active:scale-95 transform',
                isRefreshing && 'animate-spin'
              )}
              title="Refresh activities"
              disabled={isRefreshing}
            >
              <RefreshCw className="h-5 w-5" />
            </button>
          </div>
        </div>

        <ActivityErrorBoundary>
          {/* Error Message */}
          {error && (
            <div className="bg-red-500/10 text-red-500 p-4 rounded-xl border border-red-500/20">
              <p>{error}</p>
              <button
                onClick={() => window.location.reload()}
                className="text-sm underline mt-2 hover:text-red-400"
              >
                Try Again
              </button>
            </div>
          )}

          {/* Loading State */}
          {isLoading && <ActivitySkeleton count={5} />}

          {/* Filters */}
          {!isLoading && (
            <ActivityFilters
              activities={activities}
              searchTerm={searchTerm}
              selectedSport={selectedSport}
              selectedCity={selectedCity}
              selectedStatus={selectedStatus}
              activeTab={activeTab}
              sortOrder={sortOrder}
              showFilters={showFilters}
              onSearchChange={setSearchTerm}
              onSportChange={setSelectedSport}
              onCityChange={setSelectedCity}
              onStatusChange={setSelectedStatus}
              onTabChange={setActiveTab}
              onSortOrderChange={setSortOrder}
              onToggleFilters={() => setShowFilters(!showFilters)}
            />
          )}

          {/* Activities List */}
          {!isLoading && (
            <ActivityList
              activities={filteredActivities}
              initialLoadCount={20}
              batchSize={10}
              isOwnProfile={true}
              activeTab={activeTab}
              onManageSession={handleManageSession}
              onAccept={handleAcceptActivity}
              onRematch={handleRematch}
              onDeclineRequest={handleDeclineActivity}
              onRate={handleRateActivity}
              isAccepting={
                selectedMatch
                  ? loadingStates[selectedMatch.id]?.isAccepting
                  : false
              }
              isDeclining={
                selectedMatch
                  ? loadingStates[selectedMatch.id]?.isDeclining
                  : false
              }
              isRating={
                selectedMatch
                  ? loadingStates[selectedMatch.id]?.isRating
                  : false
              }
            />
          )}

          {/* Manage Activity Modal */}
          {selectedMatch && (
            <ManageActivityModal
              activity={selectedMatch}
              isOpen={showManageModal}
              onClose={() => {
                setShowManageModal(false);
                setSelectedMatch(null);
              }}
              onUpdate={handleUpdateActivity}
              onDelete={(reason) =>
                handleDeclineActivity(selectedMatch, reason)
              }
            />
          )}
        </ActivityErrorBoundary>
      </div>
    </DashboardLayout>
  );
};

export default Activities;
