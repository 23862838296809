import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from './ui/dialog';
import { LoginForm } from './auth/LoginForm';
import { useNavigate } from 'react-router-dom';

const STORAGE_KEY = 'playclub_credentials';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (email: string, password: string, rememberMe: boolean) => Promise<void>;
  onRequestAccess?: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  onRequestAccess
}) => {
  const [email, setEmail] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        const { email } = JSON.parse(stored);
        return email || '';
      }
    } catch (error) {
      console.warn('Error reading stored credentials:', error);
    }
    return '';
  });
  
  const [password, setPassword] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        const { password } = JSON.parse(stored);
        return password || '';
      }
    } catch (error) {
      console.warn('Error reading stored credentials:', error);
    }
    return '';
  });
  
  const [rememberMe, setRememberMe] = useState(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      return !!stored;
    } catch (error) {
      console.warn('Error reading stored credentials:', error);
      return false;
    }
  });
  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await onSuccess(email, password, rememberMe);
      
      // Store or remove credentials based on rememberMe
      if (rememberMe) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify({ email, password }));
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
      
    } catch (err) {
      let errorMessage = 'Failed to login';
      if (err instanceof Error) {
        if (err.message.includes('Invalid login credentials')) {
          errorMessage = 'Invalid email or password';
        } else if (err.message.includes('network')) {
          errorMessage = 'Network error. Please check your connection.';
        } else {
          errorMessage = err.message;
        }
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAccess = () => {
    onClose();
    onRequestAccess?.();
  };

  const handleRememberMeChange = (checked: boolean) => {
    setRememberMe(checked);
    if (!checked) {
      localStorage.removeItem(STORAGE_KEY);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[400px] bg-dark-200 text-white border-dark-300 p-6">
        <DialogHeader className="space-y-3">
          <DialogTitle className="text-2xl">Welcome Back</DialogTitle>
          <DialogDescription className="text-dark-800">
            Sign in to your playclub account to continue
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <LoginForm
            email={email}
            password={password}
            rememberMe={rememberMe}
            isLoading={isLoading}
            error={error}
            onEmailChange={setEmail}
            onPasswordChange={setPassword}
            onRememberMeChange={handleRememberMeChange}
            onSubmit={handleSubmit}
            onForgotPassword={() => console.log('Forgot password')}
          />

          <div className="text-center pt-2">
            <span className="text-dark-800 text-sm">
              Not a member yet?{' '}
              <button
                type="button"
                onClick={handleRequestAccess}
                className="text-gold-400 hover:text-gold-500 transition-colors font-medium"
              >
                Apply for Membership
              </button>
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;