/**
 * Normalizes text by converting to lowercase, removing diacritics,
 * and special characters for consistent comparison
 */
export function normalizeText(text: string): string {
  return text.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^a-z0-9\s]/g, "") // Remove special characters
    .trim();
}

/**
 * Extracts the city name from a location string
 * e.g. "Madrid, Community of Madrid, Spain" -> "Madrid"
 */
export function extractCityName(location: string): string {
  if (!location) return '';
  // Split by comma and take first part
  const parts = location.split(',');
  return parts[0].trim();
}

/**
 * Formats a location string to show "City, Country"
 * e.g. "Madrid, Community of Madrid, Spain" -> "Madrid, Spain"
 */
export function formatLocation(location: string): string {
  if (!location) return '';
  const parts = location.split(',').map(part => part.trim());
  
  // If we only have one part, return it
  if (parts.length === 1) return parts[0];
  
  // If we have multiple parts, take first (city) and last (country)
  const city = parts[0];
  const country = parts[parts.length - 1];
  
  return `${city}, ${country}`;
}
/**
 * Sanitizes user input by removing potentially harmful characters
 */
function sanitizeInput(input: string): string {
  return input.trim()
    .replace(/[<>]/g, '') // Remove potential HTML/XML injection
    .replace(/[;'"]/g, ''); // Remove potential SQL injection
}

/**
 * Formats text for display by capitalizing first letter of each word
 */
function formatDisplayText(text: string): string {
  return text
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}