import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Message, Conversation } from '../../../types/chat';
import Sidebar from './Sidebar';
import ChatWindow from '../ChatWindow';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { useProfile } from '@/hooks/useProfile';
import { useChat } from '@/hooks/useChat';

interface ChatLayoutProps {
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
  messages: Message[];
  isLoading: boolean;
  error: string | null;
  messageInput: string;
  setMessageInput: (value: string) => void;
  isTyping: boolean;
  onSendMessage: (receiverId: string) => void;
  onMarkAsRead: (messageId: string) => void;
  showMobileList: boolean;
  conversations: Conversation[];
  isChatActive?: boolean;
  createConversation?: (connectionId: string) => Promise<{ id: string } | null>;
  isSidebarOpen?: boolean;
  isInitialized: boolean;
  onChatStateChange?: (isActive: boolean) => void;
}

const ChatLayout: React.FC<ChatLayoutProps> = ({
  activeChat,
  onChatSelect,
  messages,
  isLoading,
  error,
  messageInput,
  setMessageInput,
  isTyping,
  onSendMessage,
  onMarkAsRead,
  showMobileList: propShowMobileList,
  conversations,
  isChatActive = false,
  createConversation,
  isSidebarOpen = true,
  isInitialized,
  onChatStateChange
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { profile, fetchProfile } = useProfile();
  const [showMobileList, setShowMobileList] = useState(propShowMobileList);
  const [isChatActiveState, setIsChatActiveState] = useState(isChatActive);

  // Get connected and non-connected conversations from props
  const connectedConversations = conversations.filter(conv => conv.isConnected);
  const nonConnectedConversations = conversations.filter(conv => !conv.isConnected);

  // Use activeChat from props
  const activeConversation = conversations?.find(c => c.id === activeChat);

  // Memoize sorted conversations
  const sortedConnectedConversations = React.useMemo(() => {
    return [...connectedConversations].sort((a, b) =>
      new Date(b.time).getTime() - new Date(a.time).getTime()
    );
  }, [connectedConversations]);

  const sortedNonConnectedConversations = React.useMemo(() => {
    return [...nonConnectedConversations].sort((a, b) =>
      new Date(b.time).getTime() - new Date(a.time).getTime()
    );
  }, [nonConnectedConversations]);

  // Debug logging - CRUCIAL FOR DIAGNOSIS
  useEffect(() => {
    console.log('ChatLayout - Render Info:', {
      activeChat,
      isInitialized,
      hasConversations: conversations.length > 0,
      isLoading,
      activeConversationExists: !!activeConversation
    });
  }, [activeChat, isInitialized, conversations, isLoading, activeConversation]);

  // Adjust showMobileList based on screen size when activeChat changes
  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setShowMobileList(isMobile && !activeChat);
  }, [activeChat]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      if (isMobile && !activeChat) {
        setShowMobileList(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeChat]);

  const handleChatSelect = useCallback(
    (chatId: string) => {
      onChatSelect(chatId);
      onChatStateChange?.(true);
      if (window.innerWidth < 768) {
        setShowMobileList(false);
      }
    },
    [onChatSelect, onChatStateChange]
  );

  const handleBack = useCallback(() => {
    setShowMobileList(true);
    onChatStateChange?.(false);
    document.documentElement.classList.remove('chat-active');
  }, [onChatStateChange]);

  if (error) {
    return (
      <div className="fixed inset-x-0 bottom-16 top-16 lg:left-64 flex items-center justify-center">
        <div className="text-red-400 bg-dark-200 rounded-xl p-6 border border-dark-300">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-dark-300 hover:bg-dark-400 rounded-lg transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    // Outer container: fixed full-screen on mobile, but on medium+ screens it's inset below the top nav and above the bottom nav.
    <div className={cn(
      "fixed inset-x-0 bottom-0 top-0 md:top-16 md:bottom-16 flex",
      isSidebarOpen ? "lg:left-64" : "lg:left-20"
    )}>
      {/* Left Sidebar */}
      <div
        className={cn(
          "w-full md:w-[380px] md:flex-shrink-0 flex flex-col border-r border-dark-300 overflow-y-auto",
          !showMobileList && "hidden md:flex"
        )}
      >
        <Sidebar
          activeChat={activeChat}
          onChatSelect={handleChatSelect}
          conversations={sortedConnectedConversations}
          connectedConversations={sortedConnectedConversations}
          nonConnectedConversations={sortedNonConnectedConversations}
          createConversation={createConversation}
        />
      </div>

      {/* Right Chat Area */}
      <div className={cn(
        "flex-1 overflow-y-auto",
        showMobileList ? "hidden md:block" : "",
        isChatActiveState && "fixed inset-0 md:relative md:inset-auto"
      )}>
        {activeChat ? (
          <ChatWindow
            conversation={activeConversation}
            messages={messages}
            isLoading={isLoading}
            error={error}
            messageInput={messageInput}
            setMessageInput={setMessageInput}
            isTyping={isTyping}
            onSendMessage={onSendMessage}
            onMarkAsRead={onMarkAsRead}
            onBack={handleBack}
          />
        ) : (
          <div className="h-full flex items-center justify-center text-dark-800">
            Select a conversation to start messaging
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatLayout;
