import { Activity } from '../types';

export const ACTIVITY_SHORTCUTS: Activity[] = [
  // Initial visible activities
  { name: 'Running', emoji: '🏃‍♂️' },
  { name: 'Pilates', emoji: '🧘‍♂️' },
  { name: 'Padel', emoji: '🎾' },
  { name: 'Yoga', emoji: '🧘‍♀️' },
  { name: 'Tennis', emoji: '🎾' },
  { name: 'Golf', emoji: '⛳' },
  
  // Additional activities shown when expanded
  { name: 'Swimming', emoji: '🏊‍♂️' },
  { name: 'Cycling', emoji: '🚴‍♂️' },
  { name: 'Fencing', emoji: '🤺' },
  { name: 'Football', emoji: '⚽' },
  { name: 'Basketball', emoji: '🏀' },
  { name: 'Badminton', emoji: '🏸' },
  { name: 'Table Tennis', emoji: '🏓' },
  { name: 'Volleyball', emoji: '🏐' },
  { name: 'Weight Training', emoji: '🏋️‍♀️' },
  { name: 'Pickleball', emoji: '🎾' },
  { name: 'Karate', emoji: '🥋' },
  { name: 'Taekwondo', emoji: '🥋' },
  { name: 'Aikido', emoji: '🥋' },
  { name: 'Horse Riding', emoji: '🐎' },
  { name: 'Polo', emoji: '🏇' },
  { name: 'Athletic', emoji: '🏃' },
  { name: 'Futsal', emoji: '⚽' },
  { name: 'Billiards', emoji: '🎱' },
  { name: 'Sailing', emoji: '⛵' },
  { name: 'Wakeboarding', emoji: '🏄‍♂️' },
  { name: 'Wakesurfing', emoji: '🏄‍♂️' },
  { name: 'Aerobics', emoji: '💃' },
  { name: 'Zumba', emoji: '💃' },
  { name: 'Equestrian', emoji: '🐎' },
  { name: 'Climbing', emoji: '🧗‍♂️' },
  { name: 'Aquatics', emoji: '🏊‍♂️' },
  { name: 'Cricket', emoji: '🏏' },
  { name: 'Hockey', emoji: '🏑' },
  { name: 'Spa', emoji: '💆‍♂️' },
  { name: 'Netball', emoji: '🏐' },
  { name: 'Rugby', emoji: '🏉' },
  { name: 'Softball', emoji: '🥎' },
  { name: 'Trekking', emoji: '🥾' },
  { name: 'Gymnastics', emoji: '🤸‍♀️' },
  { name: 'Surfing', emoji: '🏄‍♂️' },
  { name: 'Skiing', emoji: '⛷️' },
  { name: 'Snowboarding', emoji: '🏂' }
];