import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';
import { VenuePhoto } from '@/types/venue';

const SWIPE_THRESHOLD = 100; // increased threshold for more deliberate swipes
const WINDOW_WIDTH = typeof window !== 'undefined' ? window.innerWidth : 1000;
const SPRING_CONFIG = {
  type: "tween",
  duration: 0.3,
  ease: "easeInOut"
};

interface VenueGalleryProps {
  photos: VenuePhoto[];
  isOpen: boolean;
  onClose: () => void;
  venueName: string;
}

export const VenueGallery: React.FC<VenueGalleryProps> = ({
  photos,
  isOpen,
  onClose,
  venueName
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isGridView, setIsGridView] = useState(false);

  const handlePrevious = () => {
    setDirection(-1);
    setCurrentIndex((prev) => (prev === 0 ? photos.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prev) => (prev === photos.length - 1 ? 0 : prev + 1));
  };

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? WINDOW_WIDTH : -WINDOW_WIDTH,
      opacity: 0.3,
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? WINDOW_WIDTH : -WINDOW_WIDTH,
      opacity: 0.3,
    }),
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-5xl p-0 bg-dark-200 border-dark-300 max-h-[90vh] flex flex-col">
        <DialogTitle className="sr-only">
          {isGridView ? 'Photo Grid' : 'Photo Gallery'} - {venueName}
        </DialogTitle>
        
        <div className="relative flex flex-col flex-1 overflow-hidden">
          {/* Close button */}
          <button
            onClick={onClose}
            className="fixed top-4 right-4 z-50 p-2 rounded-full !bg-black/40 !hover:bg-black/60 dark:!bg-black/40 dark:!hover:bg-black/60 shadow-lg backdrop-blur-sm transition-all duration-200"
            aria-label="Close gallery"
          >
            <X className="h-5 w-5 !text-white dark:!text-white" />
          </button>

          {/* View toggle */}
          <div className="fixed top-4 left-4 z-50">
            <button
              onClick={() => setIsGridView(!isGridView)}
              className="p-2 rounded-full !bg-black/40 !hover:bg-black/60 dark:!bg-black/40 dark:!hover:bg-black/60 shadow-lg backdrop-blur-sm transition-all duration-200"
            >
              <svg viewBox="0 0 24 24" className="h-5 w-5 !text-white dark:!text-white">
                {isGridView ? (
                  <path
                    fill="currentColor"
                    d="M3 5v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2zm12 14h-4v-4h4v4zm0-6h-4V9h4v4zm0-6h-4V3h4v4zm6 12h-4v-4h4v4zm0-6h-4V9h4v4zm0-6h-4V3h4v4z"
                  />
                ) : (
                  <path
                    fill="currentColor"
                    d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"
                  />
                )}
              </svg>
            </button>
          </div>

          {isGridView ? (
            // Grid view
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 p-2 overflow-y-auto">
              {photos.map((photo, index) => (
                <motion.button
                  key={index}
                  onClick={() => {
                    setCurrentIndex(index);
                    setIsGridView(false);
                  }}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                  className="aspect-square relative overflow-hidden group"
                >
                  <img
                    src={photo.photo_reference || photo.url}
                    alt={`${venueName} photo ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                  <motion.div 
                    className={cn(
                      "absolute inset-0 bg-black/30",
                      "transition-opacity duration-200"
                    )}
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                  />
                </motion.button>
              ))}
            </div>
          ) : (
            // Single photo view
            <div 
              className="relative flex-1 overflow-hidden bg-black flex items-center justify-center"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'ArrowLeft') handlePrevious();
                if (e.key === 'ArrowRight') handleNext();
              }}
              role="button"
              aria-label="Gallery image viewer"
            >
              <AnimatePresence initial={false} custom={direction}>
                <motion.div
                  key={currentIndex}
                  custom={direction}
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={0.3}
                  onDragEnd={(e, info) => {
                    const delta = info.offset.x;
                    if (Math.abs(delta) > SWIPE_THRESHOLD) {
                      if (delta > 0) {
                        handlePrevious();
                      } else {
                        handleNext();
                      }
                    }
                  }}
                  variants={variants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={SPRING_CONFIG}
                  className="w-full h-full flex items-center justify-center"
                >
                  <motion.img
                    src={photos[currentIndex].photo_reference || photos[currentIndex].url}
                    alt={`${venueName} photo ${currentIndex + 1}`}
                    className="max-w-full max-h-[80vh] object-contain"
                    draggable={false}
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    exit={{ scale: 0.9 }}
                    transition={SPRING_CONFIG}
                  />
                </motion.div>
              </AnimatePresence>

              {/* Navigation buttons */}
              {photos.length > 1 && (
                <>
                  <button
                    onClick={handlePrevious}
                    className={cn(
                      "absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full z-10 !bg-black/40 !hover:bg-black/60 dark:!bg-black/40 dark:!hover:bg-black/60",
                      "shadow-lg backdrop-blur-sm transition-opacity duration-200"
                    )}
                    aria-label="Previous photo"
                  >
                    <ChevronLeft className="h-6 w-6 !text-white dark:!text-white" />
                  </button>
                  <button
                    onClick={handleNext}
                    className={cn(
                      "absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full z-10 !bg-black/40 !hover:bg-black/60 dark:!bg-black/40 dark:!hover:bg-black/60",
                      "shadow-lg backdrop-blur-sm transition-opacity duration-200"
                    )}
                    aria-label="Next photo"
                  >
                    <ChevronRight className="h-6 w-6 !text-white dark:!text-white" />
                  </button>
                </>
              )}

              {/* Progress indicator */}
              <div className="fixed bottom-4 left-1/2 -translate-x-1/2 flex items-center gap-1">
                {photos.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentIndex(index)}
                    className={cn(
                      "w-2 h-2 rounded-full transition-all duration-200",
                      index === currentIndex ? "bg-white" : "bg-white/50"
                    )}
                    aria-label={`Go to photo ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
