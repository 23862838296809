import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { motion } from 'framer-motion';
import { Info, Plus } from 'lucide-react';
import { OnboardingStepProps } from '../types';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../ui/select';
import { Popover, PopoverTrigger, PopoverContent } from '../../ui/popover';
import { CitySearchCombobox } from '../../ui/combobox/CitySearchCombobox';
import { ProfileImageUpload } from './profile/components/ProfileImageUpload';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FloatingNavigation from '../navigation/FloatingNavigation';
import { cn } from '@/lib/utils';
import { parsePhoneNumberFromString, isValidPhoneNumber } from 'libphonenumber-js';
import { supabase } from '@/lib/supabase';

interface ProfileStrength {
  score: number;
  feedback: { field: string; status: 'complete' | 'incomplete' }[];
}

const ProfileStep: React.FC<OnboardingStepProps> = ({ 
  data,
  onUpdate,
  onNext,
  onBack
}) => {
  const [formData, setFormData] = useState({
    fullName: data?.fullName || '',
    email: data?.email || '',
    phoneNumber: data?.phoneNumber || '',
    company: data?.company || '',
    jobTitle: data?.jobTitle || '',
    city: data?.city || '',
    club_id: data?.club_id || null,
    custom_club: data?.custom_club || '',
    club_name: data?.club || '', // For display purposes
    gender: data?.gender || '',
    profilePic: data?.profilePic || ''
  });

  // Now clubs will be fetched from the backend as the user types
  const [clubs, setClubs] = useState<Array<{ club_id: number; club_name: string }>>([]);
  const [showClubDropdown, setShowClubDropdown] = useState(false);
  const [profileStrength, setProfileStrength] = useState<ProfileStrength>({
    score: 0,
    feedback: []
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // Remove fetching all clubs on mount.
  // Instead, define a function to fetch clubs from the backend using the user's input.
  const fetchFilteredClubs = async (searchTerm: string) => {
    if (!searchTerm.trim()) {
      setClubs([]);
      return;
    }
    const { data: clubsData, error } = await supabase
      .from('clubs')
      .select('club_id, club_name')
      .ilike('club_name', `%${searchTerm}%`)
      .order('club_name')
      .limit(100);
    if (error) {
      console.error('Error fetching clubs:', error);
    } else {
      setClubs(clubsData || []);
    }
  };

  // Create a debounced version of the fetch function.
  const debouncedFetchClubs = useCallback(
    debounce((searchTerm: string) => {
      fetchFilteredClubs(searchTerm);
    }, 300),
    []
  );

  // Calculate profile strength
  useEffect(() => {
    const feedback = [
      { field: 'Profile Picture', status: formData.profilePic ? 'complete' : 'incomplete' },
      { field: 'Full Name', status: formData.fullName ? 'complete' : 'incomplete' },
      { field: 'Email', status: formData.email ? 'complete' : 'incomplete' },
      { field: 'City', status: formData.city ? 'complete' : 'incomplete' },
      { field: 'Phone', status: formData.phoneNumber ? 'complete' : 'incomplete' },
      { field: 'Company', status: formData.company ? 'complete' : 'incomplete' }
    ] as const;

    const completedFields = feedback.filter(f => f.status === 'complete').length;
    const score = Math.round((completedFields / feedback.length) * 100);

    setProfileStrength({ score, feedback });
  }, [formData]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      setErrors(prev => ({ ...prev, image: 'Image must be less than 2MB' }));
      return;
    }

    try {
      setImagePreview(URL.createObjectURL(file));
      const { data: uploadData } = await supabase.storage
        .from('avatars')
        .upload(`${Date.now()}-${file.name}`, file);

      if (uploadData?.path) {
        const { data: { publicUrl } } = supabase.storage
          .from('avatars')
          .getPublicUrl(uploadData.path);

        setFormData(prev => ({ ...prev, profilePic: publicUrl }));
        onUpdate?.({ ...data, profilePic: publicUrl });
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      setErrors(prev => ({ ...prev, image: 'Failed to upload image' }));
    }
  };

  const validateField = (name: string, value: string) => {
    switch (name) {
      case 'profilePic':
        return !value ? 'Profile picture is required' : '';
      case 'fullName':
        return !value?.trim() ? 'Full name is required' : '';
      case 'email':
        return !value?.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) 
          ? 'Valid email is required' : '';
      case 'phoneNumber':
        // Only validate if there's a value to check
        if (!value) return 'Phone number is required';
        // Consider valid if longer than minimum length
        if (value.length < 6) return 'Invalid phone number';
        return '';
      default:
        return '';
    }
  };

  // We keep handleFieldChange for setting values and for club selection when a user clicks an option.
  const handleFieldChange = (name: string, value: string) => {
    const error = validateField(name, value);
    setErrors(prev => ({ ...prev, [name]: error }));
    
    if (name === 'club') {
      const selectedClub = clubs.find(club => 
        club.club_name.toLowerCase() === value.toLowerCase()
      );
      
      if (selectedClub) {
        // Club was selected from dropdown
        setFormData(prev => ({
          ...prev,
          club_id: selectedClub.club_id,
          club_name: selectedClub.club_name,
          custom_club: null
        }));
        onUpdate?.({ 
          ...data, 
          club_id: selectedClub.club_id,
          club_name: selectedClub.club_name,
          custom_club: null
        });
      } else {
        // Custom club entry
        setFormData(prev => ({
          ...prev,
          club_id: null,
          club_name: value,
          custom_club: value
        }));
        onUpdate?.({ 
          ...data, 
          club_id: null,
          club_name: value,
          custom_club: value
        });
      }
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
      onUpdate?.({ ...data, [name]: value });
    }
  };

  return (
    <div className="h-full flex flex-col">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className={cn(
          "flex flex-col mx-auto w-[90%] pt-4 sm:pt-6 pb-32 overflow-y-auto relative",
          "max-w-[500px]"
        )}
      >
        {/* Profile Image Upload */}
        <div className="mb-8 text-center">
          <ProfileImageUpload
            value={formData.profilePic}
            onChange={(value) => {
              handleFieldChange('profilePic', value);
              if (!value) {
                setErrors(prev => ({ ...prev, profilePic: 'Profile picture is required' }));
              } else {
                setErrors(prev => {
                  const { profilePic, ...rest } = prev;
                  return rest;
                });
              }
            }}
            className="w-28 h-28 mx-auto"
            error={errors.profilePic}
          />
          {!formData.profilePic && (
            <p className={cn(
              "mt-4 py-2 text-sm",
              errors.profilePic ? "text-red-500" : "text-dark-800"
            )}>
              Profile picture is required
            </p>
          )}
        </div>
        <form className="space-y-6 overflow-y-auto">
          {/* Full Name */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Full Name
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Your full name helps other members recognize you
                </PopoverContent>
              </Popover>
            </Label>
            <Input
              value={formData.fullName}
              onChange={(e) => handleFieldChange('fullName', e.target.value)}
              className={cn(
                "bg-dark-300 border-dark-400 text-white",
                errors.fullName && "border-red-500"
              )}
              placeholder="Enter your full name"
            />
            {errors.fullName && (
              <p className="mt-1 text-sm text-red-500">{errors.fullName}</p>
            )}
          </div>

          {/* Email */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Email
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Your email is used for account verification and communications
                </PopoverContent>
              </Popover>
            </Label>
            <Input
              type="email"
              value={formData.email}
              onChange={(e) => handleFieldChange('email', e.target.value)}
              className={cn(
                "bg-dark-300 border-dark-400 text-white",
                errors.email && "border-red-500"
              )}
              placeholder="Enter your email"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          {/* Phone Number */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Phone Number
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Your phone number is required for account security and member verification
                </PopoverContent>
              </Popover>
            </Label>
            <PhoneInput
              country={'us'}
              value={formData.phoneNumber}
              onChange={(phoneNumber) => handleFieldChange('phoneNumber', phoneNumber)}
              inputClass={cn(
                "!w-full !h-11 !bg-dark-300 !text-white !border-dark-400",
                "!rounded-lg !pl-12 !pr-4",
                errors.phoneNumber && "!border-red-500"
              )}
              containerClass="!bg-transparent"
              buttonClass={cn(
                "!bg-dark-300 !border-dark-400 !rounded-l-lg",
                "!hover:bg-dark-400 !transition-colors"
              )}
              dropdownClass="!bg-dark-300 !text-white !text-opacity-90"
              searchClass="!bg-dark-300 !text-white !text-opacity-90 !border-dark-400"
              countryCodeEditable={false}
              enableSearch
              disableSearchIcon
            />
          </div>

          {/* City */}
          <div>
            <Label className="flex items-center text-white mb-2">
              City
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Set your current city to let others know where you are. Update it anytime you travel so members can connect with you in your new location.
                </PopoverContent>
              </Popover>
            </Label>
            <CitySearchCombobox
              value={formData.city}
              onChange={(city) => handleFieldChange('city', city)}
              placeholder="Search for your city..."
              className="[&_input]:bg-dark-300 [&_input]:border-dark-400 [&_input]:text-white"
            />
          </div>

          {/* Club Selection - Always Visible */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Private Members Club (Optional)
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300">
                  Select an existing members club or add a new one by typing
                </PopoverContent>
              </Popover>
            </Label>
            <div className="relative">
              <Input
                type="text"
                value={formData.club_name}
                onChange={(e) => {
                  const value = e.target.value;
                  // Update formData and trigger backend search
                  setFormData(prev => ({ ...prev, club_name: value }));
                  debouncedFetchClubs(value);
                  setShowClubDropdown(true);
                }}
                onFocus={() => setShowClubDropdown(true)}
                onBlur={() => {
                  // Delay hiding dropdown to allow click events to register
                  setTimeout(() => setShowClubDropdown(false), 200);
                }}
                className="bg-dark-300 border-dark-400 text-white"
                placeholder="Enter or select your club"
                autoComplete="off"
              />
              {showClubDropdown && clubs.length > 0 && (
                <div className={cn(
                  "absolute z-10 w-full mt-1 py-1",
                  "bg-dark-300 border border-dark-400 rounded-lg",
                  "max-h-48 overflow-y-auto shadow-lg"
                )}>
                  {clubs.map((club) => (
                    <button
                      key={club.club_id}
                      type="button"
                      onClick={() => {
                        handleFieldChange('club', club.club_name);
                        setShowClubDropdown(false);
                      }}
                      className={cn(
                        "w-full px-3 py-2 text-left",
                        "text-white hover:bg-dark-400",
                        "transition-colors"
                      )}
                    >
                      {club.club_name}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Gender */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Gender
              <span className="text-red-500 ml-1">*</span>
            </Label>
            <Select
              value={formData.gender}
              onValueChange={(value) => handleFieldChange('gender', value)}
            >
              <SelectTrigger className="bg-dark-300 border-dark-400 text-white">
                <SelectValue placeholder="Select gender" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
                <SelectItem value="rather_not_say">Rather not say</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Company */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Company
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Add your company you currently work for
                </PopoverContent>
              </Popover>
            </Label>
            <Input
              value={formData.company}
              onChange={(e) => handleFieldChange('company', e.target.value)}
              className="bg-dark-300 border-dark-400 text-white"
              placeholder="Enter your company name"
            />
          </div>

          {/* Role */}
          <div>
            <Label className="flex items-center text-white mb-2">
              Role
              <span className="text-red-500 ml-1">*</span>
              <Popover>
                <PopoverTrigger asChild>
                  <Info className="h-4 w-4 ml-2 text-dark-800 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="bg-dark-200 text-white border-dark-300 max-w-[250px] text-wrap break-words">
                  Add your current role at the company
                </PopoverContent>
              </Popover>
            </Label>
            <Input
              value={formData.jobTitle}
              onChange={(e) => handleFieldChange('jobTitle', e.target.value)}
              className="bg-dark-300 border-dark-400 text-white"
              placeholder="Enter your role"
            />
          </div>
        </form>
      </motion.div>

      <FloatingNavigation 
        onBack={onBack}
        onNext={onNext}
        isNextDisabled={
          !formData.profilePic ||
          !formData.fullName?.trim() ||
          !formData.email?.trim() ||
          !formData.city?.trim() ||
          !formData.phoneNumber?.trim() ||
          !formData.company?.trim() ||
          !formData.jobTitle?.trim() ||
          !formData.gender ||
          Object.values(errors).some(error => error)
        }
      />
    </div>
  );
};

export default ProfileStep;
