import React from 'react';
import { Settings2 } from 'lucide-react';

import { cn } from '@/lib/utils';

interface ManageButtonProps {
  onClick: () => void;
  className?: string;
}

const ManageButton: React.FC<ManageButtonProps> = ({ onClick, className }) => (
  <button
    onClick={onClick}
    className={cn(
      "flex items-center justify-center gap-2",
      "w-9 h-9 sm:h-10 sm:px-4",
      "bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors",
      className
    )}
  >
    <Settings2 className="h-4 w-4" />
    <span className="hidden sm:inline">Manage</span>
  </button>
);

export default ManageButton;