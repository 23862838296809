import React, { useRef, useEffect, useState } from 'react';
import ActivityCardList from './ActivityCardList';
import { Activity } from '../../types/activity';
import NewRatingModal from './modals/NewRatingModal';
import { Loader2 } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useAuth } from '@/contexts/AuthContext';
import { useActivityLoadingStore } from '../../store/activityLoadingStore';
import { supabase } from '@/lib/supabase';

interface ActivityListProps {
  activities: Activity[];
  isOwnProfile: boolean;
  initialLoadCount?: number;
  batchSize?: number;
  activeTab?: 'current' | 'past';
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onAccept: (activity: Activity) => void;
  onDeclineRequest: (activity: Activity, reason?: string) => void;
  onRate?: (activity: Activity) => void;
}

const ActivityList: React.FC<ActivityListProps> = ({
  activities,
  isOwnProfile,
  initialLoadCount = 20,
  batchSize = 10,
  activeTab = 'current',
  onManageSession,
  onRematch,
  onAccept,
  onDeclineRequest,
  onRate = () => {}
}) => {
  const { user } = useAuth();
  const { loadingStates } = useActivityLoadingStore();
  const [displayedActivities, setDisplayedActivities] = useState<Activity[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [selectedActivity, setSelectedActivity] = useState<Activity | null>(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [actionStates, setActionStates] = useState<Record<string, { 
    isAccepting: boolean;
    isDeclining: boolean;
  }>>({});
  const loaderRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout>();

  // Initialize with first batch
  useEffect(() => {
    if (!user) {
      setError('Authentication required');
      return;
    }
    const initial = activities.slice(0, initialLoadCount);
    setDisplayedActivities(initial);
    setHasMore(initial.length < activities.length);
  }, [activities, initialLoadCount, user]);

  // Setup intersection observer for infinite scroll
  useEffect(() => {
    if (!user) return;

    const cleanup = () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
    };

    cleanup();

    observerRef.current = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore) {
          setIsLoading(true);
          // Use functional update so we always have the latest state
          loadingTimeoutRef.current = setTimeout(() => {
            setDisplayedActivities((prevActivities) => {
              const currentLength = prevActivities.length;
              const nextBatch = activities.slice(currentLength, currentLength + batchSize);
              if (nextBatch.length > 0) {
                const newActivities = [...prevActivities, ...nextBatch];
                setHasMore(newActivities.length < activities.length);
                return newActivities;
              } else {
                setHasMore(false);
                return prevActivities;
              }
            });
            setIsLoading(false);
          }, 300);
        }
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1
      }
    );

    if (loaderRef.current) {
      observerRef.current.observe(loaderRef.current);
    }

    return cleanup;
  }, [activities, batchSize, hasMore, user]);

  const handleRateActivity = async (activity: Activity, ratings: Record<string, number>, feedback: string) => {
    if (!activity || activity.status !== 'played' || activity.isRated) {
      return;
    }

    const overallRating = Number((
      (ratings.rating_punctuality +
       ratings.rating_sportsmanship +
       ratings.rating_skill_match +
       ratings.rating_communication) / 4
    ).toFixed(2));

    const { error } = await supabase
        .from('activities')
        .update({
            rating_punctuality: ratings.rating_punctuality,
            rating_sportsmanship: ratings.rating_sportsmanship,
            rating_skill_match: ratings.rating_skill_match,
            rating_communication: ratings.rating_communication,
            rating_overall: overallRating,
            rating_feedback: feedback,
            rating_categories: {
                punctuality: ratings.rating_punctuality >= 4,
                sportsmanship: ratings.rating_sportsmanship >= 4,
                skill_match: ratings.rating_skill_match >= 4,
                communication: ratings.rating_communication >= 4
            },
            is_rated: true,
            rating_timestamp: new Date().toISOString()
        })
        .eq('id', activity.id);

    if (error) {
      console.error('Error updating rating:', error);
    } else {
      setDisplayedActivities(prevActivities =>
        prevActivities.map(prevActivity =>
          prevActivity.id === activity.id
            ? {
                ...prevActivity,
                isRated: true,
                rating_overall: overallRating,
                rating_punctuality: ratings.rating_punctuality,
                rating_sportsmanship: ratings.rating_sportsmanship,
                rating_skill_match: ratings.rating_skill_match,
                rating_communication: ratings.rating_communication,
                rating_feedback: feedback,
                rating_categories: {
                  punctuality: ratings.rating_punctuality >= 4,
                  sportsmanship: ratings.rating_sportsmanship >= 4,
                  skill_match: ratings.rating_skill_match >= 4,
                  communication: ratings.rating_communication >= 4
                }
              }
            : prevActivity
        )
      );
      console.log('Rating submitted successfully for activity:', activity.id);
    }
  };

  const handleRatingSubmit = async (ratings: Record<string, number>, feedback: string) => {
    if (!selectedActivity) return;
    setIsSubmitting(true);
    try {
      await handleRateActivity(selectedActivity, ratings, feedback);
      setShowRatingModal(false);
      setSelectedActivity(null);
    } catch (error) {
      console.error('Error submitting rating:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAccept = async (activity: Activity) => {
    setActionStates(prev => ({
      ...prev,
      [activity.id]: { ...prev[activity.id], isAccepting: true }
    }));
    
    try {
      await onAccept(activity);
    } finally {
      setActionStates(prev => ({
        ...prev,
        [activity.id]: { ...prev[activity.id], isAccepting: false }
      }));
    }
  };

  const handleDecline = async (activity: Activity, reason?: string) => {
    setActionStates(prev => ({
      ...prev,
      [activity.id]: { ...prev[activity.id], isDeclining: true }
    }));
    
    try {
      await onDeclineRequest(activity, reason);
    } finally {
      setActionStates(prev => ({
        ...prev,
        [activity.id]: { ...prev[activity.id], isDeclining: false }
      }));
    }
  };

  if (error) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <p className="text-dark-800">{error}</p>
      </div>
    );
  }

  if (activities.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl border border-dark-400">
        <p className="text-dark-800">No activities found</p>
      </div>
    );
  }

  return (
    <div className="space-y-4 relative">
      {displayedActivities.map((activity) => {
        const canRate = activity.status === 'played' && 
                        !activity.isRated && 
                        activity.rating_overall === undefined &&
                        activity.rating_punctuality === undefined;
        
        return (
          <ActivityCardList
            key={`list-${activity.id}`}
            activities={[activity]}
            isOwnProfile={isOwnProfile}
            isPastActivity={activeTab === 'past'}
            onManageSession={onManageSession}
            onRematch={onRematch}
            onAccept={() => handleAccept(activity)}
            onDeclineRequest={(reason) => handleDecline(activity, reason)}
            onRate={canRate ? (activity) => {
              setSelectedActivity(activity);
              setShowRatingModal(true);
            } : undefined}
            isAccepting={loadingStates[activity.id]?.isAccepting}
            isDeclining={loadingStates[activity.id]?.isDeclining}
            isRating={loadingStates[activity.id]?.isRating}
          />
        );
      })}
      
      {/* Loader / End message */}
      {hasMore ? (
        <div 
          ref={loaderRef}
          className={cn(
            "flex justify-center py-4",
            "transition-opacity duration-300",
            isLoading ? "opacity-100" : "opacity-0"
          )}
        >
          <Loader2 className="h-6 w-6 text-gold-400 animate-spin" />
        </div>
      ) : (
        <div className="text-center text-dark-800">
          No more activities to load
        </div>
      )}
      
      {selectedActivity && (
        <NewRatingModal
          isOpen={showRatingModal}
          onClose={() => {
            setShowRatingModal(false);
            setSelectedActivity(null);
          }}
          onSubmit={handleRatingSubmit}
          playerName={selectedActivity.opponent.name}
          venueName={selectedActivity.venue.name}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default ActivityList;
