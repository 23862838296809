export const PEXELS_CONFIG = {
  CITYSCAPE_KEYWORDS: [
    'city panorama',
    'city skyline',
    'city landmarks',
    'city architecture',
    'urban landscape',
    'downtown',
    'aerial city'
  ].join(' '),

  EXCLUDE_KEYWORDS: [
    'person',
    'people',
    'crowd',
    'portrait',
    'selfie',
    'interior',
    'room',
    'food',
    'car',
    'pet'
  ].join(' -'),

  NUM_IMAGES: 5,
  RESULTS_PER_PAGE: 30, // Increased to get more options

  // Unsplash fallback URLs for major cities
  CITY_FALLBACKS: {
    'dubai': 'https://images.unsplash.com/photo-1512453979798-5ea266f8880c?auto=format&fit=crop&w=1600&q=80',
    'singapore': 'https://images.unsplash.com/photo-1525625293386-3f8f99389edd?auto=format&fit=crop&w=1600&q=80',
    'london': 'https://images.unsplash.com/photo-1513635269975-59663e0ac1ad?auto=format&fit=crop&w=1600&q=80',
    'paris': 'https://images.unsplash.com/photo-1502602898657-3e91760cbb34?auto=format&fit=crop&w=1600&q=80',
    'new york': 'https://images.unsplash.com/photo-1496442226666-8d4d0e62e6e9?auto=format&fit=crop&w=1600&q=80',
    'tokyo': 'https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?auto=format&fit=crop&w=1600&q=80',
    'hong kong': 'https://images.unsplash.com/photo-1536599018102-9f803c140fc1?auto=format&fit=crop&w=1600&q=80',
    'shanghai': 'https://images.unsplash.com/photo-1545893835-abaa50cbe628?auto=format&fit=crop&w=1600&q=80',
    'luxembourg': 'https://images.unsplash.com/photo-1587844999824-8aea7fc75a4a?auto=format&fit=crop&w=1600&q=80'
  },

  FALLBACK_IMAGES: [
    'https://images.pexels.com/photos/2346216/pexels-photo-2346216.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/1519088/pexels-photo-1519088.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/3052361/pexels-photo-3052361.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/1538177/pexels-photo-1538177.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/1434580/pexels-photo-1434580.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/466685/pexels-photo-466685.jpeg?auto=compress&cs=tinysrgb&w=1600',
    'https://images.pexels.com/photos/2129796/pexels-photo-2129796.jpeg?auto=compress&cs=tinysrgb&w=1600'
  ]
} as const;