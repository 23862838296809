import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Progress } from '../ui/progress';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { TOTAL_ONBOARDING_STEPS, ONBOARDING_STEPS } from './constants';
import { OnboardingData } from './types';
import { initialOnboardingData } from './constants/initial-data';
import WelcomeStep from './steps/WelcomeStep';
import ProfileStep from './steps/ProfileStep';
import SportsSelectionStep from './steps/SportsSelectionStep';
import PasswordStep from './steps/PasswordStep';
import CompletionStep from './steps/CompletionStep';
import { signUpWithEmail } from '../../lib/supabase/auth';
import { getSports, insertUserSports } from '../../lib/supabase/sports';
import { supabase } from '../../lib/supabase';
import { cn } from '../../lib/utils';

interface OnboardingFlowProps {
  onComplete: () => void;
  inviteToken?: string;
}

function OnboardingFlow({
  onComplete,
  inviteToken,
}: OnboardingFlowProps) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(ONBOARDING_STEPS.WELCOME);
  const [inviterInfo, setInviterInfo] = useState<{
    inviterId: string;
    inviterName: string;
  } | null>(null);
  const [formData, setFormData] = useState<OnboardingData>(
    initialOnboardingData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isPasswordStepMounted, setIsPasswordStepMounted] = useState(false);
  const progress = (currentStep / TOTAL_ONBOARDING_STEPS) * 100;

  // Get invite email from URL params
  const inviteEmail = searchParams.get('email')?.split('#')[0];

  // Initialize form data with either invite email or authenticated user's email
  useEffect(() => {
    if (inviteEmail) {
      const email = decodeURIComponent(inviteEmail).trim();
      setFormData(prev => ({ ...prev, email }));
      
      // Fetch inviter info
      const fetchInviter = async () => {
        try {
          const { data, error } = await supabase
            .from('user_invites')
            .select('inviter_id, inviter_full_name')
            .eq('invitee_email', email)
            .single();

          if (error) throw error;
          if (data) {
            setInviterInfo({
              inviterId: data.inviter_id,
              inviterName: data.inviter_full_name
            });
          }
        } catch (error) {
          console.error('Error fetching inviter:', error);
        }
      };
      
      fetchInviter();
    } else if (user?.email) {
      setFormData(prev => ({ ...prev, email: user.email }));
    }
  }, [inviteEmail, user]);

  // Redirect if no valid access
  useEffect(() => {
    if (!inviteEmail) {
      navigate('/', { replace: true });
    }
  }, [inviteEmail, user, navigate]);

  useEffect(() => {
    console.log('OnboardingFlow State:', {
      currentStep,
      formData,
      isSubmitting,
      submitError,
      progress,
      isPasswordStepMounted,
    });
  }, [
    currentStep,
    formData,
    isSubmitting,
    submitError,
    progress,
    isPasswordStepMounted,
  ]);

  useEffect(() => {
    setIsPasswordStepMounted(currentStep === ONBOARDING_STEPS.PASSWORD);
  }, [currentStep]);

  const handlePasswordSubmit = async (password: string) => {
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      console.log('Starting signup process with:', {
        email: formData.email,
        formData: { ...formData, password: '***' }
      });

      //console.log('OnbvoardingFlow Password: ', password);
      
      // Call signUpWithEmail which now handles everything
      await signUpWithEmail(formData.email, password, formData, inviterInfo?.inviterId);
      
      console.log('✅ Signup successful');
      setCurrentStep(ONBOARDING_STEPS.COMPLETION);

    } catch (err) {
      console.error('Sign up error:', err);
      setSubmitError(
        err instanceof Error ? err.message : 'Failed to create account'
      );
    } finally {
      setIsSubmitting(false);
    }
  };


  const handleNext = () => {
    console.log('Moving to next step:', currentStep + 1);
    if (currentStep < TOTAL_ONBOARDING_STEPS) {
      setCurrentStep(currentStep + 1);
    } else {
      navigate('/dashboard');
    }
  };

  const handleBack = () => {
    console.log('Moving to previous step:', currentStep - 1);
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const updateFormData = (data: Partial<OnboardingData>) => {
    console.log('Updating form data:', data);
    setFormData({ ...formData, ...data });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-dark-50 to-dark-100 flex flex-col">
      {/* Floating Progress Bar */}
      {currentStep > 1 && (
        <motion.div
          className={cn(
            "fixed top-0 left-0 right-0 z-50 px-4 py-4",
            "bg-dark-50/80 backdrop-blur-sm border-b border-dark-300"
          )}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="max-w-3xl mx-auto">
            <Progress
              value={progress}
              className={cn(
                "h-1.5 sm:h-2 md:h-3",
                "bg-dark-300/50 rounded-full overflow-hidden"
              )}
            />
          </div>
        </motion.div>
      )}

      {/* Main Content */}
      <div className="w-[95%] max-w-3xl mx-auto pt-16 flex-1">
        <motion.div
          key={currentStep}
          className={cn(
            'flex flex-col relative',
            'h-full w-full',
            'bg-dark-200/95 rounded-xl border border-dark-300 shadow-xl',
            'overflow-hidden',
            'shadow-xl shadow-dark-900/10'
          )}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{
            duration: 0.3,
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
        >
          {currentStep === ONBOARDING_STEPS.WELCOME && (
            <WelcomeStep 
              onNext={handleNext}
              inviterName={inviterInfo?.inviterName}
            />
          )}
          {currentStep === ONBOARDING_STEPS.PROFILE && (
            <ProfileStep
              data={formData}
              onUpdate={updateFormData}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {currentStep === ONBOARDING_STEPS.SPORTS && (
            <SportsSelectionStep
              data={formData}
              onUpdate={updateFormData}
              onNext={handleNext}
              onBack={handleBack}
            />
          )}
          {currentStep === ONBOARDING_STEPS.PASSWORD && (
            <div className="flex-1">
              <PasswordStep
                isSubmitting={isSubmitting}
                error={submitError}
                onNext={handlePasswordSubmit}
                onBack={handleBack}
              />
            </div>
          )}
          {currentStep === ONBOARDING_STEPS.COMPLETION && (
            <CompletionStep onComplete={onComplete} />
          )}
        </motion.div>
      </div>
    </div>
  );
}

export default OnboardingFlow;