import { useState, useEffect } from "react";
import { VenueApiResult } from "../../../../types/venue";
import { getUserLocation, DEFAULT_LOCATION } from "../../../../lib/utils/location";
import { calculateDistance } from "../../../../lib/utils/coordinates";
import { useDebounce } from "use-debounce";

export function useVenueDistance(
  venues: VenueApiResult[],
  referencePoint: { lat: number; lng: number } | null = null
) {
  const [distances, setDistances] = useState<Record<string, number>>({});
  const [debouncedVenues] = useDebounce(venues, 300);

  useEffect(() => {
    let mounted = true;

    const calculateDistances = async () => {
      try {
        let userLat, userLng;

        if (referencePoint) {
          userLat = referencePoint.lat;
          userLng = referencePoint.lng;
        } else {
          const location = await getUserLocation();
          userLat = location.latitude;
          userLng = location.longitude;
        }

        if (!mounted) return;

        const newDistances: Record<string, number> = {};
        debouncedVenues.forEach((venue) => {
          if (venue.coordinates?.lat && venue.coordinates?.lng) {
            const distance = calculateDistance(userLat, userLng, venue.coordinates.lat, venue.coordinates.lng);
            newDistances[venue.id] = distance;
          } else {
            newDistances[venue.id] = -1;
          }
        });

        setDistances(newDistances);
      } catch (error) {
        console.error("Error calculating distances:", error);
      }
    };

    if (debouncedVenues.length > 0) {
      calculateDistances();
    }

    return () => {
      mounted = false;
    };
  }, [debouncedVenues, referencePoint]);

  return distances;
}
