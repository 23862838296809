
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Users2, Briefcase } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { cn } from '@/lib/utils';

interface CityMembersListProps {
  city: string;
}

const CityMembersList: React.FC<CityMembersListProps> = ({ city }) => {
  const [members, setMembers] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  useEffect(() => {
    const fetchCityMembers = async () => {
      try {
        setIsLoading(true);
        setError(null);

        if (!user) {
          throw new Error('Not authenticated');
        }

        // Fetch users in the city
        const { data, error } = await supabase
          .from('users')
          .select(`
            id,
            auth_id,
            full_name,
            avatar_url,
            city,
            company,
            job_type,
            user_sports (
              sports (
                name,
                emoji
              )
            )
          `)
          .neq('auth_id', user.id)
          .ilike('city', `%${city}%`);

        if (error) throw error;
        // Transform data to match Player type
        const transformedPlayers: Player[] = data.map(member => {
          // Format sports with emojis
          const sports = member.user_sports?.map(us => 
            `${us.sports.emoji} ${us.sports.name}`
          ) || [];
        });

        // Check connection status
        // Fetch users in the city
        const { data: cityMembers, error: membersError } = await supabase
          .from('users')
          .select(`
            id,
            auth_id,
            full_name,
            avatar_url,
            city,
            job_type,
            company,
            user_sports (
              sports (
                name,
                emoji
              )
            )
          `)
          .neq('auth_id', user.id)
          .ilike('city', `%${city}%`);

        if (membersError) throw membersError;
        setMembers(cityMembers || []);
      } catch (error) {
        console.error('Error fetching city members:', error);
        setError(error instanceof Error ? error.message : 'Failed to load members');
      } finally {
        setIsLoading(false);
      }
    };
    fetchCityMembers();
  }, [city, user?.id]);

  if (isLoading) {
    return <p className="text-dark-800">Loading members...</p>;
  }

  if (error) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl">
        <p className="text-red-400 mb-2">{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="text-sm underline hover:text-red-400"
        >
          Try Again
        </button>
      </div>
    );
  }

  if (members.length === 0) {
    return (
      <div className="text-center py-12 bg-dark-300 rounded-xl">
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Players Found</h3>
        <p className="text-dark-800">
          No members found in {city}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 max-w-3xl mx-auto">
      {members.map((member) => (
        <div
          key={member.id}
          className={cn(
            "bg-dark-200 rounded-2xl overflow-hidden border border-dark-300",
            "hover:border-gold-400 transition-all duration-300",
            "shadow-lg hover:shadow-xl transform hover:scale-[1.01]"
          )}
        >
          <div className="p-4 flex items-center space-x-4">
            <button
              onClick={() => navigate(`/dashboard/members/${member.auth_id}`)}
              className="flex-shrink-0"
            >
              <img
                src={member.avatar_url || defaultAvatar}
                alt={member.full_name}
                className={cn(
                  "w-12 h-12 rounded-full object-cover",
                  "border-2 border-dark-400 group-hover:border-gold-400",
                  "transition-all duration-300 shadow-xl"
                )}
              />
            </button>
            <div className="flex-1">
              <button
                onClick={() => navigate(`/dashboard/members/${member.auth_id}`)}
                className="text-white font-medium hover:text-gold-400 transition-colors"
              >
                {member.full_name}
              </button>
              <div className="flex items-center text-dark-800 text-sm">
                <Briefcase className="h-4 w-4 mr-1.5 shrink-0" />
                {member.job_type}
                {member.job_type && member.company && " @ "}
                {member.company}
              </div>
              
              {/* Sports */}
              {member.user_sports?.length > 0 && (
                <div className="flex flex-wrap gap-1.5 mt-3">
                  {member.user_sports.map((sport: any, idx: number) => (
                    <span
                      key={idx}
                      className={cn(
                        "text-xs px-2.5 py-1 rounded-full",
                        "border border-transparent transition-colors",
                        "dark:bg-dark-400 dark:text-white"
                      )}
                    >
                      {sport.sports.emoji} {sport.sports.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CityMembersList;
