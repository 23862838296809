import React, { useState, useEffect } from "react";
import { Star, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/lib/supabase";
import CitiesPlayedModal from "@/components/members/CitiesPlayedModal";
import { Member } from "../../../types/member";
import { useProfile } from "@/hooks/useProfile";
import { cn } from "@/lib/utils";

interface ProfileStatsProps {
  member: Member;
  onShowConnections: () => void;
  onShowReviews: () => void;
}

const ProfileStats: React.FC<ProfileStatsProps> = ({
  member,
  onShowConnections,
  onShowReviews
}) => {
  const [matchesPlayed, setMatchesPlayed] = useState<number>(0);
  const [citiesPlayed, setCitiesPlayed] = useState<number>(member.citiesPlayed.length);
  const [citiesList, setCitiesList] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rating, setRating] = useState<number>(0);
  const [isLoadingMatches, setIsLoadingMatches] = useState(true);
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [isLoadingRating, setIsLoadingRating] = useState(true);
  const [hasRatings, setHasRatings] = useState(false);
  const [showMutualModal, setShowMutualModal] = useState(false);
  const [connectionCount, setConnectionCount] = useState(member.connections);
  const [isLoadingConnections, setIsLoadingConnections] = useState(false);
  const DEFAULT_AVATAR = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';
  const [mutualConnections, setMutualConnections] = useState<{
    count: number;
    connections: Array<{
      id: string;
      name: string;
      image: string;
      city: string;
    }>;
    loading: boolean;
  }>({
    count: 0,
    connections: [],
    loading: false
  });
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;
  const { matchesPlayed: profileMatchesPlayed } = useProfile();
  const navigate = useNavigate();

  // Handle both connections button and mutual connections click
  const handleConnectionsClick = (e: React.MouseEvent) => {
    // If clicking the mutual connections button, show modal instead
    const target = e.target as HTMLElement;
    if (target.closest('.mutual-connections-btn')) {
      e.stopPropagation();
      setShowMutualModal(true);
    } else {
      // Otherwise navigate to network tab
      navigate(`/dashboard/members/${member.id}`, { 
        state: { activeTab: 'network' }
      });
    }
  };
  const handleMatchesClick = () => {
    navigate('/dashboard/activities', {
      state: { tab: 'matches' }
    });
  };

  // Fetch rating data
  useEffect(() => {
    const fetchRating = async () => {
      try {
        if (!member?.id) return;

        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', member.id)
          .single();

        if (userError) throw userError;

        const { data: ratingData, error: ratingError } = await supabase
          .from('user_ratings')
          .select('overall_rating')
          .eq('user_id', userData.id)
          .single();

        if (ratingError) {
          if (ratingError.code === 'PGRST116') {
            // No rating found
            setRating(0);
            setHasRatings(false);
            return;
          }
          throw ratingError;
        }

        setHasRatings(!!ratingData?.overall_rating);
        setRating(parseFloat(ratingData?.overall_rating) || 0);
      } catch (error) {
        console.error('Error fetching rating:', error);
        setRating(0);
        setHasRatings(false);
      } finally {
        setIsLoadingRating(false);
      }
    };

    fetchRating();
  }, [member?.id]);

  // Fetch mutual connections count
  useEffect(() => {
    const fetchMutualCount = async () => {
      setMutualConnections(prev => ({ ...prev, loading: true }));
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Fetch user IDs from 'users' table
        const { data: users, error: userError } = await supabase
          .from('users')
          .select('id')
          .in('auth_id', [user.id, member.id]);

        if (userError || !users || users.length !== 2) {
          console.error('Error fetching user IDs:', userError);
          return;
        }

        const [user1, user2] = users;

        // Fetch mutual connections with full details
        const { data, error } = await supabase
          .rpc('get_mutual_friends', { 
            user1: user1.id,
            user2: user2.id 
          });

        if (error) {
          console.error('Error fetching mutual connections:', error);
          return;
        }

        // Map results to expected structure
        const connections = data?.map(conn => ({
          id: conn.auth_id,         // Ensure this matches your RPC return field
          name: conn.full_name,     // Ensure this matches the field in your RPC
          image: conn.avatar_url || DEFAULT_AVATAR, // Ensure correct image field
          city: conn.city || 'Unknown' // Ensure correct city field
        })) || [];

        setMutualConnections({
          count: connections.length,
          connections,
          loading: false
        });
      } catch (error) {
        console.error('Error in fetchMutualCount:', error);
      } finally {
        setMutualConnections(prev => ({ ...prev, loading: false }));
      }
    };

    if (member.id) {
      fetchMutualCount();
    }
  }, [member.id]);

  useEffect(() => {
    const fetchStats = async () => {
      if (!member.id) return;

      try {
        setIsLoadingMatches(true);
        setIsLoadingCities(true);

        console.log("Fetching stats for member:", member.id);

        // Step 1: Get the internal user ID from Supabase
        const { data: userData, error: userError } = await supabase
          .from("users")
          .select("id")
          .eq("auth_id", member.id)
          .single();

        if (userError || !userData) {
          console.error("Error fetching user ID:", userError);
          return;
        }

        const internalUserId = userData.id;
        console.log("Internal user ID:", internalUserId);

        // Step 2: Query the activities table for past confirmed matches
        const { count, error: matchError } = await supabase
          .from("activities")
          .select("*", { count: "exact", head: true })
          .or(`player_1_id.eq.${internalUserId},player_2_id.eq.${internalUserId}`)
          .lt("date", new Date().toISOString().split("T")[0])
          .eq("status", "confirmed");

        if (matchError) {
          console.error("Error fetching matches played:", matchError);
          return;
        }

        console.log("Match count:", count);
        setMatchesPlayed(count || 0);

        // Step 3: Query the unique cities from venue_city column
        const { data: cityData, error: cityError } = await supabase
          .from("activities")
          .select("venue_city")
          .or(`player_1_id.eq.${internalUserId},player_2_id.eq.${internalUserId}`)
          .lt("date", new Date().toISOString().split("T")[0])
          .eq("status", "confirmed")
          .neq("venue_city", null);

        if (cityError) {
          console.error("Error fetching cities played:", cityError);
          return;
        }

        console.log("Raw city data response:", cityData);

        // Extract unique cities (first word before comma)
        const uniqueCities = new Set(
          cityData
            .map((activity) => activity.venue_city?.split(",")[0].trim())
            .filter((city) => city)
        );

        console.log("Unique Cities Played:", Array.from(uniqueCities));
        setCitiesPlayed(uniqueCities.size);
        setCitiesList(Array.from(uniqueCities));

      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setIsLoadingMatches(false);
        setIsLoadingCities(false);
      }
    };

    fetchStats();
  }, [member.id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      {/* Matches Played */}
      <div className="bg-dark-300 rounded-xl p-4 text-center">
        <div className="text-2xl font-bold text-gold-400">
          {isLoadingMatches ? "..." : matchesPlayed}
        </div>
        <div className="text-dark-800 text-sm mt-1">Matches Played</div>
      </div>

      {/* Cities Played */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-dark-300 rounded-xl p-4 text-center hover:bg-dark-400 transition-colors"
      >
        <div className="text-2xl font-bold text-gold-400">
          {isLoadingCities ? "..." : citiesPlayed}
        </div>
        <div className="text-dark-800 text-sm mt-1">Cities Played</div>
      </button>

      {/* Connections */}
      <button
        onClick={handleConnectionsClick}
        className={cn(
          "relative rounded-xl p-4 text-center transition-colors group",
          isLoadingConnections ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className={cn(
          "text-2xl font-bold",
          "text-gold-600",
          "dark:text-gold-400",
          "flex items-center justify-center gap-2"
        )}>
          {connectionCount}
          {!mutualConnections.loading && mutualConnections.count > 0 && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setShowMutualModal(true);
              }}
              className={cn(
                "mutual-connections-btn",
                "text-sm px-3 py-1 rounded-full cursor-pointer",
                "bg-gold-400/10 hover:bg-gold-400/20",
                "border border-gold-400/20",
                "transition-colors duration-200",
                "group-hover:bg-gold-400/30",
                "flex items-center gap-1"
              )}
            >
              {mutualConnections.count} mutual
            </div>
          )}
          {mutualConnections.loading && (
            <span className="ml-2 text-sm text-dark-800 animate-pulse">
              Loading...
            </span>
          )}
        </div>
        <div className={cn(
          "text-sm mt-1",
          "text-dark-800",
          "dark:text-dark-800"
        )}>
          Connections
        </div>
      </button>

      {/* Rating Button */}
      <button 
        onClick={() => navigate(`/dashboard/members/${member.id}`, { state: { activeTab: 'ratings' }})}
        className={cn(
          "rounded-xl p-4 text-center transition-colors",
          isLoadingRating ? "animate-pulse" : "",
          "bg-light-200 hover:bg-light-300",
          "dark:bg-dark-300 dark:hover:bg-dark-400"
        )}
      >
        <div className="flex items-center justify-center text-2xl font-bold text-gold-600 dark:text-gold-400">
          {hasRatings ? rating.toFixed(1) : '-'}
          <Star className="h-5 w-5 ml-1" />
        </div>
        <div className="text-sm mt-1 text-light-600 dark:text-dark-800">
          Rating
        </div>
      </button>

      {/* Cities Played Modal */}
      <CitiesPlayedModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        cities={citiesList}
        memberName={member.name}
      />
      
      {/* Mutual Connections Modal */}
      {showMutualModal && mutualConnections.connections.length > 0 && (
        <div className="fixed inset-0 z-50 bg-dark-50/80 backdrop-blur-sm">
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <div className="bg-dark-200 rounded-2xl w-full max-w-2xl border border-dark-300">
                {/* Header */}
                <div className="p-6 border-b border-dark-300">
                  <div className="flex items-center justify-between">
                    <div>
                      <h2 className="text-xl font-bold text-white">
                        Mutual Connections
                      </h2>
                      <p className="text-dark-800">
                        {mutualConnections.count} shared connection{mutualConnections.count !== 1 ? 's' : ''} with {member.name}
                      </p>
                    </div>
                    <button
                      onClick={() => setShowMutualModal(false)}
                      className="text-dark-800 hover:text-white transition-colors"
                      aria-label="Close modal"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Connections Grid */}
                <div className="p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {mutualConnections.connections.map((connection, index) => (
                      <div
                        key={`mutual-${connection.id || index}`}
                        onClick={() => {
                          setShowMutualModal(false);
                          if (connection.id) {
                            navigate(`/dashboard/members/${connection.id}`);
                          }
                        }}
                        className="bg-dark-300 rounded-xl p-4 hover:bg-dark-400 transition-all cursor-pointer flex items-center gap-3 border border-dark-400"
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && connection.id) {
                            navigate(`/dashboard/members/${connection.id}`);
                          }
                        }}
                      >
                        <img
                          src={connection.image}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.src = DEFAULT_AVATAR;
                            target.onerror = null; // Prevent infinite error loop
                          }}
                          alt={connection.name}
                          className={cn(
                            "w-12 h-12 rounded-full object-cover",
                            "border-2 border-dark-400 transition-colors",
                            "hover:border-gold-400"
                          )}
                        />
                        <div>
                          <div className="text-white font-medium">{connection.name}</div>
                          <div className="text-dark-800 text-sm">{connection.city || 'Unknown location'}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileStats;