import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { VenueApiResult } from '../../../types/venue';
import { VenueSearchSection } from './VenueSearchSection';
import { useVenueSearch } from './hooks/useVenueSearch';
import { useVenueDistance } from './hooks/useVenueDistance';
import { useInfiniteScroll } from './hooks/useInfiniteScroll';
import { ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import VenueListItem from './components/VenueListItem';
import VenueListControls from './components/VenueListControls';
import SimpleMap from '../maps/SimpleMap';
import { useAddressSearch } from './hooks/useAddressSearch';
import { sortVenues } from '../../../lib/utils/venue-sorting';
import type { VenueSortOption } from '../../../lib/utils/venue-sorting';
import { getUserLocation, DEFAULT_LOCATION } from '../../../lib/utils/location';
import { cn } from '@/lib/utils';

interface VenueApiListProps {
  city: string;
}

const ITEMS_PER_PAGE = 40;

export function VenueApiList({ city }: VenueApiListProps) {
  const [sortBy, setSortBy] = useState<VenueSortOption>('distance');
  const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [isLocationChecked, setIsLocationChecked] = useState(false);
  const [userReferencePoint, setUserReferencePoint] = useState<{ lat: number; lng: number } | null>(null);
  const searchResultsRef = useRef<HTMLDivElement>(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const [hasSeenScrollIndicator, setHasSeenScrollIndicator] = useState(() => {
    return localStorage.getItem('hasSeenVenueScrollIndicator') === 'true';
  });
  const [availableActivities, setAvailableActivities] = useState<Set<string>>(new Set());
  const scrollTimerRef = useRef<NodeJS.Timeout>();
  const hasShownIndicatorRef = useRef(false);

  // Fetch user location only if permission is granted
  useEffect(() => {
    const checkPermissionAndSetLocation = async () => {
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });

      if (permissionStatus.state === "granted") {
        const userLocation = await getUserLocation();
        setUserReferencePoint({ lat: userLocation.latitude, lng: userLocation.longitude });
      } else {
        setUserReferencePoint(DEFAULT_LOCATION);
      }

      setIsLocationChecked(true);
    };

    checkPermissionAndSetLocation();
  }, []);

  const {
    searchTerm,
    setSearchTerm,
    filteredVenues,
    isLoading: isVenuesLoading
  } = useVenueSearch(city);

  // Extract unique activities from venues
  useEffect(() => {
    const activities = new Set<string>();
    filteredVenues.forEach(venue => {
      venue.activities?.forEach(activity => {
        activities.add(activity);
      });
    });
    setAvailableActivities(activities);
  }, [filteredVenues]);

  const {
    address,
    referencePoint,
    isLoading: isAddressLoading,
    error: addressError,
    setAddress,
    setReferencePoint,
    resetLocation
  } = useAddressSearch();

  // 🚀 Fix: Memoize filteredVenues to prevent unnecessary re-renders
  const memoizedVenues = useMemo(() => filteredVenues, [filteredVenues]);

  // 🚀 Fix: Always call useVenueDistance() to maintain hook order
  const distances = useVenueDistance(isLocationChecked ? memoizedVenues : [], referencePoint || userReferencePoint);

  // Sort venues based on current criteria
  const sortedVenues = React.useMemo(() => 
    sortVenues(memoizedVenues, sortBy, distances),
    [memoizedVenues, sortBy, distances]
  );

  const {
    page,
    loading: isLoadingMore,
    hasMore,
    lastElementRef,
    reset: resetScroll
  } = useInfiniteScroll({
    totalItems: sortedVenues.length,
    itemsPerPage: ITEMS_PER_PAGE
  });

  // Calculate displayed venues
  const displayedVenues = React.useMemo(() => 
    sortedVenues.slice(0, page * ITEMS_PER_PAGE),
    [sortedVenues, page]
  );

  // Handle refresh
  const handleRefresh = React.useCallback(async () => {
    resetScroll();
    setSearchTerm('');
    setSelectedTags([]);
    setSortBy('distance');
    await resetLocation();
  }, [resetScroll, setSearchTerm, setSelectedTags, setSortBy, resetLocation]);

  const handleTagSelect = useCallback((tag: string) => {
    setSelectedTags(prev => {
      if (prev.includes(tag)) {
        return [];
      }
      return [tag];
    });
    setSearchTerm(tag);
  }, [setSearchTerm]);

  // Scroll indicator effect
  useEffect(() => {
    if (filteredVenues.length > 0 && !hasSeenScrollIndicator && !hasShownIndicatorRef.current) {
      hasShownIndicatorRef.current = true;
      
      // Clear any existing timers
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current);
      }

      // Show indicator after a short delay
      scrollTimerRef.current = setTimeout(() => {
        setShowScrollIndicator(true);

        // Hide indicator after 5 seconds
        scrollTimerRef.current = setTimeout(() => {
          setShowScrollIndicator(false);
          localStorage.setItem('hasSeenVenueScrollIndicator', 'true');
          setHasSeenScrollIndicator(true);
        }, 5000);
      }, 1000);
    }

    // Cleanup function
    return () => {
      if (scrollTimerRef.current) {
        clearTimeout(scrollTimerRef.current);
      }
    };
  }, [filteredVenues.length, hasSeenScrollIndicator, hasShownIndicatorRef]);

  if (isVenuesLoading) {
    return (
      <div className="flex justify-center py-12">
        <div className="h-8 w-8 text-gold-400 animate-spin rounded-full border-2 border-current border-t-transparent" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <VenueSearchSection
        searchTerm={searchTerm}
        totalVenues={filteredVenues.length}
        availableActivities={Array.from(availableActivities)}
        onSearchChange={setSearchTerm}
        viewMode={viewMode}
        onViewModeChange={setViewMode}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onRefresh={handleRefresh}
        isLoading={isAddressLoading}
        address={address}
        onAddressChange={setAddress}
        onLocationSelect={setReferencePoint}
        isAddressLoading={isAddressLoading}
        addressError={addressError}
        selectedTags={selectedTags}
        onTagSelect={handleTagSelect}
      />
      
      {/* Scroll Indicator */}
      {showScrollIndicator && !hasSeenScrollIndicator && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          className={cn(
            "fixed left-1/2 bottom-24 z-50 -translate-x-1/2",
            "flex flex-col items-center gap-2",
            "pointer-events-none md:hidden"
          )}
        >
          <div className={cn(
            "text-sm px-3 py-1 rounded-full",
            "dark:text-dark-800 dark:bg-dark-200/80",
            "light:text-light-900 light:bg-light-100/80",
            "backdrop-blur-sm"
          )}>
            Scroll to see results
          </div>
          <ChevronDown 
            className={cn(
              "h-6 w-6",
              "dark:text-gold-400 light:text-gold-600",
              "animate-bounce"
            )}
          />
        </motion.div>
      )}

      {viewMode === 'map' ? (
        <div className="h-[calc(100vh-12rem)]">
          <SimpleMap 
            venues={sortedVenues}
            className="w-full h-full"
          />
        </div>
      ) : (
        <div>
          <div ref={searchResultsRef}>
          {displayedVenues.map((venue, index) => (
            <VenueListItem
              key={venue.id}
              venue={venue}
              distance={distances[venue.id]}
              isLast={index === displayedVenues.length - 1}
              lastElementRef={lastElementRef}
            />
          ))}
          </div>

          {/* Loading indicator */}
          {isLoadingMore && hasMore && (
            <div className="flex justify-center py-6 mt-4">
              <div className="h-6 w-6 text-gold-400 animate-spin rounded-full border-2 border-current border-t-transparent" />
            </div>
          )}


          {/* No venues found message */}
          {!isLoadingMore && displayedVenues.length === 0 && (
            <div className="text-center py-12 bg-dark-300 rounded-xl">
              <h3 className="text-lg font-medium text-white mb-2">No Venues Found</h3>
              <p className="text-dark-800">
                Try adjusting your search filters
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}