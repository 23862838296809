import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { cn } from '@/lib/utils';

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 !bg-black !text-white">
      <div className="container mx-auto px-4 py-12">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center !text-white hover:text-gold-400 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back
        </button>

        <div className="max-w-3xl mx-auto">
          <h1 className={cn(
            "text-4xl font-bold mb-2",
            "bg-gradient-to-r from-white via-gold-200 to-gold-400",
            "bg-clip-text text-transparent"
          )}>Terms of Service</h1>
          <p className="!text-white/70 mb-8">Last Updated: March 25, 2025</p>

          <div className="space-y-12 !text-white/70">
            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">1. Acceptance of Terms</h2>
              <p>
                By accessing or using the playclub.ai mobile application (the "App"), you agree to be bound by these Terms of Service ("Terms") and our Privacy Policy. If you do not agree with these Terms, do not use the App.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">2. Eligibility</h2>
              <p>
                The App is intended solely for users who are 18 years of age or older. By using the App, you represent and warrant that you are at least 18 years old.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">3. Account Registration & Membership Verification</h2>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Membership Verification:</strong>
                  <p className="mt-1">
                    To join our exclusive network, you must complete a verification process that includes providing valid identification and complying with club-specific guest policies.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Accuracy:</strong>
                  <p className="mt-1">
                    You agree to provide accurate and complete information during registration. playclub.ai reserves the right to suspend or terminate accounts found to be in violation.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">4. Platform Use & Services</h2>
              <p className="mb-4">
                playclub.ai is a membership-based digital platform that connects verified private sports and fitness club members to a global network of clubs. Our services include:
              </p>
              <div className="space-y-4">
                <div>
                  <strong className="!text-white">Exclusive Membership & Global Network:</strong>
                  <p className="mt-1">
                    Connect, play, and engage with like-minded sports and wellness enthusiasts worldwide.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Premium Access:</strong>
                  <p className="mt-1">
                    Seamlessly search, discover, and gain entry to premium sports and wellness clubs through referrals from trusted existing members.
                  </p>
                </div>
                <div>
                  <strong className="!text-white">Matching & Performance Tracking:</strong>
                  <p className="mt-1">
                    Leverage AI to match you with compatible partners for sports sessions while delivering performance insights to help you elevate your game.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">5. Prohibited Conduct</h2>
              <p>
                You agree not to use the App for any activity that is unlawful or harmful, including any conduct that could be deemed as sexually exploiting or endangering minors. Such behavior will result in immediate account termination and may be reported to authorities.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">6. Intellectual Property</h2>
              <p>
                All content and intellectual property on the App is owned by or licensed to playclub.ai. You agree not to copy, reproduce, or distribute any materials without prior written consent.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">7. Payment and Fees</h2>
              <p>
                Certain features of the App may require payment in the future. By providing payment information, you agree to the applicable fees and billing terms. Guest fees are collected according to club rules, and playclub.ai retains a revenue share as agreed with partner clubs.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">8. Termination</h2>
              <p>
                playclub.ai reserves the right to suspend or terminate your account at any time, with or without notice, if you violate these Terms or engage in any activity deemed harmful or unlawful.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">9. Limitation of Liability</h2>
              <p>
                To the fullest extent permitted by law, playclub.ai shall not be liable for any indirect, incidental, special, or consequential damages arising out of or related to your use of the App.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">10. Modifications to Terms</h2>
              <p>
                We reserve the right to update these Terms at any time. Significant changes will be communicated to users. Continued use of the App constitutes acceptance of the revised Terms.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">11. Governing Law</h2>
              <p>
                These Terms are governed by and construed in accordance with the laws of Delaware, without regard to its conflict of laws provisions.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold !text-white mb-4">12. Contact Information</h2>
              <p>
                For any questions, concerns, or further information regarding these Terms, contact us at:{' '}
                <a href="mailto:info@playclub.ai" className="!text-gold-400 hover:!text-gold-500">
                  info@playclub.ai
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
