import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { cn } from '@/lib/utils';
import { extractCityName } from '@/lib/utils/text';

interface CityLinkProps {
  city: string;
  showIcon?: boolean;
  className?: string;
  iconClassName?: string;
  asChild?: boolean; // New prop to control rendering element
}

const CityLink: React.FC<CityLinkProps> = ({
  city,
  showIcon = true,
  className,
  iconClassName,
  asChild = false // Default to button
}) => {
  const navigate = useNavigate();
  const cityName = extractCityName(city);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/dashboard/facilities', { 
      state: { selectedCity: cityName }
    });
  };

  const sharedProps = {
    onClick: handleClick,
    className: cn(
      "flex items-center text-dark-800 hover:text-gold-400 transition-colors",
      className
    )
  };

  const content = (
    <>
      {showIcon && (
        <MapPin className={cn(
          "h-4 w-4 mr-1 flex-shrink-0",
          iconClassName
        )} />
      )}
      <span className="truncate">{cityName}</span>
    </>
  );

  // Render as div if asChild is true, otherwise as button
  return asChild ? (
    <div {...sharedProps}>{content}</div>
  ) : (
    <button {...sharedProps}>{content}</button>
  );
};

export default CityLink;