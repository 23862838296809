import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users2, Clock, Check, X, MapPin } from 'lucide-react';
import { useConnectionStore } from '@/store/connectionStore';
import { extractCityName } from '../../../lib/utils/text';
import { cn } from '@/lib/utils';

interface PendingConnectionsListProps {
  connections: any[];
}

const PendingConnectionsList: React.FC<PendingConnectionsListProps> = ({ connections }) => {
  const navigate = useNavigate();
  const { acceptRequest, declineRequest, cancelRequest } = useConnectionStore();
  const defaultAvatar =
    'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  if (connections.length === 0) {
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        "bg-dark-300 border-dark-400"
      )}>
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Pending Requests</h3>
        <p className="text-dark-800 max-w-sm mx-auto">
          You don't have any pending connection requests at the moment.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 max-w-3xl mx-auto">
      {connections.map((connection) => {
        const user = connection.other_user;
        return (
          <div
            key={connection.connection_id}
            className={cn(
              "bg-dark-200 rounded-2xl overflow-hidden border border-dark-300",
              "hover:border-gold-400 transition-all duration-300",
              "shadow-lg hover:shadow-xl transform hover:scale-[1.01]"
            )}
          >
            <div className="p-4 flex items-center space-x-4">
              <button
                onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                className="flex-shrink-0"
              >
                <img
                  src={user.avatar_url || defaultAvatar}
                  alt={user.full_name}
                  className="w-12 h-12 rounded-full object-cover border-2 border-dark-400 hover:border-gold-400 transition-all duration-300 shadow-xl"
                />
              </button>

              <div className="flex-1 min-w-0">
                <button
                  onClick={() => navigate(`/dashboard/members/${user.auth_id}`)}
                  className="text-white font-medium hover:text-gold-400 transition-colors block truncate"
                >
                  {user.full_name}
                </button>

                <div className="flex items-center text-dark-800 text-sm mt-0.5">
                  <MapPin className="h-4 w-4 mr-1" />
                  {extractCityName(user.city)}
                </div>

                <div className="flex items-center text-dark-800 text-sm mt-2">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>
                    {connection.is_initiator ? 'Request sent ' : 'Request received '}
                    {new Date(connection.created_at).toLocaleDateString()}
                  </span>
                </div>

                {user.sports?.length > 0 && (
                  <div className="flex flex-wrap gap-1.5 mt-2">
                    {user.sports.map((sport: any, idx: number) => (
                      <span
                        key={idx}
                        className={cn(
                          "text-xs px-2.5 py-1 rounded-full",
                          "bg-dark-400 text-white"
                        )}
                      >
                        {sport.emoji} {sport.name}
                      </span>
                    ))}
                  </div>
                )}

                {/* Action Buttons */}
                <div className="flex items-center gap-2 mt-4">
                  {connection.is_initiator ? (
                    <button
                      onClick={() => cancelRequest(connection.connection_id)}
                      className={cn(
                        "flex items-center justify-center gap-2 relative",
                        "px-4 py-2.5 rounded-lg",
                        "bg-dark-300 hover:bg-dark-400",
                        "text-dark-800 hover:text-white",
                        "border border-dark-400 hover:border-dark-500",
                        "transition-all duration-200",
                        "transform hover:scale-105 active:scale-95",
                        "shadow-sm hover:shadow-md"
                      )}
                    >
                      <X className="h-4 w-4" />
                      <span>Cancel Request</span>
                    </button>
                  ) : (
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => acceptRequest(connection.connection_id)}
                        className={cn(
                          "flex-1 flex items-center justify-center gap-2",
                          "px-4 py-2.5 rounded-lg",
                          "bg-green-500/10 hover:bg-green-500",
                          "text-green-500 hover:text-white",
                          "border border-green-500/20 hover:border-green-500",
                          "transition-all duration-200",
                          "transform hover:scale-105 active:scale-95",
                          "shadow-sm hover:shadow-md"
                        )}
                      >
                        <Check className="h-4 w-4" />
                        <span>Accept</span>
                      </button>
                      <button
                        onClick={() => declineRequest(connection.connection_id)}
                        className={cn(
                          "flex-1 flex items-center justify-center gap-2",
                          "px-4 py-2.5 rounded-lg",
                          "bg-dark-300 hover:bg-dark-400",
                          "text-dark-800 hover:text-white",
                          "border border-dark-400 hover:border-dark-500",
                          "transition-all duration-200",
                          "transform hover:scale-105 active:scale-95",
                          "shadow-sm hover:shadow-md"
                        )}
                      >
                        <X className="h-4 w-4" />
                        <span>Decline</span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PendingConnectionsList;
