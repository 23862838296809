import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '../../ui/button';
import { cn } from '@/lib/utils';
import { Bot, Building2, Trophy, Users, UserPlus, CheckCircle2 } from 'lucide-react';

interface CompletionStepProps {
  onComplete: () => void;
}
import { useNavigate } from 'react-router-dom';

const CompletionStep: React.FC<CompletionStepProps> = ({ onComplete }) => {
  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="h-full flex flex-col bg-dark-200 rounded-xl p-4 sm:p-6 lg:p-8 border border-dark-300"
    >
      {/* Welcome Header */}
      <motion.div 
        className="flex-shrink-0 mb-6 sm:mb-8"
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ 
          type: "spring",
          stiffness: 260,
          damping: 20 
        }}
      >
        <div className="bg-green-400/20 p-4 rounded-full w-16 h-16 mx-auto flex items-center justify-center">
          <CheckCircle2 className="h-8 w-8 text-green-400" />
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col items-center justify-between max-w-md mx-auto w-full">
        <div className="text-center mb-8">
          <motion.h2 
            className="text-2xl sm:text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-white via-gold-200 to-gold-400 mb-3"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            You're in. Let's play!
          </motion.h2>
          <motion.p 
            className="text-dark-800 text-sm sm:text-base"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            Your elite sports network awaits. Here's how to get started:
          </motion.p>
        </div>

        {/* Quick Actions Grid */}
        <motion.div 
          className="flex flex-col gap-3 w-full mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <button
            onClick={() => navigate('/dashboard/match')}
            className={cn(
              "bg-dark-300 rounded-xl p-4",
              "hover:bg-dark-400 transition-all duration-300",
              "border border-dark-400 hover:border-gold-400",
              "group flex items-center gap-4"
            )}
          >
            <Users className="h-6 w-6 text-gold-400 group-hover:scale-110 transition-transform flex-shrink-0" />
            <div className="text-left">
              <h3 className="text-sm font-medium text-white">Connect with Players</h3>
              <p className="text-xs text-dark-800">Find and match with players in your city</p>
            </div>
          </button>

          <button
            onClick={() => navigate('/dashboard/messages')}
            className={cn(
              "bg-dark-300 rounded-xl p-4",
              "hover:bg-dark-400 transition-all duration-300",
              "border border-dark-400 hover:border-gold-400",
              "group flex items-center gap-4"
            )}
          >
            <Bot className="h-6 w-6 text-gold-400 group-hover:scale-110 transition-transform flex-shrink-0" />
            <div className="text-left">
              <h3 className="text-sm font-medium text-white">Chat with Lucy AI</h3>
              <p className="text-xs text-dark-800">Ask anything about playclub</p>
            </div>
          </button>

          <button
            onClick={() => navigate('/dashboard/facilities')}
            className={cn(
              "bg-dark-300 rounded-xl p-4",
              "hover:bg-dark-400 transition-all duration-300",
              "border border-dark-400 hover:border-gold-400",
              "group flex items-center gap-4"
            )}
          >
            <Building2 className="h-6 w-6 text-gold-400 group-hover:scale-110 transition-transform flex-shrink-0" />
            <div className="text-left">
              <h3 className="text-sm font-medium text-white">Browse Private Clubs</h3>
              <p className="text-xs text-dark-800">Explore exclusive venues worldwide</p>
            </div>
          </button>

          <button
            onClick={() => navigate('/dashboard/profile')}
            className={cn(
              "bg-dark-300 rounded-xl p-4",
              "hover:bg-dark-400 transition-all duration-300",
              "border border-dark-400 hover:border-gold-400",
              "group flex items-center gap-4"
            )}
          >
            <UserPlus className="h-6 w-6 text-gold-400 group-hover:scale-110 transition-transform flex-shrink-0" />
            <div className="text-left">
              <h3 className="text-sm font-medium text-white">Invite Members</h3>
              <p className="text-xs text-dark-800">Grow your network selectively</p>
            </div>
          </button>
        </motion.div>

        {/* Enter Button */}
        <motion.div
          className="w-full"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5 }}
        >
         <Button
  onClick={() => {
    const url = "https://playclub.ai/dashboard";
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null; // Ensures security and prevents Gmail from keeping it in-app
  }}
  className={cn(
    "w-full sm:w-auto px-6 py-3 sm:px-8 sm:py-4",
    "text-base sm:text-lg font-medium",
    "bg-gradient-to-r from-gold-400 to-gold-500",
    "hover:from-gold-500 hover:to-gold-600",
    "text-dark-50 rounded-xl",
    "transform transition-all duration-300",
    "hover:scale-105 hover:shadow-lg",
    "focus:outline-none focus:ring-2 focus:ring-gold-400 focus:ring-offset-2 focus:ring-offset-dark-200"
  )}
>
  Enter playclub.ai
</Button>

        </motion.div>
      </div>
    </motion.div>
  );
};

export default CompletionStep;