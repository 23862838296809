import React from 'react';
import { CheckCircle2 } from 'lucide-react';
import { cn } from '@/lib/utils';

interface EliteApplicationSuccessProps {
  onClose: () => void;
}

const EliteApplicationSuccess: React.FC<EliteApplicationSuccessProps> = ({ onClose }) => {
  return (
    <div className={cn(
      "backdrop-blur-[2px] bg-dark-200/40 rounded-2xl p-8 max-w-md mx-auto text-center",
      "border border-dark-300/50 shadow-xl"
    )}>
      <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
        <CheckCircle2 className="h-8 w-8 text-dark-50" />
      </div>
      <h3 className="text-xl font-semibold text-white mb-2">Application Received</h3>
      <p className="text-white/80 text-lg mb-6">
        We review each application to maintain a high-quality network. Not all are accepted, but that's what keeps us exceptional. Stay tuned! 🌟
      </p>
      <button
        onClick={onClose}
        className={cn(
          "w-full px-8 py-3 bg-gradient-to-r from-gold-400 to-gold-500",
          "hover:from-gold-500 hover:to-gold-600",
          "text-dark-50 rounded-xl transition-all duration-300",
          "transform hover:scale-105 shadow-lg hover:shadow-xl",
          "border border-gold-300/20 backdrop-blur-sm"
        )}
      >
        Close
      </button>
    </div>
  );
};

export default EliteApplicationSuccess;
