import React, { useState } from 'react';
import { Calendar, Clock, CheckCircle2 } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Button } from '../ui/button';
import { Activity } from '../../types/activity';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '../ui/select';
import { cn } from '@/lib/utils';

interface RematchButtonProps {
  activity: Activity;
  onRematch: (activity: Activity) => void;
  className?: string;
}

const RematchButton: React.FC<RematchButtonProps> = ({
  activity,
  onRematch,
  className,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const timeSlots = [
    '09:00',
    '10:00',
    '11:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
  ];

  const getButtonLabel = () => {
    return 'Play Again';
  };

  const handleSubmit = async () => {
    if (!selectedDate || !selectedTime) return;

    setIsSubmitting(true);
    try {
      const newActivity = {
        player1Id: activity.player1Id,
        sport: activity.sport,
        opponent: activity.opponent,
        date: selectedDate,
        time: selectedTime,
        venue: activity.venue,
      };

      await onRematch(newActivity);

      setShowSuccess(true);
    } catch (error) {
      console.error('Failed to send rematch request:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setShowSuccess(false);
    setSelectedDate('');
    setSelectedTime('');
  };

  const minDate = new Date().toISOString().split('T')[0];
  const maxDate = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
    .toISOString()
    .split('T')[0];

  if (showSuccess) {
    return (
      <Dialog open={true} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle className="sr-only">Request Confirmation</DialogTitle>
          </DialogHeader>
          <div className="text-center py-6">
            <div className="w-16 h-16 bg-green-500 rounded-full flex items-center justify-center mx-auto mb-6">
              <CheckCircle2 className="h-8 w-8 text-dark-50" />
            </div>
            <h3 className="text-xl font-semibold text-white mb-2">
              Request Sent!
            </h3>
            <p className="text-dark-800 mb-6">
              Your {activity.sport.split(' ')[1].toLowerCase()} request has been
              sent to {activity.opponent.name}. You'll be notified once they
              respond.
            </p>
            <Button
              onClick={handleClose}
              className="bg-gold-400 hover:bg-gold-500 text-dark-50 w-full"
            >
              Done
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        className={cn(
          'flex items-center justify-center',
          'h-9 px-3 sm:h-10 sm:px-4',
          'bg-gold-400 hover:bg-gold-500 text-dark-50 rounded-lg transition-colors',
          'text-sm font-medium whitespace-nowrap',
          className
        )}
      >
        <span>Play Again</span>
      </Button>

      <Dialog open={showModal} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[425px] bg-dark-200 text-white border-dark-300">
          <DialogHeader>
            <DialogTitle>
              {getButtonLabel()} with {activity.opponent.name}
            </DialogTitle>
          </DialogHeader>

          <div className="space-y-4 py-4">
            <div>
              <label className="block text-dark-800 mb-2">Date</label>
              <div className="relative">
                <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                <input
                  type="date"
                  min={minDate}
                  max={maxDate}
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  className="w-full pl-10 bg-dark-300 border border-dark-400 rounded-xl px-4 py-2 text-white"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-dark-800 mb-2">Time</label>
              <div className="relative">
                <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
                <Select value={selectedTime} onValueChange={setSelectedTime}>
                  <SelectTrigger className="pl-10 bg-dark-300 border-dark-400 text-white">
                    <SelectValue placeholder="Select time" />
                  </SelectTrigger>
                  <SelectContent>
                    {timeSlots.map((time) => (
                      <SelectItem key={time} value={time}>
                        {time}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="flex items-center space-x-3">
              <Button
                type="button"
                onClick={handleClose}
                className="flex-1 bg-dark-300 hover:bg-dark-400 text-white"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting || !selectedDate || !selectedTime}
                className="flex-1 bg-gold-400 hover:bg-gold-500 text-dark-50"
              >
                {isSubmitting ? 'Sending...' : 'Confirm'}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RematchButton;
