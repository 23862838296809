import React, { useEffect } from 'react';
import { Bell, Loader2 } from 'lucide-react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useNotificationStore } from '../../store/notificationStore';
import NotificationItem from './NotificationItem';
import { cn } from '@/lib/utils';

const NotificationsDropdown: React.FC = () => {
  const { 
    notifications, 
    unreadCount, 
    isLoading, 
    error,
    markAllAsRead,
    fetchNotifications
  } = useNotificationStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const lastFetchRef = React.useRef<number>(Date.now());
  const FETCH_COOLDOWN = 30000; // 30 seconds cooldown between fetches

  // Fetch notifications on mount and periodically
  useEffect(() => {
    fetchNotifications();
    
    // Set up periodic refresh
    const interval = setInterval(() => {
      fetchNotifications();
    }, FETCH_COOLDOWN);

    return () => clearInterval(interval);
  }, [fetchNotifications]);

  // Fetch notifications when dropdown opens
  const handleOpenChange = React.useCallback((open: boolean) => {
    setIsOpen(open);

    if (open) {
      const now = Date.now();
      // Only fetch if enough time has passed since last fetch
      if (now - lastFetchRef.current > FETCH_COOLDOWN) {
        fetchNotifications();
        lastFetchRef.current = now;
      }
    }
  }, [fetchNotifications]);

  // Memoize notification list to prevent unnecessary re-renders
  const notificationsList = React.useMemo(() => {
    return notifications.map((notification) => (
      notification.id ? (
        <NotificationItem
          key={`notification-${notification.id}`}
          notification={notification}
          onClose={() => setIsOpen(false)}
        />
      ) : null
    ));
  }, [notifications]);

  // Handle mark all as read
  const handleMarkAllAsRead = React.useCallback(async () => {
    await markAllAsRead();
    setIsOpen(false); 
    lastFetchRef.current = Date.now(); // Update last fetch time
  }, [markAllAsRead]);
  
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger asChild>
        <button
          aria-label={`Notifications ${unreadCount > 0 ? `(${unreadCount} unread)` : ''}`}
          className={cn(
            "relative p-2 rounded-lg text-dark-800 hover:text-white hover:bg-dark-400 transition-colors"
          )}
        >
          <Bell className="h-5 w-5" />
          {unreadCount > 0 && (
            <span className={cn(
              "absolute -top-0.5 -right-0.5 min-w-[18px] h-[18px] bg-gold-400 rounded-full text-dark-50 text-[10px] font-medium flex items-center justify-center px-1"
            )}>
              {unreadCount}
            </span>
          )}
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          sideOffset={5}
          className={cn(
            "w-80 sm:w-96 z-50",
            "bg-white dark:bg-dark-200",
            "border border-gray-200 dark:border-dark-300",
            "rounded-xl shadow-lg",
            "animate-in fade-in-0 zoom-in-95",
            "data-[side=bottom]:slide-in-from-top-2",
            "data-[side=left]:slide-in-from-right-2",
            "data-[side=right]:slide-in-from-left-2",
            "data-[side=top]:slide-in-from-bottom-2"
          )}
        >
          <div className="p-4 border-b border-gray-200 dark:border-dark-300">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Notifications
              </h3>
              {unreadCount > 0 && notifications.length > 0 && (
                <button
                  onClick={handleMarkAllAsRead}
                  className="text-sm text-gold-400 hover:text-gold-500"
                >
                  Mark all as read
                </button>
              )}
            </div>
          </div>

          <div className="max-h-[60vh] overflow-y-auto">
            {isLoading ? (
              <div className="flex justify-center py-8">
                <div className="flex flex-col items-center space-y-2">
                  <Loader2 className="h-6 w-6 animate-spin text-gold-400 transition-all duration-200" />
                  <span className="text-sm text-dark-800">Loading notifications...</span>
                </div>
              </div>
            ) : error ? (
              <div className="py-8 text-center text-red-400">
                {error}
                <button
                  onClick={fetchNotifications}
                  className="block mx-auto mt-2 text-sm underline hover:text-red-300"
                >
                  Try again
                </button>
              </div>
            ) : notifications.length > 0 ? (
              <div className="divide-y divide-gray-200 dark:divide-dark-300">
                {notificationsList.filter(Boolean)}
              </div>
            ) : (
              <div className="py-8 text-center text-gray-500 dark:text-dark-800">
                No notifications yet
              </div>
            )}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default NotificationsDropdown;