import React, { useState, useEffect } from 'react';
import { Crown } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';
import { cn } from '@/lib/utils';
import { useEliteStore } from '../../store/eliteStore';
import { supabase } from '../../lib/supabase';
import EliteApplicationSuccess from './EliteApplicationSuccess';

interface BlurOverlayProps {
  title?: string;
  description?: string;
  componentId: string;
  className?: string;
  mode?: 'full' | 'contained';
}

const BlurOverlay: React.FC<BlurOverlayProps> = ({
  title = 'Elite Training Network',
  description = 'Connect with elite trainers worldwide. Available exclusively for Elite members.',
  componentId,
  className,
  mode = 'full'
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { isDarkMode } = useTheme();
  const { addPendingApplication, hasPendingApplication } = useEliteStore();
  const [isApplicationPending, setIsApplicationPending] = useState(false);
  const isStorePending = hasPendingApplication(componentId);
  const isPending = isApplicationPending || isStorePending;

  // Check if user has already applied
  useEffect(() => {
    const checkApplicationStatus = async () => {
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError || !user) return;

        const { data, error } = await supabase
          .from('EliteApplications')
          .select('id')
          .eq('user_id', user.id)
          .maybeSingle();

        if (error) throw error;
        setIsApplicationPending(!!data);
        if (data) {
          addPendingApplication(componentId);
        }
      } catch (error) {
        console.error('Error checking application status:', error);
      }
    };

    checkApplicationStatus();
  }, []);

 const handleApply = async () => {
  const {
    data: { user },
    error: authError,
  } = await supabase.auth.getUser();

  if (!user || authError) return;

  const { error } = await supabase
    .from('EliteApplications')
    .insert({ user_id: user.id });

  if (!error || error.code === '23505') {
    addPendingApplication(componentId);
    setIsApplicationPending(true);
    setShowSuccess(true);
  } else {
    console.error('Application error:', error);
  }
};

  if (showSuccess) {
    return <EliteApplicationSuccess onClose={() => setShowSuccess(false)} />;
  }

  return (
    <div className={cn(
      "backdrop-blur-[2px] bg-dark-200/40 rounded-2xl p-8 max-w-md mx-auto text-center",
      "border border-dark-300/50 shadow-xl",
      className
    )}>
      <div className={cn(
        "bg-gold-400/30 p-4 rounded-full w-16 h-16 mx-auto mb-6",
        "shadow-lg shadow-gold-400/10",
        "flex items-center justify-center"
      )}>
        <Crown className="h-8 w-8 text-gold-400" />
      </div>
      <h3 className={cn(
        "text-2xl font-semibold mb-3",
        "bg-gradient-to-r from-white via-gold-200 to-gold-400 bg-clip-text text-transparent"
      )}>
        {title}
      </h3>
      <p className="text-white/80 mb-6">{description}</p>
     <button 
  onClick={handleApply}
  disabled={isPending}
  className={cn(
    "px-8 py-3",
    isPending 
      ? "bg-dark-400/80 !text-white border-gold-400/20"
      : "bg-gradient-to-r from-gold-400 to-gold-500 hover:from-gold-500 hover:to-gold-600 text-white",
    "rounded-xl transition-all duration-300",
    "transform hover:scale-105 shadow-lg hover:shadow-xl",
    "border border-gold-300/20 backdrop-blur-sm",
    isPending && "cursor-not-allowed"
  )}
>
  {isPending ? 'Application Pending' : 'Apply to Elite'}
</button>
    </div>
  );
};

export default BlurOverlay;
