import React from 'react';
import type { Conversation } from '../../../types/chat';
import ConversationItem from './ConversationItem';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { useConnectionStore } from '@/store/connectionStore';

// Memoize individual conversation items
const MemoizedConversationItem = React.memo(ConversationItem);

interface ConversationListProps {
  conversations: Conversation[];
  activeChat: string | null;
  onChatSelect: (chatId: string) => void;
  isConnectedGroup?: boolean;
}

const ConversationList: React.FC<ConversationListProps> = ({
  conversations = [], // Provide default empty array
  activeChat,
  onChatSelect,
  isConnectedGroup = false
}) => {
  const { isDarkMode } = useTheme();
  
  // Sort conversations by most recent message
  const sortedConversations = React.useMemo(() => {
    return [...conversations].sort((a, b) => {
      const timeA = new Date(a.time).getTime();
      const timeB = new Date(b.time).getTime();
      return timeB - timeA;
    });
  }, [conversations]);
  if (!Array.isArray(conversations)) {
    console.error('Conversations prop must be an array');
    return (
      <div className={cn(
        "text-center py-12",
        "rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-light-100 border-light-200"
      )}>
        <p className={cn(
          isDarkMode ? "text-dark-800" : "text-light-600"
        )}>
          Error loading conversations
        </p>
      </div>
    );
  }

  if (conversations.length === 0) {
    return (
      <div className={cn(
        "text-center py-12",
        "rounded-xl border",
        isDarkMode 
          ? "bg-dark-300 border-dark-400" 
          : "bg-light-100 border-light-200"
      )}>
        <p className={cn(
          isDarkMode ? "text-dark-800" : "text-light-600"
        )}>
          No conversations found
        </p>
      </div>
    );
  }

  return (
    <div className={cn(
      "divide-y",
      isDarkMode ? "divide-dark-300/50" : "divide-light-200/50",
      "divide-opacity-50",
      "overflow-y-visible"
    )}>
      {sortedConversations.map((conversation) => (
        <MemoizedConversationItem
          key={conversation.id}
          conversation={conversation}
          isActive={activeChat === conversation.id}
          onClick={() => onChatSelect(conversation.id)}
        />
      ))}
    </div>
  );
};

export default React.memo(ConversationList);