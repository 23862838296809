import React, { useState } from 'react';
import { Input } from '../../ui/input';
import { Label } from '../../ui/label';
import { Button } from '../../ui/button';
import { supabase } from '../../../lib/supabase';
import { useNavigate } from 'react-router-dom';
import { AlertCircle, CheckCircle2 } from 'lucide-react';
import { cn } from '@/lib/utils';

const SecuritySettings: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentPassword || !newPassword || !confirmPassword) return;
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      // First verify current password by attempting to sign in
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: (await supabase.auth.getUser()).data.user?.email || '',
        password: currentPassword,
      });

      if (signInError) {
        throw new Error('Current password is incorrect');
      }

      // Update password
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });

      if (updateError) throw updateError;

      // Clear form
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');

      // Show success state
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error('Failed to update password:', error);
      setError(
        error instanceof Error ? error.message : 'Failed to update password'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <h2 className="text-xl font-semibold text-white mb-6">Change Password</h2>

      {showSuccess && (
        <div className="bg-green-500/10 text-green-500 px-4 py-3 rounded-lg flex items-center mb-6">
          <CheckCircle2 className="h-5 w-5 mr-2" />
          <div>
            <p className="font-medium">
              Your password has been updated successfully!
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Label htmlFor="currentPassword" className="text-dark-800">
            Current Password
          </Label>
          <Input
            id="currentPassword"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            className={cn(
              'bg-dark-300 border-dark-400 text-white mt-1',
              error && error.includes('Current password') && 'border-red-500'
            )}
            required
          />
        </div>

        <div>
          <Label htmlFor="newPassword" className="text-dark-800">
            New Password
          </Label>
          <Input
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className={cn(
              'bg-dark-300 border-dark-400 text-white mt-1',
              error &&
                !error.includes('Current password') &&
                !error.includes('match') &&
                'border-red-500'
            )}
            required
          />
        </div>

        <div>
          <Label htmlFor="confirmPassword" className="text-dark-800">
            Confirm New Password
          </Label>
          <Input
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className={cn(
              'bg-dark-300 border-dark-400 text-white mt-1',
              error && error.includes('match') && 'border-red-500'
            )}
            required
          />
        </div>

        {error && (
          <div className="bg-red-500/10 text-red-500 text-sm px-4 py-2 rounded-lg flex items-center">
            <AlertCircle className="h-4 w-4 mr-2" />
            {error}
          </div>
        )}

        <div className="flex justify-end pt-4">
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              showSuccess ||
              !currentPassword ||
              !newPassword ||
              !confirmPassword
            }
            className={cn(
              'bg-gold-400 hover:bg-gold-500 text-dark-50',
              'min-w-[120px] relative'
            )}
          >
            {isSubmitting ? 'Updating...' : 'Update Password'}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SecuritySettings;
