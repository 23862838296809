import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VenueApiResult } from '../../../types/venue';
import { VenuePhoto } from './components/VenueCard/VenuePhoto';
import { VenueHeader } from './components/VenueCard/VenueHeader';
import { VenueContent } from './components/VenueCard/VenueContent';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';
import { useTheme } from '@/contexts/ThemeContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';

interface VenueCardProps {
  venue: VenueApiResult;
  distance?: number;
}

const VenueCard: React.FC<VenueCardProps> = ({ venue, distance }) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const cardRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [clubId, setClubId] = useState<string | null>(null);

  // Fetch club ID when component mounts
  useEffect(() => {
    const fetchClubId = async () => {
      try {
        // Sanitize club name for query
        const sanitizedName = venue.name
          .replace(/['"]/g, '') // Remove quotes
          .replace(/\s*\([^)]*\)\s*/g, ''); // Remove parentheses and their contents
        
        console.log('Searching for club:', { original: venue.name, sanitized: sanitizedName });

        const { data } = await supabase
          .from('clubs')
          .select('club_id')
          .or(`club_name.eq.${venue.name},club_name.eq.${sanitizedName}`)
          .single();
        
        if (data?.club_id) {
          setClubId(data.club_id);
        } else {
          // Try fuzzy match if exact match fails
          const { data: fuzzyData } = await supabase
            .from('clubs')
            .select('club_id')
            .ilike('club_name', `%${sanitizedName}%`)
            .single();
            
          if (fuzzyData?.club_id) {
            setClubId(fuzzyData.club_id);
          }
        }
      } catch (error) {
        console.error('Error fetching club ID:', error);
      }
    };
    
    fetchClubId();
  }, [venue.name]);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();

    try {
      // Sanitize club name for query
      const sanitizedName = venue.name
        .replace(/['"]/g, '') // Remove quotes
        .replace(/\s*\([^)]*\)\s*/g, '') // Remove parentheses and their contents
        .trim()
        .toLowerCase();
        
      // First try exact match
      let { data: clubData, error } = await supabase
        .from('clubs')
        .select('club_id, club_name')
        .ilike('club_name', venue.name)
        .maybeSingle();

      // If no exact match, try with sanitized name
      if (!clubData && !error) {
        ({ data: clubData, error } = await supabase
          .from('clubs')
          .select('club_id, club_name')
          .ilike('club_name', `%${sanitizedName}%`)
          .maybeSingle());
      }

      if (error) {
        console.error('Supabase error:', error.message);
        return;
      }

      if (!clubData?.club_id) {
        console.warn(`No matching club found for: "${venue.name}"`);
        return;
      }

      console.log('Club match found:', {
        venueName: venue.name,
        sanitizedName,
        matchedClub: clubData.club_name,
        clubId: clubData.club_id
      });

      navigate(`/dashboard/clubs/${clubData.club_id}`);
    } catch (err) {
      console.error('Unexpected error navigating to club profile:', err);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Card needs to be 50% visible
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText(venue.address);
    } catch (error) {
      console.error('Failed to copy address:', error);
    }
  };

  return (
    <div 
      ref={cardRef}
      className={cn( 
        "flex flex-col md:flex-row bg-dark-200 rounded-xl overflow-hidden",
        "border transition-all duration-500",
        isVisible 
          ? "border-gold-400 shadow-lg shadow-dark-900/5 scale-[1.01]" 
          : "border-dark-300 scale-100",
        "hover:border-gold-400 hover:shadow-xl hover:shadow-dark-900/5 hover:scale-[1.01]"
      )}
    >
      <VenuePhoto venue={venue} />
      
      <div className="flex-1 p-3">
        <VenueHeader
          name={venue.name}
          address={venue.address}
          distance={distance}
          logo={venue.logo}
          onClick={handleClick}
          onCopyAddress={handleCopyAddress}
        />
        
        <VenueContent venue={venue} />
      </div>
    </div>
  );
};

export default VenueCard;