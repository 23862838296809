import React, { useEffect, useMemo } from 'react';
import { VenueApiResult } from '../../../types/venue';
import { VENUE_TYPE_IMAGES } from '../../../lib/services/venues/venue-type-images';
import { cn } from '@/lib/utils';
import { supabase } from '@/lib/supabase';

interface VenueMarkerProps {
  venue: VenueApiResult;
  map: google.maps.Map | null;
  theme: 'dark' | 'light';
}

// Helper function to create a custom marker icon with the logo inside a circular clip and a thin gold ring.
function createCustomMarkerIcon(logoUrl: string, size: number = 30): Promise<string> {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Unable to get canvas context'));
      return;
    }
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const ringWidth = 4; // Adjust this value to change the gold ring thickness.
      const radius = (size / 2) - ringWidth;
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0, size, size);
      ctx.beginPath();
      ctx.arc(size / 2, size / 2, radius + 0.5, 0, Math.PI * 2);
      ctx.lineWidth = ringWidth;
      ctx.strokeStyle = '#FFD700'; // Gold color.
      ctx.stroke();
      resolve(canvas.toDataURL());
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = logoUrl;
  });
}

const VenueMarker: React.FC<VenueMarkerProps> = ({ venue, map, theme }) => {
  const position = useMemo(() => venue.coordinates, [venue.coordinates]);

  useEffect(() => {
    if (!map || !position || !venue.name) return;

    // Create the marker with a default circle icon.
    const marker = new google.maps.Marker({
      position,
      map,
      title: venue.name,
      animation: google.maps.Animation.DROP,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        fillColor: '#FFC933',
        fillOpacity: 1,
        strokeColor: '#202124',
        strokeWeight: 2,
      },
    });

    // If a club logo exists, generate a custom icon.
    if (venue.logo) {
      createCustomMarkerIcon(venue.logo, 30)
        .then((dataUrl) => {
          if (dataUrl) {
            marker.setIcon({
              url: dataUrl,
              scaledSize: new google.maps.Size(30, 30),
            });
          }
        })
        .catch((err) => {
          console.error('Failed to create custom marker icon:', err);
        });
    }

    const venueImage =
      venue.photos?.[0]?.url ||
      VENUE_TYPE_IMAGES[venue.type as keyof typeof VENUE_TYPE_IMAGES] ||
      VENUE_TYPE_IMAGES.default;

    // Map activities to only show the emoji (first token).
    const activitiesDisplay = venue.activities
      ?.slice(0, 3)
      .map((activity) => {
        const emoji = activity.split(' ')[0];
        return `<span style="margin-right: 4px;">${emoji}</span>`;
      })
      .join('');

    // Define colors based on theme.
    const bgColor = theme === 'dark' ? '#202124' : '#fff';
    const textColor = theme === 'dark' ? '#fff' : '#000';
    const shadow =
      theme === 'dark'
        ? '0 4px 6px rgba(0,0,0,0.3)'
        : '0 4px 6px rgba(0,0,0,0.1)';

    // The club name is rendered with id="clubName" to attach a click handler later.
    const contentString = `
      <div style="width: 200px; font-family: system-ui; background: ${bgColor}; border-radius: 8px; overflow: hidden; box-shadow: ${shadow}; color: ${textColor};">
        <div style="position: relative;">
          <!-- Custom close button -->
          <button 
            onclick="this.parentElement.parentElement.style.display='none'"
            style="
              position: absolute;
              top: 4px;
              right: 4px;
              width: 20px;
              height: 20px;
              background: rgba(0,0,0,0.5);
              border: none;
              border-radius: 50%;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2">
              <path d="M18 6L6 18M6 6l12 12"/>
            </svg>
          </button>
          <img 
            src="${venueImage}" 
            style="width: 100%; height: 80px; object-fit: cover;"
          />
          ${venue.logo ? `
            <div style="position: absolute; bottom: -12px; left: 10px; width: 28px; height: 28px; border-radius: 4px; overflow: hidden; border: 2px solid #202124; background: #202124;">
              <img src="${venue.logo}" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
          ` : ''}
        </div>
        <div style="padding: 8px; padding-top: ${venue.logo ? '16px' : '8px'};">
          <div style="display: flex; justify-content: space-between; align-items: flex-start; margin-bottom: 4px;">
            <div style="flex: 1;">
              <h3 id="clubName" style="margin: 0; font-size: 13px; font-weight: 600; line-height: 1.3; cursor: pointer; text-decoration: underline;">
                ${venue.name}
              </h3>
            </div>
            ${venue.rating ? `
              <div style="background: rgba(255,201,51,0.1); padding: 2px 4px; border-radius: 4px; text-align: center; min-width: 36px;">
                <div style="font-size: 12px; font-weight: 600; color: #FFC933;">★ ${venue.rating}</div>
                ${venue.user_ratings_total ? `
                  <div style="font-size: 9px; color: #7A7B7F; margin-top: 1px;">${venue.user_ratings_total}</div>
                ` : ''}
              </div>
            ` : ''}
          </div>
          ${activitiesDisplay ? `
            <div style="margin: 4px 0; font-size: 12px;">${activitiesDisplay}</div>
          ` : ''}
          <div style="font-size: 10px; line-height: 1.3; margin: 4px 0; color: ${theme === 'dark' ? '#7A7B7F' : '#555'};">
            ${venue.address}
          </div>
          <div style="margin-top: 6px;">
            <button 
              onclick="window.open('https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(venue.address)}', '_blank')"
              style="width: 100%; padding: 4px 6px; background: #FFC933; color: #202124; border: none; border-radius: 6px; font-size: 12px; font-weight: 500; cursor: pointer; display: flex; align-items: center; justify-content: center; gap: 4px;"
            >
              <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polygon points="3 11 22 2 13 21 11 13 3 11"/>
              </svg>
              Navigate
            </button>
          </div>
        </div>
      </div>
    `;

    // Dynamically import InfoBox.
    import('google-maps-infobox')
      .then((module) => {
        const InfoBoxConstructor = module.InfoBox || module.default;
        if (typeof InfoBoxConstructor !== 'function') {
          throw new Error('InfoBox is not a constructor');
        }
        const infoBox = new InfoBoxConstructor({
          content: contentString,
          disableAutoPan: false,
          alignBottom: true,
          boxStyle: {
            background: 'none',
            border: 'none',
            margin: '0',
            padding: '0'
          },
          closeBoxURL: '', // Remove default close button.
          pixelOffset: new google.maps.Size(-100, -10)
        });

        // When marker is clicked, close any previously open InfoBox and open this one.
        marker.addListener('click', () => {
          if ((window as any).currentInfoBox) {
            (window as any).currentInfoBox.close();
          }
          (window as any).currentInfoBox = infoBox;
          infoBox.open(map, marker);
          // After the infoBox content is rendered, attach a click handler to the club name.
          setTimeout(() => {
            const clubNameEl = document.getElementById('clubName');
            if (clubNameEl) {
              clubNameEl.addEventListener('click', async (e) => {
                e.stopPropagation();
                try {
                  const { data: clubData, error } = await supabase
                    .from('clubs')
                    .select('club_id')
                    .ilike('club_name', `%${venue.name}%`)
                    .maybeSingle();
                  if (error) {
                    console.error('Supabase error:', error.message);
                    return;
                  }
                  if (!clubData?.club_id) {
                    console.warn(`No matching club found for: "${venue.name}"`);
                    return;
                  }
                  window.location.href = `/dashboard/clubs/${clubData.club_id}`;
                } catch (err) {
                  console.error('Unexpected error navigating to club profile:', err);
                }
              });
            }
          }, 100);
        });

        // Close the InfoBox when clicking anywhere on the map.
        const mapClickListener = map.addListener('click', () => {
          infoBox.close();
        });

        // Cleanup on unmount.
        return () => {
          marker.setMap(null);
          infoBox.close();
          google.maps.event.removeListener(mapClickListener);
          google.maps.event.clearInstanceListeners(marker);
        };
      })
      .catch((err) => {
        console.error('Failed to load InfoBox:', err);
      });

    return () => {
      marker.setMap(null);
    };
  }, [venue, map, theme, position]);

  return null;
};

export default VenueMarker;
