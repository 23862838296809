import React, { useState, useEffect } from 'react';
import { MapPin, Briefcase, Plus, Shield } from 'lucide-react';
import { getCurrentTitle } from '../../../data/points';
import { sportEmojis } from '../../../lib/constants';
import { Member } from '@/types/member';
import ProfileImage from './ProfileImage';
import { useTheme } from '@/contexts/ThemeContext';
import SocialLinks from '../../social/SocialLinks';
import { SportsSelector } from './SportsSelector';
import { avatarCache } from '@/lib/utils/avatarCache';
import { cn } from '@/lib/utils';
import { Loader2 } from 'lucide-react';
import { CitySearchCombobox } from '../../ui/combobox/CitySearchCombobox';
import { useProfile } from '@/hooks/useProfile';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';

interface ProfileHeaderProps {
  member: Member;
  cityImage: string;
  onImageChange?: (file: File) => void;
  profile?: any;
  socialLinks?: {
    linkedinUrl?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;
  };
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  member,
  cityImage,
  onImageChange,
  profile,
  socialLinks,
}) => {
  const { isDarkMode } = useTheme();
  const { updateProfile } = useProfile();
  const [showSportsEditor, setShowSportsEditor] = useState(false);
  const navigate = useNavigate();
  const [isEditingCity, setIsEditingCity] = useState(false);
  const [cityError, setCityError] = useState<string | null>(null);
  const [isSavingCity, setIsSavingCity] = useState(false);
  const [avatarKey, setAvatarKey] = useState(Date.now());

  const handleClubClick = async () => {
    if (profile?.club?.club_name) {
      try {
        const { data } = await supabase
          .from('clubs')
          .select('club_id')
          .eq('club_name', profile.club.club_name.trim())
          .single();

        if (data?.club_id) {
          navigate(`/dashboard/clubs/${data.club_id}`);
        }
      } catch (error) {
        console.error('Error fetching club ID:', error);
      }
    }
  };

  const handleCityChange = async (city: string) => {
    try {
      setIsSavingCity(true);
      setCityError(null);
      await updateProfile({ city });
      setIsEditingCity(false);
      if (profile) {
        profile.city = city;
      }
    } catch (error) {
      console.error('Failed to update city:', error);
      setCityError('Failed to update city. Please try again.');
    } finally {
      setIsSavingCity(false);
    }
  };

  // **🏆 Memoized Current Title Based on Points**
  const currentTitle = React.useMemo(() => {
    return getCurrentTitle(member.points.total);
  }, [member.points.total]);

  // Update avatar cache when profile changes
  useEffect(() => {
    if (profile?.avatar_url && member?.id) {
      avatarCache.setAvatar(member.id, profile.avatar_url);
      setAvatarKey(Date.now());
    }
  }, [profile?.avatar_url, member?.id]);

  // **🚀 Memoized Job Info**
  const jobInfo = React.useMemo(() => ({
    jobTitle: profile?.job_type || member?.jobTitle || 'Role',
    company: profile?.company || member?.company || 'Company'
  }), [profile?.job_type, profile?.company, member?.jobTitle, member?.company]);

  // **🏅 Memoized Sports Transformation**
  const userSports = React.useMemo(() => {
    const userSportsArray = Array.isArray(profile?.user_sports) ? profile.user_sports : [];
    if (userSportsArray.length) {
      return userSportsArray
        .filter((us: any) => us?.sports?.name)
        .map((us: any) => ({
          name: us.sports.name,
          emoji: us.sports.emoji || '🎯'
        }));
    }
    const memberSportsArray = Array.isArray(member?.sports) ? member.sports : [];
    return memberSportsArray.map((sport: string) => ({
      name: sport,
      emoji: sportEmojis[sport.toLowerCase()] || '🎯'
    }));
  }, [profile?.user_sports, member?.sports]);

  // **📲 Extract Social Links**
  const finalSocialLinks = {
    linkedinUrl: socialLinks?.linkedinUrl || profile?.linkedin_url,
    facebookUrl: socialLinks?.facebookUrl || profile?.facebook_url,
    instagramUrl: socialLinks?.instagramUrl || profile?.instagram_url,
  };

  // Determine which club info to display:
  // Prefer the joined club name if available; otherwise, use custom_club.
  const clubDisplay = profile?.club?.club_name || profile?.custom_club;

  return (
    <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
      <div className="relative h-64">
        <img
          src={cityImage}
          alt={`${member.city} cityscape`}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
      </div>

      <div className="relative px-6 pb-8">
        <div className="flex flex-col items-center -mt-16">
          <div className="relative">
            <ProfileImage
              src={profile?.avatar_url || member.image}
              key={avatarKey}
              alt={member.name}
              size={128}
              onImageChange={onImageChange}
              className="shadow-xl ring-4 ring-dark-200"
            />
          </div>
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-white">
              {profile?.full_name || member?.name}
            </h2>
            <div className="mt-4 mb-3">
              {userSports?.length > 0 ? (
                <div className="relative inline-block">
                  <div className="flex flex-wrap justify-center gap-1.5 group">
                    {userSports.map((sport, index) => (
                      <span
                        key={index}
                        className={cn(
                          "inline-flex items-center px-2.5 py-1 rounded-full text-sm",
                          "bg-dark-300 text-white",
                          "transition-all duration-200",
                          "group-hover:opacity-90"
                        )}
                      >
                        {sport.emoji} {sport.name}
                      </span>
                    ))}
                    <button
                      onClick={() => setShowSportsEditor(true)}
                      className={cn(
                        "inline-flex items-center justify-center",
                        "w-8 h-8 rounded-full",
                        "bg-dark-300",
                        "text-gold-400 hover:text-gold-300 hover:bg-dark-400",
                        "transform hover:scale-110 active:scale-95",
                        "transition-all duration-200",
                        "shadow-lg"
                      )}
                      title="Edit sports"
                    >
                      <Plus className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={() => setShowSportsEditor(true)}
                  className={cn(
                    "inline-flex items-center gap-2 px-4 py-2",
                    "bg-dark-300 hover:bg-dark-400",
                    "text-gold-400",
                    "rounded-full transition-all duration-200",
                    "shadow-lg",
                    "transform hover:scale-105 active:scale-95"
                  )}
                >
                  <Plus className="h-5 w-5" />
                  <span>Add Sports</span>
                </button>
              )}
            </div>
            <div className="flex items-center justify-center text-dark-800 mt-1">
              {isEditingCity ? (
                <div className="w-64 relative z-[60]">
                  <div className="relative">
                    <CitySearchCombobox
                      value={profile?.city || member.city}
                      onChange={handleCityChange}
                      placeholder="Enter city..."
                      className={cn(
                        isDarkMode
                          ? "bg-dark-300/50 border-dark-400/50 backdrop-blur-sm hover:bg-dark-300 hover:border-dark-400"
                          : "bg-light-100/90 border-light-300 backdrop-blur-sm hover:bg-light-200 hover:border-light-400",
                        "focus-within:border-gold-400",
                        "transition-all duration-200",
                        cityError && "!border-red-500"
                      )}
                      error={cityError}
                    />
                    {isSavingCity && (
                      <div className="absolute inset-0 flex items-center justify-center bg-dark-300/50 backdrop-blur-sm rounded-lg">
                        <Loader2 className="h-5 w-5 animate-spin text-gold-400" />
                      </div>
                    )}
                  </div>
                  {cityError && (
                    <p className="absolute text-xs text-red-500 mt-1 left-0">{cityError}</p>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => setIsEditingCity(true)}
                  className={cn(
                    "flex items-center gap-2 px-4 py-1.5 rounded-full",
                    isDarkMode
                      ? "bg-dark-300/50 hover:bg-dark-300 text-dark-800 hover:text-white border-transparent hover:border-dark-400"
                      : "bg-light-200/90 hover:bg-light-300 text-light-600 hover:text-light-900 border-light-300 hover:border-light-400",
                    "backdrop-blur-sm",
                    "transition-all duration-200",
                    "group"
                  )}
                  disabled={isSavingCity}
                >
                  <MapPin className="h-4 w-4 text-gold-400 group-hover:scale-110 transition-transform" />
                  <span>{profile?.city || member.city}</span>
                </button>
              )}
            </div>
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <Briefcase className="h-4 w-4 mr-1" />
              {`${jobInfo.jobTitle} @ ${jobInfo.company}`}
            </div>
            {/* Display club info if available */}
            {clubDisplay && (
              <div className="flex items-center justify-center text-dark-800 mt-1">
                <button
                  onClick={handleClubClick}
                  className="flex items-center hover:text-gold-400 transition-colors"
                >
                  <Shield className="h-4 w-4 mr-1" />
                  <span>{clubDisplay}</span>
                </button>
              </div>
            )}
            <SocialLinks
              linkedinUrl={finalSocialLinks.linkedinUrl}
              facebookUrl={finalSocialLinks.facebookUrl}
              instagramUrl={finalSocialLinks.instagramUrl}
              className="mt-2"
            />
          </div>
        </div>
        
        {/* Sports Editor Modal */}
        {showSportsEditor && (
          <div className="fixed inset-0 z-50 bg-dark-50/80 backdrop-blur-sm flex items-center justify-center p-4">
            <div className="w-full max-w-2xl">
              <SportsSelector
                onClose={() => setShowSportsEditor(false)}
                onSportsUpdate={() => {
                  // Trigger a profile refresh
                  window.location.reload();
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
