import React, { useRef, useState } from 'react';
import { Camera } from 'lucide-react';
import { cn } from '@/lib/utils';
import { storage } from '@/lib/supabase/storage';
import { compressImage } from '@/lib/utils/image';
import { useAuth } from '@/contexts/AuthContext';
import { ProfileImage } from './ProfileImage';

interface ProfileImageUploadProps {
  value?: string;
  onChange: (value: string) => void;
  className?: string;
  error?: string;
}

export const ProfileImageUpload: React.FC<ProfileImageUploadProps> = ({
  value,
  onChange,
  className,
  error
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isCompressing, setIsCompressing] = useState(false);
  const { user } = useAuth();

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {    
    const file = e.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadError(null);
    
    if (file.size > 2 * 1024 * 1024) {
      try {
        setIsCompressing(true);
        const compressedFile = await compressImage(file, {
          maxWidth: 800,
          maxHeight: 800,
          quality: 0.8
        });
        setIsCompressing(false);

        if (compressedFile.size > 2 * 1024 * 1024) {
          setUploadError('Image is too large even after compression');
          return;
        }
        await uploadFile(compressedFile);
      } catch (err) {
        setUploadError('Image size must be less than 2MB');
        setIsUploading(false);
        return;
      }
    } else {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file: File) => {
    try {      
      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        throw new Error('Please select a JPEG or PNG image');
      }

      const { url, error } = await storage.uploadFile({
        bucket: 'avatars',
        file,
      });

      if (error) throw error;
      if (!url) throw new Error('No URL returned from upload');

      onChange(url);
    } catch (err) {
      console.error('Error uploading avatar:', err);
      setUploadError('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
      setIsCompressing(false);
    }
  };

  const handleRemove = () => {
    onChange('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={cn("relative group flex flex-col items-center", className)}>
      <div className="relative">
        <ProfileImage 
          src={value} 
          className={cn(
            "mx-auto overflow-hidden shadow-xl relative",
            isUploading && "opacity-50"
          )}
          size={128}
          error={error || uploadError}
        />
        
        {/* Glow Effect */}
        <div className={cn(
          "absolute -inset-1",
          "bg-gradient-to-r from-gold-400/10 to-gold-500/10",
          "rounded-full blur-lg",
          "opacity-0 group-hover:opacity-100",
          "transition-opacity duration-500",
          "-z-10"
        )} />

        {/* Upload Button */}
        <label
          htmlFor="profile-image-upload"
          className={cn(
            "absolute inset-0 cursor-pointer overflow-hidden",
            "flex items-center justify-center",
            "bg-dark-900/0",
            "opacity-0 group-hover:opacity-100",
            "transition-opacity duration-200",
            isUploading && "cursor-wait"
          )}
        >
          <div className={cn(
            "absolute inset-0",
            "bg-dark-900/40",
            "rounded-full",
            "flex items-center justify-center",
            "transition-all duration-300",
            "backdrop-blur-sm"
          )}>
            {isUploading ? (
              <div className="h-6 w-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
            ) : (
              <Camera className="h-6 w-6 text-white transform scale-75 group-hover:scale-100 transition-transform" />
            )}
          </div>
        </label>
      </div>

      <input
        ref={fileInputRef}
        id="profile-image-upload"
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        disabled={isUploading}
        className="sr-only"
        aria-label="Upload profile picture"
      />
    </div>
  );
};