import React from 'react';
import { Activity } from '../../types/activity';
import RematchButton from './RematchButton';
import ManageButton from './buttons/ManageButton';
import { MessageCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { cn } from '@/lib/utils';
import AcceptButton from './buttons/AcceptButton';
import DeclineButton from './buttons/DeclineButton';
import RateActivityButton from './buttons/RateActivityButton';

interface ActivityActionsProps {
  activity: Activity;
  onManageSession: (activity: Activity) => void;
  onRematch: (activity: Activity) => void;
  onDeclineRequest: (reason?: string) => void;
  onAccept: () => void;
  onRate: () => void;
  isAccepting?: boolean;
  isDeclining?: boolean;
  isRating?: boolean;
}

const ActivityActions: React.FC<ActivityActionsProps> = ({
  activity,
  onManageSession,
  onRematch,
  onDeclineRequest,
  onAccept,
  onRate = () => {},
  isAccepting = false,
  isDeclining = false,
  isRating = false
}) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  // Check if current user is the initiator (player 1)
  const isInitiator = user?.id === activity.player1Id;

  // Determine which buttons to show
  const showAcceptDecline = activity.status === 'new_request' && !isInitiator;
  
  const showManage = activity.status === 'confirmed' || 
                     (activity.status === 'new_request' && isInitiator);
  
  // Show rate button for played activities that haven't been rated yet
  const showRate = activity.status === 'played' && 
                  !activity.isRated && 
                  !activity.rating_overall &&
                  !activity.rating_punctuality &&
                  !activity.rating_sportsmanship &&
                  !activity.rating_skill_match &&
                  !activity.rating_communication;

  const showRematch = activity.status === 'played';

  const handleMessageClick = async () => {
    try {
      // Get current user's database ID
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user?.id)
        .single();

      if (userError) throw userError;

      // Get opponent's database ID
      const { data: opponentData, error: opponentError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', activity.opponent.id)
        .single();

      if (opponentError) throw opponentError;

      // Get or create conversation using RPC function
      const { data: conversation, error: conversationError } = await supabase.rpc(
        'get_or_create_conversation',
        {
          user1_id: userData.id,
          user2_id: opponentData.id
        }
      );

      if (conversationError) throw conversationError;

      // Navigate to messages with the conversation ID
      navigate('/dashboard/messages', {
        state: {
          activeChat: conversation,
          initialMessage: `Hi ${activity.opponent.name}!`,
          showMobileList: false
        }
      });

    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  return (
    <div className="flex items-center gap-1.5 sm:gap-2">
      {showRate && (
        <RateActivityButton 
          onClick={onRate}
          isLoading={isRating}
          className="min-w-[72px] sm:min-w-[90px]"
        />
      )}
      
      {showRematch && (
        <RematchButton 
          activity={activity} 
          onRematch={onRematch}
          className="min-w-[72px] sm:min-w-[90px]"
        />
      )}
      
      {showAcceptDecline && (
        <div className={cn(
          "flex items-center gap-1.5 sm:gap-2"
        )}>
          <AcceptButton 
            isLoading={isAccepting}
            isAccepted={activity.status === 'confirmed'}
            onClick={onAccept} 
            className="min-w-[72px] sm:min-w-[90px]"
          />
          <DeclineButton 
            isLoading={isDeclining}
            isDeclined={activity.status === 'declined'}
            onClick={onDeclineRequest} 
            className="min-w-[72px] sm:min-w-[90px]"
          />
        </div>
      )}
      
      {showManage && (
        <ManageButton 
          onClick={() => onManageSession(activity)}
          className="min-w-[72px] sm:min-w-[90px]"
        />
      )}
      
      {/* Message Button */}
      <button
        onClick={handleMessageClick}
        className={cn(
          "flex items-center justify-center gap-2",
          "h-9 px-2 sm:h-10 sm:px-3",
          "bg-dark-300 hover:bg-dark-400 text-white rounded-lg transition-colors",
          "min-w-[40px] sm:min-w-[90px]"
        )}
      >
        <MessageCircle className="h-4 w-4" />
        <span className="hidden sm:inline">Message</span>
      </button>
    </div>
  );
};

export default ActivityActions;