import React from 'react';
import { MapPin, UserX, Check, Users2, Briefcase, Clock } from 'lucide-react';
import { useConnectionStore } from '@/store/connectionStore';
import { extractCityName } from '../../../lib/utils/text';
import { cn } from '@/lib/utils';

const BlockedMembersList = () => {
  const { connections, unblockUser } = useConnectionStore();
  const defaultAvatar = 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png';

  const blockedUsers = connections.filter((conn) => conn.status === 'blocked');

  if (blockedUsers.length === 0) {
    return (
      <div className={cn(
        "text-center py-12 rounded-xl border",
        "bg-dark-300 border-dark-400"
      )}>
        <Users2 className="h-12 w-12 text-dark-800 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-white mb-2">No Blocked Members</h3>
        <p className="text-dark-800 max-w-sm mx-auto">
          You haven't blocked any members yet
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 max-w-3xl mx-auto">
      {blockedUsers.map((connection) => (
        <div
          key={connection.connection_id}
          className={cn(
            "bg-dark-200 rounded-2xl overflow-hidden border border-dark-300",
            "hover:border-gold-400 transition-all duration-300",
            "shadow-lg hover:shadow-xl transform hover:scale-[1.01]",
            "dark:bg-dark-200 dark:border-dark-300",
            "light:bg-white light:border-gray-200"
          )}
        >
          {/* Profile Banner */}
          <div className={cn(
            "relative h-24",
            "dark:bg-gradient-to-br dark:from-dark-300 dark:via-dark-400 dark:to-dark-300",
            "light:bg-gradient-to-br light:from-gray-100 light:via-gray-50 light:to-gray-100"
          )}>
            <div className={cn(
              "absolute inset-0 bg-gradient-to-t",
              "dark:from-dark-200/90 dark:to-transparent",
              "light:from-white/90 light:to-transparent"
            )} />
            <div className="absolute -bottom-10 left-4">
              <div className="relative">
                <img
                  src={connection.other_user?.avatar_url || defaultAvatar}
                  alt={connection.other_user?.full_name || 'User'}
                  className={cn(
                    "w-20 h-20 rounded-full object-cover",
                    "border-4 border-dark-200 group-hover:border-gold-400/50",
                    "transition-all duration-300 shadow-xl",
                    "dark:border-dark-200 dark:group-hover:border-gold-400",
                    "light:border-white light:group-hover:border-gold-500"
                  )}
                />
              </div>
            </div>
          </div>

          {/* Content */}
          <div className="p-4 pt-14 relative">
            <div className="space-y-3">
              {/* Name and Location */}
              <div>
                <div className={cn(
                  "text-lg font-medium",
                  "text-white hover:text-gold-400 transition-colors"
                )}>
                  {connection.other_user?.full_name}
                </div>
                <div className={cn(
                  "text-sm mt-1.5 flex items-center",
                  "dark:text-dark-800",
                  "light:text-gray-500"
                )}>
                  <MapPin className={cn(
                    "h-4 w-4 mr-1",
                    "dark:text-dark-800/75",
                    "light:text-gray-400"
                  )} />
                  {extractCityName(connection.other_user?.city)}
                </div>

                {/* Job Info */}
                {(connection.other_user?.job_type || connection.other_user?.company) && (
                  <div className="flex items-center text-dark-800 text-sm">
                    <Briefcase className="h-4 w-4 mr-1.5 shrink-0" />
                    {connection.other_user?.job_type}
                    {connection.other_user?.job_type && connection.other_user?.company && " at "}
                    {connection.other_user?.company}
                  </div>
                )}

                {/* Blocked Date */}
                <div className="flex items-center text-dark-800 text-sm mt-2">
                  <Clock className="h-4 w-4 mr-1" />
                  <span>Blocked on {new Date(connection.updated_at).toLocaleDateString()}</span>
                </div>

                {/* Unblock Button */}
                <div className="mt-4">
                  <button
                    onClick={() => unblockUser(connection.connection_id)}
                    className={cn(
                      "w-full flex items-center justify-center gap-2 relative",
                      "px-4 py-2.5 rounded-lg",
                      "bg-dark-300 hover:bg-dark-400",
                      "text-dark-800 hover:text-white",
                      "border border-dark-400 hover:border-dark-500",
                      "transition-all duration-200",
                      "transform hover:scale-105 active:scale-95",
                      "shadow-sm hover:shadow-md"
                    )}
                  >
                    <Check className="h-4 w-4" />
                    <span>Unblock Member</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlockedMembersList;