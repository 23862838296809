import { supabase } from '../supabase';
import { API_CONFIG } from '../constants';
import { Post, Comment } from '../../types/social';
import { compressImage } from '../utils/image';

const { MAX_RETRIES, RETRY_DELAY } = API_CONFIG;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const COMMENTS_PER_PAGE = 10;
const USER_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes

// Cache for user data
interface UserCacheEntry {
  data: { auth_id: string; full_name: string }[];
  timestamp: number;
}

const userCache = new Map<string, UserCacheEntry>();
const GLOBAL_CACHE_KEY = 'all_users';

// Get all users for mention matching
export async function getAllUsers() {
  try {
    const now = Date.now();
    
    // Return cached data if valid
    const cached = userCache.get(GLOBAL_CACHE_KEY);
    if (cached && now - cached.timestamp < USER_CACHE_DURATION) {
      return cached.data;
    }
    
    const { data, error } = await supabase
      .from('users')
      .select('auth_id, full_name');

    if (error) throw error;

    // Update cache
    userCache.set(GLOBAL_CACHE_KEY, {
      data: data || [],
      timestamp: now
    });

    return data;
  } catch (error) {
    console.error('Error fetching users:', error);
    // Return cached data if available, even if expired
    const cached = userCache.get(GLOBAL_CACHE_KEY);
    return cached?.data || [];
  }
}

// Get likes for multiple posts in a batch
export async function getPostLikesBatch(postIds: string[]): Promise<Record<string, boolean>> {
  try {
    if (!postIds.length) return {};

    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    const { data, error } = await supabase
      .rpc('get_post_likes_batch', {
        post_ids: postIds,
        user_auth_id: user.id
      });

    if (error) throw error;

    // Transform response into a map of postId -> isLiked
    return (data || []).reduce((acc, { post_id, is_liked }) => {
      acc[post_id] = is_liked;
      return acc;
    }, {} as Record<string, boolean>);
  } catch (error) {
    console.error('Error getting post likes batch:', error);
    return {};
  }
}

// Utility function for retrying failed requests
async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    if (retries > 0 && error instanceof Error && error.message.includes('Failed to fetch')) {
      console.log(`Network error, retrying... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return fetchWithRetry(operation, retries - 1);
    }
    throw error;
  }
}

// Check if user has liked a post
export async function checkPostLike(postId: string): Promise<boolean> {
  try {
    // Get current user
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User not found');

    // Check if post is already liked
    const { data, error } = await supabase
      .from('post_likes')
      .select('id')
      .eq('post_id', postId)
      .eq('user_id', userData.id)
      .maybeSingle();

    if (error) throw error;
    return !!data;
  } catch (error) {
    console.error('Error checking post like:', error);
    return false;
  }
}

// Toggle like on a post
export async function toggleLike(postId: string): Promise<boolean> {
  try {
    // Validate UUID format
    const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (!UUID_REGEX.test(postId)) {
      console.warn('Invalid post ID:', postId);
      return false;
    }

    // Get current user
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) {
      // Handle network errors gracefully
      if (userError.message.toLowerCase().includes('failed to fetch')) {
        console.warn('Network error checking auth status');
        return false;
      }
      console.error('Auth error checking likes:', userError);
      return false;
    }
    if (!user) {
      console.warn('No authenticated user');
      return false;
    }

    // Get user's database ID
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) {
      // Handle network errors gracefully
      if (userDataError.message.toLowerCase().includes('failed to fetch')) {
        console.warn('Network error fetching user data');
        return false;
      }
      console.warn('User data error:', userDataError);
      return false;
    }
    if (!userData) {
      console.warn('User not found');
      return false;
    }

    // Check if post is already liked
    const { data: existingLike, error: checkError } = await supabase
      .from('post_likes')
      .select('id, post_id')
      .eq('post_id', postId)
      .eq('user_id', userData.id)
      .maybeSingle();

    if (checkError) {
      // Handle invalid UUID format error gracefully
      if (checkError.code === '22P02') {
        console.log('Invalid post ID format:', postId);
        return false;
      }
      // Handle network errors gracefully
      if (checkError.message.toLowerCase().includes('failed to fetch')) {
        console.warn('Network error checking like status');
        return false;
      }
      console.warn('Like check error:', checkError);
      return false;
    }

    if (existingLike) {
      // Unlike - delete existing like
      const { error: unlikeError } = await supabase
        .from('post_likes')
        .delete()
        .eq('id', existingLike.id);

      if (unlikeError) throw unlikeError;
      return false;
    } else {
      // Like - create new like if it doesn't exist
      const { error: likeError } = await supabase
        .from('post_likes')
        .upsert({
          post_id: postId,
          user_id: userData.id
        }, {
          onConflict: 'post_id,user_id'
        }, {
          onConflict: 'post_id,user_id'
        });

      if (likeError) throw likeError;
      return true;
    }
    
  } catch (error) {
    console.error('Error toggling like:', error);
    return false;
  }
}

// Get comments for a post with pagination
export async function getComments(postId: string, page: number = 1): Promise<{
  comments: Comment[];
  hasMore: boolean;
  total: number;
}> {
  try {
    const from = (page - 1) * COMMENTS_PER_PAGE;
    const to = from + COMMENTS_PER_PAGE - 1;

    const { data, error, count } = await fetchWithRetry(() => supabase
      .from('comments')
      .select(`
        id,
        content,
        created_at,
        users (
          id,
          auth_id,
          full_name,
          avatar_url
        )
      `, { count: 'exact' })
      .eq('post_id', postId)
      .order('created_at', { ascending: true })
      .range(from, to));

    if (error) throw error;

    const comments = data.map(comment => ({
      id: comment.id,
      content: comment.content,
      author: {
        id: comment.users.auth_id,
        name: comment.users.full_name,
        image: comment.users.avatar_url || 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=300&q=80'
      },
      createdAt: new Date(comment.created_at).toISOString()
    }));

    return {
      comments,
      hasMore: count ? count > to + 1 : false,
      total: count || 0
    };
  } catch (error) {
    console.error('Error fetching comments:', error);
    // Return empty state for network errors
    if (error instanceof Error && error.message.includes('Failed to fetch')) {
      return {
        comments: [],
        hasMore: false,
        total: 0
      };
    }
    throw error;
  }
}

// Create a new comment
export async function createComment(
  postId: string, 
  content: string,
  parentId?: string
): Promise<Comment> {
  try {
    // Validate inputs
    if (!postId?.trim()) throw new Error('Post ID is required');
    if (!content?.trim()) throw new Error('Comment content is required');

    // Get current user with retry
    let retries = MAX_RETRIES;
    let user;
    
    while (retries > 0) {
      try {
        const { data: { user: currentUser }, error: userError } = await supabase.auth.getUser();
        if (userError) throw userError; 
        if (!currentUser) throw new Error('Not authenticated');
        user = currentUser;
        break;
      } catch (err) {
        if (err instanceof Error && err.message.includes('Failed to fetch')) {
          retries--;
          if (retries > 0) {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
        }
        throw err;
      }
    }

    if (!user) throw new Error('Failed to authenticate user');

    // Get user's database record
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id, full_name, avatar_url')
      .eq('auth_id', user.id)
      .single();

    if (userDataError) throw userDataError;
    if (!userData) throw new Error('User profile not found');

    // Create comment with retry
    retries = MAX_RETRIES;
    let commentData;
    
    while (retries > 0) {
      try {
        const { data, error: commentError } = await supabase
          .from('comments')
          .insert({
            post_id: postId,
            user_id: userData.id,
            content: content.trim()
          })
          .select(`
            id,
            content,
            created_at,
            users (
              id,
              auth_id,
              full_name,
              avatar_url
            )
          `)
          .single();

        if (commentError) throw commentError;
        if (!data) throw new Error('Failed to create comment');
        commentData = data;
        break;
      } catch (err) {
        if (err instanceof Error && err.message.includes('Failed to fetch')) {
          retries--;
          if (retries > 0) {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
        }
        throw err;
      }
    }

    // Return formatted comment
    return {
      id: commentData.id,
      content: content.trim(),
      author: {
        id: user.id,
        name: userData.full_name,
        image: userData.avatar_url || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png'
      },
      createdAt: commentData.created_at
    };
  } catch (error) {
    console.error('Failed to create comment:', error);
    // Provide more descriptive error messages
    if (error instanceof Error) {
      if (error.message.includes('Failed to fetch')) {
        throw new Error('Unable to create comment. Please check your connection.');
      }
      if (error.message.includes('Not authenticated')) {
        throw new Error('Please sign in to comment.');
      }
    }
    throw error;
  }
}

// Update a comment
async function updateComment(commentId: string, content: string): Promise<void> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('comments')
      .update({ content: content.trim() })
      .eq('id', commentId)
      .single();

    if (error) throw error;
  } catch (error) {
    console.error('Failed to update comment:', error);
    throw error;
  }
}

// Delete a comment
async function deleteComment(commentId: string): Promise<void> {
  try {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) throw new Error('Not authenticated');

    const { error } = await supabase
      .from('comments')
      .delete()
      .eq('id', commentId);

    if (error) throw error;
  } catch (error) {
    console.error('Failed to delete comment:', error);
    throw error;
  }
}
