import React, { useState, useEffect } from 'react';
import RequestMatchModal from '../../matchmaking/RequestMatchModal';
import { MapPin, Briefcase, Shield } from 'lucide-react';
import { getCurrentTitle } from '../../../data/points';
import { sportEmojis } from '../../../lib/constants';
import CityLink from '../../ui/CityLink';
import { Member } from '@/types/member';
import ProfileImage from './ProfileImage';
import { useTheme } from '@/contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import SocialLinks from '../../social/SocialLinks';
import { extractCityName } from '../../../lib/utils/text';
import { supabase } from '@/lib/supabase';

interface ProfileHeaderProps {
  member: Member;
  cityImage: string;
  onImageChange?: (file: File) => void;
  profile?: any;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  member,
  cityImage,
  onImageChange,
  profile,
}) => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const handleClubClick = async () => {
    if (profile?.club?.club_name) {
      try {
        const { data } = await supabase
          .from('clubs')
          .select('club_id')
          .eq('club_name', profile.club.club_name)
          .single();

        if (data?.club_id) {
          navigate(`/dashboard/clubs/${data.club_id}`);
        }
      } catch (error) {
        console.error('Error fetching club ID:', error);
      }
    }
  };

  const [showMatchModal, setShowMatchModal] = useState(false);
  const [selectedSport, setSelectedSport] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [isLoadingRating, setIsLoadingRating] = useState(true);

  const handleSportClick = (sport: string) => {
    setSelectedSport(sport);
    setShowMatchModal(true);
  };

  // Fetch rating when profile loads
  useEffect(() => {
    const fetchRating = async () => {
      if (!profile?.id) return;
      
      try {
        const { data, error } = await supabase
          .from('user_ratings')
          .select('overall_rating')
          .eq('user_id', profile.id)
          .single();

        if (error) {
          if (error.code !== 'PGRST116') { // Not found error
            console.error('Error fetching rating:', error);
          }
          setRating(0);
          return;
        }

        setRating(parseFloat(data.overall_rating) || 0);
      } catch (error) {
        console.error('Error fetching rating:', error);
        setRating(0);
      } finally {
        setIsLoadingRating(false);
      }
    };

    fetchRating();
  }, [profile?.id]);

  // **🏆 Memoized Current Title Based on Points**
  const currentTitle = React.useMemo(() => {
    return getCurrentTitle(member.points.total);
  }, [member.points.total]);

  // **🚀 Memoized Job Info (Fetched from `users` instead of `user_profiles`)**
  const jobInfo = React.useMemo(() => ({
    jobTitle: profile?.job_type || member?.jobTitle || 'Role',
    company: profile?.company || member?.company || 'Company'
  }), [profile?.job_type, profile?.company, member?.jobTitle, member?.company]);

  // **🏅 Memoized Sports Transformation**
  const userSports = React.useMemo(() => {
    if (profile?.user_sports?.length) {
      return profile.user_sports.map(
        (us: any) => ({
          name: us.sports.name,
          emoji: us.sports.emoji
        })
      );
    }
    return member.sports.map(sport => ({
      name: sport,
      emoji: sportEmojis[sport] || '🎯'
    }));
  }, [profile?.user_sports, member?.sports]);

  // **📲 Extract Social Links Directly from `users` Table**
  const socialLinks = {
    linkedinUrl: profile?.linkedin_url || null,
    facebookUrl: profile?.facebook_url || null,
    instagramUrl: profile?.instagram_url || null
  };

  // Determine which club info to display:
  // Use the official club name if available; otherwise, use custom_club.
  const clubDisplay = profile?.club?.club_name || profile?.custom_club;

  return (
    <div className="bg-dark-200 rounded-2xl overflow-hidden border border-dark-300">
      <div className="relative h-64">
        <img
          src={cityImage}
          alt={`${member.city} cityscape`}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-dark-200 via-dark-200/40 to-transparent" />
      </div>

      <div className="relative px-6 pb-8">
        <div className="flex flex-col items-center -mt-16">
          <div className="relative">
            <ProfileImage
              src={profile?.avatar_url || member.image}
              alt={member.name}
              size={128}
              onImageChange={onImageChange}
              className="shadow-xl ring-4 ring-dark-200"
            />
          </div>
          <div className="mt-4 text-center">
            <h2 className="text-2xl font-bold text-white">
              {profile?.full_name || member?.name}
            </h2>
            {userSports?.length > 0 && (
              <div className="flex flex-wrap justify-center gap-1.5 mt-2 mb-3">
                {userSports.map((sport, index) => (
                  <span
                    onClick={() => handleSportClick(`${sport.emoji} ${sport.name}`)}
                    key={index}
                    className="inline-flex items-center px-2.5 py-1 bg-dark-300 rounded-full text-sm text-white hover:bg-dark-400 transition-colors cursor-pointer"
                  >
                    {sport.emoji} {sport.name}
                  </span>
                ))}
              </div>
            )}
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <CityLink city={profile?.city || member.city} />
            </div>
            <div className="flex items-center justify-center text-dark-800 mt-1">
              <Briefcase className="h-4 w-4 mr-1" />
              {`${jobInfo.jobTitle} @ ${jobInfo.company}`}
            </div>
            {clubDisplay && (
              <div className="flex items-center justify-center text-dark-800 mt-1">
                <button
                  onClick={handleClubClick}
                  className="flex items-center hover:text-gold-400 transition-colors"
                >
                  <Shield className="h-4 w-4 mr-1" />
                  <span>{clubDisplay}</span>
                </button>
              </div>
            )}
            <SocialLinks
              linkedinUrl={socialLinks.linkedinUrl}
              facebookUrl={socialLinks.facebookUrl}
              instagramUrl={socialLinks.instagramUrl}
              className="mt-2"
            />
          </div>
        </div>
      </div>
      
      {/* Match Modal */}
      {showMatchModal && (
        <RequestMatchModal
          isOpen={showMatchModal}
          onClose={() => {
            setShowMatchModal(false);
            setSelectedSport(null);
          }}
          member={{
            id: member.id,
            name: member.name,
            city: member.city,
            sports: member.sports
          }}
          preSelectedSport={selectedSport}
        />
      )}
    </div>
  );
};

export default ProfileHeader;