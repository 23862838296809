import React from 'react';
import { Star, Clock, Trophy, Target, MessageCircle } from 'lucide-react';
import { ReviewStats } from '../../types/review';
import { cn } from '@/lib/utils';

interface ReviewStatsProps {
  stats: ReviewStats;
}

const ReviewStatsDisplay: React.FC<ReviewStatsProps> = ({ stats }) => {
  const {
    averageRating,
    totalRatings,
    avgPunctuality,
    avgSportsmanship,
    avgSkillMatch,
    avgCommunication
  } = stats;

  return (
    <div className="bg-dark-200 rounded-2xl p-6 border border-dark-300">
      <div className="flex items-start justify-between">
        <div>
          <h3 className="text-lg font-semibold text-white">Overall Rating</h3>
          <div className="flex items-center mt-2">
            <div className="flex items-center">
              <Star className="h-6 w-6 text-gold-400 fill-gold-400" />
              <span className="text-2xl font-bold text-white ml-2">
                {(averageRating || 0).toFixed(1)}
              </span>
            </div>
            <span className="text-dark-800 ml-3">
              ({totalRatings} {totalRatings === 1 ? 'rating' : 'ratings'})
            </span>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mt-6">
        {/* Punctuality Rating */}
        <div className="bg-dark-300 rounded-xl p-4">
          <div className="flex items-center text-dark-800 mb-2">
            <Clock className="h-4 w-4 mr-2" />
            <span>Punctuality</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-gold-400 fill-gold-400" />
            <span className="text-xl font-bold text-white ml-2">
              {(avgPunctuality || 0).toFixed(1)}
            </span>
          </div>
        </div>

        {/* Sportsmanship Rating */}
        <div className="bg-dark-300 rounded-xl p-4">
          <div className="flex items-center text-dark-800 mb-2">
            <Trophy className="h-4 w-4 mr-2" />
            <span>Sportsmanship</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-gold-400 fill-gold-400" />
            <span className="text-xl font-bold text-white ml-2">
              {(avgSportsmanship || 0).toFixed(1)}
            </span>
          </div>
        </div>

        {/* Skill Match Rating */}
        <div className="bg-dark-300 rounded-xl p-4">
          <div className="flex items-center text-dark-800 mb-2">
            <Target className="h-4 w-4 mr-2" />
            <span>Skill Match</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-gold-400 fill-gold-400" />
            <span className="text-xl font-bold text-white ml-2">
              {(avgSkillMatch || 0).toFixed(1)}
            </span>
          </div>
        </div>

        {/* Communication Rating */}
        <div className="bg-dark-300 rounded-xl p-4">
          <div className="flex items-center text-dark-800 mb-2">
            <MessageCircle className="h-4 w-4 mr-2" />
            <span>Communication</span>
          </div>
          <div className="flex items-center">
            <Star className="h-5 w-5 text-gold-400 fill-gold-400" />
            <span className="text-xl font-bold text-white ml-2">
              {(avgCommunication || 0).toFixed(1)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStatsDisplay;