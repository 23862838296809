import React, { useRef, useEffect, useMemo, useState } from 'react';
import MessageGroup from './MessageGroup';
import TypingIndicator from './TypingIndicator';
import type { Message } from '../../types/chat';
import { format } from 'date-fns';

interface MessageListProps {
  messages: Message[];
  isTyping: boolean;
  typingName: string;
  conversation?: {
    name: string;
    image: string;
  };
  onDelete?: (messageId: string) => void;
  onEdit?: (messageId: string, newContent: string) => void;
  onReply?: (message: Message) => void;
  editingMessage?: string | null;
}

const groupMessagesByDate = (messages: Message[] = []): Record<string, Message[]> => {
  const groups: Record<string, Message[]> = {};
  
  messages.forEach(message => {
    // Handle both string and Date types for message.time
    const messageDate = typeof message.time === 'string' 
      ? new Date(message.time) 
      : message.time;

    // Validate the date
    if (!(messageDate instanceof Date) || isNaN(messageDate.getTime())) {
      console.warn('Invalid date for message:', message);
      return;
    }

    const dateKey = messageDate.toDateString();
    
    if (!groups[dateKey]) {
      groups[dateKey] = [];
    }
    groups[dateKey].push(message);
  });
  
  return groups;
};

const MessageList: React.FC<MessageListProps> = ({
  messages,
  isTyping,
  typingName,
  conversation,
  onDelete,
  onEdit,
  onReply,
  editingMessage
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  
  // Add console log when messages prop changes
  React.useEffect(() => {
    console.log('MessageList received messages:', {
      componentName: 'MessageList',
      messagesLength: messages?.length || 0,
      messages: messages?.map(m => ({
        id: m.id,
        content: m.content,
        time: m.time,
        isSender: m.isSender,
        status: m.status
      }))
    });
  }, [messages]);

  // Memoize grouped messages
  const messageGroups = useMemo(() => {
    if (!Array.isArray(messages)) {
      console.warn('Messages is not an array:', messages);
      return {};
    }

    return groupMessagesByDate(messages);
  }, [messages]);

  useEffect(() => {
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Scroll immediately for first load
    if (Array.isArray(messages) && messages.length > 0) {
      scrollToBottom();
    }

    // Use requestAnimationFrame for smooth scrolling on updates
    const timeoutId = setTimeout(() => {
      requestAnimationFrame(scrollToBottom);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [messages]);

  return (
    <div className="h-full overflow-y-auto px-2 sm:px-4 py-6 overflow-x-hidden relative z-10 bg-transparent">
      {Object.entries(messageGroups).map(([date, messages]) => (
        <MessageGroup
          key={date}
          date={date}
          messages={messages}
          conversation={conversation}
          onDelete={onDelete}
          onEdit={onEdit}
          onReply={onReply}
          editingId={editingMessage}
        />
      ))}
      <div ref={messagesEndRef} className="h-0" />
      {isTyping && <TypingIndicator name={typingName} />}
    </div>
  );
};

export default MessageList;