import { Venue, VenueSuggestion } from '../types/venue';
import { venues } from '../data/venues';
import { searchLocation, MapLocation } from './mapping';

function searchVenues(query: string, city?: string): VenueSuggestion[] {
  const normalizedQuery = query.toLowerCase();

  return venues
    .filter(venue => {
      if (city && venue.location.toLowerCase() !== city.toLowerCase()) {
        return false;
      }

      return (
        venue.name.toLowerCase().includes(normalizedQuery) ||
        venue.address.toLowerCase().includes(normalizedQuery)
      );
    })
    .map((venue): VenueSuggestion => ({
      id: venue.id,
      name: venue.name,
      location: venue.address,
      type: venue.type,
      category: venue.category,
      coordinates: venue.coordinates
    }));
}

// ✅ New helper: resolve city bounds dynamically using OSM
async function getCityBoundingBox(city: string): Promise<string | null> {
  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(city)}&format=json&limit=1`
    );

    const results = await response.json();

    if (results.length === 0) return null;

    const { boundingbox } = results[0];
    // Format: [south, north, west, east]
    return `&bbox=${boundingbox[2]},${boundingbox[0]},${boundingbox[3]},${boundingbox[1]}`;
  } catch (err) {
    console.error('Failed to resolve bounding box for city:', city, err);
    return null;
  }
}

// ✅ Updated function
export async function searchVenuesWithGeocode(
  query: string,
  city: string
): Promise<(VenueSuggestion | MapLocation)[]> {
  if (!query.trim() || !city) return [];

  try {
    const venueSuggestions = searchVenues(query, city);

    const bbox = await getCityBoundingBox(city);

    const photonUrl = `https://photon.komoot.io/api/?q=${encodeURIComponent(query)}${bbox || ''}&limit=10`;

    const response = await fetch(photonUrl);
    const data = await response.json();

    const photonResults = (data.features || []).map((feature: any): MapLocation => ({
      name: feature.properties.name || feature.properties.street || '',
      address: [
        feature.properties.street,
        feature.properties.housenumber,
        feature.properties.postcode,
        feature.properties.city || city
      ]
        .filter(Boolean)
        .join(', '),
      coordinates: {
        lat: feature.geometry.coordinates[1],
        lng: feature.geometry.coordinates[0]
      }
    }));

    const allResults = [...venueSuggestions, ...photonResults];

    // Optional: deduplicate by name/address
    const unique = Array.from(
      new Map(allResults.map(v => [v.name.toLowerCase() + v.address?.toLowerCase(), v])).values()
    );

    return unique;
  } catch (error) {
    console.error('searchVenuesWithGeocode failed:', error);
    return [];
  }
}
