import React from 'react';
import { LucideIcon } from 'lucide-react';
import { motion } from 'framer-motion';

interface MembershipFeatureProps {
  icon: LucideIcon;
  title: string;
  text: string;
  delay?: number;
}

const MembershipFeature: React.FC<MembershipFeatureProps> = ({
  icon: Icon,
  title,
  text,
  delay = 0
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ once: true }}
      transition={{ delay }}
      className="flex items-start group"
    >
      <div className="mr-4 p-3 !bg-dark-200 dark:!bg-dark-200 rounded-xl group-hover:!bg-dark-300 transition-all duration-300 group-hover:scale-110">
        <Icon className="h-6 w-6 !text-gold-400 dark:!text-gold-400" />
      </div>
      <div>
        <h3 className="text-lg font-medium !text-white dark:!text-white mb-2 group-hover:text-gold-400 transition-colors">
          {title}
        </h3>
        <p className="!text-white/70 dark:!text-white/70 leading-relaxed">
          {text}
        </p>
      </div>
    </motion.div>
  );
};

export default MembershipFeature;
