import { VenueApiResult } from '../../../types/venue';
import { VenueCache } from './cache';
import { VenueError } from './error';
import { formatVenueData } from './venue.formatter';

export class CityVenueService {
  static async getVenues(city: string): Promise<VenueApiResult[]> {
    const formattedCity = city.replace(/\s+/g, '_').toLowerCase();
    const cityVenuesUrl = `https://raw.githubusercontent.com/uri100/json_pc/refs/heads/main/${formattedCity}.json`;
    const cacheKey = `${formattedCity}_venues`;

    try {
      // Check cache first
      const cachedData = VenueCache.get(cacheKey);
      if (cachedData) return cachedData;

      // Fetch JSON from GitHub
      const response = await fetch(cityVenuesUrl);
      if (!response.ok) {
        console.warn(`Failed to fetch venues for ${city}. Status: ${response.status}`);
        return [];
      }

      const data = await response.json();

      // Convert key-value object to array of venue objects
      const venues = Object.entries(data).map(([key, value]) => {
        const venue = value as Record<string, any>;

        // Safety check for missing fields
        if (!venue) {
          console.warn(`Skipping venue "${key}" due to missing data.`);
          return null;
        }

        // Extract coordinates safely
        const coordinates = {
          lat: venue.coordinates?.lat || venue.geometry?.location?.lat,
          lng: venue.coordinates?.lng || venue.geometry?.location?.lng
        };

        if (coordinates.lat === undefined || coordinates.lat === null || 
            coordinates.lng === undefined || coordinates.lng === null) {
          console.warn(`Skipping venue "${key}" due to missing coordinates.`);
          return null;
        }

        return {
          id: key,
          name: venue.name || key,
          type: venue.type || 'Fitness',
          address: venue.formatted_address || venue.address || 'Address not available',
          coordinates,
          activities: venue.activities || [],
          amenities: venue.amenities || [],
          rating: venue.rating ?? 0,
          photos: venue.photo_urls ? venue.photo_urls.map((url: string) => ({
            url,
            height: 400,
            width: 400
          })) : [],
          reviews: venue.reviews || [],
          user_ratings_total: venue.user_ratings_total || 0,
          opening_hours: venue.opening_hours || undefined,
          details: {
            website: venue.website || '',
            phone: venue.formatted_phone_number || venue.phone || '',
          },
          description: venue.description || venue.editorial_summary?.overview,
          logo: venue.logo || ''
        };
      }).filter((venue): venue is VenueApiResult => venue !== null);

      if (!Array.isArray(venues)) {
        console.warn(`Unexpected JSON structure for ${city}. Skipping.`);
        return [];
      }

      const formattedData = venues;
      VenueCache.set(cacheKey, formattedData);
      return formattedData;
    } catch (error: any) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
          ? error
          : JSON.stringify(error);

      console.error(`Error fetching venues for ${city}: ${errorMessage}`);
      return [];
    }
  }
}