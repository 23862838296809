import React from 'react';
import {
  Share2,
  Link2,
  Twitter,
  Facebook,
  Mail,
  Instagram,
  MessageCircle,
} from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { motion, AnimatePresence } from 'framer-motion';
import { useNotificationStore } from '../../store/notificationStore';
import { SITE_CONFIG } from '../../lib/config/site';

interface SharePopoverProps {
  post: {
    id: string;
    author: {
      name: string;
    };
    content: string;
  };
  shareCount: number;
  onShare: () => void;
}

const SharePopover: React.FC<SharePopoverProps> = ({
  post,
  shareCount,
  onShare,
}) => {
  const { addNotification } = useNotificationStore();
  const [isOpen, setIsOpen] = React.useState(false);

  const shareOptions = [
    {
      name: 'Copy Link',
      icon: Link2,
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(
            `${SITE_CONFIG.baseUrl}/dashboard?post=${post.id}`
          );
          onShare();
          setIsOpen(false);
        } catch (error) {
          console.error('Failed to copy link:', error);
        }
      },
      color: 'bg-dark-300',
    },
    {
      name: 'WhatsApp',
      icon: MessageCircle,
      onClick: () => {
        window.open(
          `https://wa.me/?text=${encodeURIComponent(
            `Check out ${post.author.name}'s post on ${SITE_CONFIG.name}:\n\n${post.content}\n\n${SITE_CONFIG.baseUrl}/dashboard?post=${post.id}`
          )}`,
          '_blank'
        );
        onShare();
        setIsOpen(false);
      },
      color: 'bg-[#25D366]',
    },
    {
      name: 'Instagram',
      icon: Instagram,
      onClick: () => {
        navigator.clipboard.writeText(
          `${SITE_CONFIG.baseUrl}/posts/${post.id}`
        );
        onShare();
        setIsOpen(false);
      },
      color: 'bg-gradient-to-r from-[#833AB4] via-[#FD1D1D] to-[#F77737]',
    },
    {
      name: 'Twitter',
      icon: Twitter,
      onClick: () => {
        window.open(
          `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            `Check out ${post.author.name}'s post on ${
              SITE_CONFIG.name
            }:\n\n${post.content.slice(0, 100)}...`
          )}&url=${encodeURIComponent(
            `${SITE_CONFIG.baseUrl}/dashboard?post=${post.id}`
          )}`,
          '_blank'
        );
        onShare();
        setIsOpen(false);
      },
      color: 'bg-[#1DA1F2]',
    },
    {
      name: 'Facebook',
      icon: Facebook,
      onClick: () => {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            `${SITE_CONFIG.baseUrl}/dashboard?post=${post.id}`
          )}`,
          '_blank'
        );
        onShare();
        setIsOpen(false);
      },
      color: 'bg-[#4267B2]',
    },
    {
      name: 'Email',
      icon: Mail,
      onClick: () => {
        window.location.href = `mailto:?subject=${encodeURIComponent(
          `Check out this post on ${SITE_CONFIG.name}`
        )}&body=${encodeURIComponent(
          `${post.author.name} shared a post on ${SITE_CONFIG.name}:\n\n${post.content}\n\n${SITE_CONFIG.baseUrl}/dashboard?post=${post.id}`
        )}`;
        onShare();
        setIsOpen(false);
      },
      color: 'bg-dark-300',
    },
  ];

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button className="flex items-center space-x-2 text-dark-800 hover:text-gold-400 transition-colors">
          <Share2 className="h-5 w-5" />
          <span>{shareCount}</span>
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="w-72 p-3 bg-dark-200 border-dark-300"
        sideOffset={5}
      >
        <AnimatePresence>
          <div className="grid grid-cols-3 gap-2">
            {shareOptions.map((option, index) => (
              <motion.button
                key={option.name}
                onClick={option.onClick}
                className={`${option.color} p-3 rounded-xl hover:opacity-90 transition-all flex flex-col items-center justify-center space-y-1`}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.05 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <option.icon className="h-5 w-5 text-white" />
                <span className="text-white text-xs">{option.name}</span>
              </motion.button>
            ))}
          </div>
        </AnimatePresence>
      </PopoverContent>
    </Popover>
  );
};

export default SharePopover;
