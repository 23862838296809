import { Message, Conversation } from '../types/chat';
import { useConnectionStore } from '../store/connectionStore';
import { supabase } from '../lib/supabase';
import { useAuth } from '@/contexts/AuthContext';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useProfile } from '@/hooks/useProfile';

/**
 * Custom hook to fetch and cache the current user's database ID.
 */
function useUserDbId(user: { id: string } | null) {
  const [userDbId, setUserDbId] = useState<string | null>(null);

  useEffect(() => {
    if (!user?.id) return;

    (async () => {
      const { data, error } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();
      if (error) {
        console.error('Error fetching user DB id:', error);
      } else if (data) {
        setUserDbId(data.id);
      }
    })();
  }, [user?.id]);

  return userDbId;
}

// Helper to transform message data
const transformMessage = (msg: any, userDbId: string | null, senderAvatar?: string): Message => ({
  id: msg.id,
  content: msg.content,
  time: new Date(msg.created_at),
  isSender: userDbId === msg.sender_id,
  status: msg.read_at ? 'read' : msg.sender_id === userDbId ? 'delivered' : 'sent',
  senderAvatar
});

function useChat(conversationId?: string) {
  const { user } = useAuth();
  const { profile } = useProfile();
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [connectedConversations, setConnectedConversations] = useState<Conversation[]>([]);
  const [nonConnectedConversations, setNonConnectedConversations] = useState<Conversation[]>([]);
  const [messageInput, setMessageInput] = useState('');
  const [isMessagesLoading, setIsMessagesLoading] = useState(true);
  const [isConversationsLoading, setIsConversationsLoading] = useState(true);
  const isLoading = isConversationsLoading || isMessagesLoading;
  const [error, setError] = useState<string | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const typingTimeoutRef = useRef<NodeJS.Timeout>();
  const { connections, fetchConnections } = useConnectionStore();
  const messageChannelRef = useRef<ReturnType<typeof supabase.channel>>();
  const conversationChannelRef = useRef<ReturnType<typeof supabase.channel>>();
  const [isInitialized, setIsInitialized] = useState(false);

  // Fetch connections if they are not already loaded
  useEffect(() => {
    if (!connections || connections.length === 0) {
      console.warn("⚠️ No connections found, fetching now...");
      fetchConnections();
    }
  }, [connections, fetchConnections]);

  // Cache the current user's DB ID
  const userDbId = useUserDbId(user);

  // Debug logging (only during development)
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Chat state:', { conversations, isInitialized, messages, error });
    }
  }, [conversations, isInitialized, messages, error]);

  /**
   * Fetch conversations via an RPC call and transform the result to match our Conversation type.
   */
  const fetchConversations = useCallback(async () => {
    try {
      if (!user?.id) {
        console.warn('No authenticated user');
        return;
      }
      setIsConversationsLoading(true);
      console.log('Fetching conversations...');

      //New RPC function for retreiving conversations
      const { data, error: fetchError } = await supabase.rpc('get_conversations_for_user', {
        user_auth_id: user.id,
      });

      if (fetchError) throw fetchError;

      console.log('Get conversations for user: ', data);
      console.log('Connections: ', connections);
      
      const transformedConversations = (data || []).map((conv: any) => ({
        id: conv.conversation_id, // ✅ Ensure we use `conversationId`
        name: conv.other_user_name,
        image: conv.other_user_avatar || 'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        city: conv.other_user_city, // ✅ Maintain `city` field for backward compatibility
        lastMessage: conv.last_message,
        time: conv.last_message_at,
        unread: Number(conv.unread_count),
        online: false, // ✅ Keep `online` placeholder for future enhancements
        isConnected: connections.some(conn => 
          conn.other_user?.id === conv.other_user_id && 
          conn.status === 'accepted'
        ),
        otherUserId: conv.other_user_id, // ✅ Ensure `otherUserId` is mapped correctly
      }));

      console.log('Transformed Conversations', transformedConversations);
      
      // Separate conversations into connected and non-connected
      const connected = transformedConversations.filter(conv => conv.isConnected);
      const nonConnected = transformedConversations.filter(conv => !conv.isConnected);
      
      setConnectedConversations(connected);
      setNonConnectedConversations(nonConnected);
      setConversations(transformedConversations);
      setIsInitialized(true);
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError(err instanceof Error ? err.message : 'Failed to load conversations');
    } finally {
      setIsConversationsLoading(false);
    }
  }, [user?.id, connections]);

  /**
   * Fetch messages for a given conversation.
   */
  const fetchMessages = useCallback(async (convId?: string) => {
    if (!convId) return;
    console.log('Fetching messages for conversation:', convId);
    try {
      setIsMessagesLoading(true);
      setError(null);

      const { data, error: messagesError } = await supabase
        .from('messages')
        .select('id, content, created_at, read_at, sender_id')
        .eq('conversation_id', convId)
        .order('created_at', { ascending: true });

      if (messagesError) throw messagesError;

      console.log('Fetched messages: ', data);
      console.log('Current user DB ID:', userDbId);
      // Mark unread messages as read when conversation is opened
      const hasUnreadMessages = data.some(msg => !msg.read_at && msg.sender_id !== userDbId);
      if (hasUnreadMessages) {
        console.log('Marking messages as read for conversation:', convId);
        const { error: rpcError } = await supabase.rpc('mark_messages_as_read', {
          conversation_id_param: convId
        });
        
        if (rpcError) {
          console.error('Error marking messages as read:', rpcError);
        } else {
          console.log('Successfully marked messages as read via RPC');
        }
      }
      // Transform messages after updating read status
      const transformedMessages = (data || []).map(msg => transformMessage(
        {
        ...msg,
        // If message was unread and not from us, mark it as read since we just called the RPC
        read_at: (!msg.read_at && msg.sender_id !== userDbId) ? new Date().toISOString() : msg.read_at
        },
        userDbId,
        msg.sender_id === userDbId ? profile?.avatar_url : undefined
      ));

      console.log('Transformed Messages:', transformedMessages);

      setMessages(transformedMessages);
    } catch (err) {
      console.error('Error fetching messages:', err);
      setError(err instanceof Error ? err.message : 'Failed to load messages');
    } finally {
      setIsMessagesLoading(false);
    }
  }, [userDbId]); // Add userDbId as a dependency

  // Automatically fetch messages whenever the conversationId changes.
  useEffect(() => {
    if (conversationId) {
      fetchMessages(conversationId);
    }
  }, [conversationId, fetchMessages]);

  /**
   * ORIGINAL Send a message with an optimistic UI update.
   *
  const handleSendMessage = useCallback(async (convId: string, messageContent: string) => {
    if (!convId || !messageContent?.trim() || !userDbId) {
      console.warn('Invalid message, conversation ID, or user ID not available', {
        convId, messageContent: messageContent?.trim(), userDbId
      });
      return;
    }
    
    try {
      
      const { data, error } = await supabase
        .from('messages')
        .insert({
          conversation_id: convId,
          sender_id: userDbId,
          content: messageContent.trim(),
          created_at: new Date().toISOString(),
        })
        .select()
        .single();
      
      if (error) throw error;

      // Transform and add message to state
      const formattedMessage = transformMessage(data, userDbId);
      setMessages(prevMessages => [...prevMessages, formattedMessage]);
      setMessageInput('');
      
    } catch (err) {
      console.error('❌ Error sending message:', err);
      setError(err instanceof Error ? err.message : 'Failed to send message.');
    }
  }, [userDbId]); // Add userDbId to dependencies
*/


  
  /**
   * Updated handleSendMessage with AI functionality 
   */
  const AI_AGENT_USER_ID = 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600';
  const N8N_WEBHOOK_URL = 'https://playlclub.app.n8n.cloud/webhook/a889d2ae-2159-402f-b326-5f61e90f602e/chat';
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  // Helper function to handle webhook retries
  const fetchWithRetry = async (url: string, options: RequestInit, retries = MAX_RETRIES): Promise<Response> => {
    try {
      const response = await fetch(url, options);
      
      // If response is not ok, throw error with details
      if (!response.ok) {
        const errorBody = await response.text();
        throw new Error(JSON.stringify({
          status: response.status,
          statusText: response.statusText,
          body: errorBody
        }));
      }
      
      return response;
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying webhook call, ${retries} attempts remaining...`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        return fetchWithRetry(url, options, retries - 1);
      }
      throw error;
    }
  };

  const handleSendMessage = useCallback(async (convId: string, messageContent: string) => {
    if (!convId || !messageContent?.trim() || !userDbId) {
      console.warn('Invalid message, conversation ID, or user ID not available', {
        convId, messageContent: messageContent?.trim(), userDbId
      });
      return;
    }
    
    try {
      
      const { data, error } = await supabase
        .from('messages')
        .insert({
          conversation_id: convId,
          sender_id: userDbId,
          content: messageContent.trim(),
          created_at: new Date().toISOString(),
        })
        .select()
        .single();
      
      if (error) throw error;

      // Transform and add message to state
      const formattedMessage = transformMessage(data, userDbId);
      setMessages(prevMessages => [...prevMessages, formattedMessage]);
      setMessageInput(''); 

          // 2️⃣ Get conversation participants to check if recipient is Lucy
        const { data: participants, error: participantsError } = await supabase
          .from('conversation_participants')
          .select('user_id')
          .eq('conversation_id', convId);
    
        if (participantsError) throw participantsError;
        if (!participants || participants.length < 2) throw new Error('Invalid conversation');
    
        const recipientId = participants.find(p => p.user_id !== userDbId)?.user_id;
        if (!recipientId) throw new Error('Recipient not found');
    
        // 3️⃣ If recipient is Lucy (AI Agent), show typing indicator and send message to webhook
        if (recipientId === AI_AGENT_USER_ID) {
          console.log('⚡ Sending message to AI Agent (Lucy)...');
          
          setIsTyping(true);
    
          try {
            const aiResponse = await fetchWithRetry(N8N_WEBHOOK_URL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + btoa(`agent@playclub.ai:!123Wefasd`)
              },
              body: JSON.stringify({
                sender_id: userDbId,
                chatInput: messageContent.trim(),
                sessionId: convId,
                timestamp: new Date().toISOString()
              })
            });

            // Validate response format
            const contentType = aiResponse.headers.get('content-type');
            if (!contentType?.includes('application/json')) {
              throw new Error('Invalid response format from AI webhook');
            }

            const responseJson = await aiResponse.json();
            if (!responseJson?.output) {
              throw new Error('Invalid or empty AI response');
            }

            // Store AI response in Supabase
            const { error: aiError } = await supabase
              .from('messages')
              .insert({
                conversation_id: convId,
                sender_id: AI_AGENT_USER_ID,
                content: responseJson.output,
                created_at: new Date().toISOString(),
              });

            if (aiError) throw aiError;

            console.log('✅ AI response processed successfully');
          } catch (webhookError) {
            console.error('AI webhook error:', webhookError);
            throw new Error(
              webhookError instanceof Error
                ? webhookError.message
                : 'Failed to process AI response'
            );
          } finally {
            // Ensure typing indicator is cleared after a delay
            if (typingTimeoutRef.current) {
              clearTimeout(typingTimeoutRef.current);
            }
            typingTimeoutRef.current = setTimeout(() => {
              setIsTyping(false);
            }, 1000);
          }
        }
          
    } catch (err) {
      console.error('Error in message handling:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to send message';

      // Show user-friendly error message
      setError('Unable to process message. Please try again in a moment.');
      
      setIsTyping(false); // Ensure typing indicator is cleared
    }
  }, [userDbId, typingTimeoutRef]); // Add typingTimeoutRef to dependencies

  // Cleanup typing timeout on unmount
  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);
  
  /**
   * Subscribe to real-time messages.
   */
  useEffect(() => {
    if (!conversationId) return;

    // Cleanup existing subscriptions
    if (messageChannelRef.current) {
      supabase.removeChannel(messageChannelRef.current);
    }

    // Subscribe to new messages for this conversation
    messageChannelRef.current = supabase
      .channel(`messages:${conversationId}`)
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'messages',
        filter: `conversation_id=eq.${conversationId}`,
      }, async (payload) => {
        // Only process messages we didn't send
        if (payload.new.sender_id !== userDbId) {
          const newMessage = transformMessage({
            ...payload.new,
            read_at: new Date().toISOString() // Mark as read immediately
          }, userDbId, payload.new.sender_id === userDbId ? profile?.avatar_url : undefined);
          setMessages(prevMessages => [...prevMessages, newMessage]);
          
          // Mark message as read via RPC since conversation is open
          const { error: rpcError } = await supabase.rpc('mark_messages_as_read', {
            conversation_id_param: conversationId
          });
          
          if (rpcError) {
            console.error('Error marking new message as read:', rpcError);
          }

          // Update conversation list
          setConversations(prevConvs => {
            const updatedConvs = prevConvs.map(conv => {
              if (conv.id === conversationId) {
                return {
                  ...conv,
                  lastMessage: payload.new.content,
                  time: payload.new.created_at,
                  unread: 0 // Message is read immediately since conversation is open
                };
              }
              return conv;
            });
            
            // Re-sort conversations to move the updated one to top
            return updatedConvs.sort((a, b) => 
              new Date(b.time).getTime() - new Date(a.time).getTime()
            );
          });
        }
      })
      .subscribe();

    return () => {
      if (messageChannelRef.current) {
        supabase.removeChannel(messageChannelRef.current);
      }
    };
  }, [conversationId, userDbId]);

  // Subscribe to conversation updates
  useEffect(() => {
    if (!user?.id) return;

    if (conversationChannelRef.current) {
      supabase.removeChannel(conversationChannelRef.current);
    }

    conversationChannelRef.current = supabase
      .channel('conversations')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'conversations',
      }, () => {
        // Refresh conversations list when any conversation changes
        fetchConversations();
      })
      .subscribe();

    return () => {
      if (conversationChannelRef.current) {
        supabase.removeChannel(conversationChannelRef.current);
      }
    };
  }, [user?.id, fetchConversations]);

  // Fetch conversations on mount.
  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  return {
    messages,
    isLoading,
    error,
    messageInput,
    setMessageInput,
    isTyping,
    conversations,
    connectedConversations,
    nonConnectedConversations,
    isInitialized,
    handleSendMessage: useCallback((convId: string) => {
      if (messageInput?.trim()) {
        handleSendMessage(convId, messageInput);
      }
    }, [messageInput, handleSendMessage]), 
    fetchConversations,
  };
}

export { useChat };