import React from 'react';
import { Shield, Users, Dumbbell } from 'lucide-react';
import { cn } from '@/lib/utils';

interface CityTabsProps {
  activeTab: 'clubs' | 'players' | 'trainers';
  onTabChange: (tab: 'clubs' | 'players' | 'trainers') => void;
}

const CityTabs: React.FC<CityTabsProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="flex border-b border-dark-300">
      <button
        onClick={() => onTabChange('clubs')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'clubs'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        } ${cn(
          "flex-1 sm:flex-initial",
          "justify-center sm:justify-start"
        )}`}
      >
        <Shield className="h-4 w-4" />
        <span className="text-sm sm:text-base">Clubs</span>
      </button>
      <button
        onClick={() => onTabChange('players')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'players'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        } ${cn(
          "flex-1 sm:flex-initial",
          "justify-center sm:justify-start"
        )}`}
      >
        <Users className="h-4 w-4" />
        <span className="text-sm sm:text-base">Players</span>
      </button>
      <button
        onClick={() => onTabChange('trainers')}
        className={`px-4 py-2 -mb-px border-b-2 transition-colors flex items-center space-x-2 ${
          activeTab === 'trainers'
            ? 'border-gold-400 text-gold-400'
            : 'border-transparent text-dark-800 hover:text-white'
        } ${cn(
          "flex-1 sm:flex-initial",
          "justify-center sm:justify-start"
        )}`}
      >
        <Dumbbell className="h-4 w-4" />
        <span className="text-sm sm:text-base">Trainers</span>
      </button>
    </div>
  );
};

export default CityTabs;