import React from 'react';
import { useTheme } from '../../../contexts/ThemeContext';
import { Label } from '../../ui/label';
import { Switch } from '../../ui/switch';
import UnifiedVenueSelector from '@/components/facilities/venues-api/components/UnifiedVenueSelector';
import { cn } from '@/lib/utils';

interface LocationStepProps {
  city: string;
  selectedVenue: string;
  onVenueChange: (venue: string) => void;
  letReceiverDecide: boolean;
  onLetReceiverDecideChange: (value: boolean) => void;
  memberName: string;
}

const LocationStep: React.FC<LocationStepProps> = ({
  city,
  selectedVenue,
  onVenueChange,
  letReceiverDecide,
  onLetReceiverDecideChange,
  memberName
}) => {
  const { isDarkMode } = useTheme();
  const firstName = memberName.split(' ')[0];

  return (
    <div className="space-y-4">
      <h3 className={cn(
        "text-xl font-semibold",
        isDarkMode ? "text-white" : "text-gray-900"
      )}>
        Where would you like to play in {city}?
      </h3>

      {/* Venue Selector - Only show if letReceiverDecide is false */}
      {!letReceiverDecide && (
        <UnifiedVenueSelector
          city={city}
          value={selectedVenue}
          onChange={(venue) => onVenueChange(venue.name)}
          placeholder="Enter venue name or address"
        />
      )}

      {/* Let Player Decide Toggle */}
      <div className={cn(
        "flex items-center justify-between rounded-xl p-4",
        isDarkMode ? "bg-dark-300" : "bg-gray-100"
      )}>
        <div>
          <Label className={isDarkMode ? "text-white" : "text-gray-900"}>
            Choose venue later
          </Label>
          <p className={cn(
            "text-sm",
            isDarkMode ? "text-dark-800" : "text-gray-500"
          )}>
            Decide on the venue after confirmation
          </p>
        </div>
        <Switch
          checked={letReceiverDecide}
          onCheckedChange={onLetReceiverDecideChange}
        />
      </div>
    </div>
  );
};

export default LocationStep;
