import React, { useState, useEffect } from 'react';
import { Activity, Plus, Search, X, Loader2 } from 'lucide-react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/supabase';
import { useTheme } from '@/contexts/ThemeContext';
import { cn } from '@/lib/utils';

// Sport categories
const SPORT_CATEGORIES = {
  'Racket Sports': [
    'Tennis', 'Padel', 'Badminton', 'Table Tennis', 'Squash', 'Pickleball'
  ],
  'Ball Sports': [
    'American Football', 'Baseball', 'Basketball', 'Billiards', 'Bocce', 'Cricket', 'Dodgeball', 'Floorball', 
    'Football', 'Futsal', 'Handball', 'Handball (Beach)', 'Hockey', 'Lacrosse', 'Netball', 'Petanque', 'Rugby', 
    'Sepak Takraw', 'Softball', 'Speedball', 'Spikeball', 'Ultimate Frisbee', 'Volleyball'
  ],
  'Water Sports': [
    'Swimming', 'Surfing', 'Sailing', 'Wakeboarding', 'Aquatics', 'Bodyboarding', 'Body Surfing', 'Canoeing', 
    'Canoe Polo', 'Deep Sea Fishing', 'Fishing', 'Flyboarding', 'Freediving', 'Hydrofoil Surfing', 'Jet Skiing', 
    'Kayaking', 'Kiteboarding', 'Stand-Up Paddleboarding (SUP)', 'Kitesurfing', 'Kneeboarding', 'Parasailing', 'Rowing', 'Scuba Diving', 'Sea Sports', 
    'Skimboarding', 'Skurfing', 'Snorkeling', 'Stand-Up Paddleboarding', 'Underwater Hockey', 'Wakesurfing', 
    'Water Polo', 'Whitewater Rafting', 'Windsurfing'
  ],
  'Fitness & Wellness': [
    'Yoga', 'Pilates', 'Fitness', 'Running', 'Cycling', 'Aerial Silks', 'Aerial Yoga', 'Aerobics', 'Aqua Aerobics', 
    'Aqua Zumba', 'Barre', 'Bodyweight Training', 'Breathwork', 'Chair Yoga', 'Cold Plunge Therapy', 'CrossFit', 
    'Cryotherapy', 'Dance', 'Foam Rolling', 'Functional Training', 'Gym', 'High-Intensity Interval Training (HIIT)', 
    'Hot Pilates', 'Hot Yoga', 'Infrared Sauna', 'Meditation', 'Mindfulness', 'Piloxing', 'Postnatal Fitness', 
    'Powerlifting', 'Rebounding', 'Sound Bath', 'Spinning / Indoor Cycling', 'Stretching', 'Tai Chi', 'TRX Training', 
    'Weight Training', 'Zumba'
  ],
  'Winter Sports': [
    'Skiing', 'Snowboarding', 'Biathlon', 'Bobsledding', 'Cross-Country Skiing', 'Curling', 'Heli-Skiing', 
    'Ice Climbing', 'Ice Hockey', 'Ice Skating', 'Skeleton', 'Snowmobiling', 'Snowshoeing', 'Speed Skating'
  ],
  'Combat Sports': [
    'Boxing', 'Martial Arts', 'Karate', 'Taekwondo', 'Aikido', 'Brazilian Jiu-Jitsu', 'Capoeira', 'Hapkido', 'Iaido', 
    'Judo', 'Jujitsu', 'Kendo', 'Kickboxing', 'Krav Maga', 'Kung Fu', 'Muay Thai', 'Sambo', 'Savate', 'Sumo Wrestling', 
    'Systema', 'Wrestling'
  ],
  'Equestrian': [
    'Dressage', 'Equestrian', 'Eventing', 'Horse Racing', 'Horse Riding', 'Polo', 'Show Jumping', 'Vaulting'
  ],
  'Individual Sports': [
    'Archery', 'Athletics', 'BMX', 'Climbing', 'Cycling', 'Darts', 'Golf', 'Handcycling', 'Indoor Climbing', 
    'Orienteering', 'Parkour', 'Quad Biking', 'Roller Skating', 'Running', 'Skateboarding', 'Speed Walking', 
    'Trail Biking', 'Trail Running', 'Trekking', 'Triathlon'
  ],
  'Motorsports': [
    'Car Racing', 'Drifting', 'Karting', 'Motorcycle Racing', 'Off-Road Driving', 'Rally Racing', 'Drag Racing', 
    'Formula Racing', 'Go-Karting', 'Motocross', 'Off-Roading'
  ],
  'Social Sports': [
    'Cornhole'
  ]
} as const;


interface SportsSelectorProps {
  onClose: () => void;
  onSportsUpdate: () => void;
}

interface Sport {
  id: string;
  name: string;
  emoji: string;
  category?: string;
}

export const SportsSelector: React.FC<SportsSelectorProps> = ({ onClose, onSportsUpdate }) => {
  const { isDarkMode } = useTheme();
  const [availableSports, setAvailableSports] = useState<Sport[]>([]);
  const [initialSports, setInitialSports] = useState<Sport[]>([]);
  const [selectedSports, setSelectedSports] = useState<Sport[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Filter sports based on search term
  const filteredSports = React.useMemo(() => {
    return availableSports.filter(sport =>
      sport.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [availableSports, searchTerm]);

  // Group sports by category
  const categorizedSports = React.useMemo(() => {
    const grouped: Record<string, Sport[]> = {};
    
    filteredSports.forEach(sport => {
      const category = Object.entries(SPORT_CATEGORIES).find(([_, sports]) =>
        sports.includes(sport.name)
      )?.[0] || 'Other';
      
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(sport);
    });
    
    return grouped;
  }, [filteredSports]);

  // Check if there are unsaved changes
  const hasChanges = React.useMemo(() => {
    if (initialSports.length !== selectedSports.length) return true;
    return !initialSports.every(sport => 
      selectedSports.some(s => s.id === sport.id)
    );
  }, [initialSports, selectedSports]);

  // Fetch available sports and user's current sports
  useEffect(() => {
    const fetchSports = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Get current user
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError) throw userError;
        if (!user) throw new Error('Not authenticated');

        // Get user's database ID
        const { data: userData, error: userDataError } = await supabase
          .from('users')
          .select('id')
          .eq('auth_id', user.id)
          .single();

        if (userDataError) throw userDataError;
        if (!userData) throw new Error('User not found');

        // Get all available sports
        const { data: allSports, error: sportsError } = await supabase
          .from('sports')
          .select('*')
          .order('name');

        if (sportsError) throw sportsError;

        // Get user's current sports
        const { data: userSports, error: userSportsError } = await supabase
          .from('user_sports')
          .select(`
            sports (
              id,
              name,
              emoji
            )
          `)
          .eq('user_id', userData.id);

        if (userSportsError) throw userSportsError;

        const currentSports = userSports?.map(us => us.sports) || [];
        setAvailableSports(allSports);
        setSelectedSports(currentSports);
        setInitialSports(currentSports);

      } catch (error) {
        console.error('Error fetching sports:', error);
        setError(error instanceof Error ? error.message : 'Failed to load sports');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSports();
  }, []);

  const handleSportToggle = (sport: Sport) => {
    setSelectedSports(prev => {
      const isSelected = prev.some(s => s.id === sport.id);
      if (isSelected) {
        return prev.filter(s => s.id !== sport.id);
      } else {
        return [...prev, sport];
      }
    });
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);

      // Get current user's database ID
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('id')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');

      // Delete all existing user sports
      const { error: deleteError } = await supabase
        .from('user_sports')
        .delete()
        .eq('user_id', userData.id);

      if (deleteError) throw deleteError;

      // Insert new sports selections
      if (selectedSports.length > 0) {
        const { error: insertError } = await supabase
          .from('user_sports')
          .insert(
            selectedSports.map(sport => ({
              user_id: userData.id,
              sport_id: sport.id
            }))
          );

        if (insertError) throw insertError;
      }

      // Update initial state
      setInitialSports(selectedSports);
      
      // Trigger parent update
      onSportsUpdate();
      onClose();

    } catch (error) {
      console.error('Error saving sports:', error);
      setError(error instanceof Error ? error.message : 'Failed to save sports');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-dark-200 rounded-xl p-6 border border-dark-300 max-h-[90vh] overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Activity className="h-5 w-5 text-gold-400" />
          <div>
            <h3 className="text-lg font-semibold text-white">Edit Sports</h3>
            <p className="text-dark-800 text-sm">
              {selectedSports.length} sport{selectedSports.length !== 1 ? 's' : ''} selected
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Button 
            onClick={onClose} 
            variant="outline" 
            className="border-dark-400"
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={!hasChanges || isSaving}
            className={cn(
              "bg-gold-400 hover:bg-gold-500 text-dark-50",
              "min-w-[100px]"
            )}
          >
            {isSaving ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Saving...
              </>
            ) : (
              'Save Changes'
            )}
          </Button>
        </div>
      </div>

      {selectedSports.length > 0 && (
        <div className="mb-6">
          <div className="flex flex-wrap gap-1.5">
            {selectedSports.map((sport) => (
              <button
                key={sport.id}
                onClick={() => handleSportToggle(sport)}
                className={cn(
                  "inline-flex items-center px-3 py-1.5 rounded-full text-sm",
                  "bg-gold-400 text-dark-50 hover:bg-gold-500",
                  "transform hover:scale-105 active:scale-95",
                  "transition-all duration-200"
                )}
              >
                {sport.emoji} {sport.name}
                <X className="h-3.5 w-3.5 ml-2" />
              </button>
            ))}
          </div>
        </div>
      )}

      {error && (
        <div className="mb-4 p-3 bg-red-500/10 text-red-500 text-sm rounded-lg border border-red-500/20">
          {error}
        </div>
      )}

      {/* Search Bar */}
      <div className="relative mb-6">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-dark-800" />
        <Input
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search sports..."
          className="pl-10 bg-dark-300 border-dark-400 text-white"
        />
      </div>

      {/* Category Tabs */}
      <div className="mb-6 -mx-6 px-6 overflow-x-auto">
        <div className="flex space-x-2 pb-2">
          <button
            onClick={() => setActiveCategory(null)}
            className={cn(
              "px-4 py-2 rounded-lg whitespace-nowrap transition-colors",
              !activeCategory ? "bg-gold-400 text-dark-50" : "bg-dark-300 text-dark-800 hover:text-white"
            )}
          >
            All Sports
          </button>
          {Object.keys(SPORT_CATEGORIES).map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={cn(
                "px-4 py-2 rounded-lg whitespace-nowrap transition-colors",
                activeCategory === category ? "bg-gold-400 text-dark-50" : "bg-dark-300 text-dark-800 hover:text-white"
              )}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Sports Grid */}
      <div className="space-y-6">
        {Object.entries(categorizedSports)
          .filter(([category]) => !activeCategory || category === activeCategory)
          .map(([category, sports]) => (
            <div key={category}>
              <h3 className="text-dark-800 text-sm font-medium mb-3">{category}</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
                {sports.map((sport) => {
                  const isSelected = selectedSports.some(s => s.id === sport.id);
                  return (
                    <button
                      key={sport.id}
                      onClick={() => handleSportToggle(sport)}
                      disabled={isLoading || isSaving}
                      className={cn(
                        "flex items-center space-x-2 p-3 rounded-lg",
                        "transition-all duration-200",
                        "transform hover:scale-[1.02] active:scale-[0.98]",
                        (isLoading || isSaving) && "opacity-50 cursor-not-allowed",
                        isDarkMode
                          ? isSelected
                            ? "bg-dark-300 text-gold-400"
                            : "bg-dark-300/50 text-white hover:bg-dark-400"
                          : isSelected
                            ? "bg-gold-100 text-gold-600 border border-gold-200"
                            : "bg-light-100 text-light-900 hover:bg-light-200 border border-light-200"
                      )}
                    >
                      <span>{sport.emoji}</span>
                      <span>{sport.name}</span>
                      {isSelected ? (
                        <X className={cn(
                          "h-4 w-4 ml-auto",
                          isDarkMode ? "text-dark-800" : "text-light-500"
                        )} />
                      ) : (
                        <Plus className={cn(
                          "h-4 w-4 ml-auto",
                          isDarkMode ? "text-dark-800" : "text-light-500"
                        )} />
                      )}
                    </button>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};