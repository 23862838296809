import React from 'react';
import { MapPin } from 'lucide-react';
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';

interface VenueHeaderProps {
  name: string;
  address: string;
  distance?: number;
  logo?: string;
  onClick?: () => void;
  onCopyAddress?: () => void;
}

export const VenueHeader: React.FC<VenueHeaderProps> = ({
  name,
  address,
  distance,
  logo,
  onClick,
  onCopyAddress
}) => {
  const { isDarkMode } = useTheme();

  return (
    <div className="mb-3">
      <div 
        className="flex items-center gap-3 mb-2 flex-wrap"
      >
        {logo && (
          <button className={cn(
            "flex-shrink-0 w-8 h-8 rounded-lg overflow-hidden border cursor-pointer",
            "dark:border-dark-400 hover:border-gold-400 hover:scale-105",
            "light:border-light-300 hover:border-gold-600",
            "transition-all duration-200"
          )}
          onClick={onClick}>
            <img
              src={logo}
              alt={`${name} logo`}
              className="w-full h-full object-cover"
            />
          </button>
        )}
        <button className={cn(
          "text-lg font-medium text-white cursor-pointer",
          "hover:text-gold-400 hover:scale-[1.02]",
          "transition-all duration-200"
        )}
        onClick={onClick}>
          {name}
        </button>
      </div>

      {/* Address and Distance Row */}
      <div className="flex items-center justify-between cursor-default">
        <div className="flex items-center text-xs text-dark-800 min-w-0">
          <MapPin className="h-3.5 w-3.5 flex-shrink-0 mr-1" />
          <span className="truncate">{address}</span>
        </div>

        {distance !== undefined && (
          <div className="text-xs text-dark-800 ml-3">
            {distance}km away
          </div>
        )}
      </div>
    </div>
  );
};