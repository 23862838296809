import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useSwipeGesture = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Define the routes for your root tabs.
    const tabRoutes = [
      '/dashboard/feed',
      '/dashboard/facilities',
      '/dashboard/activities',
      '/dashboard/messages'
    ];

    // Normalize paths by trimming trailing slashes and converting to lowercase.
    const normalize = (path: string) => path.replace(/\/+$/, '').toLowerCase();

    // Animate the transition and navigate to the target route.
    const animateAndNavigate = (targetRoute: string) => {
      const root = document.getElementById('root');
      if (root) {
        root.classList.add('fade-out');
        setTimeout(() => {
          navigate(targetRoute);
          root.classList.remove('fade-out');
        }, 200);
      } else {
        navigate(targetRoute);
      }
    };

    // Expose functions to allow manual navigation if needed.
    (window as any).navigateToNextTab = () => {
      const current = normalize(location.pathname);
      const i = tabRoutes.findIndex(route => normalize(route) === current);
      if (i !== -1 && i < tabRoutes.length - 1) {
        animateAndNavigate(tabRoutes[i + 1]);
      }
    };

    (window as any).navigateToPreviousTab = () => {
      const current = normalize(location.pathname);
      const i = tabRoutes.findIndex(route => normalize(route) === current);
      if (i > 0) {
        animateAndNavigate(tabRoutes[i - 1]);
      }
    };

    let startX = 0, startY = 0, startTarget: EventTarget | null = null;

    const onTouchStart = (e: TouchEvent) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      startTarget = e.target;
    };

    // Define elements where swipe should be prevented (for example, images or maps).
    const isSwipeSensitive = (el: HTMLElement): boolean => {
      const sensitiveTags = ['img', 'canvas'];
      const sensitiveClasses = ['map', 'leaflet', 'gallery', 'slider', 'swiper', 'carousel'];
      const tag = el.tagName.toLowerCase();
      const classList = Array.from(el.classList).map(c => c.toLowerCase());
      if (sensitiveTags.includes(tag)) return true;
      if (classList.some(cls => sensitiveClasses.some(s => cls.includes(s)))) return true;
      return false;
    };

    const onTouchEnd = (e: TouchEvent) => {
      const endX = e.changedTouches[0].clientX;
      const endY = e.changedTouches[0].clientY;
      const deltaX = endX - startX;
      const deltaY = endY - startY;
      const absX = Math.abs(deltaX);
      const absY = Math.abs(deltaY);
      const threshold = 50;
      const maxYThreshold = 30;
      const isModalOpen = (window as any).isModalOpen === true;

      if (isModalOpen) return;

      // Walk up the DOM from the initial touch target and block swipe if necessary.
      if (startTarget instanceof Element) {
        let node: HTMLElement | null = startTarget as HTMLElement;
        while (node) {
          if (
            node.getAttribute('data-prevent-swipe') !== null ||
            node.closest('[data-prevent-swipe]') !== null ||
            isSwipeSensitive(node)
          ) {
            return; // Block swipe.
          }
          node = node.parentElement;
        }
      }

      // Check if the gesture qualifies as a horizontal swipe.
      if (absX > threshold && absY < maxYThreshold) {
        const pathname = normalize(location.pathname);
        const isTabPage = tabRoutes.some(route => normalize(route) === pathname);

        // Only trigger tab-switching swipe events if you're on a root tab route.
        if (!isTabPage) {
          // If not on a root tab, ignore the swipe.
          return;
        }

        // On a root tab, send the appropriate swipe event.
        if (deltaX > 0) {
          window.webkit.messageHandlers.swipeEvent.postMessage('swipe-right');
        } else {
          window.webkit.messageHandlers.swipeEvent.postMessage('swipe-left');
        }
      }
    };

    document.addEventListener('touchstart', onTouchStart);
    document.addEventListener('touchend', onTouchEnd);

    return () => {
      document.removeEventListener('touchstart', onTouchStart);
      document.removeEventListener('touchend', onTouchEnd);
    };
  }, [location.pathname, navigate]);
};
