import { supabase } from '../supabase';
import { compressImage } from '../utils/image';

interface UploadOptions {
  bucket: 'avatars' | 'social-feed';
  file: File;
  path?: string; // Optional path parameter
}

export const storage = {
  async uploadFile({ bucket, file, path }: UploadOptions) {
    try {
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      const userId = user.id;

      // Validate file type
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        throw new Error('Invalid file type. Only JPEG, PNG, and GIF images are allowed.');
      }

      // Check file size (5MB limit)
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSize) {
        throw new Error('File size exceeds 5MB limit');
      }

      // Compress image
      const compressedFile = await compressImage(file, {
        maxWidth: 2048,
        maxHeight: 2048,
        quality: 0.8
      });

      // Generate unique filename
      const timestamp = Date.now();
      const extension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
      const sanitizedName = file.name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
      const fileName = `user_directory/${userId}/${timestamp}-${sanitizedName}.${extension}`;

      // Use `path` if provided, otherwise use `fileName`
      const finalPath = path || fileName;
      console.log('Generated file name details:', {
        userId,
        timestamp,
        sanitizedName,
        finalPath,
      });

console.log('Generated file path:', fileName);
console.log('User ID:', userId);

      
      console.log('Uploading file:', {
        bucket,
        path: finalPath,
        type: file.type,
        size: compressedFile.size,
      });

      // Upload file
      const { data, error: uploadError } = await supabase.storage
        .from(bucket)
        .upload(fileName, compressedFile, {
          cacheControl: '3600',
          upsert: false,
          contentType: file.type,
          duplex: 'half',
          owner: userId
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        if (uploadError.message?.includes('Invalid file path')) {
          throw new Error('Storage path error - please try again');
        }
        throw uploadError;
      }

      if (!data?.path) {
        throw new Error('No file path returned from upload');
      }

      // Get public URL
      const { data: { publicUrl } } = supabase.storage
        .from(bucket)
        .getPublicUrl(data.path);

      console.log('Upload successful. Public URL:', publicUrl);

      return {
        path: data.path,
        url: publicUrl,
      };
    } catch (error) {
      console.error('Upload error:', error);
      throw error instanceof Error ? error : new Error('Failed to upload file');
    }
  },

  async deleteFile(bucket: 'avatars' | 'social-feed' | 'videos', path: string) {
    try {
      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      // Verify file belongs to user
     if (!path.startsWith(`user_directory/${user.id}/`)) {
  throw new Error('Cannot delete files outside your directory');
}

      const { error } = await supabase.storage
        .from(bucket)
        .remove([path]);

      if (error) {
        console.error('Delete error:', error);
        throw error;
      }
      console.log('File deleted successfully:', path);
      return true;
    } catch (error) {
      console.error('Delete error:', error);
      throw error instanceof Error ? error : new Error('Failed to delete file');
    }
  },
};