import React from 'react';
import { Camera } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface ProfileImageProps {
  src?: string;
  size?: number;
  className?: string;
  error?: string;
}

export const ProfileImage: React.FC<ProfileImageProps> = ({
  src,
  size = 96,
  className,
  error
}) => {
  return (
    <div
      className="relative"
    >
      <div 
      className={cn(
        "relative rounded-full overflow-hidden",
        "border-2 border-dark-400/50",
        "group hover:border-gold-400/50",
        "transition-all duration-300 shadow-lg hover:shadow-xl",
        "bg-gradient-to-br from-dark-300/50 to-dark-400/50",
        className
      )}
      style={{ width: size, height: size }}
    >
      {src ? (
        <img
          src={src}
          alt="Profile"
          className="w-full h-full object-cover"
        />
      ) : (
        <div className={cn(
          "w-full h-full",
          "bg-dark-300/50",
          "flex items-center justify-center",
          "group-hover:bg-dark-300/70",
          "transition-colors duration-300"
        )}>
          <Camera className="h-8 w-8 text-dark-800 group-hover:text-gold-400 transition-colors" />
        </div>
      )}
    </div>
    {error && (
      <p className="absolute -bottom-6 left-0 right-0 text-center text-sm text-red-500">
        {error}
      </p>
    )}
    </div>
  );
};