import React from 'react';
import { cn } from '@/lib/utils';
import { SearchBar } from './components/search/SearchBar';
import { ActivityTags } from './components/search/ActivityTags';
import VenueListControls from './components/VenueListControls';

interface VenueSearchSectionProps {
  searchTerm: string;
  onSearchChange: (value: string) => void;
  totalVenues: number;
  availableActivities: string[];
  viewMode: 'list' | 'map';
  onViewModeChange: (mode: 'list' | 'map') => void;
  sortBy: string;
  onSortChange: (value: any) => void;
  onRefresh: () => void;
  isLoading: boolean;
  address: string;
  onAddressChange: (address: string) => void;
  onLocationSelect: (location: { lat: number; lng: number }) => void;
  isAddressLoading?: boolean;
  addressError?: string;
  selectedTags: string[];
  onTagSelect: (tag: string) => void;
  className?: string;
}

export const VenueSearchSection: React.FC<VenueSearchSectionProps> = ({
  searchTerm,
  totalVenues,
  availableActivities,
  onSearchChange,
  viewMode,
  onViewModeChange,
  sortBy,
  onSortChange,
  onRefresh,
  isLoading,
  address,
  onAddressChange,
  onLocationSelect,
  isAddressLoading,
  addressError,
  selectedTags,
  onTagSelect,
  className
}) => {
  return (
    <div className="space-y-3">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4">
        <h2 className="text-lg font-semibold text-white flex-shrink-0 mb-2 sm:mb-0">
          Found {totalVenues} Member Club{totalVenues !== 1 ? 's' : ''}
        </h2>
        <div className="flex items-center gap-2 ml-auto">
          <VenueListControls
            viewMode={viewMode}
            onViewModeChange={onViewModeChange}
            sortBy={sortBy}
            onSortChange={onSortChange}
            isLoading={isLoading}
            address={address}
            onAddressChange={onAddressChange}
            onLocationSelect={onLocationSelect}
            isAddressLoading={isAddressLoading}
            addressError={addressError}
          />
        </div>
      </div>
      <div className="space-y-3">
        <SearchBar
          value={searchTerm}
          onChange={onSearchChange}
          placeholder="Search for clubs, activities, addresses..."
        />
        <ActivityTags
          selectedTags={selectedTags}
          availableActivities={availableActivities}
          onTagSelect={onTagSelect}
        />
      </div>
    </div>
  );
};