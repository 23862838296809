import { supabase } from '../supabase';
import { storage } from './storage';
import { postsCache } from '../cache';
import { validateVideoFile } from '../utils/video';
import { extractCityName } from '../utils/text';
import { compressImage } from '../utils/image';
import { CACHE_CONFIG } from '../cache';
import { Post } from '../../types/post';

// Performance monitoring
const PERFORMANCE_DEBUG = true;
const BATCH_SIZE = 10;

async function createPost(content: string, media?: { type: 'image' | 'video', file: File }): Promise<Post> {
  try {
    // Get current user
    const { data: { session }, error: userError } = await supabase.auth.getSession();
    if (userError || !session) throw new Error('Not authenticated');

    const user = session.user;

    // Fetch user profile
    const { data: userData, error: profileError } = await supabase
      .from('users')
      .select('*')
      .eq('auth_id', user.id)
      .single();

    if (profileError) throw profileError;
    if (!userData) throw new Error('User profile not found');

    let mediaUrl;
    if (media) {
      const { type, file } = media;
      let processedFile = file;
      const bucket = type === 'image' ? 'social-feed' : 'videos';

      try {
        if (type === 'image') {
          // Validate and process image
          if (file.size > 10 * 1024 * 1024) {
            throw new Error('Image size must be less than 10MB');
          }
          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
          if (!allowedTypes.includes(file.type)) {
            throw new Error('Only JPEG, PNG and GIF images are allowed');
          }
          processedFile = await compressImage(file, {
            maxWidth: 2048,
            maxHeight: 2048,
            quality: 0.8
          });
        } else {
          // Validate video file
          if (file.size > 50 * 1024 * 1024) {
            throw new Error('Video size must be less than 50MB');
          }
          const allowedTypes = ['video/mp4', 'video/quicktime', 'video/mov'];
          if (!allowedTypes.includes(file.type)) {
            throw new Error('Only MP4, MOV and QuickTime videos are allowed');
          }
        }

        // Generate unique filename
        const timestamp = Date.now();
        const extension = file.name.split('.').pop()?.toLowerCase() || 'jpg';
        const sanitizedName = file.name.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();
        const filePath = `user_directory/${user.id}/${timestamp}-${sanitizedName}.${extension}`;

        console.log('Uploading file:', {
          bucket,
          path: filePath,
          type: file.type,
          size: processedFile.size
        });

        const { data: uploadData, error: uploadError } = await supabase.storage
          .from(bucket)
          .upload(filePath, processedFile, {
            cacheControl: '3600',
            upsert: false,
            contentType: type === 'video' ? 'video/mp4' : file.type,
            duplex: 'half',
            owner: user.id
          });

        if (uploadError) throw uploadError;

        // Get public URL
        const { data: { publicUrl } } = supabase.storage
          .from(bucket)
          .getPublicUrl(filePath);

        mediaUrl = publicUrl;
      } catch (error) {
        console.error('Media upload error:', error);
        throw error;
      }
    }

    // Insert post
    const { data, error } = await supabase
      .from('posts')
      .insert({
        user_id: userData.id,
        content: content.trim(),
        [media?.type === 'video' ? 'video_url' : 'media_url']: mediaUrl,
        likes_count: 0,
        comments_count: 0,
        shares_count: 0
      })
      .select('*, users(auth_id, full_name, avatar_url, city)')
      .single();

    if (error) {
      // Cleanup uploaded media if post insertion fails
      if (mediaUrl) {
        try {
          const bucket = mediaUrl.includes('videos') ? 'videos' : 'social-feed';
          const filePath = `user_directory/${user.id}/${mediaUrl.split('/').slice(-2).join('/')}`;
          if (filePath) {
            await supabase.storage
              .from(bucket)
              .remove([filePath]);
          }
        } catch (deleteError) {
          console.error('Error cleaning up media:', deleteError);
        }
      }
      throw error;
    }

    // Update cache
    const newPost = {
      id: data.id,
      author: {
        id: data.users.auth_id,
        name: data.users.full_name,
        image: data.users.avatar_url ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        location: data.users.city
      },
      content: data.content,
      media: data.media_url,
      video: data.video_url,
      likes: data.likes_count,
      comments: data.comments_count,
      shares: data.shares_count,
      timeAgo: data.created_at // We store created_at here, but do not rely on it for pagination
    };

    postsCache.set(newPost.id, newPost, CACHE_CONFIG);

    return newPost;
  } catch (error) {
    console.error('Post creation error:', error);
    throw error;
  }
}

interface GetPostsResponse {
  posts: Post[];
  hasMore: boolean;
}

// UPDATED LAZY LOADING
async function getPosts(lastPostId?: string): Promise<GetPostsResponse> {
  const handleError = async (error: unknown, context: string) => {
    if (error instanceof Error && error.message === 'Failed to fetch') {
      return { posts: [], hasMore: false };
    }
    console.error('Error loading posts:', error);
    return { posts: [], hasMore: false };
  };

  try {
    // Get session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError) {
      return handleError(sessionError, 'Session error');
    }
    if (!session?.user) throw new Error('Not authenticated');

    // Get user's database record
    const { data: userData, error: userDataError } = await supabase
      .from('users')
      .select('id')
      .eq('auth_id', session.user.id)
      .single();

    if (userDataError) {
      return handleError(userDataError, 'User data error');
    }
    if (!userData) throw new Error('User profile not found');

    // Build query
    let query = supabase
      .from('posts')
      .select(`
        id,
        content,
        media_url,
        video_url,
        likes_count,
        comments_count,
        shares_count,
        created_at,
        users (
          auth_id,
          full_name,
          avatar_url,
          city
        )
      `)
      .order('created_at', { ascending: false })
      // Fetch one extra row to detect if there's more
      .limit(BATCH_SIZE + 1);

    // Cursor-based pagination
    if (lastPostId) {
      // Grab last post's created_at
      const { data: lastPost, error: lastPostError } = await supabase
        .from('posts')
        .select('created_at')
        .eq('id', lastPostId)
        .single();

      if (lastPostError) {
        return handleError(lastPostError, 'Failed to get last post timestamp');
      }
      if (lastPost?.created_at) {
        // Only fetch posts older (in time) than the last batch
        query = query.lt('created_at', lastPost.created_at);
      }
    }

    // Execute
    const { data, error } = await query;
    if (error) {
      return handleError(error, 'Database query error');
    }

    // If we got more than BATCH_SIZE, we know there's more
    const hasMore = (data || []).length > BATCH_SIZE;
    // Slice off the extra row so we only return exactly BATCH_SIZE
    const finalPosts = hasMore ? data.slice(0, BATCH_SIZE) : data;

    // Transform
    const transformedPosts = (finalPosts || []).map(post => ({
      id: post.id,
      author: {
        id: post.users.auth_id,
        name: post.users.full_name,
        image: post.users.avatar_url ||
          'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
        location: extractCityName(post.users.city)
      },
      content: post.content,
      media: post.media_url,
      video: post.video_url,
      likes: post.likes_count,
      comments: post.comments_count,
      shares: post.shares_count,
      // We'll store created_at in timeAgo for display, but rely on actual timestamps for sorting
      timeAgo: post.created_at
    }));

    return { posts: transformedPosts, hasMore };
  } catch (error) {
    return await handleError(error, 'Error in getPosts');
  }
}

export { getPosts, createPost };
