import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { Player } from '../../types/matchmaking';
import { DEFAULT_FILTERS } from '../../lib/utils/filters';
import { sortQuickPlayResults } from '../../lib/utils/sorting';
import { getUserLocation } from '../../lib/utils/location';
import { calculateLocationDistance } from '../../lib/utils/coordinates';
import { supabase } from '../../lib/supabase';
import { sportEmojis } from '../../lib/constants';
// NEW: Import connection store to determine connection state for the connect button
import { useConnectionStore } from '../../store/connectionStore';

// Lucy AI's IDs
const LUCY_AI_IDS = {
  auth_id: 'a515d5df-8a26-409f-98e3-ea5e70c223f1',
  user_id: 'a125a4ca-5ff3-45d6-b8b7-cfe3c015c600'
};

// Cache configuration
const CACHE_DURATION = 60 * 1000; // 1 minute cache
const BATCH_SIZE = 20;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const STALE_WHILE_REVALIDATE = true;

// Maximum retries for fetch operations
const NETWORK_ERRORS = new Set([
  'failed to fetch',
  'network request failed',
  'networkerror',
  'network timeout',
  'failed to fetch resource'
]);

// Memory cache
const playersCache = new Map<string, {
  data: Player[];
  timestamp: number;
  isStale: boolean;
}>();

// Cache management
function getCachedPlayers(cacheKey: string): Player[] | null {
  const cached = playersCache.get(cacheKey);
  if (!cached) return null;

  const now = Date.now();
  const age = now - cached.timestamp;

  if (age < CACHE_DURATION) {
    return cached.data;
  }

  if (STALE_WHILE_REVALIDATE && age < CACHE_DURATION * 2) {
    cached.isStale = true;
    return cached.data;
  }

  playersCache.delete(cacheKey);
  return null;
}

function setCachedPlayers(cacheKey: string, data: Player[]) {
  playersCache.set(cacheKey, {
    data,
    timestamp: Date.now(),
    isStale: false
  });
}

async function fetchWithRetry<T>(
  operation: () => Promise<T>,
  retries = MAX_RETRIES,
  attempt = 1
): Promise<T> {
  try {
    return await operation();
  } catch (error) {
    const isNetworkError = error instanceof Error &&
      NETWORK_ERRORS.has(error.message.toLowerCase());

    if (retries > 0 && isNetworkError) {
      const delay = RETRY_DELAY * Math.pow(2, attempt - 1);
      console.log(`Network error, retrying in ${delay}ms... (${retries} attempts remaining)`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return fetchWithRetry(operation, retries - 1, attempt + 1);
    }
    throw error;
  }
}

export function useQuickMatch() {
  const { connections } = useConnectionStore();
  const [searchState, setSearchState] = useState<'searching' | 'found' | 'request-sent' | 'not-found'>('searching');
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [potentialPlayers, setPotentialPlayers] = useState<Player[]>([]);
  const [allPlayers, setAllPlayers] = useState<Player[]>([]);
  const [sports, setSports] = useState<string[]>(['all']);
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);

  // NEW: Get connection information from the connection store.


  const [searchTerm, setSearchTerm] = useState(DEFAULT_FILTERS.searchTerm);
  const [selectedSport, setSelectedSport] = useState(DEFAULT_FILTERS.sport);
  const [selectedGender, setSelectedGender] = useState(DEFAULT_FILTERS.gender);
  const [selectedRating, setSelectedRating] = useState(DEFAULT_FILTERS.rating);
  const [showConnectionsOnly, setShowConnectionsOnly] = useState(DEFAULT_FILTERS.connectionsOnly);
  const cacheKeyRef = useRef<string>(`${Date.now()}`);
  const filtersRef = useRef({
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly
  });

  // Get user's location on mount
  useEffect(() => {
    let mounted = true;

    const getLocation = async () => {
      try {
        const location = await getUserLocation();
        if (mounted) {
          setUserLocation(location);
        }
      } catch (error) {
        console.warn('Failed to get user location:', error);
      }
    };

    getLocation();

    return () => {
      mounted = false;
    };
  }, []);

  // Cache for profile images
  const imageCache = useRef<Map<string, string>>(new Map());

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    console.log('Fetching users with filters:', {
      selectedGender,
      selectedSport,
      selectedRating,
      showConnectionsOnly
    });

    // Generate cache key based on filters
    const cacheKey = `${selectedSport}_${selectedGender}_${selectedRating}_${showConnectionsOnly}_${cacheKeyRef.current}`;

    // Check cache first
    const cached = getCachedPlayers(cacheKey);
    if (cached) {
      console.log('Using cached players:', {
        count: cached.length,
        genders: cached.map(p => p.gender)
      });
      setPotentialPlayers(cached);
      setAllPlayers(cached); 
      setSearchState('found');
      setIsLoading(false);
      return cached;
    }

    try {
      // Get current session
      const { data: { session }, error: sessionError } = await fetchWithRetry(() =>
        supabase.auth.getSession(), 3
      );

      if (sessionError) throw sessionError;
      if (!session?.user) {
        throw new Error('Please sign in to continue');
      }

      // Get current user
      const { data: currentUser, error: userError } = await fetchWithRetry(() =>
        supabase
          .from('users')
          .select(`
            id,
            auth_id,
            city,
            gender,
            user_profiles (preferences)
          `)
          .eq('auth_id', session.user.id)
          .single()
        , 3);

      if (userError || !currentUser) {
        throw new Error('Unable to load user profile');
      }

      // Optionally, the following block was previously used to fetch connected user IDs if "Show Connections Only" is enabled.
      // With the connection store now in use, you might remove this if desired.
      let connectedUserIds: string[] = [];
      if (showConnectionsOnly) {
        const { data: connectionsData, error: connectionsError } = await supabase
          .from('connections')
          .select('initiator_id, receiver_id')
          .eq('status', 'accepted')
          .or(`initiator_id.eq.${currentUser.id},receiver_id.eq.${currentUser.id}`);

        if (connectionsError) throw connectionsError;

        connectedUserIds = connectionsData.map(conn =>
          conn.initiator_id === currentUser.id ? conn.receiver_id : conn.initiator_id
        );
      }

      // Fetch users in batches with optimized query
      const users = [];
      let hasMore = true;
      let offset = 0;
      let retryCount = 0;
      const MAX_BATCH_RETRIES = 3;

      while (hasMore) {
        try {
          let userQuery = supabase
            .from('users')
            .select(`
              id,
              auth_id,
              full_name,
              avatar_url,
              city,
              skill_level,
              company,
              job_type,
              gender,
              facebook_url,    
              linkedin_url,    
              instagram_url,   
              user_profiles (preferences),
              user_sports (sports (name, emoji))
            `)
            .neq('id', currentUser.id) // Exclude current user
            .neq('auth_id', LUCY_AI_IDS.auth_id) // Exclude Lucy AI by auth_id
            .neq('id', LUCY_AI_IDS.user_id); // Also exclude by user_id for extra safety

          // If "Show Connections Only" is enabled, you can optionally filter via connectedUserIds
          if (showConnectionsOnly && connectedUserIds.length > 0) {
            userQuery = userQuery.in('id', connectedUserIds);
          }

          const { data, error } = await fetchWithRetry(() => userQuery, 3);

          if (error) {
            if (retryCount < MAX_BATCH_RETRIES) {
              retryCount++;
              await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
              continue;
            }
            throw new Error('Unable to load players');
          }

          if (!data || data.length === 0) {
            hasMore = false;
            break;
          }

          console.log('Fetched users batch:', {
            count: data.length,
            genders: data.map(u => u.gender)
          });

          users.push(...data);
          offset += BATCH_SIZE;
          retryCount = 0;

          if (data.length < BATCH_SIZE) {
            hasMore = false;
          }
        } catch (error) {
          if (retryCount < MAX_BATCH_RETRIES) {
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
            continue;
          }
          throw error;
        }
      }

      // Process users and collect sports
      const allSports = new Set<string>();
      const processedUsers = users.map(user => {
        // Skip Lucy AI if she somehow gets through the query filter
        if (user.auth_id === LUCY_AI_IDS.auth_id || user.id === LUCY_AI_IDS.user_id) return null;

        // Get formatted sports with emojis
        const formattedSports = user.user_sports?.map(us => 
          `${us.sports.emoji} ${us.sports.name}`
        ).filter(Boolean) || [];

        // Add each formatted sport to the set
        formattedSports.forEach(sport => allSports.add(sport));

        // Calculate distance using user location if available; otherwise use a city-based fallback
        let distance = -1;
        if (
          userLocation &&
          user.user_profiles?.preferences?.latitude &&
          user.user_profiles?.preferences?.longitude
        ) {
          try {
            distance = calculateLocationDistance(
              userLocation,
              {
                latitude: user.user_profiles.preferences.latitude,
                longitude: user.user_profiles.preferences.longitude
              }
            );
            if (isNaN(distance) || distance === null) distance = -1;
          } catch (e) {
            console.error(`Error calculating distance for ${user.full_name}:`, e);
          }
        } else {
          distance = calculateLocationDistance(
            currentUser.city || 'Unknown',
            user.city || 'Unknown'
          );
        }

        // NEW: Determine connection status using the connection store.
        // This finds if there is a connection for this user and sets flags accordingly.
        const connection = connections.find(conn => conn.other_user?.auth_id === user.auth_id);
        const isConnected = connection?.status === 'accepted';
        const isPending = connection?.status === 'pending';

        return {
          id: user.auth_id,
          name: user.full_name,
          gender: user.gender || 'rather_not_say',
          age: user.user_profiles?.preferences?.age || 0,
          location: user.city,
          sports: formattedSports,
          rating: user.user_profiles?.preferences?.rating || 4.5,
          matchPercentage: Math.floor(Math.random() * 15 + 85),
          availability: ['morning', 'afternoon', 'evening'],
          image:
            imageCache.current.get(user.auth_id) ||
            user.avatar_url ||
            'https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png',
          company: {
            name: user.company || 'Company',
            position: user.job_type || 'Role'
          },
          distance: Math.round(distance),
          isConnected, // Use the connection status from the store
          isPending,   // Also include pending connection status
          recentActivity: 'Recently active',
            
  // 🔹 Include social links
  facebookUrl: user.facebook_url || null,
  linkedinUrl: user.linkedin_url || null,
  instagramUrl: user.instagram_url || null
        };
      }).filter(Boolean); // Remove any null entries (Lucy AI)

      console.log('Processed users:', processedUsers);
      setPotentialPlayers(processedUsers);
      setAllPlayers(processedUsers);
      // Update sports list with all unique sports
      setSports(['all', ...Array.from(allSports)].sort());
      setSearchState('found');
      setError(null);
      return processedUsers;

    } catch (error) {
      console.error('Error fetching users:', error);
      setError({ message: 'Failed to fetch users. Please try again.' });
      return [];
    } finally {
      setIsLoading(false);
    }
  }, [userLocation]);

  // NEW: Client-side filtering
  const filteredPlayers = useMemo(() => {
    if (!allPlayers.length) return [];

    // Update filters ref to ensure we have latest values
    filtersRef.current = {
      searchTerm,
      selectedSport,
      selectedGender,
      selectedRating,
      showConnectionsOnly
    };

    return allPlayers.filter(player => {
      const searchTermLower = searchTerm.toLowerCase().trim();
      const matchesSearch =
        !searchTermLower ||
        player.name.toLowerCase().includes(searchTermLower) ||
        player.location.toLowerCase().includes(searchTermLower) ||
        player.sports.some(sport => sport.toLowerCase().includes(searchTermLower)) ||
        player.company.name.toLowerCase().includes(searchTermLower) ||
        player.company.position.toLowerCase().includes(searchTermLower);

      const matchesSport =
        selectedSport === 'all' ||
        player.sports.some(sport =>
          sport.toLowerCase().includes(selectedSport.toLowerCase())
        );

      const matchesGender = selectedGender === 'all' || player.gender === selectedGender;
      const matchesRating = selectedRating === 'all' || player.rating >= parseFloat(selectedRating);
      
      // Improved connections filter using the connection store data
      const matchesConnections = !showConnectionsOnly || 
        connections.some(conn => 
          conn.other_user?.auth_id === player.id && 
          conn.status === 'accepted'
        );

      return matchesSearch && matchesSport && matchesGender && matchesRating && matchesConnections;
    }).sort((a, b) => {
      if (a.distance === -1) return 1;
      if (b.distance === -1) return -1;
      return a.distance - b.distance;
    });
  }, [allPlayers, searchTerm, selectedSport, selectedGender, selectedRating, showConnectionsOnly]);

  const resetSearch = useCallback(() => {
    // Update cache key to force a new fetch
    cacheKeyRef.current = `${Date.now()}`;
    
    // Reset filters ref
    filtersRef.current = {
      searchTerm: DEFAULT_FILTERS.searchTerm,
      selectedSport: DEFAULT_FILTERS.sport,
      selectedGender: DEFAULT_FILTERS.gender,
      selectedRating: DEFAULT_FILTERS.rating,
      showConnectionsOnly: DEFAULT_FILTERS.connectionsOnly
    };

    // Clear caches
    playersCache.clear();

    // Reset all filters (image cache is preserved)
    setSearchTerm(DEFAULT_FILTERS.searchTerm);
    setSelectedSport(DEFAULT_FILTERS.sport);
    setSelectedGender(DEFAULT_FILTERS.gender);
    setSelectedRating(DEFAULT_FILTERS.rating);
    setShowConnectionsOnly(DEFAULT_FILTERS.connectionsOnly);
    setIsInitialSearch(true);
    setAllPlayers([]);
    setSearchState('searching');
    setError(null);

    // Trigger an immediate search
    setTimeout(() => {
      fetchUsers();
      setIsInitialSearch(false);
    }, 100);
  }, [fetchUsers]);

  const { fetchConnections } = useConnectionStore(); 

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      if (!mounted) return;

      try {
        setIsLoading(true);
        await fetchConnections();
        const players = await fetchUsers();
        if (mounted) {
          setPotentialPlayers(players);
          setIsInitialSearch(false);
          setSearchState('found');
        }
      } catch (error) {
        if (mounted) {
          setError(error instanceof Error ? { message: error.message } : { message: 'Failed to load players' });
          setSearchState('not-found');
        }
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    if (isInitialSearch) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [isInitialSearch]);

  useEffect(() => {
    return () => {
      setPotentialPlayers([]);
    };
  }, []);

  return {
    potentialPlayers: filteredPlayers,
    searchState,
    isInitialSearch,
    isLoading,
    error,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    resetSearch,
    fetchUsers
  };
}