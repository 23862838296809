import { useState, useCallback, useEffect, useRef } from 'react';
import { toggleLike, getPostLikesBatch } from '../../lib/supabase/social';

// Cache for like status to prevent repeated checks
const likeStatusCache = new Map<string, boolean>();

interface UsePostInteractionsProps {
  postId: string;
  initialLikes: number;
  initialShares: number;
  batchLikes?: Record<string, boolean>;
}

export function usePostInteractions({ postId, initialLikes, initialShares, batchLikes }: UsePostInteractionsProps) {
  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(initialLikes);
  const [sharesCount, setSharesCount] = useState(initialShares);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isTemporaryPost = postId.startsWith('temp-');

  // Check initial like status
  useEffect(() => {
    // Use batch likes if available
    if (batchLikes && batchLikes[postId] !== undefined) {
      setIsLiked(batchLikes[postId]);
    }
  }, [postId, batchLikes]);
  const handleLike = useCallback(async () => {
    if (isLoading) return;
    
    // For temporary posts, just show optimistic UI update
    if (isTemporaryPost) {
      setIsLiked(prev => !prev);
      setLikesCount(prev => prev + (isLiked ? -1 : 1));
      return;
    }

    // Optimistically update UI
    setIsLiked(prev => !prev);
    setLikesCount(prev => prev + (isLiked ? -1 : 1));

    setIsLoading(true);
    setError(null);

    try {
      const newIsLiked = await toggleLike(postId);
      // Only update if the request was successful
      if (newIsLiked !== isLiked) {
        setIsLiked(newIsLiked);
        setLikesCount(prev => newIsLiked ? prev + 1 : prev - 1);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
      // Only show error if it's not a connection issue
      if (!(error instanceof Error) || !error.message.includes('Failed to fetch')) {
        setError(error instanceof Error ? error.message : 'Failed to toggle like');
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLiked, likesCount, postId, isLoading]);

  const handleShare = useCallback(() => {
    setSharesCount(prev => prev + 1);
  }, []);

  return {
    isLiked,
    likesCount,
    sharesCount,
    isLoading,
    error,
    handleLike,
    handleShare
  };
}