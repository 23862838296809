import React, { useMemo } from 'react';
import { Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { ACTIVITY_EMOJIS } from '../../../lib/utils/emoji';
import { useTheme } from '../../../contexts/ThemeContext';

interface SportSelectionStepProps {
  selectedSport: string;
  onSportSelect: (emoji: string, name: string) => void;
  memberName: string;
  city: string;
  sports: string[]; // Dynamically received sports list
}

const SportSelectionStep: React.FC<SportSelectionStepProps> = ({
  selectedSport,
  onSportSelect,
  memberName,
  city,
  sports // Dynamic list of available sports
}) => {
  const { isDarkMode } = useTheme();
  const firstName = memberName?.split(' ')?.[0] || 'Player';

  // Add Walk & Talk as a default activity
  const walkAndTalk = {
    emoji: ACTIVITY_EMOJIS.walk[Math.random() < 0.5 ? 'male' : 'female'],
    name: 'Walk & Talk'
  };

  // Extract emoji and name correctly from the array
  const availableSports = useMemo(() => {
    const userSports = sports.map((sport) => {
      const [emoji, ...nameParts] = sport.split(' ');
      return {
        emoji,
        name: nameParts.join(' '),
      };
    });
    
    // Add Walk & Talk to available sports if not already present
    if (!userSports.some(sport => sport.name === 'Walk & Talk')) {
      userSports.push(walkAndTalk);
    }
    
    return userSports;
  }, [sports]);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className={cn(
          "text-xl font-semibold",
          isDarkMode ? "text-white" : "text-gray-900"
        )}>
          What would you like to play with {firstName} in {city}?
        </h3>
      </div>

      {/* Available Sports */}
      <div className="grid grid-cols-3 gap-3">
        {availableSports.map(({ emoji, name }) => {
          const isSelected = selectedSport === `${emoji} ${name}`;
          return (
            <motion.button
              key={name}
              onClick={() => onSportSelect(emoji, name)}
              className={cn(
                "flex flex-col items-center p-6 rounded-xl transition-all duration-300",
                "transform hover:scale-105",
                isSelected
                  ? "bg-gold-400 text-dark-50"
                  : isDarkMode
                    ? "bg-dark-300 text-white hover:bg-dark-400"
                    : "bg-gray-100 text-gray-900 hover:bg-gray-200"
              )}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="text-4xl mb-3">{emoji}</span>
              <span className="text-base font-medium">{name}</span>
            </motion.button>
          );
        })}
      </div>
    </div>
  );
};

export default SportSelectionStep;
