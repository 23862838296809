import React from 'react';
import { Heart, MessageCircle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface PostActionsProps {
  isLiked: boolean;
  likesCount: number;
  commentsCount: number;
  sharesCount: number;
  showComments: boolean;
  onLike: () => void;
  onComment: () => void;
  shareButton: React.ReactNode;
}

const PostActions: React.FC<PostActionsProps> = ({
  isLiked,
  likesCount,
  commentsCount,
  sharesCount,
  showComments,
  onLike,
  onComment,
  shareButton
}) => {
  return (
    <div className="flex items-center justify-between mt-6 pt-4 border-t border-dark-300">
      <motion.button
        onClick={onLike}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="relative flex items-center space-x-2 group overflow-visible"
      >
        <AnimatePresence mode="wait">
          {isLiked ? (
            // Liked state with animation
            <motion.div
              key="liked"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="relative overflow-visible"
            >
              <Heart className="h-6 w-6 text-red-500 fill-red-500" />
              
              {/* Burst particles */}
              {[...Array(8)].map((_, i) => (
                <motion.div
                  key={i}
                  initial={{ scale: 0, opacity: 1 }}
                  animate={{ 
                    scale: 2,
                    opacity: 0,
                    x: Math.cos(i * Math.PI / 4) * 10,
                    y: Math.sin(i * Math.PI / 4) * 10
                  }}
                  transition={{
                    duration: 0.6,
                    ease: "easeOut"
                  }}
                  className="absolute inset-0 flex items-center justify-center"
                >
                  <div 
                    className="w-1 h-1 rounded-full bg-red-500"
                    style={{
                      transform: `rotate(${i * 45}deg) translateY(-10px)`,
                    }}
                  />
                </motion.div>
              ))}
            </motion.div>
          ) : (
            // Unliked state
            <motion.div
              key="unliked"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
            >
              <Heart className="h-6 w-6 text-white group-hover:text-red-500/50 transition-colors" />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Like count */}
        <span 
          className={`text-sm transition-colors ${
            isLiked ? 'text-red-500' : 'text-dark-800'
          }`}
        >
          {likesCount}
        </span>
      </motion.button>
      <motion.button
        onClick={onComment}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="flex items-center space-x-2 text-dark-800 hover:text-gold-400 transition-colors"
      >
        <MessageCircle className={`h-6 w-6 ${showComments ? 'text-gold-400' : ''}`} />
        <span className="text-sm">{commentsCount}</span>
      </motion.button>

      {shareButton}
    </div>
  );
};

export default PostActions;

const handleLike = async () => {
  try {
    // Skip if post ID is not a valid UUID
    const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (!post.id || !UUID_REGEX.test(post.id)) {
      console.log('Invalid post ID:', post.id);
      return;
    }

    const result = await toggleLike(post.id);
    setIsLiked(result);
    setLikesCount(prev => result ? prev + 1 : prev - 1);
  } catch (error) {
    console.error('Error toggling like:', error);
  }
};