import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DashboardLayout from '../components/DashboardLayout';
import ProfileSection from '../components/profile/ProfileSection';

const Profile: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const urlTab = searchParams.get('tab');
  const locationState = location.state as { activeTab?: string };
  const initialTab = urlTab || locationState?.activeTab || 'posts';
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    // Update tab when either URL param or location state changes
    const newTab = urlTab || locationState?.activeTab;
    if (newTab) {
      setActiveTab(newTab);
      // Update URL if needed
      if (!urlTab) {
        const newParams = new URLSearchParams(searchParams);
        newParams.set('tab', newTab);
        navigate(`?${newParams.toString()}`, { replace: true });
      }
    }
  }, [urlTab, locationState?.activeTab]);

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <ProfileSection 
          defaultTab={activeTab}
          onTabChange={(tab: string) => {
            setActiveTab(tab);
            const newParams = new URLSearchParams(searchParams);
            newParams.set('tab', tab);
            navigate(`?${newParams.toString()}`, { replace: true });
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default Profile;
