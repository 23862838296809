// 📍 Earth's radius in kilometers
const EARTH_RADIUS_KM = 6371;

// ✅ Validate coordinates
function isValidCoordinate(lat: number, lon: number): boolean {
  return (
    typeof lat === 'number' &&
    typeof lon === 'number' &&
    !isNaN(lat) &&
    !isNaN(lon) &&
    Math.abs(lat) <= 90 &&
    Math.abs(lon) <= 180
  );
}

// 📏 Calculate Haversine Distance between two coordinates
export function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  if (!isValidCoordinate(lat1, lon1) || !isValidCoordinate(lat2, lon2)) {
    console.warn('Invalid coordinates:', { lat1, lon1, lat2, lon2 });
    return -1;
  }

  const toRadians = (angle: number) => angle * (Math.PI / 180);
  
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const lat1Rad = toRadians(lat1);
  const lat2Rad = toRadians(lat2);

  const a = 
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * 
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(EARTH_RADIUS_KM * c);
}

// 🏙️ City coordinates lookup
const cityCoordinates: Record<string, { lat: number; lng: number }> = {
 // 🇪🇺 Europe
  'London': { lat: 51.5074, lng: -0.1278 },
  'Paris': { lat: 48.8566, lng: 2.3522 },
  'Berlin': { lat: 52.5200, lng: 13.4050 },
  'Madrid': { lat: 40.4168, lng: -3.7038 },
  'Valencia': { lat: 39.4699, lng: -0.3763 },
  'Monte Carlo': { lat: 43.7402, lng: 7.4266 },
  'Nice': { lat: 43.7102, lng: 7.2620 },
  'Rome': { lat: 41.9028, lng: 12.4964 },
  'Vienna': { lat: 48.2082, lng: 16.3738 },
  'Athens': { lat: 37.9838, lng: 23.7275 },
  'Lisbon': { lat: 38.7223, lng: -9.1393 },
  'Oslo': { lat: 59.9139, lng: 10.7522 },
  'Stockholm': { lat: 59.3293, lng: 18.0686 },
  'Helsinki': { lat: 60.1699, lng: 24.9384 },
  'Copenhagen': { lat: 55.6761, lng: 12.5683 },
  'Brussels': { lat: 50.8503, lng: 4.3517 },
  'Zurich': { lat: 47.3769, lng: 8.5417 },
  'Geneva': { lat: 46.2044, lng: 6.1432 },
  'Amsterdam': { lat: 52.3676, lng: 4.9041 },
  'Barcelona': { lat: 41.3851, lng: 2.1734 },
  'Milan': { lat: 45.4642, lng: 9.1900 },
  'Frankfurt': { lat: 50.1109, lng: 8.6821 },
  'Dublin': { lat: 53.3498, lng: -6.2603 },
  'Warsaw': { lat: 52.2297, lng: 21.0122 },
  'Prague': { lat: 50.0755, lng: 14.4378 },
  'Budapest': { lat: 47.4979, lng: 19.0402 },
  'Riga': { lat: 56.9496, lng: 24.1052 },
  'Vilnius': { lat: 54.6872, lng: 25.2797 },
  'Tallinn': { lat: 59.4370, lng: 24.7536 },
  'Sofia': { lat: 42.6975, lng: 23.3242 },
  'Bucharest': { lat: 44.4268, lng: 26.1025 },
  'Belgrade': { lat: 44.7866, lng: 20.4489 },
  'Sarajevo': { lat: 43.8563, lng: 18.4131 },
  'Podgorica': { lat: 42.4304, lng: 19.2594 },
  'Ljubljana': { lat: 46.0511, lng: 14.5051 },
  'Bratislava': { lat: 48.1486, lng: 17.1077 },
  'Skopje': { lat: 41.9981, lng: 21.4254 },
  'Tirana': { lat: 41.3275, lng: 19.8189 },
  'Reykjavik': { lat: 64.1355, lng: -21.8954 },
  'Andorra la Vella': { lat: 42.5078, lng: 1.5211 },
  'San Marino': { lat: 43.9333, lng: 12.4500 },
  'Vaduz': { lat: 47.1416, lng: 9.5215 },
  'Gibraltar': { lat: 36.1408, lng: -5.3536 },
  'Porto': { lat: 41.1496, lng: -8.6109 },
  'Seville': { lat: 37.3891, lng: -5.9845 },
  'Granada': { lat: 37.1773, lng: -3.5986 },
  'Malaga': { lat: 36.7213, lng: -4.4214 },
  'Bilbao': { lat: 43.2630, lng: -2.9350 },
  'Lyon': { lat: 45.7640, lng: 4.8357 },
  'Marseille': { lat: 43.2965, lng: 5.3698 },
  'Bordeaux': { lat: 44.8378, lng: -0.5792 },
  'Strasbourg': { lat: 48.5734, lng: 7.7521 },
  'Cologne': { lat: 50.9375, lng: 6.9603 },
  'Düsseldorf': { lat: 51.2277, lng: 6.7735 },
  'Stuttgart': { lat: 48.7758, lng: 9.1829 },
  'Naples': { lat: 40.8518, lng: 14.2681 },
  'Palermo': { lat: 38.1157, lng: 13.3615 },
  'Turin': { lat: 45.0703, lng: 7.6869 },
  'Gothenburg': { lat: 57.7089, lng: 11.9746 },
  'Bergen': { lat: 60.3913, lng: 5.3221 },
  'Krakow': { lat: 50.0647, lng: 19.9450 },
  'Wroclaw': { lat: 51.1079, lng: 17.0385 },
  'Poznan': { lat: 52.4064, lng: 16.9252 },
  'Cluj-Napoca': { lat: 46.7712, lng: 23.6236 },
  'Split': { lat: 43.5081, lng: 16.4402 },
  'Dubrovnik': { lat: 42.6507, lng: 18.0944 },
  
  // 🇺🇸 United States (Major Cities)
  'New York': { lat: 40.7128, lng: -74.0060 },
  'Los Angeles': { lat: 34.0522, lng: -118.2437 },
  'Chicago': { lat: 41.8781, lng: -87.6298 },
  'Houston': { lat: 29.7604, lng: -95.3698 },
  'San Francisco': { lat: 37.7749, lng: -122.4194 },
  'Washington DC': { lat: 38.9072, lng: -77.0369 },
  'Seattle': { lat: 47.6062, lng: -122.3321 },
  'Miami': { lat: 25.7617, lng: -80.1918 },
  'Boston': { lat: 42.3601, lng: -71.0589 },
  'Dallas': { lat: 32.7767, lng: -96.7970 },
  'Atlanta': { lat: 33.7490, lng: -84.3880 },
  'Denver': { lat: 39.7392, lng: -104.9903 },
  'Las Vegas': { lat: 36.1699, lng: -115.1398 },
  'Phoenix': { lat: 33.4484, lng: -112.0740 },
  'San Diego': { lat: 32.7157, lng: -117.1611 },
  'Philadelphia': { lat: 39.9526, lng: -75.1652 },
  'Austin': { lat: 30.2672, lng: -97.7431 },
  'Charlotte': { lat: 35.2271, lng: -80.8431 },
  'San Antonio': { lat: 29.4241, lng: -98.4936 },
  'Orlando': { lat: 28.5383, lng: -81.3792 },
  'New Orleans': { lat: 29.9511, lng: -90.0715 },
  'Portland': { lat: 45.5051, lng: -122.6750 },
  'Minneapolis': { lat: 44.9778, lng: -93.2650 },
  'Nashville': { lat: 36.1627, lng: -86.7816 },
  'Indianapolis': { lat: 39.7684, lng: -86.1581 },
  'Kansas City': { lat: 39.0997, lng: -94.5786 },
  'Columbus': { lat: 39.9612, lng: -82.9988 },

  // 🇨🇦 Canada
  'Toronto': { lat: 43.7001, lng: -79.4163 },
  'Vancouver': { lat: 49.2827, lng: -123.1207 },
  'Montreal': { lat: 45.5017, lng: -73.5673 },
  'Ottawa': { lat: 45.4215, lng: -75.6972 },
  'Calgary': { lat: 51.0447, lng: -114.0719 },
  'Edmonton': { lat: 53.5461, lng: -113.4938 },
  'Winnipeg': { lat: 49.8951, lng: -97.1384 },
  'Quebec City': { lat: 46.8139, lng: -71.2082 },

  // 🇦🇺 Australia
  'Sydney': { lat: -33.8688, lng: 151.2093 },
  'Melbourne': { lat: -37.8136, lng: 144.9631 },
  'Brisbane': { lat: -27.4698, lng: 153.0251 },
  'Perth': { lat: -31.9505, lng: 115.8605 },
  'Adelaide': { lat: -34.9285, lng: 138.6007 },
  'Canberra': { lat: -35.2809, lng: 149.1300 },
  'Auckland': { lat: -36.8485, lng: 174.7633 },
  'Wellington': { lat: -41.2865, lng: 174.7762 },

  // 🇨🇳 China
  'Beijing': { lat: 39.9042, lng: 116.4074 },
  'Shanghai': { lat: 31.2304, lng: 121.4737 },
  'Hong Kong': { lat: 22.3193, lng: 114.1694 },
  'Guangzhou': { lat: 23.1291, lng: 113.2644 },

  // 🇮🇳 India
  'New Delhi': { lat: 28.6139, lng: 77.2090 },
  'Mumbai': { lat: 19.0760, lng: 72.8777 },
  'Bangalore': { lat: 12.9716, lng: 77.5946 },
  'Chennai': { lat: 13.0827, lng: 80.2707 },

  // 🇯🇵 Japan
  'Tokyo': { lat: 35.682839, lng: 139.759455 },
  'Osaka': { lat: 34.6937, lng: 135.5023 },

  // 🇧🇷 Brazil
  'São Paulo': { lat: -23.5505, lng: -46.6333 },
  'Rio de Janeiro': { lat: -22.9068, lng: -43.1729 },

  // 🇲🇽 Mexico
  'Mexico City': { lat: 19.4326, lng: -99.1332 },
  'Guadalajara': { lat: 20.6597, lng: -103.3496 },

  // 🇷🇺 Russia
  'Moscow': { lat: 55.7558, lng: 37.6173 },
  'Saint Petersburg': { lat: 59.9343, lng: 30.3351 },

  // 🇹🇷 Turkey
  'Istanbul': { lat: 41.0082, lng: 28.9784 },
  'Ankara': { lat: 39.9334, lng: 32.8597 },

  // 🇮🇱 Israel
  'Tel Aviv': { lat: 32.0853, lng: 34.7818 },
  'Jerusalem': { lat: 31.7683, lng: 35.2137 },

  // 🇸🇬 Singapore
  'Singapore': { lat: 1.3521, lng: 103.8198 },

  // 🇮🇩 Indonesia
  'Jakarta': { lat: -6.2088, lng: 106.8456 },

  // 🇹🇭 Thailand
  'Bangkok': { lat: 13.7563, lng: 100.5018 },

  // Other  
'Delhi': { lat: 28.7041, lng: 77.1025 },
'Karachi': { lat: 24.8607, lng: 67.0011 },
'Dhaka': { lat: 23.8103, lng: 90.4125 },
'Buenos Aires': { lat: -34.6037, lng: -58.3816 },
'Kolkata': { lat: 22.5726, lng: 88.3639 },
'Kinshasa': { lat: -4.4419, lng: 15.2663 },
'Lahore': { lat: 31.5497, lng: 74.3436 },
'Bogotá': { lat: 4.7110, lng: -74.0721 },
'Hyderabad': { lat: 17.3850, lng: 78.4867 },
'Tehran': { lat: 35.6892, lng: 51.3890 },
'Riyadh': { lat: 24.7136, lng: 46.6753 },
'Baghdad': { lat: 33.3152, lng: 44.3661 },
'Addis Ababa': { lat: 9.0300, lng: 38.7400 },
'Kiev': { lat: 50.4501, lng: 30.5234 },
'Munich': { lat: 48.1351, lng: 11.5820 },
'Zürich': { lat: 47.3769, lng: 8.5417 },
  'Asunción': { lat: -25.2637, lng: -57.5759 },
  'Asuncion': { lat: -25.2637, lng: -57.5759 },
  'Maria Anzbach': { lat: 48.1935, lng: 15.9304 },
  'Doha': { lat: 25.2854, lng: 51.5310 },
  'Kuwait City': { lat: 29.3759, lng: 47.9774 },
  'Muscat': { lat: 23.5859, lng: 58.4059 },
  'Beirut': { lat: 33.8938, lng: 35.5018 },
  'Johannesburg': { lat: -26.2041, lng: 28.0473 },
  'Cape Town': { lat: -33.9249, lng: 18.4241 },
  'Lagos': { lat: 6.5244, lng: 3.3792 },
  'Nairobi': { lat: -1.2864, lng: 36.8172 },
  'Cairo': { lat: 30.0444, lng: 31.2357 },
  'Casablanca': { lat: 33.5731, lng: -7.5898 },
  'Chengdu': { lat: 30.5728, lng: 104.0668 },
  'Macau': { lat: 22.1987, lng: 113.5439 },
  'Taipei': { lat: 25.0330, lng: 121.5654 },
  'Manila': { lat: 14.5995, lng: 120.9842 },
  'Kuala Lumpur': { lat: 3.1390, lng: 101.6869 },
  'Ho Chi Minh City': { lat: 10.7769, lng: 106.7009 },
  'Hanoi': { lat: 21.0285, lng: 105.8544 },
  'Seoul': { lat: 37.5665, lng: 126.9780 },
  'Busan': { lat: 35.1796, lng: 129.0756 },
  'Lima': { lat: -12.0464, lng: -77.0428 },
  'Santiago': { lat: -33.4489, lng: -70.6693 },
  'Bogota': { lat: 4.7110, lng: -74.0721 },
  'Quito': { lat: -0.1807, lng: -78.4678 },
  'La Paz': { lat: -16.5000, lng: -68.1500 },
  'Caracas': { lat: 10.4806, lng: -66.9036 },
  'Montevideo': { lat: -34.9011, lng: -56.1645 },
  'San Salvador': { lat: 13.6894, lng: -89.1872 },
  'Benicassim': { lat: 40.0575, lng: 0.0642 },
  'Benicasim': { lat: 40.0575, lng: 0.0642 },
  'Benicàssim': { lat: 40.0575, lng: 0.0642 },
  'Benicàssim / Benicasim': { lat: 40.0575, lng: 0.0642 },
  'Dubai': { lat: 25.2048, lng: 55.2708 },
  // Additional missing cities:
'San Jose': { lat: 37.3382, lng: -121.8863 },
'Detroit': { lat: 42.3314, lng: -83.0458 },
'Baltimore': { lat: 39.2904, lng: -76.6122 },
'Memphis': { lat: 35.1495, lng: -90.0490 },
'Sacramento': { lat: 38.5816, lng: -121.4944 },
'Pittsburgh': { lat: 40.4406, lng: -79.9959 },
'Hamilton': { lat: 43.2557, lng: -79.8711 },
'Manchester': { lat: 53.4808, lng: -2.2426 },
'Birmingham': { lat: 52.4862, lng: -1.8904 },
'Glasgow': { lat: 55.8642, lng: -4.2518 },
'Hamburg': { lat: 53.5511, lng: 9.9937 },
'Florence': { lat: 43.7696, lng: 11.2558 },
'Bologna': { lat: 44.4949, lng: 11.3426 },
'Rotterdam': { lat: 51.9244, lng: 4.4777 },
'The Hague': { lat: 52.0705, lng: 4.3007 },
'Minsk': { lat: 53.9006, lng: 27.5590 },
'Izmir': { lat: 38.4237, lng: 27.1428 },

'Islamabad': { lat: 33.6844, lng: 73.0479 },
'Pune': { lat: 18.5204, lng: 73.8567 },
'Ahmedabad': { lat: 23.0225, lng: 72.5714 },
'Yokohama': { lat: 35.4437, lng: 139.6380 },
'Incheon': { lat: 37.4563, lng: 126.7052 },
'Chongqing': { lat: 29.5630, lng: 106.5516 },
'Xi\'an': { lat: 34.3416, lng: 108.9398 },
'Amman': { lat: 31.9454, lng: 35.9284 },
'Surabaya': { lat: -7.2575, lng: 112.7521 },
'Brasília': { lat: -15.7939, lng: -47.8828 },
'Belo Horizonte': { lat: -19.9173, lng: -43.9346 },
'Monterrey': { lat: 25.6866, lng: -100.3161 },
'Medellín': { lat: 6.2442, lng: -75.5812 },
'Algiers': { lat: 36.7538, lng: 3.0588 },
'Alexandria': { lat: 31.2001, lng: 29.9187 },
'Accra': { lat: 5.6037, lng: -0.1870 },
'Kampala': { lat: 0.3476, lng: 32.5825 },
'Khartoum': { lat: 15.5007, lng: 32.5599 },
'Christchurch': { lat: -43.5321, lng: 172.6362 },
'Edinburgh': { lat: 55.9533, lng: -3.1883 },
'Belfast': { lat: 54.5973, lng: -5.9301 },
'Cardiff': { lat: 51.4816, lng: -3.1791 },
'Jacksonville': { lat: 30.3322, lng: -81.6557 },
'Fort Worth': { lat: 32.7555, lng: -97.3308 },
'El Paso': { lat: 31.7619, lng: -106.4850 },
'Oklahoma City': { lat: 35.4676, lng: -97.5164 },
'Louisville': { lat: 38.2527, lng: -85.7585 },
'Milwaukee': { lat: 43.0389, lng: -87.9065 },
'Omaha': { lat: 41.2565, lng: -95.9345 },
'Raleigh': { lat: 35.7796, lng: -78.6382 },
'Zagreb': { lat: 45.8150, lng: 15.9785 },
'Valletta': { lat: 35.8989, lng: 14.5146 },
'Pristina': { lat: 42.6629, lng: 21.1655 },
'Tbilisi': { lat: 41.7151, lng: 44.8271 },
'Baku': { lat: 40.4093, lng: 49.8671 },
'Nicosia': { lat: 35.1856, lng: 33.3823 },
'Tampa': { lat: 27.9506, lng: -82.4572 },
'Salt Lake City': { lat: 40.7608, lng: -111.8910 },
'Honolulu': { lat: 21.3069, lng: -157.8583 },
'Manama': { lat: 26.2235, lng: 50.5876 },
'Sana\'a': { lat: 15.3547, lng: 44.2064 },
'Damascus': { lat: 33.5138, lng: 36.2765 },
'Haifa': { lat: 32.7940, lng: 34.9896 },
'Beersheba': { lat: 31.2520, lng: 34.7913 },
'Beer sheva': { lat: 31.2520, lng: 34.7913 },
'Eilat': { lat: 29.5563, lng: 34.9482 },
'Graz': { lat: 47.0707, lng: 15.4395 },
'Linz': { lat: 48.3069, lng: 14.2858 },
'Salzburg': { lat: 47.8095, lng: 13.0550 },
'Innsbruck': { lat: 47.2692, lng: 11.4041 },
'Ciudad del Este': { lat: -25.5061, lng: -54.6111 },
'Encarnación': { lat: -27.3300, lng: -55.8667 },
'Encarnacion': { lat: -27.3300, lng: -55.8667 },
'Zaragoza': { lat: 41.6488, lng: -0.8891 },
'Alicante': { lat: 38.3452, lng: -0.4810 },
'Vigo': { lat: 42.2406, lng: -8.7207 },
'Venice': { lat: 45.4408, lng: 12.3155 },
'Genoa': { lat: 44.4056, lng: 8.9463 },
'Liverpool': { lat: 53.4084, lng: -2.9916 },
'Cleveland': { lat: 41.4993, lng: -81.6944 },
'Shenzhen': { lat: 22.5431, lng: 114.0579 },
'Hangzhou': { lat: 30.2741, lng: 120.1551 },
'Wuhan': { lat: 30.5928, lng: 114.3055 },
'Rosario': { lat: -32.9442, lng: -60.6505 },
'Salvador': { lat: -12.9777, lng: -38.5016 },
'Novosibirsk': { lat: 55.0084, lng: 82.9357 },
'Dammam': { lat: 26.4207, lng: 50.0888 },
'Pamplona': { lat: 42.8125, lng: -1.6458 },
'Toulouse': { lat: 43.6047, lng: 1.4442 },
'Nantes': { lat: 47.2184, lng: -1.5536 },
'Leipzig': { lat: 51.3397, lng: 12.3731 },
'Catania': { lat: 37.5079, lng: 15.0830 },
'Abuja': { lat: 9.0579, lng: 7.4951 },
'Johor Bahru': { lat: 1.4927, lng: 103.7414 },
'Daejeon': { lat: 36.3504, lng: 127.3845 },
'Kaohsiung': { lat: 22.6273, lng: 120.3014 },
'Leeds': { lat: 53.8008, lng: -1.5491 },
'Sheffield': { lat: 53.3811, lng: -1.4701 },
'Newcastle': { lat: 54.9783, lng: -1.6178 },
'Bristol': { lat: 51.4545, lng: -2.5879 },
'Dakar': { lat: 14.7167, lng: -17.4677 },
'Tunis': { lat: 36.8065, lng: 10.1815 },
'Luanda': { lat: -8.8390, lng: 13.2894 },
'Durban': { lat: -29.8587, lng: 31.0218 },
'Yerevan': { lat: 40.1792, lng: 44.4991 },
'Nur-Sultan': { lat: 51.1694, lng: 71.4491 },
'Almaty': { lat: 43.238949, lng: 76.889709 },
'Colombo': { lat: 6.9271, lng: 79.8612 },
'Kathmandu': { lat: 27.7172, lng: 85.3240 },
'Ulaanbaatar': { lat: 47.8864, lng: 106.9057 },
'Abu Dhabi': { lat: 24.4539, lng: 54.3773 },
'Isfahan': { lat: 32.6546, lng: 51.6680 },
'Brighton': { lat: 50.8225, lng: -0.1372 },
'Bern': { lat: 46.9480, lng: 7.4474 },
'Nuremberg': { lat: 49.4521, lng: 11.0767 },
'Dresden': { lat: 51.0504, lng: 13.7373 },
'Malmö': { lat: 55.6050, lng: 13.0038 },
'Malmo': { lat: 55.6050, lng: 13.0038 },
'Lille': { lat: 50.6292, lng: 3.0573 },
'Montpellier': { lat: 43.6119, lng: 3.8772 },
'Verona': { lat: 45.4384, lng: 10.9916 },
'Chisinau': { lat: 47.0105, lng: 28.8638 },
'San Juan': { lat: 18.4655, lng: -66.1057 },
'Valparaíso': { lat: -33.0472, lng: -71.6127 },
'Amalfi': { lat: 40.6342, lng: 14.6029 },
'Amalfi Coast': { lat: 40.6342, lng: 14.6029 },
'Klosterneuburg': { lat: 48.3052, lng: 16.3256 },
'Bazra': { lat: 32.2141, lng: 34.8768 },
'Luxembourg': { lat: 49.6116, lng: 6.1319 }
};

// 📍 Get city coordinates dynamically with **Normalized City Names**
export function getCityCoordinates(city: string): { lat: number; lng: number } | null {
  if (!city) return null;
  
  // Extract only the **text before the first comma**
  const normalizedCity = city.split(',')[0].trim();

  return cityCoordinates[normalizedCity] || null;
}

// 🌍 Calculate distance between two locations (city names or coordinates)
export function calculateLocationDistance(
  location1: { latitude: number; longitude: number } | string,
  location2: { latitude: number; longitude: number } | string
): number {
  try {
    if (!location1 || !location2) {
      console.warn('Missing location data:', { location1, location2 });
      return -1;
    }

    let coords1, coords2;

    if (typeof location1 === 'string') {
      coords1 = getCityCoordinates(location1);
      if (!coords1) {
        console.warn(`City not found: ${location1}`);
        return -1;
      }
    } else {
      coords1 = { lat: location1.latitude, lng: location1.longitude };
    }

    if (typeof location2 === 'string') {
      coords2 = getCityCoordinates(location2);
      if (!coords2) {
        console.warn(`City not found: ${location2}`);
        return -1;
      }
    } else {
      coords2 = { lat: location2.latitude, lng: location2.longitude };
    }

    return calculateDistance(coords1.lat, coords1.lng, coords2.lat, coords2.lng);
  } catch (error) {
    console.warn('Error calculating distance:', error);
    return -1;
  }
}

// Calculate distance between two cities
export function calculateCityDistance(city1: string, city2: string): number {
  // Early return if cities are the same
  if (city1.toLowerCase() === city2.toLowerCase()) {
    return 0;
  }

  // Normalize city names for comparison
  const normalizeCity = (city: string) => {
    // Take only the part before the first comma and trim
    const cityName = city.split(',')[0].trim().toLowerCase();
    // Handle common variations
    if (cityName === 'tel aviv-yafo') return 'tel aviv';
    return cityName;
  };

  const city1Normalized = normalizeCity(city1);
  const city2Normalized = normalizeCity(city2);

  // Find coordinates, trying both normalized and original names
  const coords1 = cityCoordinates[city1Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city1Normalized)];
  const coords2 = cityCoordinates[city2Normalized] || cityCoordinates[Object.keys(cityCoordinates).find(key => normalizeCity(key) === city2Normalized)];

  if (!coords1 || !coords2) {
    console.warn(`Could not find coordinates for cities: ${city1Normalized} (${!!coords1}) and/or ${city2Normalized} (${!!coords2})`);
    return -1; // Return -1 to indicate distance calculation was not possible
  }

  // Calculate distance using Haversine formula
  const distance = calculateDistance(
    coords1.lat,
    coords1.lng,
    coords2.lat,
    coords2.lng
  );

  // Log distance calculation for debugging
  console.log(`Distance from ${city1} to ${city2}: ${distance}km`);
  
  return distance;
}

// 🎯 Example Usage:
console.log(calculateLocationDistance('Paris', 'London')); // ✅ Expected: ~344 km
console.log(calculateLocationDistance('New York, USA', 'Los Angeles, CA, USA')); // ❌ Expected: -1 (City not found)
console.log(calculateLocationDistance({ latitude: 48.8566, longitude: 2.3522 }, 'Berlin')); // ✅ Expected: ~878 km
console.log(calculateLocationDistance({ latitude: 48.8566, longitude: 2.3522 }, { latitude: 51.5074, longitude: -0.1278 })); // ✅ Expected: ~344 km