import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { loadGoogleMaps } from '@/lib/utils/maps';
import { VenueApiResult } from '../../../types/venue';
import VenueMarker from './VenueMarker';

interface SimpleMapProps {
  venues?: VenueApiResult[];
  className?: string;
}

const SimpleMap: React.FC<SimpleMapProps> = ({ venues = [], className }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const overlayRef = useRef<google.maps.OverlayView | null>(null);
  const userInteractedRef = useRef(false);
  const [error, setError] = useState<string | null>(null);
  const [mapReady, setMapReady] = useState(false);

  // Initialize the map and add the dark overlay.
  useEffect(() => {
    let mounted = true;

    const initMap = async () => {
      if (!mapRef.current) return;
      try {
        await loadGoogleMaps();
        if (!mounted) return;

        // Set center to first venue if available; otherwise default.
        const center = venues.length
          ? { lat: venues[0].coordinates.lat, lng: venues[0].coordinates.lng }
          : { lat: 0, lng: 0 };

        const map = new google.maps.Map(mapRef.current, {
          center,
          zoom: 13,
          gestureHandling: 'greedy',
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false,
        });

        // Create a custom overlay that covers the entire map with a translucent dark layer.
        class DarkOverlay extends google.maps.OverlayView {
          div: HTMLDivElement | null = null;
          onAdd() {
            this.div = document.createElement('div');
            // Adjust the RGBA below for your desired darkness and transparency.
            this.div.style.backgroundColor = 'rgba(0, 0, 0, 0.2)';
            this.div.style.position = 'absolute';
            this.div.style.top = '0';
            this.div.style.left = '0';
            this.div.style.width = '100%';
            this.div.style.height = '100%';
            // Allow map interactions to pass through.
            this.div.style.pointerEvents = 'none';
            const panes = this.getPanes();
            if (panes && panes.overlayLayer) {
              panes.overlayLayer.appendChild(this.div);
            }
          }
          draw() {
            if (!this.div) return;
            const projection = this.getProjection();
            const bounds = this.getMap().getBounds();
            if (!bounds) return;
            const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
            const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());
            this.div.style.left = sw.x + 'px';
            this.div.style.top = ne.y + 'px';
            this.div.style.width = (ne.x - sw.x) + 'px';
            this.div.style.height = (sw.y - ne.y) + 'px';
          }
          onRemove() {
            if (this.div && this.div.parentNode) {
              this.div.parentNode.removeChild(this.div);
              this.div = null;
            }
          }
        }
        overlayRef.current = new DarkOverlay();
        overlayRef.current.setMap(map);

        // Add listeners to track user interaction.
        map.addListener('dragstart', () => {
          userInteractedRef.current = true;
        });
        map.addListener('zoom_changed', () => {
          userInteractedRef.current = true;
        });
        // Global listener to close all info windows on map click.
        map.addListener('click', () => {
          google.maps.event.trigger(map, 'closeAllInfoWindows');
        });

        mapInstanceRef.current = map;
        setMapReady(true);
      } catch (err) {
        console.error('Map init error:', err);
        setError('Failed to load map');
      }
    };

    initMap();

    return () => {
      mounted = false;
      if (mapInstanceRef.current) {
        google.maps.event.clearInstanceListeners(mapInstanceRef.current);
        mapInstanceRef.current = null;
      }
      if (overlayRef.current) {
        overlayRef.current.setMap(null);
      }
    };
  }, []);

  // Update map bounds only when venues change and if user hasn't interacted.
  useEffect(() => {
    if (mapInstanceRef.current && venues.length > 0 && !userInteractedRef.current) {
      const bounds = new google.maps.LatLngBounds();
      venues.forEach((venue) => {
        if (venue.coordinates?.lat && venue.coordinates?.lng) {
          bounds.extend(venue.coordinates);
        }
      });
      const padding = window.innerWidth >= 1024 ? 80 : 40;
      mapInstanceRef.current.fitBounds(bounds, { padding });
      google.maps.event.addListenerOnce(mapInstanceRef.current, 'bounds_changed', () => {
        const zoom = mapInstanceRef.current!.getZoom();
        if (zoom && zoom > 17) {
          mapInstanceRef.current!.setZoom(17);
        }
      });
    }
  }, [venues]);

  if (error) {
    return (
      <div className="bg-dark-300 rounded-xl p-6 text-center">
        <h3 className="text-lg font-medium text-white mb-2">Failed to Load Map</h3>
        <p className="text-dark-800">{error}</p>
      </div>
    );
  }

  return (
    <div
      ref={mapRef}
      className={cn('w-full h-full rounded-xl', className)}
      style={{ touchAction: 'none' }} // ensures touch events are handled by the map
    >
      {mapReady &&
        venues.map((venue) => (
          <VenueMarker key={venue.id} venue={venue} map={mapInstanceRef.current!} theme="dark" />
        ))}
    </div>
  );
};

export default SimpleMap;
