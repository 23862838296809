// src/pages/QuickPlay.tsx
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Users } from 'lucide-react';
import DashboardLayout from '../components/DashboardLayout';
import { useQuickMatch } from '../hooks/matchmaking/useQuickMatch';
import QuickPlayFilters from '../components/matchmaking/filters/QuickPlayFilters';
import PlayerResults from '../components/matchmaking/modals/PlayerResults';
import QuickPlayWelcome from '../components/matchmaking/QuickPlayWelcome';
import { createActivity } from '../lib/supabase/activities';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { Player } from '../types/matchmaking';

export default function QuickPlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    const checkWidth = () => setShowFilters(window.innerWidth >= 640);
    checkWidth();
    window.addEventListener("resize", checkWidth);
    return () => window.removeEventListener("resize", checkWidth);
  }, []);

  const [pageKey, setPageKey] = useState(0);
  const searchKeyRef = React.useRef<number | undefined>(undefined);
  const isInitialMount = React.useRef(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const { state } = location;
  const [isSearching, setIsSearching] = useState(false);

  // 🧠 Patch: Simulate navigation state on first mount to fix layout
  useEffect(() => {
    if (!state?.fromNav) {
      navigate("/dashboard/quick-play", { replace: true, state: { fromNav: true } });
    }
  }, [state, navigate]);

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('quickplay_welcomed');
    setShowWelcome(!hasSeenWelcome);
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
    document.body.style.zoom = '1';
    document.body.style.overflow = 'auto';
    document.body.style.height = 'auto';
    document.documentElement.style.height = 'auto';
    setPageKey(prev => prev + 1);
  }, []);

  const {
    potentialPlayers,
    searchState,
    isInitialSearch,
    isLoading,
    error,
    searchTerm,
    selectedSport,
    selectedGender,
    selectedRating,
    showConnectionsOnly,
    sports,
    setSearchTerm,
    setSelectedSport,
    setSelectedGender,
    setSelectedRating,
    setShowConnectionsOnly,
    filterPlayers,
    resetSearch
  } = useQuickMatch();

  const handleSearchAgain = React.useCallback(() => {
    resetSearch();
  }, [resetSearch]);

  const handleMatchSelect = React.useCallback(async (player: Player) => {
    try {
      // Add match creation logic here
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (state?.searchKey !== searchKeyRef.current || state?.preSelectedSport) {
      searchKeyRef.current = state?.searchKey;
      setShowFilters(false);
      resetSearch();

      if (state?.preSelectedSport) {
        setSelectedSport(state.preSelectedSport);
      }
    }
  }, [state?.searchKey, state?.preSelectedSport, resetSearch, setSelectedSport]);

  return (
    <DashboardLayout key={pageKey}>
      <div className="space-y-6 min-h-[calc(100vh-9rem)]">
        {showWelcome && <QuickPlayWelcome />}

        {!showWelcome && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="relative space-y-4"
          >
            <div className={cn(
              "bg-dark-200 rounded-xl border border-dark-300 overflow-hidden transition-all duration-300",
              isSearching ? "p-3" : "p-4"
            )}>
              <div className={cn(
                "flex items-start transition-all duration-300",
                isSearching ? "mb-2" : "mb-3"
              )}>
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  className={cn(
                    "bg-gold-400/10 rounded-full flex items-center justify-center",
                    isSearching ? "w-8 h-8 mr-2" : "w-10 h-10 mr-3"
                  )}
                >
                  <Users className={cn("text-gold-400", isSearching ? "h-4 w-4" : "h-5 w-5")} />
                </motion.div>
                <div>
                  <h1 className={cn("font-bold text-white transition-all duration-300", isSearching ? "text-base" : "text-lg")}>
                    Connect & Play
                  </h1>
                  {!isSearching && (
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="text-dark-800 text-xs">
                      Find club members in town to play with
                    </motion.p>
                  )}
                </div>
              </div>

              <div className="max-w-lg mx-auto">
                <QuickPlayFilters
                  searchTerm={searchTerm}
                  selectedSport={selectedSport}
                  selectedGender={selectedGender}
                  selectedRating={selectedRating}
                  showConnectionsOnly={showConnectionsOnly}
                  onSearchChange={(value) => {
                    setSearchTerm(value);
                    setIsSearching(!!value);
                  }}
                  onSportChange={setSelectedSport}
                  onGenderChange={setSelectedGender}
                  onRatingChange={setSelectedRating}
                  onConnectionsOnlyChange={setShowConnectionsOnly}
                  sports={sports}
                  showFilters={showFilters}
                  onToggleFilters={() => setShowFilters(!showFilters)}
                  onSearchAgain={handleSearchAgain}
                  isSearching={isSearching}
                />
              </div>
            </div>
          </motion.div>
        )}

        {isLoading && (
          <div className="flex items-center justify-center min-h-[calc(100vh-24rem)] px-4">
            <motion.div
              className="w-full max-w-md mx-auto text-center relative overflow-hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <div className="relative mx-auto" style={{ aspectRatio: '1/1', maxWidth: '24rem' }}>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10" style={{ animation: 'wave-out 3s ease-out infinite' }} />
                  <div className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10" style={{ animation: 'wave-out 3s ease-out infinite 1s' }} />
                  <div className="absolute w-1/2 h-1/2 rounded-full bg-gold-400/10" style={{ animation: 'wave-out 3s ease-out infinite 2s' }} />
                  <div className="absolute w-full h-full">
                    <div
                      className="absolute inset-0 bg-gradient-to-t from-gold-400/40 via-gold-400/20 to-transparent origin-center"
                      style={{
                        clipPath: 'polygon(50% 50%, 65% 0, 35% 0)',
                        animation: 'spin 2s linear infinite',
                        animationPlayState: 'running'
                      }}
                    />
                  </div>
                </div>
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-1/4 h-1/4 relative">
                    <img
                      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/gold_symbol-removebg-n5agUlyNamyXXN0FU8QGzScjug8DVS.png"
                      alt="Playclub Symbol"
                      className="w-full h-full object-contain"
                      style={{ animation: 'spin 2s linear infinite', animationPlayState: 'running' }}
                    />
                  </div>
                </div>
              </div>
              <h3 className="text-lg font-medium text-white mt-1">Finding Players Near You</h3>
              <div className="flex justify-center items-center mt-4 space-x-2">
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse"></span>
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse delay-150"></span>
                <span className="h-2 w-2 rounded-full bg-yellow-500 animate-pulse delay-300"></span>
              </div>
            </motion.div>
          </div>
        )}

        {!isLoading && <PlayerResults players={potentialPlayers} />}
      </div>
    </DashboardLayout>
  );
}
