import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { City } from '@/types/city';
import { searchCities } from '@/lib/services/geocoding';
import { extractCityName } from '@/lib/utils/text';
import { cn } from '@/lib/utils';
import { Check } from 'lucide-react';
import { useDebounce } from 'use-debounce';
import { ComboboxInput } from './ComboboxInput';
import { ComboboxList } from './ComboboxList';
import { ComboboxItem } from './ComboboxItem';

interface CitySearchComboboxProps {
  value: string;
  onChange: (city: string) => void;
  placeholder?: string;
  className?: string;
  error?: string;
  country?: string;
}

export const CitySearchCombobox: React.FC<CitySearchComboboxProps> = ({
  value,
  onChange,
  placeholder = "Enter city...",
  className,
  error,
  country
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [suggestions, setSuggestions] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);
  const [selectedFromDropdown, setSelectedFromDropdown] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);

  const handleSearch = useCallback(async (query: string) => {
    if (!query.trim() || query.length < 2) {
      setSuggestions([]);
      setSearchError(null);
      return;
    }

    setIsLoading(true);
    setSearchError(null);

    try {
      const results = await searchCities(query);
      // Filter to only include cities and transform results
      let filteredResults = results
        .filter(city => city.name && !city.name.includes(',')) // Remove entries with commas
        .map(city => ({
          ...city,
          name: extractCityName(city.name) // Clean up city name
        }))
        .filter((city, index, self) => // Remove duplicates
          index === self.findIndex(c => c.name.toLowerCase() === city.name.toLowerCase())
        );

      // Apply country filter if specified
      if (country) {
        filteredResults = filteredResults.filter(city => 
          city.country?.toLowerCase() === country.toLowerCase()
        );
      }
      
      setSuggestions(filteredResults);
      if (filteredResults.length === 0) {
        setSearchError('No cities found matching your search');
        setSelectedFromDropdown(false);
      }
    } catch (error) {
      console.error('Error searching cities:', error);
      setSearchError('Unable to search cities at this time');
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, [country]);

  useEffect(() => {
    handleSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    setSelectedCity(null); // Clear selected city when input changes
    setIsOpen(true);
    setSelectedFromDropdown(false); // Reset validation when user types
    setSearchError(null);
  };

  const handleSuggestionClick = (city: City) => {
    // Only use the city name without state/country
    const cityName = extractCityName(city.name);
    setSelectedCity(city);
    setSearchTerm(cityName);
    onChange(cityName);
    setIsOpen(false);
    setSearchError(null);
    setSelectedFromDropdown(true);
  };

  // Reset search term to selected city name if user types and then blurs without selecting
  const handleBlur = () => {
    setTimeout(() => {
      if (selectedCity) {
        setSearchTerm(extractCityName(selectedCity.name));
      }
      setIsOpen(false);
    }, 200);
  };

  return (
    <div ref={containerRef} className={cn("relative", className)}>
      <ComboboxInput
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={() => setIsOpen(true)}
        onBlur={handleBlur}
        placeholder={placeholder}
        leftIcon={<MapPin className="h-5 w-5 text-gold-400" />}
        rightIcon={isLoading ? <Loader2 className="h-4 w-4 text-gold-400 animate-spin" /> : 
                  selectedFromDropdown && <Check className="h-4 w-4 text-green-500" />}
        error={error || searchError}
        className="h-9"
      />

      {!selectedFromDropdown && searchTerm && !error && !searchError && (
        <p className="text-yellow-500 text-sm mt-1">
          Please select a city from the suggestions below:
        </p>
      )}

      {isOpen && suggestions.length > 0 && (
        <ComboboxList>
          {suggestions.map((city, index) => (
            <ComboboxItem
              key={`${city.name}-${index}`}
              onClick={() => handleSuggestionClick(city)}
              selected={selectedCity?.name === city.name}
              className={cn(
                "hover:bg-dark-300/80 transition-colors duration-200",
                selectedCity?.name === city.name && "bg-dark-300"
              )}
            >
              <MapPin className={cn(
                "h-4 w-4 mr-2 transition-colors",
                selectedCity?.name === city.name ? "text-gold-400" : "text-dark-800"
              )} />
              <div>
                <div className="text-white">{extractCityName(city.name)}</div>
                {city.country && (
                  <div className="text-dark-800 text-xs">
                    {city.country}
                  </div>
                )}
              </div>
            </ComboboxItem>
          ))}
        </ComboboxList>
      )}
    </div>
  );
};