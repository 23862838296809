import { create } from 'zustand';
import { search } from '@/lib/supabase/search';
import { SearchResults } from '@/types/search';

interface SearchState {
  query: string;
  results: SearchResults;
  isLoading: boolean;
  hasSearched: boolean;
  setQuery: (query: string) => void;
  executeSearch: (query: string) => Promise<void>;
  clearResults: () => void;
}

const initialResults: SearchResults = {
  players: [],
  posts: [],
  clubs: [],
};

export const useSearchStore = create<SearchState>((set) => ({
  query: '',
  results: initialResults,
  isLoading: false,
  hasSearched: false,

  setQuery: (query) => {
    set({ query });
  },

  clearResults: () => {
    set({ query: '', results: initialResults, hasSearched: false });
  },

  executeSearch: async (query) => {
    if (!query.trim()) {
      set({ results: initialResults, hasSearched: false, isLoading: false });
      return;
    }

    set({ isLoading: true, hasSearched: true });
    try {
      const data = await search(query);
      set({
        results: {
          players: data.players || [],
          posts: data.posts || [],
          clubs: data.clubs || [],
        },
        isLoading: false,
      });
    } catch (error) {
      console.error('❌ Search error:', error);
      set({ results: initialResults, isLoading: false });
    }
  },
}));
