import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createActivity } from '../lib/supabase/activities';
import { Activity } from '../types/activity';
//import { addNotification } from '@/lib/supabase/notifications';
import { supabase } from '@/lib/supabase';

export function useActivityCreation() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleCreateActivity = async (activity: Omit<Activity, 'id'>) => {
    setIsSubmitting(true);
    setIsSuccess(false);
    try {
      // Get current user's name
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) throw new Error('Not authenticated');

      /* Get user's full name from users table
      const { data: userData, error: userDataError } = await supabase
        .from('users')
        .select('full_name')
        .eq('auth_id', user.id)
        .single();

      if (userDataError) throw userDataError;
      if (!userData) throw new Error('User not found');*/

      await createActivity(activity);
      
      /* Moved to backend
      addNotification({
        type: 'match_request',
        content: `${userData.full_name} requested to play ${activity.sport} with you.`,
        user_id: activity.opponent.id,
        metadata: {
          activeTab: 'activities'
        }
      });*/

      setIsSuccess(true);
    } catch (error) {
      console.error('Failed to create activity:', error);
      
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    isSuccess,
    handleCreateActivity
  };
}